import React, {  useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '../../../hooks/useBreakpoints';

import moment from 'moment'

const SearchMatchesComponent = ({ Data, setData, type }) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isSpecific, setIsSpecific] = useState(true);

  const breakPointWidth = useBreakpoints().width;
  const typeScreen = breakPointWidth > 1150 ? 'desktop' : breakPointWidth < 768 ? 'mobile' : 'tablet';

  const { t } = useTranslation();


  const _handledChangeSelect = (value) => {
    setIsSpecific(value);
    filterData(searchInputValue, value);

  }

  const getMatchDuration = duration => {
    return moment(parseInt(duration) * 1000).format('mm:ss');
  };

  const getDate = date => {
    return  moment(date).format('MM-DD-YYYY')
  };

  const filterData = (value, specific) => {
    if (value.trim() != '') {
        const dataFilter = Data.filter((item)=> {
            const keyValues = value.split(' ');
            const objectiveWords = item.participant1?.name + ' '+
                                   item.participant2?.name + ' '+
                                   item.participant1?.country + ' '+
                                   item.participant2?.country + ' '+
                                   item.matchGender + ' '+
                                   item.matchId + ' '+
                                   item.matchType + ' '+
                                   item.seniority + ' '+
                                   item.weight + ' '+
                                   `${getMatchDuration(item.duration)}` + ' '+
                                   `${item.giNogi === 1 ? 'Gi' : 'NoGi'}` + ' '+
                                   item.academy.name + ' '+
                                   getDate(item.matchDatetime)+ ' '+
                                   getDate(item.matchEndtime) + ' '+
                                  `${item.matchEndtime?t('matchesSearch.ended'): t('matchesSearch.missing')}`;

        var existKey = keyValues?.map((data) => data.trim() == '' ? isSpecific : objectiveWords?.trim().toLowerCase().includes(data.trim().toLowerCase()));
        return (specific === true) ? existKey?.every((item) => item === true) : existKey?.some((item) => item === true);
      }
      );

      setData(dataFilter);
    } else {
      setData(Data);
    }

  };
  const search = async target => {
    setSearchInputValue(target.value);
    filterData(target.value, isSpecific);
  };
  return (
    <>
    <InputGroup className={(typeScreen === 'mobile') ? "w-100 mt-3" : (typeScreen === 'tablet') ? "w-75 mt-3" : "w-50 mt-3"}>
      <InputGroup.Text className='bg bg-primary'>
      <FontAwesomeIcon
        icon="search"
        className="text-light"
      />
      </InputGroup.Text>
     
      <FormControl
       type="search"
       placeholder={t('searchText')}
       aria-label="Search"
       className=" search-input shadow-none"
        onChange={({ target }) => {
          search(target);
        }}
       />

    </InputGroup>

    </>
  );
};
export default SearchMatchesComponent;
