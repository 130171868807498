import { useState } from 'react';
import { Button, Col, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from 'components/common/Divider';
import clearBracketMatches from 'api/brackets/clear-matches';
import { toast } from 'react-toastify';

const ButtonClearMatches = ({
  setRefetch,
  isRefetch,
  academyId,
  bracketUuid,
  eventUuid
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [processing, setProcessing] = useState(false);

  const { t } = useTranslation();

  const handleClearMatches = async () => {
    try {
      setProcessing(true);

      const result = await clearBracketMatches({
        academyID: academyId,
        bracketUUID: bracketUuid,
        eventUUID: eventUuid
      });

      if (result.success) {
        toast.success(t('bracketAddParticipant.clearMatchesSuccessToast'));
        setModalShow(false);
        setRefetch(!isRefetch);
      } else {
        toast.error(result.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Col>
        <Divider />
        <Button
          variant="danger"
          className="rounded"
          onClick={() => setModalShow(true)}
          size="sm"
        >
          <FontAwesomeIcon icon={'ban'} />
          {'  '}
          {t('bracketAddParticipant.clearMatches')}
        </Button>
      </Col>

      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('bracketAddParticipant.clearMatches')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{t('bracketAddParticipant.confirmClearMatches')}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleClearMatches}
            disabled={processing}
          >
            {processing ? (
              <Spinner
                animation="grow"
                style={{ width: 15, height: 15, marginTop: 4 }}
              />
            ) : (
              t('bracketAddParticipant.confirmClearYesBtn')
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ButtonClearMatches;
