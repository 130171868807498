import axios from 'axios';
import moment from 'moment';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { decodeEventDetail } from './get-event';

const apiRoot = process.env.REACT_APP_API_ROOT;

const getPublicEventDetail = async (eventUUID: string) => {
  try {
    const response = await axios.get(`${apiRoot}/public-event/${eventUUID}`);
    return decodeEventDetail(response.data);
  } catch (e) {
    return null;
  }
};

export default getPublicEventDetail;
