/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { getUsers } from 'api/user';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import TableListComponent from 'components/utils/shared/table/table';
import SearchUsersComponent from './SeachUsers';
import UserHeader from './UsersHeader';
import ActionComponent from './compenents/action_component';

import SelectCurrency from 'components/common/CurrencySelector';
const getSymbolFromCurrency = require('currency-symbol-map');

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Form, Spinner, Col, Row } from 'react-bootstrap';
import Divider from 'components/common/Divider';

import { toast } from 'react-toastify';
import moment from 'moment';

import DefaultDateTimePicker from 'components/common/DateTimePicker';
import addAthleteSubscription from 'api/user/subscription';

const mapStateToProp = state => ({
  academyId: state.auth.academyId
});

const AthleteSubscriptionModal = ({
  academyId,
  setRefetchUser,
  show,
  onHide
}) => {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const [formData, setFormData] = useState({});

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAddSubscription = async () => {
    try {
      setIsProcessing(true);

      await addAthleteSubscription({
        academyID: academyId,
        userID: show.id,
        userUUID: show.uuid,
        payment: `${getSymbolFromCurrency(formData.currency.target.value)}${
          formData.price
        }`,
        validUntil: moment(formData.validUntil).format('yyyy-MM-DD')
      });

      toast.success(t('users.table.subscriptions.successToast'));

      setFormData({});

      onHide();
      setRefetchUser(show.id);
    } catch (error) {
      console.log(error);
      toast.error(t('users.table.subscriptions.errorToast'));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal show={!!show} onHide={onHide}>
      <div className="d-flex justify-content-end m-3">
        <FontAwesomeIcon icon="xmark" />
      </div>
      <Modal.Body>
        <h5>{t('users.table.subscriptions.title')}</h5>

        <Divider />
        <Form.Group className="mb-3">
          <Form.Label>{t('users.table.subscriptions.validUntil')}</Form.Label>
          <DefaultDateTimePicker
            showTimeSelect={false}
            minDate={new Date()}
            dateFormat="dd/MM/yyyy"
            placeholder={t('users.table.subscriptions.validUntil')}
            value={formData.validUntil}
            onValueChange={async newDate => {
              handleChange('validUntil', newDate);
            }}
          />
        </Form.Group>

        <></>

        <Form.Group className="mb-3">
          <Form.Label>{t('users.table.subscriptions.price')}</Form.Label>
          <Row>
            <Col md="4">
              <SelectCurrency
                className="form-control"
                placeholder={t('users.table.subscriptions.currency')}
                value={formData.currency}
                onChange={value => {
                  handleChange('currency', value);
                }}
                onCurrencySelected={value => {
                  handleChange('currency', value);
                }}
              />
            </Col>
            <Col md="8">
              <Form.Control
                value={formData.price}
                type="number"
                onChange={event => {
                  handleChange('price', event.currentTarget.value);
                }}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
            setFormData({});
          }}
          variant="secondary"
        >
          {t('bracketModal.option1')}
        </Button>
        <Button
          variant="primary"
          onClick={handleAddSubscription}
          disabled={isProcessing || !formData.price || !formData.currency}
        >
          {isProcessing ? (
            <Spinner
              animation="grow"
              style={{ width: 15, height: 15, marginTop: 4 }}
            />
          ) : (
            t('bracketModal.option2')
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Users = ({ academyId }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [showAthleteSubscriptionModal, setShowAthleteSubscriptionModal] =
    useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [refetchUser, setRefetchUser] = useState('0');

  const { t } = useTranslation();

  const columns = [
    {
      accessor: 'id',
      Header: t('usersColumns.column1')
    },
    {
      accessor: 'name',
      Header: t('usersColumns.column2')
    },
    {
      accessor: 'email',
      Header: t('usersColumns.column3')
    },
    {
      accessor: 'country',
      Header: t('usersColumns.column4'),
      Cell: rowData => {
        const { country } = rowData.row.original;
        return (
          <div className="d-flex justify-content-center">
            <ReactCountryFlag
              countryCode={country}
              style={{ width: '25px', height: 'auto' }}
              svg
            />
          </div>
        );
      }
    },
    {
      accessor: '',
      Header: t('users.table.subscriptions.validUntil'),
      Cell: rowData => {
        const { uuid, active, activeUntil } = rowData.row.original;
        if (!activeUntil) {
          return '-';
        }
        return <span>{moment(activeUntil).format('yyyy-MM-DD')}</span>;
      }
    },
    {
      accessor: 'action',
      Header: t('matchesColumns.column5'),
      Cell: rowData => {
        const { active, id, uuid } = rowData.row.original;

        return (
          <>
            <ActionComponent
              uuid={uuid}
              active={active}
              setRefetchUser={setRefetchUser}
              showAthleteSubscriptionModal={() => {
                setShowAthleteSubscriptionModal({
                  id,
                  uuid
                });
              }}
            />
          </>
        );
      }
    }
  ];

  useEffect(async () => {
    const getUsersFunc = async () => {
      const users = await getUsers(academyId);
      setData(users);
      setFilterData(users);
    };

    setIsLoading(true);
    await getUsersFunc();
    setIsLoading(false);
  }, [refetchUser]);

  useEffect(() => {
    if (!filterData) return;
    (async () => {
      setFilterData(filterData);
    })();
  }, [filterData]);
  return (
    <>
      <SearchUsersComponent Data={data} setData={setFilterData} />

      <small className="mb-3 ms-1 text-600">{t('users.table.help')}</small>

      <TableListComponent
        columns={columns}
        data={filterData}
        handleDeleteMatch={() => {}}
        headerComponent={<UserHeader table />}
        isLoading={isLoading}
      />

      <AthleteSubscriptionModal
        academyId={academyId}
        setRefetchUser={setRefetchUser}
        show={showAthleteSubscriptionModal}
        onHide={() => {
          setShowAthleteSubscriptionModal(null);
        }}
      />
    </>
  );
};
export default connect(mapStateToProp)(Users);
