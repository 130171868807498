import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsOrderBracket = {
  academyID: number;
  eventID: number;
  bracketUUIDDragged: string;
  bracketUUIDDropped: string;
  order: 'up' | 'down';
};
const reorderBracket = async (data: ArgsOrderBracket): Promise<boolean> => {
  try {
    const response = await axios.put(`${apiRoot}/bracket_order`, {
      ...data
    });

    return response.status == 200;
  } catch (e) {
    return false;
  }
};

export const updateBracketMat = async (data: {
  academyID: number;
  eventID: number;
  bracketUUIDDragged: string;
  bracketMatDropped: number;
}): Promise<boolean> => {
  try {
    const response = await axios.put(`${apiRoot}/bracket_mat`, {
      ...data
    });

    return response.status == 200;
  } catch (e) {
    return false;
  }
};

export default reorderBracket;
