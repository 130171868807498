import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsDeleteEntry = {
  eventId: number;
  eventEntryId: string;
};
const deleteEntry = async (data: ArgsDeleteEntry): Promise<boolean> => {
  try {
    const response = await axios.delete(`${apiRoot}/event-entry`, {
      data: {
        ...data
      }
    });
    return response.status == 200;
  } catch (e) {
    return false;
  }
};

export const updateEventEntry = async (data: {
  id: number;
  eventEntryId: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  gender: string;
  gi_nogi: number;
  seniority: string;
}): Promise<any> => {
  try {
    const response = await axios.put(`${apiRoot}/event-entry`, {
      ...data
    });
    return response;
  } catch (e) {
    return false;
  }
};

export default deleteEntry;
