import { useEffect, useState, forwardRef } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from 'components/common/Flex';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { filter, map, size, find, last, findIndex, orderBy, keys, indexOf, isEmpty } from 'lodash';
import reorderBracket, { updateBracketMat } from 'api/brackets/bracket-sequence';

import { arrayMoveImmutable } from 'array-move';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  DndContext,
  DragOverlay,
  closestCenter,
  closestCorners,
  rectIntersection,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  useDroppable,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';


function customCollisionDetectionAlgorithm(args) {

  const rectCollisions = rectIntersection(args)
  if (rectCollisions.length > 0) {
    return rectCollisions;
  }

  const centerCollisions = closestCenter(args);
  if (centerCollisions.length > 0) {
    return centerCollisions;
  }

  // If there are no collisions with the center, return closestCorner intersections
  return closestCorners(args);
};

const getMatBracketsFromBrackets = (mat, brackets, eventInfo) => {
  return orderBy(
    filter(
      brackets || eventInfo.brackets || [],
      bracket => bracket.mat === mat
    ),
    bracket => bracket.order || 0
  )
}


const MatListDropZone = ({
  id,
  activeMat,
  children,
  matIdThatBracketIsOver
}) => {
  const { setNodeRef, isOver, over } = useDroppable({ id });

  const isBracketHoveringMat = (isOver) && id != activeMat && id == matIdThatBracketIsOver;

  return (
    <div
      ref={setNodeRef}
      className={isBracketHoveringMat ? 'drop-mat-target' : null}
    >
      {children}
    </div>
  );
}


const MatBracketItem = forwardRef(({ id, bracket, bracketIndex: index, matBracketsLength, eventInfo, handleReorderBracket, ...props }, ref) => {

  const { t } = useTranslation();

  const { academyId, isAcademy } = useSelector(state => state.auth);

  const participantsNo = size(
    (bracket.participants || []).filter(m => m.confirmed)
  );

  const { startTime, endTime } = bracket;
  const { event } = eventInfo;


  return (
    <div
      className={`border mb-1 dark__bg-1100 p-1 rounded-1 matBracket-status 
     ${startTime != null ? 'matBracket-in-progress-status' : ''}
     ${endTime != null ? 'matBracket-completed-status' : ''}`
      }
      key={bracket.bracketUUID}
      {...props}
      ref={ref}
    >
      <Flex>
        <div className="flex-1 ps-3">
          <h6>
            <div className="pt-1 d-flex justify-content-between">
              <div>
                <Link
                  className="text-primary me-1"
                  to={
                    location.pathname.includes('dashboard')
                      ? `/dashboard/brackets/${event.academy_id}/${event.id}/${bracket.bracketUUID}`
                      : `/public-events/brackets/${event.academy_id}/${event.id}/${bracket.bracketUUID}`
                  }
                >
                  <span>{bracket.description}</span>
                </Link>

                {!!participantsNo && (
                  <>
                    <br />
                    <SoftBadge pill bg="primary" className="fs--2 mt-1">
                      {participantsNo}{' '}
                      {t('bracketAddParticipant.participants')}
                    </SoftBadge>
                  </>
                )}
              </div>

              <div class="d-flex flex-column">
                {index > 0 && event.academy_id === academyId && isAcademy && (
                  <FontAwesomeIcon
                    icon={'caret-up'}
                    onClick={async () => {
                      await handleReorderBracket(bracket.bracketUUID, 'up');
                    }}
                    className="me-1 cursor-pointer"
                  />
                )}

                <small className="">{'-'}</small>
                {index < matBracketsLength - 1 &&
                  event.academy_id === academyId &&
                  isAcademy && (
                    <FontAwesomeIcon
                      onClick={async () => {
                        await handleReorderBracket(
                          bracket.bracketUUID,
                          'down'
                        );
                      }}
                      icon={'caret-down'}
                      className="me-1 cursor-pointer"
                    />
                  )}
              </div>
            </div>
          </h6>
        </div>
      </Flex>
    </div>
  )
});

const SortableMatItem = (props) => {

  const { academyId } = useSelector(state => state.auth);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
    data: {
      matId: props.bracket.mat,
    },
    disabled: (props.eventInfo.event.academy_id !== academyId)
  });


  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <MatBracketItem ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />
  );
}

const SingleMatList = ({ mat, activeDraggedBracket, matBrackets, eventInfo, matIdThatBracketIsOver }) => {

  const { t } = useTranslation();
  const location = useLocation();

  const { id, event } = eventInfo;


  const handleReorderBracket = async (bracketDraggedId, order) => {
    const indexOfBracketDragged = findIndex(
      matBrackets,
      bracket => bracket.bracketUUID === bracketDraggedId
    );
    const indexOfBracketDropped =
      order === 'up' ? indexOfBracketDragged - 1 : indexOfBracketDragged + 1;
    const bracketDropped = matBrackets[indexOfBracketDropped].bracketUUID;

    try {
      let result = await reorderBracket({
        academyID: event.academy_id,
        bracketUUIDDragged: bracketDraggedId,
        bracketUUIDDropped: bracketDropped,
        eventID: event.id,
        order: order
      });

      if (result) {
        const updatedMatBrackets = map(matBrackets, (bracket, index) => {
          if (index === indexOfBracketDragged) {
            return { ...matBrackets[indexOfBracketDropped] };
          } else if (index === indexOfBracketDropped) {
            return { ...matBrackets[indexOfBracketDragged] };
          }
          return { ...bracket };
        });

        setMatBrackets(updatedMatBrackets);
        toast.success(t('events.eventDetail.mats.reorderSuccess'), {
          autoClose: 2000
        });
      }
    } catch (error) { }
  };

  //if no brackets in the mat, 
  //don't render the mat...
  if (isEmpty(matBrackets)) {
    return null;
  }

  return (
    <Col lg={6} key={mat}>
      <MatListDropZone
        id={mat.toString()}
        matIdThatBracketIsOver={matIdThatBracketIsOver}
        activeMat={activeDraggedBracket?.mat || 0}>
        <Card className="my-3">
          <FalconCardHeader
            title={`${t('events.eventDetail.mats.title')} ${mat}`}
            titleTag="h4"
            className="py-2"
            light
            endEl={
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                as={Link}
                to={
                  location.pathname.includes('dashboard')
                    ? `/dashboard/events/mat/${event.academy_id}/${id}/${mat}`
                    : `/public-events/mat/${event.academy_id}/${id}/${mat}`
                }
              >
                {t('events.eventDetail.mats.viewMatBtn')}
              </Button>
            }
          />
          <Card.Body className="pb-0">
            <Row>

              <SortableContext
                items={map(matBrackets, (m) => m.bracketUUID)}
                strategy={verticalListSortingStrategy}
              >

                {map(matBrackets, (bracket, index) => {
                  return (
                    <SortableMatItem
                      id={bracket.bracketUUID}
                      key={bracket.bracketUUID}
                      index={index}
                      bracketIndex={index}
                      bracket={bracket}
                      matBracketsLength={size(matBrackets)}
                      eventInfo={eventInfo}
                      handleReorderBracket={handleReorderBracket}
                    />
                  );
                })}

              </SortableContext>
            </Row>
          </Card.Body>

          <Card.Footer className="bg-light py-2">
            <Row className="g-0 flex-between-center">
              <Col xs="auto">
                <span>
                  {size(matBrackets)} {t('events.eventDetail.mats.bracket')}
                </span>
              </Col>
              <Col xs="auto">
                <span>
                  {t('events.eventDetail.mats.eta')}
                  {last(matBrackets) ? last(matBrackets).startTime || '-' : '-'}
                </span>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </MatListDropZone>
    </Col>
  )
}

export const BracketMat = ({ bracketMats, brackets, eventInfo, setEventBrackets }) => {
  const { t } = useTranslation();
  const { event } = eventInfo;

  const [matsBrackets, setMatsBrackets] = useState({});

  const [activeBracketId, setActiveBracketId] = useState(null);
  const [activeBracketMatId, setActiveBracketMatId] = useState(null);
  const [matIdThatBracketIsOver, setMatIdThatBracketIsOver] = useState(null);

  const activeDraggedBracket = activeBracketMatId && activeBracketId ? find(matsBrackets[activeBracketMatId], (m) => m.bracketUUID == activeBracketId) : null;

  useEffect(() => {
    let mats = {};
    map(bracketMats, (matId) => {
      mats[matId] = getMatBracketsFromBrackets(matId, brackets, eventInfo);
    })
    setMatsBrackets(mats);
  }, [brackets]);

  const handleDragStart = (dragEvent) => {
    const { active } = dragEvent;
    setActiveBracketId(active.id);
    setActiveBracketMatId(active.data?.current?.matId || null);
  }

  const handleDragOver = ({ active, over }) => {
    const overId = over?.id;
    setMatIdThatBracketIsOver(overId);

    if (!overId) {
      return;
    }
  };

  const handleDragEnd = async (dragEvent) => {

    const { active, over } = dragEvent;

    if (!activeDraggedBracket) {
      return;
    }

    //if 'sortable' is present in the event data, 
    //it was a a sort
    if (!!over?.data?.current?.sortable) {

      const matBrackets = matsBrackets[activeDraggedBracket.mat];

      const oldIndex = indexOf(map(matBrackets, m => m.bracketUUID), active?.id || "");
      const newIndex = indexOf(map(matBrackets, m => m.bracketUUID), over?.id || "");

      if (oldIndex != newIndex) {

        const oldIndex = indexOf(map(matBrackets, m => m.bracketUUID), active.id);
        const newIndex = indexOf(map(matBrackets, m => m.bracketUUID), over.id);

        const bracketDragged = active.id;
        const bracketDropped = over.id;

        setMatsBrackets((prev) => {
          return {
            ...prev,
            [activeDraggedBracket.mat]: arrayMoveImmutable(prev[activeDraggedBracket.mat], oldIndex, newIndex)
          }
        });


        let result = await reorderBracket({
          academyID: event.academy_id,
          bracketUUIDDragged: bracketDragged,
          bracketUUIDDropped: bracketDropped,
          eventID: event.id
        });

        toast.success(t('events.eventDetail.mats.reorderSuccess'), {
          autoClose: 2000
        });
      }
    }
    else if (!!over?.id) {
      const destinationMat = Number(over.id);

      //if the mats is different from destination Mat
      if ([activeDraggedBracket.mat] != destinationMat) {

        //if the bracket mat exists.. 
        if (!!matsBrackets[destinationMat]) {

          setMatsBrackets((prev) => {
            return {
              ...prev,
              //remove the bracket from the original mat
              [activeDraggedBracket.mat]: filter(prev[activeDraggedBracket.mat], (m) => m.bracketUUID !== activeDraggedBracket.bracketUUID),
              //add the new bracket to the destination mat.. 
              [destinationMat]: [...prev[destinationMat], {
                ...activeDraggedBracket,
                mat: destinationMat,
              }]
            }
          });

          let result = await updateBracketMat({
            academyID: event.academy_id,
            bracketUUIDDragged: activeDraggedBracket.bracketUUID,
            bracketMatDropped: destinationMat,
            eventID: event.id
          });

          toast.success(t('events.eventDetail.mats.matUpdateSuccess'), {
            autoClose: 2000
          });

        }
      }
    }

  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the handler toolbar component.
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement.
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );


  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        collisionDetection={customCollisionDetectionAlgorithm}
        onDragEnd={handleDragEnd}
      >
        {map(keys(matsBrackets), mat => {
          return (
            <SingleMatList
              key={mat}
              mat={mat}
              matBrackets={matsBrackets[mat]}
              matIdThatBracketIsOver={matIdThatBracketIsOver}
              activeDraggedBracket={activeDraggedBracket}
              eventInfo={eventInfo}
            />
          )
        })}

        <DragOverlay>
          {activeBracketId ?
            <MatBracketItem
              bracket={activeDraggedBracket}
              bracketIndex={0}
              matBracketsLength={0}
              eventInfo={{ event: {} }}
              handleReorderBracket={() => {

              }}
            /> : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

