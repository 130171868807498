/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Matches from 'components/dashboards/matches';
import Users from 'components/dashboards/users';
import CreateMatch from 'components/dashboards/create-match/CreateMatch';
import UserInvite from 'components/dashboards/invite-user/UserInvite';
import MatchResult from 'components/dashboards/match-result';
import ProtectedRoute from '../ProtectedRoute';
import Profile from 'components/dashboards/academy/profile';
import Match from 'components/dashboards/brackets/brackList/index';
import SingleElimination from 'components/dashboards/brackets/SingleElimination';
import UserProfile from 'components/dashboards/user/profile';
import Edit from 'components/dashboards/user/edit';
import CreateEvent from 'components/dashboards/events/create-an-event/CreateEvent';
import EventCreditCheckout from 'components/dashboards/events/buy-credits/CreditsCheckout';

import EventList from 'components/dashboards/events/event-list/EventList';
import EventDetail from 'components/dashboards/events/event-detail/EventDetail';
import EntryDetail from 'components/dashboards/events/entry-detail/EntryDetail';
import EditEvent from 'components/dashboards/events/edit-event/EditEvent';
import MatScheduleDetail from 'components/dashboards/events/event-detail/MatScheduleDetail';
import AcademySubscriptions from 'components/dashboards/academy/subscriptions';
import AcademySubscriptionBilling from 'components/dashboards/academy/subscriptions/Billing';
import ActivityList from 'components/dashboards/activities/ActivityList';
import CreateActivity from 'components/dashboards/activities/create/CreateActivity';
import EditActivity from 'components/dashboards/activities/edit/EditActivity';
import AcademyCredits from 'components/dashboards/academy/credits/Credits';

const MainRoutes = () => (
  <Switch>
    <ProtectedRoute path="/dashboard/matches" exact component={Matches} />
    <ProtectedRoute
      path="/dashboard/create-match"
      exact
      component={CreateMatch}
    />
    <ProtectedRoute path="/dashboard/users" exact component={Users} />
    <ProtectedRoute path="/dashboard/:isAcademy/edit" exact component={Edit} />
    <ProtectedRoute path="/dashboard/user/edit/:uuid" exact component={Edit} />
    <ProtectedRoute
      path="/dashboard/user/view/:uuid"
      exact
      component={UserProfile}
    />
    <ProtectedRoute
      path="/dashboard/match-result/:academyId/:matchid"
      exact
      component={MatchResult}
    />
    <ProtectedRoute
      path="/dashboard/brackets/:academyId/:eventID/:bracketUUID"
      exact
      component={SingleElimination}
    />
    <ProtectedRoute
      path="/dashboard/invite-user"
      exact
      component={UserInvite}
    />
    <ProtectedRoute
      path="/dashboard/activities"
      exact
      component={ActivityList}
    />
    <ProtectedRoute
      path="/dashboard/activities/create"
      exact
      component={CreateActivity}
    />
    <ProtectedRoute
      path="/dashboard/activities/update"
      exact
      component={EditActivity}
    />
    <ProtectedRoute path="/dashboard/profile" exact component={Profile} />
    <ProtectedRoute
      path="/dashboard/subscriptions"
      exact
      component={AcademySubscriptions}
    />
    <ProtectedRoute
      path="/dashboard/credits"
      exact
      component={AcademyCredits}
    />
    <ProtectedRoute
      path="/dashboard/subscriptions/checkout/:plan/:period"
      exact
      component={AcademySubscriptionBilling}
    />
    <ProtectedRoute path="/dashboard/events" exact component={EventList} />
    <ProtectedRoute
      path="/dashboard/events/update"
      exact
      component={EditEvent}
    />
    <ProtectedRoute
      path="/dashboard/events/event-detail/:academyId/:id"
      exact
      component={EventDetail}
    />
    <ProtectedRoute
      path="/dashboard/events/entry-detail/:eventId/:entryId/:eventUUID"
      exact
      component={EntryDetail}
    />
    <ProtectedRoute
      path="/dashboard/events/mat/:academyId/:id/:matId"
      exact
      component={MatScheduleDetail}
    />
    <ProtectedRoute
      path="/dashboard/events/create"
      exact
      component={CreateEvent}
    />
    <ProtectedRoute
      path="/dashboard/events/buy-credits"
      exact
      component={EventCreditCheckout}
    />
    <ProtectedRoute path="/dashboard/brackets" exact component={Match} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default MainRoutes;
