import { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { map, range } from 'lodash';
import { BracketContext } from 'context/Context';

const EventBracketItem = ({ uuid, handleRemove, handleChange }) => {
  const { t } = useTranslation();
  const [pill, setPill] = useState(false);
  const weightRef = useRef(),
    metricRef = useRef(),
    signRef = useRef();
  const [metricDisable, setMetricDisable] = useState(false);
  const [absolut, setAbsolut] = useState(false);
  const [weightDisable, setWeightDisable] = useState(false);
  const [weights, setWeights] = useState([]);
  const { weight, setWeight, weightList, setWeightList, items } =
    useContext(BracketContext);

  const handleFieldChange = (name, value) => {
    handleChange(uuid, name, value);
  };

  useEffect(() => {
    if (weight != null) {
      setMetricDisable(true);
      metricRef.current.value = weight;
    } else {
      setMetricDisable(false);
    }
    if (absolut) {
      setWeight(metricRef.current.value);
      setMetricDisable(true);
    } else if (!absolut && weightList.length == 0) {
      setWeight(null);
      setMetricDisable(false);
    }
  }, [weight, absolut]);

  const addPill = (val = null) => { 
    setPill(true);
    setMetricDisable(true);
    setWeight(metricRef.current.value);
    const obj = val
      ? `Absolut`
      : `${signRef.current.value}${Number(weightRef.current.value).toFixed(2).replace(/[^0-9.]/g, '')}${metricRef.current.value}`;

    if (obj != '' && weights.indexOf(obj) == -1) {
      setWeights(prev => {
        const result = [...prev, obj];
        handleFieldChange('weight', result);
        return result;
      });
      setWeightList([...weightList, obj]);
    }
    weightRef.current.value = '';
  };

  function handleKeyDown(event) {
    if (event.key === "e") {
      event.preventDefault();
    }
  }

  const handleWeightSelect = () => {
    if (
      metricRef.current.value != '' &&
      signRef.current.value != '' &&
      weightRef.current.value != ''
    ) {
      addPill();
    }
  };

  const handleAbsolut = e => {
    setAbsolut(true);
    if (weightList.length > 0 || absolut) {
      setMetricDisable(true);
    } else {
      setMetricDisable(e.target.checked);
    }
    if (e.target.checked && weights.indexOf('Absolut') == -1) {
      addPill('Absolut');
    } else {
      handleBadgeRemove('Absolut');
    }
  };

  const handleBadgeRemove = weight => {
    setAbsolut(false);
    setWeightList(prev => {
      const filtered = prev.filter(w => w != weight);
      if (filtered.length == 0 && !absolut) {
        setMetricDisable(false);
        setWeight(null);
      }
      return filtered;
    });

    setWeights(prev => {
      var result = prev.filter(w => w != weight);
      handleFieldChange('weight', result);
      return result;
    });
  };

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
          <Button
            size="sm"
            variant="link"
            className="p-0"
            onClick={() => handleRemove(uuid)}
          >
            <FontAwesomeIcon className="text-danger" icon="times-circle" />
          </Button>
        </div>

        <div className="gx-2 gy-3">
          <Row xs={1} md={4} lg={4}>
            <Col md={4} lg={4}>
              <Form.Group controlId="gender" className="mb-3">
                <Form.Label>{t('createMatch.fieldLabel4')}</Form.Label>
                <Form.Select
                  aria-label="gender"
                  name="gender"
                  onChange={e => handleFieldChange('gender', e.target.value)}
                  required
                >
                  <option selected value="Male">
                    {t('createMatch.fieldGenderValue1')}
                  </option>
                  <option value="Female">
                    {t('createMatch.fieldGenderValue2')}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group controlId="seniority" className="mb-3">
                <Form.Label>{t('createMatch.fieldLabel5')}</Form.Label>
                <Form.Select
                  aria-label="seniority"
                  name="seniority"
                  onChange={e => handleFieldChange('seniority', e.target.value)}
                  required
                >
                  <option selected value="Juvenile">
                    {t('createMatch.fieldSeniorityValue2')}
                  </option>
                  <option value="Adult">
                    {t('createMatch.fieldSeniorityValue1')}
                  </option>
                  <option value="Master">
                    {t('createMatch.fieldSeniorityValue3')}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group controlId="gi_nogi" className="mb-3">
                <Form.Label>Gi / NoGi</Form.Label>
                <Form.Select
                  aria-label="gi_nogi"
                  name="gi_nogi"
                  onChange={e => handleFieldChange('gi_nogi', e.target.value)}
                  required
                >
                  <option selected value="1">
                    Gi
                  </option>
                  <option value="0">NoGi</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12} lg={12}>
              <Form.Group className="mb-3" controlId="weight">
                <Form.Label>{t('createMatch.fieldLabel6')}</Form.Label>
                <Row>
                  <Col xs={2} md={2}>
                    <Form.Select
                      placeholder={t('createMatch.positiveOrNegative')}
                      ref={signRef}
                      name="sign"
                      required
                    >
                      <option value="-">-</option>
                      <option value="+">+</option>
                    </Form.Select>
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Control
                      name="weight"
                      type="number"
                      ref={weightRef}
                      disabled={weightDisable}
                      onKeyDown={handleKeyDown}
                      onBlur={handleWeightSelect}
                      required={weights.length > 0? false : true}
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    <Form.Select
                      placeholder={t('createMatch.weight')}
                      name="metric"
                      ref={metricRef}
                      disabled={metricDisable}
                      required
                    >
                      <option value="kg">kg</option>
                      <option value="lb">lb</option>
                    </Form.Select>
                  </Col>
                  <Col xs={1} md={1}>
                    <Form.Check
                      checked={absolut}
                      type="checkbox"
                      label="Absolut"
                      onChange={handleAbsolut}
                    />
                  </Col>
                  {pill ? (
                    <Row>
                      {weights.map(weight => (
                        <Col md={2} lg={2}>
                          <Button
                            style={{ margin: '5px' }}
                            vairant="primary"
                            onClick={() => handleBadgeRemove(weight)}
                          >
                            {weight} <Badge bg="secondary">x</Badge>
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    ''
                  )}
                </Row>
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="duration">
                <Form.Label>{t('createMatch.fieldLabel7')}</Form.Label>
                <Select
                  options={[
                    { value: '3:00', label: '3:00' },
                    { value: '4:00', label: '4:00' },
                    { value: '5:00', label: '5:00' },
                    { value: '6:00', label: '6:00' },
                    { value: '7:00', label: '7:00' },
                    { value: '8:00', label: '8:00' },
                    { value: '9:00', label: '9:00' },
                    { value: '10:00', label: '10:00' }
                  ]}
                  defaultValue={{ label: '5:00', value: '300' }}
                  placeholder={t('createMatch.fieldPlaceholder7')}
                  classNamePrefix="react-select"
                  onChange={selectedOption =>
                    handleFieldChange('duration', selectedOption.value)
                  }
                  name="duration"
                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="belt">
                <Form.Label>{t('events.createEvents.fields.belt')}</Form.Label>
                <Select
                  options={[
                    { value: 'white', label: 'White' },
                    { value: 'blue', label: 'Blue' },
                    { value: 'purple', label: 'Purple' },
                    { value: 'brown', label: 'Brown' },
                    { value: 'black', label: 'Black' }
                  ]}
                  defaultValue={{ label: 'white', value: 'White' }}
                  placeholder={t('events.createEvents.fields.beltPlaceholder')}
                  classNamePrefix="react-select"
                  onChange={selectedOption =>
                    handleFieldChange('belt', selectedOption.value)
                  }
                  name="belt"
                />
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="mat">
                <Form.Label>
                  {t('events.createEvents.fields.bracketMats')}
                </Form.Label>

                <Form.Select
                  aria-label="mat"
                  name="mat"
                  placeholder={t(
                    'events.createEvents.fields.bracketMatsPlaceholder'
                  )}
                  onChange={e => handleFieldChange('mat', e.target.value)}
                  required
                >
                  {map(range(1, 11), val => {                  
                    return (
                      <option value={val}>
                        {t(t('events.createEvents.fields.bracketMats'))} {val}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default EventBracketItem;

EventBracketItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  mat: PropTypes.number,
  gender: PropTypes.string.isRequired,
  gi_nogi: PropTypes.number.isRequired,
  seniority: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  belt: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired
};
