/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useGetMatchesQuery } from 'api/match';
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableSearchMatches from '../../pages/table-search-box/table-search-matches';
import TableListComponent from 'components/utils/shared/table/table';
import MacthesHeader from './MatchHeader';
import SearchMatchesComponent from './MacthesSearch';
import { Button } from 'react-bootstrap';
import { dispatchControlMatchInfo } from 'dux/auth/action-thunks/event-thunk';

const getMatchDuration = duration => {
  return moment.utc(parseInt(duration) * 1000).format('mm:ss');
};

const mapStateToProp = state => ({
  academyId: state.auth.academyId
});
const Matches = ({ academyId }) => {
  const [FilterData, setFilterData] = useState([]);

  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const columns = [
    {
      accessor: 'matchId',
      Header: t('matchesColumns.column1')
    },
    {
      accessor: 'participants',
      Header: t('matchesColumns.column2'),
      Cell: rowData => {
        const { participant1, participant2 } = rowData.row.original;
        return (
          <>
            {participant1.name} VS {participant2.name}
          </>
        );
      }
    },
    {
      accessor: 'details',
      Header: t('matchesColumns.column3'),
      Cell: rowData => {
        const { matchType, matchGender, giNogi, seniority, weight, duration } =
          rowData.row.original;
        return (
          <>
            {matchType}
            {matchGender && ` / ${matchGender}`}
            {(giNogi || giNogi === 0) && ` / ${giNogi === 1 ? 'Gi' : 'NoGi'}`}
            {seniority && ` / ${seniority}`}
            {weight && ` / ${weight}`}
            {duration && ` / ${getMatchDuration(duration)}`}
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: t('matchesColumns.column4'),
      Cell: rowData => {
        const { matchDatetime } = rowData.row.original;
        return (
          <>{matchDatetime && moment(matchDatetime).format('MM-DD-YYYY')}</>
        );
      }
    },
    {
      accessor: 'action',
      Header: t('matchesColumns.column5'),
      Cell: rowData => {
        const { matchId, matchEndtime } = rowData.row.original;
        return (
          <div className='d-flex justify-content-center'>
            {matchEndtime ? (
              <Link
                className="btn-view"
                to={`/dashboard/match-result/${academyId}/${matchId}`}
              >
                <FontAwesomeIcon className='text-primary' icon={['fas', 'eye']} />
              </Link>
            ) : (
              <button
                className="btn btn-play match-play-btn"
                onClick={() => {
                  dispatch(dispatchControlMatchInfo({
                    controlMatchAcademyId: academyId,
                    matchId: matchId,
                  }))
                  history.push(`/control-match/`)
                }}
              >
                <FontAwesomeIcon icon={['fas', 'play']} />
              </button>
            )}
          </div>
        );
      }
    }
  ];
  const { data, error, isLoading } = useGetMatchesQuery(academyId, {
    refetchOnMountOrArgChange: true
  });
  useEffect(() => {
    if (!data) return;
    setFilterData(data);
  }, [data]);


  return (
    <>
      <SearchMatchesComponent
        SearchMatchesComponent Data={data} setData={setFilterData} type="matches" />

      <small className='mb-3 ms-1 text-600'>{t('matchesSearch.help')}</small>

      <TableListComponent
        columns={columns}
        data={FilterData || []}
        handleDeleteMatch={() => { }}
        headerComponent={<MacthesHeader table />}
        isLoading={isLoading}
      />

    </>
  );
};

export default connect(mapStateToProp)(Matches);
