import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { map, range } from 'lodash';

const EventEntryItem = ({
  uuid,
  name,
  description,
  price,
  currency,
  gender,
  gi_nogi,
  seniority,
  handleRemove,
  handleChange
}) => {
  const { t } = useTranslation();

  const handleFieldChange = (name, value) => {
    handleChange(uuid,name,value);
  };

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
          <Button
            size="sm"
            variant="link"
            className="p-0"
            onClick={() => handleRemove(uuid)}
          >
            <FontAwesomeIcon className="text-danger" icon="times-circle" />
          </Button>
        </div>

        <Row className="gx-2 gy-3">
          <Col md="12">
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>{t('events.createEvents.entries.fields.name')}</Form.Label>
            <Form.Control
                type="text"
                name="name"
                placeholder={t('events.createEvents.entries.fields.namePlaceholder')}
                onChange={(e) => handleFieldChange("name",e.target.value)}
                required />
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
              <Form.Label>{t('events.createEvents.entries.fields.description')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="description"
                placeholder={t('events.createEvents.entries.fields.descriptionPlaceholder')}
                onChange={(e) => handleFieldChange("description",e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price" className="mb-3">
            <Form.Label>{t('events.createEvents.entries.fields.price')}</Form.Label>
            <Form.Control
                type="number"
                name="price"
                placeholder={t('events.createEvents.entries.fields.pricePlaceholder')}
                onChange={(e) => handleFieldChange("price",parseFloat(e.target.value ?? "0"))}
                required />
            </Form.Group>
            <Form.Group controlId="currency" className="mb-3">
              <Form.Label>{t('events.createEvents.entries.fields.currency')}</Form.Label>
              <Form.Select
                aria-label="currency"
                name="currency"
                onChange={(e) => handleFieldChange("currency",e.target.value)}
                required
              >
                <option selected value="USD">
                  USD Dollar
                </option>
                <option value="CRC">
                  CRC Colones
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="gender" className="mb-3">
              <Form.Label>{t('events.createEvents.entries.fields.gender')}</Form.Label>
              <Form.Select
                aria-label="gender"
                name="gender"
                onChange={(e) => handleFieldChange("gender",e.target.value)}
                required
              >
                <option selected value="Male">
                  {t('createMatch.fieldGenderValue1')}
                </option>
                <option value="Female">
                  {t('createMatch.fieldGenderValue2')}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="gi_nogi" className="mb-3">
              <Form.Label>{t('events.createEvents.entries.fields.gi_nogi')}</Form.Label>
              <Form.Select
                aria-label="gi_nogi"
                name="gi_nogi"
                onChange={(e) => handleFieldChange("gi_nogi",e.target.value)}
                required
              >
                <option selected value="1">
                {t('events.createEvents.entries.fields.gi')}
                </option>
                <option value="0">{t('events.createEvents.entries.fields.nogi')}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="seniority" className="mb-3">
              <Form.Label>{t('events.createEvents.entries.fields.seniority')}</Form.Label>
              <Form.Select
                aria-label="seniority"
                name="seniority"
                onChange={(e) => handleFieldChange("seniority",e.target.value)}
                required
              >
                <option selected value="Juvenile">
                  {t('createMatch.fieldSeniorityValue2')}
                </option>
                <option value="Adult">
                  {t('createMatch.fieldSeniorityValue1')}
                </option>
                <option value="Master">
                  {t('createMatch.fieldSeniorityValue3')}
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EventEntryItem;

EventEntryItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  gi_nogi: PropTypes.number.isRequired,
  seniority: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired
};
