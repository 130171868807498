import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import AthleteLoginForm from 'components/authentication/AthleteLoginForm';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';

import { CgGym } from 'react-icons/cg';
import { MdOutlineSportsKabaddi } from 'react-icons/md';
import Section from 'components/common/Section';

const Login = () => {
  const [key, setKey] = useState('academy');
  const { t } = useTranslation();

  return (
    <Section className="bg-dark light" position="center bottom" overlay>
      
      <div className="login-block">
        <Flex justifyContent="between" alignItems="center" className="mb-2">
          <h5>{t('landingRightSideNavItems.loginTitle')}</h5>
        </Flex>
        <Tabs id="logiTabs" activeKey={key} onSelect={k => setKey(k)}>
          <Tab
            eventKey="academy"
            title={
              <>
                <span>{t('landingRightSideNavItems.tab1Item')}</span> &nbsp;{' '}
                <CgGym size={16} />
              </>
            }
            theme="dark"
          >
            {key === 'academy' && <LoginForm />}
          </Tab>
          <Tab
            eventKey="athlete"
            title={
              <>
                <span>{t('landingRightSideNavItems.tab2Item')}</span> &nbsp;{' '}
                <MdOutlineSportsKabaddi size={16} />
              </>
            }
            theme="dark"
          >
            {key === 'athlete' && <AthleteLoginForm />}
          </Tab>
        </Tabs>
      </div>
    </Section>
  );
};

export default Login;
