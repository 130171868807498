/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useFormik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import CountrySelect from 'react-bootstrap-country-select';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import { useTranslation } from 'react-i18next';
import createAcademy from 'api/academy/create-academy';
import * as yup from 'yup';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const AcademyRegistrationForm = ({}) => {
  const history = useHistory();
  const [registrationError, setRegistrationError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const countriesOptions = useMemo(() => countryList().getData(), []);

  const { t } = useTranslation();

  const formSchema = yup.object({
    academyName: yup
      .string()
      .required(
        t('academyRegistration.fields.academyNameRequiredValidationError')
      )
      .test(
        'len',
        t('academyRegistration.fields.academyNameLengthValidationError'),
        val => val?.length >= 3
      ),
    email: yup
      .string()
      .email(t('academyRegistration.fields.emailInvalidValidationError'))
      .required(t('academyRegistration.fields.emailRequiredValidationError')),
    password: yup
      .string()
      .required(t('academyRegistration.fields.passwordRequiredValidationError'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        t('academyRegistration.fields.passwordRequirementValidationError')
      ),
    country: yup
      .object()
      .nullable()
      .required(t('academyRegistration.fields.countryRequiredValidationError'))
  });

  const formik = useFormik({
    initialValues: {
      academyName: '',
      email: '',
      password: '',
      country: ''
    },
    validationSchema: formSchema,
    onSubmit: async (values, actions) => {
      setIsSubmitting(true);
      setRegistrationError(null);

      try {
        await createAcademy({
          ...values,
          country: values?.country?.id
        });

        setIsSubmitting(false);
        actions.resetForm();
        toast.success(t('academyRegistration.successToast'), {
          autoClose: 2000
        });
        history.push('/');
      } catch (e) {
        setIsSubmitting(false);
        toast.error(
          <>
            <strong>
              {e.message.includes('User already exists')
                ? t('academyRegistration.emailExistsErrorToast')
                : e.message}
            </strong>
          </>,
          {
            autoClose: 2000
          }
        );
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setValues,
    setFieldValue
  } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <h4 className="fe-1 mb-3">{t('academyRegistration.title')}</h4>

      <Form.Group className="mb-3">
        <Form.Control
          placeholder={t('academyRegistration.fields.academyNamePlaceholder')}
          {...getFieldProps('academyName')}
          name="academyName"
          type="text"
          isInvalid={Boolean(touched.academyName && errors.academyName)}
        />
        <Form.Text className="text-danger">
          {touched.academyName && errors.academyName}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          placeholder={t('academyRegistration.fields.emailPlaceholder')}
          {...getFieldProps('email')}
          name="email"
          type="email"
          isInvalid={Boolean(touched.email && errors.email)}
        />
        <Form.Text className="text-danger">
          {touched.email && errors.email}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          placeholder={t('academyRegistration.fields.passwordPlaceholder')}
          {...getFieldProps('password')}
          name="password"
          type="password"
          isInvalid={Boolean(touched.password && errors.password)}
        />
        <Form.Text className="text-danger">
          {touched.password && errors.password}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        <CountrySelect
          placeholder={t('academyRegistration.fields.countryPlaceholder')}
          flags={true}
          flush={true}
          value={values.country}
          onChange={value => {
            setFieldValue('country', value);
          }}
        />
        <Form.Text className="text-danger">
          {touched.country && errors.country}
        </Form.Text>
      </Form.Group>

      {registrationError && (
        <Alert variant={'danger'} style={{ padding: 8 }}>
          {registrationError}
        </Alert>
      )}

      <Form.Group className="mb-4">
        <Button className="w-100" type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <Spinner
              animation="grow"
              style={{ width: 15, height: 15, marginTop: 4 }}
            />
          ) : (
            t('academyRegistration.submitButton')
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default AcademyRegistrationForm;
