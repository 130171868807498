import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { BsXSquareFill } from 'react-icons/bs';

const CustomDateInput = forwardRef(
  (
    {
      value,
      onClick,
      onClear,
      isInvalid,
      isValid,
      formControlProps,
      errorMessage,
      showClearIcon
    },
    ref
  ) => {
    return (
      <>
        <InputGroup>
          <Form.Control
            ref={ref}
            isInvalid={isInvalid}
            isValid={isValid}
            value={value}
            onClick={onClick}
            {...formControlProps}
          />

          {showClearIcon && (
            <InputGroup.Text>
              <BsXSquareFill className="fs-1 pointer-event" onClick={onClear} />
            </InputGroup.Text>
          )}
        </InputGroup>
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string
};

export default CustomDateInput;
