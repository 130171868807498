import { useEffect, useState } from 'react';
import { Card, Form, Image, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import defaultActivityImg from 'assets/img/generic/activity.png';
import classNames from 'classnames';

import { useUpload } from 'react-use-upload';
import { uniqueId } from 'lodash';

const ActivityBanner = ({ activityBanner, uuid, onDone }) => {
  const { t } = useTranslation();

  const UPLOAD_URL = `${process.env.REACT_APP_API_IMAGES_ROOT}/slickcomp-images/activity/${uuid}.png`;

  let [upload, { done, loading }] = useUpload(({ files }) => ({
    method: 'PUT',
    url: UPLOAD_URL,
    body: files[0],
    headers: { Authorization: window.localStorage.getItem('token') }
  }));

  const updatePhoto = file => {
    if (file) {
      setImagePath(URL.createObjectURL(file));
      upload({ files: [file] });
    }
  };

  const [imagePath, setImagePath] = useState(activityBanner);
  const [isErrorLoadingImg, setIsErrorLoadingImg] = useState(false);
  const [forceRefreshKey, setForceRefreshKey] = useState(uniqueId());

  useEffect(() => {
    var image = document.createElement('img');
    image.src = activityBanner;
    image.onerror = function () {
      setIsErrorLoadingImg(true);
      setImagePath(defaultActivityImg);
    };
  }, [activityBanner]);

  return (
    <Card className="cover-image">
      <div
        style={{
          background: `url(${imagePath}${
            isErrorLoadingImg ? '' : `&key=${forceRefreshKey}`
          }) ${`no-repeat center /cover`}`
        }}
        className={classNames(
          'activity-upload-img fit-cover rounded-top card-img-top'
        )}
      />

      <Form.Group controlId="formFile">
        <Form.Label className="cover-image-file-input">
          {loading ? (
            <Spinner
              animation="grow"
              className="me-2"
              style={{ width: 15, height: 15, marginTop: 4 }}
            />
          ) : (
            <FontAwesomeIcon icon="camera" className="me-2" />
          )}

          <span>{t('activities.edit.image')}</span>
        </Form.Label>
        <Form.Control
          type="file"
          disabled={loading}
          className="d-none"
          onChange={e => updatePhoto(e.target.files[0])}
        />
      </Form.Group>
    </Card>
  );
};

export default ActivityBanner;
