import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActivityHeader from './ActivityHeader';
import ActivityDetails from './ActivityDetails';
import ActivityFooter from './ActivityFooter';
import createNewActivity, {
  LONG_FORM_DAYS
} from 'api/activities/create-activity';
import moment from 'moment';
import { values, map, uniqBy, keys, isEmpty } from 'lodash';

import { toast } from 'react-toastify';

const mapStateToProp = state => ({
  academyId: state.auth.academyId
});

const CreateActivity = ({ academyId }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    setError,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {}
  });

  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);

  const isFormValid =
    isEmpty(keys(errors)) && isValid && !isEmpty(getValues().selectedDays);

  const onSubmit = async data => {
    setCreating(true);

    const payload = {
      academyId: academyId,
      activityName: data['name'],
      startDate: moment(data['startDate']).format(),
      endDate: moment(data['endDate']).format(),
      days: uniqBy(
        map(data['selectedDays'], key => {
          return {
            dayOfWeek: LONG_FORM_DAYS[key],
            time: moment(data['days'][key].activityTime).format('HH:mm'),
            instructor: data['days'][key].instructorName,
            availableSpaces: data['days'][key].availableSpots
          };
        }),
        m => m.dayOfWeek
      )
    };

    try {
      const result = await createNewActivity(payload);

      toast.success(
        t('activities.create.successToast'),
        {
          onClose: () => {
            window.location.href = `/dashboard/activities/`;
          }
        },
        { autoClose: 2000 }
      );
    } catch (error) {
    } finally {
      setCreating(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row className="g-3">
        <Col xs={12}>
          <ActivityHeader disabled={!isFormValid || creating} />
        </Col>
        <Col xs={12}>
          <ActivityDetails
            errors={errors}
            register={register}
            setValue={setValue}
            control={control}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
          />
        </Col>

        <Col>
          <ActivityFooter disabled={!isFormValid || creating} />
        </Col>
      </Row>
    </Form>
  );
};

export default connect(mapStateToProp)(CreateActivity);
