import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const UserRegistrationItem = ({
  name,
  bracketDesc,
  description,
  price,
  currency,
  seniority,
  gi_nogi,
  gender,
  confirmed,
  bracket_id,
  event_entry_id,
  PaymentButton,
}) => {
  const { t } = useTranslation();


  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        <Flex>
          <div className="flex-1 position-relative ps-3">
            <h6 className="fs-0 mb-0">
              <div>
                <span className="me-1">{name} <small>
                  {price} {currency}
                </small>
                  <SoftBadge pill bg="primary" className="fs--2 mx-2">
                    {confirmed ? t('events.eventDetail.entryDetail.confirmedLabel') : t('events.eventDetail.entryDetail.notConfirmedLabel')}
                  </SoftBadge>

                  {!confirmed &&
                    <>
                      <br />
                      <Button
                        as={Link}
                        variant="link"
                        size="xs"
                        onClick={() => setIsModalOpen(true)}
                        to={"#"}
                        className="py-2 athlete-entry-registration-completePaymentBtn"

                      >
                        {t('events.eventDetail.entryDetail.notConfirmedPaymentButton')}
                        <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
                      </Button>
                    </>
                  }

                </span>
                <br />
                <span className="fs--1 me-1 text-800">{bracketDesc}</span>
              </div>
            </h6>
            {description &&
              <h6 className="fs--1 mb-0">
                <div>
                  <span className="me-1">{description}</span>
                </div>
              </h6>
            }

          </div>
        </Flex>
      </div>

      <Modal show={isModalOpen} onHide={() => {
        setIsModalOpen(false);
      }} onBackdropClick={() => {
        setIsModalOpen(false);
      }}>
        <Modal.Header
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5"> {t('events.eventDetail.entryDetail.notConfirmedPaymentButton')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <PaymentButton existingEntryBracket={bracket_id} existingEntryId={event_entry_id} />
        </Modal.Body>
      </Modal>

    </>
  );
};
export default UserRegistrationItem;

