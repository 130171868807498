import { useState, useEffect } from 'react';
import { Card, Row, Alert } from 'react-bootstrap';
import SubscriptionPricingHeader from './SubscriptionPricingHeader';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { connect } from 'react-redux';
import getSubscriptionInfo from 'api/subscription/get-subscription';
import { SubscriptionsHistory } from './SubscriptionsHistory';
import { useTranslation } from 'react-i18next';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid
});

const AcademySubscriptions = ({ uuid, academyId }) => {
  const { t } = useTranslation();
  const [loading, isLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState(null);
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState('monthly');

  const loadData = async () => {
    const data = await getSubscriptionInfo({
      academyUUID: uuid
    });
    if (data) {
      setSubscriptionsData(data);
    }
    isLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading && (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      )}

      {subscriptionsData && (
        <>
          <SubscriptionsHistory
            subscriptionsHistory={subscriptionsData.subscriptionsHistory}
            academyId={academyId}
          />
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-0">
                <SubscriptionPricingHeader
                  selectedPlanPeriod={selectedPlanPeriod}
                  setSelectedPlanPeriod={setSelectedPlanPeriod}
                />
                {subscriptionsData.plans.map(plan => (
                  <SubscriptionPlanCard
                    key={plan.id}
                    plan={plan}
                    period={selectedPlanPeriod}
                  />
                ))}
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProp)(AcademySubscriptions);
