import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type CreditsData = {
  academyUUID: string;
  price: string;
  quantity: string;
};

type PurchaseCreditsData = {
  creditID: number;
  paymentInfo: object;
  academyUUID: string;
};

export const buyAcademyCredits = async (data: CreditsData) => {
  let response = await axios.put(`${apiRoot}/academy-credits`, {
    action: 'buy',
    paymentInfo: {},
    ...data
  });
  return response.data.body;
};

export const completePaymentForAcademyCredits = async (
  data: PurchaseCreditsData
) => {
  let response = await axios.put(`${apiRoot}/academy-credits`, {
    action: 'update',
    ...data
  });
  console.log('paypal response', response.data);

  return response.data;
};
