/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import ForgetPassword from 'components/authentication/simple/ForgetPassword';

import IconAlert from 'components/common/IconAlert';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ForgetPasswordPage = () => {
  const trans = useTranslation();
  const params = useParams();
  const { userType } = params as any;
  const { t } = trans as any;

  return (
    /* @ts-ignore */
    <Section className="py-0">
      <Row className="py-6">
        <Row className="flex-center pb-4">
          <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
            {/* @ts-ignore */}
            {/* <Logo /> */}
            <Card>
              <Card.Body className="p-4 p-sm-5">
                <ForgetPassword />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="flex-center pb-2">
          {userType === 'academy' && (
            <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
              <IconAlert
                variant="warning"
                hideIcon={true}
                dismissible={undefined}
                className={undefined}
                onClose={undefined}
              >
                <p className="mb-2 text-font-xs">
                  {t('forgetPassword.warningText')}
                </p>
                <p className="mb-0 text-font-xs">
                  {t('academyRegistration.warningAthleteSignup')}{' '}
                  <span>
                    <Link to="/forgot-password">
                      <span>
                        {t('forgetPassword.warningAthleteForgotPasswordBtn')}
                      </span>
                    </Link>
                  </span>
                </p>
              </IconAlert>
            </Col>
          )}
        </Row>
      </Row>
    </Section>
  );
};

export default ForgetPasswordPage;
