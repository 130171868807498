import React, { useContext } from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes
} from 'routes/routes';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
import NavbarDropdownApp from './NavbarDropdownApp';
import NavbarDropdownPages from './NavbarDropdownPages';
import NavbarNavLink from './NavbarNavLink';
import NavbarDropdownModules from './NavbarDropdownModules';
import AppContext from 'context/Context';
import { useTranslation } from 'react-i18next';

const NavbarTopDropDownMenus = () => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const { t } = useTranslation();
  const routes = t('publicHomeMenuItems', { returnObjects: true });

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      {routes.children.map(route => {
        return route.children ? (
          <NavbarDropdown title={route.name}>
            {route.children &&
              route.children.map(subRoute => {
                return (
                  <Dropdown.Item
                    key={subRoute.label}
                    as={Link}
                    className="text-500"
                    to={subRoute.to}
                    onClick={handleDropdownItemClick}
                  >
                    {subRoute.name}
                  </Dropdown.Item>
                );
              })}
          </NavbarDropdown>
        ) : (
          <NavbarNavLink
            label={route.name}
            route={route}
            className="nav-link fw-semi-bold"
          />
        );
      })}
    </>
  );
};

export default NavbarTopDropDownMenus;
