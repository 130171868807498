import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';

const MacthesHeader = ({ selectedRowIds }) => {
  const { t } = useTranslation();
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          {t('matchesHeader.title')}
        </h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div id="orders-actions">
          <Link to="/dashboard/create-match">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">
                {t('bracketHeader.new')}
              </span>
            </IconButton>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

MacthesHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default MacthesHeader;
