import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

const getUserRegistrations = async (uuid) => {
  
  try {
    const response = await axios.get(
      `${apiRoot}/user-registrations/${uuid}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
export default getUserRegistrations;
