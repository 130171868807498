import React, { useContext } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { lowerCase } from 'lodash';

import { useTranslation } from 'react-i18next';

const BillingHeader = ({ selectedPlan, period }) => {
  const { t } = useTranslation();

  const { name, priceAnnually, priceMonthly } = selectedPlan;

  return (
    <PageHeader
      title={t('subscriptions.checkout.getStarted')}
      description={`${name} - $${
        lowerCase(period) === 'monthly' ? priceMonthly : priceAnnually
      } ${period}`}
      className="mb-3"
    >
      <Button as={Link} to="/dashboard/subscriptions">
        {t('subscriptions.checkout.changePlanBtn')}
      </Button>
    </PageHeader>
  );
};

export default BillingHeader;
