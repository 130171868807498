import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export const InactiveUserAlert = () => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('alert mb-4 rounded-0 py-3 px-card alert-warning')}
    >
      <Row className="flex-between-center">
        <Col>
          <Flex>
            <FontAwesomeIcon
              icon="circle"
              className={classNames('mt-1 fs--2')}
            />
            <p className="fs--1 ps-2 mb-0">{t('activities.inactiveUser')}</p>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};
