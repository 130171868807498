import { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Col, Form, Row, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MyCKEditor from './MyCKEditor';
import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  LoadScript
} from '@react-google-maps/api';
import DefaultDateTimePicker from 'components/common/DateTimePicker';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import countryList from 'react-select-country-list';

let markerArray = [];
const lib = ['places'];
const key = process.env.REACT_APP_GOOGLE_API_KEY;

const EventDetails = ({
  errors,
  register,
  setValue,
  getValues,
  trigger,
  setError,
  control
}) => {
  const [formData, setFormData] = useState({
    startDate: null,
    earlyRegistrationDate: null,
    normalRegistrationDate: null,
    lateRegistrationDate: null,
    locationLat: null,
    locationLng: null
  });

  const countriesOptions = useMemo(() => countryList().getData(), []);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [eventDate, setEventDate] = useState(null);
  const [earlyRegistrationDate, setEarlyRegistrationDate] = useState('');
  const [normalRegistrationDate, setNormalRegistrationDate] = useState('');
  const [lateRegistrationDate, setLateRegistrationDate] = useState('');
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);

  const { t } = useTranslation();

  const onMapLoad = useCallback(map => {
    setMap(map);
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentLocation(pos);
      }
    );

    const google = window.google;
    google.maps.event.addListener(map, 'bounds_changed', () => {
      setBounds(map.getBounds());
    });
  }, []);

  const onSBLoad = useCallback(searchBox => {
    setSearchBox(searchBox);
  }, []);

  const onPlacesChanged = () => {
    markerArray = [];
    let results = searchBox.getPlaces();
    if (results.length == 1) {
      for (let i = 0; i < results.length; i++) {
        let place = results[i].geometry.location;
        setValue('locationLat', place.lat(), { shouldValidate: true });
        setValue('locationLng', place.lng(), { shouldValidate: true });
        setValue(
          'location',
          `https://www.google.com/maps/search/?api=1&query=${place.lat()},${place.lng()}`,
          { shouldValidate: true }
        );
        markerArray.push(place);
      }
      setMarkers(markerArray);
    }
  };

  const checkKeyDown = e => {
    if (e.code === 'Enter') e.preventDefault();
  };

  useEffect(() => {
    setValue('early_registration_final_date', earlyRegistrationDate, {
      shouldValidate: true
    });
  }, [earlyRegistrationDate]);

  useEffect(() => {
    setValue('normal_registration_final_date', normalRegistrationDate, {
      shouldValidate: true
    });
  }, [normalRegistrationDate]);

  useEffect(() => {
    setValue('late_registration_final_date', lateRegistrationDate, {
      shouldValidate: true
    });
  }, [lateRegistrationDate]);

  useEffect(() => {
    setValue('event_date', eventDate, { shouldValidate: true });
  }, [eventDate]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.eventDetails')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>
                  {t('events.createEvents.fields.eventTitle')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder={t(
                    'events.createEvents.fields.eventTitlePlaceholder'
                  )}
                  isInvalid={!!errors.name}
                  {...register('name', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name && errors.name.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="startDate">
                <Form.Label>
                  {t('events.createEvents.fields.eventDate')}
                </Form.Label>
                <Controller
                  control={control}
                  name="startDate"
                  render={() => (
                    <DefaultDateTimePicker
                      isInvalid={!!errors.startDate}
                      errorMessage={
                        errors.startDate ? errors.startDate.message : ''
                      }
                      value={formData.startDate}
                      onValueChange={newDate => {
                        handleChange('startDate', newDate);
                        setValue('startDate', newDate, {
                          shouldValidate: true
                        });
                        let newEventDate = newDate
                          ? moment(newDate).format('YYYY-MM-DD HH:mm:ss')
                          : '';
                        setEventDate(newEventDate);
                      }}
                    />
                  )}
                  {...register('startDate', {
                    required: true,
                    validate: newValue => {
                      const value = getValues('startDate');
                      const result = value;
                      return (
                        result ||
                        t(
                          'events.createEvents.fields.startDateValidationMessage'
                        )
                      );
                    }
                  })}
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="timezone">
                <Form.Label>
                  {t('events.createEvents.fields.timeZone')}
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="timezone"
                  isInvalid={!!errors.timezone}
                  {...register('timezone', { required: true })}
                >
                  {timezones.map(item => (
                    <option
                      value={`${item.offset}`}
                      key={`${item.offset}/${item.name}`}
                    >
                      {`${item.offset}/${item.name}`}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.timezone && errors.timezone.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="earlyRegistrationDate">
                <Form.Label>
                  {t('events.createEvents.fields.earlyRegistrationDate')}
                </Form.Label>
                <Controller
                  control={control}
                  name="earlyRegistrationDate"
                  render={() => (
                    <DefaultDateTimePicker
                      isInvalid={!!errors.earlyRegistrationDate}
                      errorMessage={
                        errors.earlyRegistrationDate
                          ? errors.earlyRegistrationDate.message
                          : ''
                      }
                      value={formData.earlyRegistrationDate}
                      onValueChange={async newDate => {
                        handleChange('earlyRegistrationDate', newDate);
                        setValue('earlyRegistrationDate', newDate, {
                          shouldValidate: true
                        });
                        let newEventDate = newDate
                          ? moment(newDate).format('YYYY-MM-DD HH:mm:ss')
                          : '';
                        setEarlyRegistrationDate(newEventDate);
                        await trigger('startDate');
                        await trigger('normalRegistrationDate');
                        await trigger('lateRegistrationDate');
                      }}
                    />
                  )}
                  {...register('earlyRegistrationDate', {
                    required: false,
                    validate: newValue => {
                      if (newValue) {
                        const value = getValues('earlyRegistrationDate');
                        const dt1 = getValues('normalRegistrationDate');
                        const dt2 = getValues('lateRegistrationDate');
                        const result =
                          moment(value).isBefore(moment(dt1)) &&
                          moment(value).isBefore(moment(dt2));
                        return (
                          result ||
                          t(
                            'events.createEvents.fields.earlyRegDateValidationMessage'
                          )
                        );
                      }
                    }
                  })}
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="normalRegistrationDate">
                <Form.Label>
                  {t('events.createEvents.fields.normalRegistrationDate')}
                </Form.Label>
                <Controller
                  control={control}
                  name="normalRegistrationDate"
                  render={() => (
                    <DefaultDateTimePicker
                      isInvalid={!!errors.normalRegistrationDate}
                      errorMessage={
                        errors.normalRegistrationDate
                          ? errors.normalRegistrationDate.message
                          : ''
                      }
                      value={formData.normalRegistrationDate}
                      onValueChange={async newDate => {
                        handleChange('normalRegistrationDate', newDate);
                        setValue('normalRegistrationDate', newDate, {
                          shouldValidate: true
                        });
                        let newEventDate = newDate
                          ? moment(newDate).format('YYYY-MM-DD HH:mm:ss')
                          : '';
                        setNormalRegistrationDate(newEventDate);
                        await trigger('startDate');
                        await trigger('earlyRegistrationDate');
                        await trigger('lateRegistrationDate');
                      }}
                    />
                  )}
                  {...register('normalRegistrationDate', {
                    required: false,
                    validate: newValue => {
                      if (newValue) {
                        const value = getValues('normalRegistrationDate');
                        const dt1 = getValues('earlyRegistrationDate');
                        const dt2 = getValues('lateRegistrationDate');
                        const result =
                          moment(value).isAfter(moment(dt1)) &&
                          moment(value).isBefore(moment(dt2));
                        return (
                          result ||
                          t(
                            'events.createEvents.fields.normalRegDateValidationMessage'
                          )
                        );
                      }
                    }
                  })}
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="lateRegistrationDate">
                <Form.Label>
                  {t('events.createEvents.fields.lateRegistrationDate')}
                </Form.Label>
                <Controller
                  control={control}
                  name="lateRegistrationDate"
                  render={() => (
                    <DefaultDateTimePicker
                      isInvalid={!!errors.lateRegistrationDate}
                      errorMessage={
                        errors.lateRegistrationDate
                          ? errors.lateRegistrationDate.message
                          : ''
                      }
                      value={formData.lateRegistrationDate}
                      onValueChange={async newDate => {
                        handleChange('lateRegistrationDate', newDate);
                        setValue('lateRegistrationDate', newDate, {
                          shouldValidate: true
                        });
                        let newEventDate = newDate
                          ? moment(newDate).format('YYYY-MM-DD HH:mm:ss')
                          : '';
                        setLateRegistrationDate(newEventDate);
                        await trigger('startDate');
                        await trigger('earlyRegistrationDate');
                        await trigger('normalRegistrationDate');
                      }}
                    />
                  )}
                  {...register('lateRegistrationDate', {
                    required: false,
                    validate: newValue => {
                      return true;
                    }
                  })}
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Label htmlFor="contact_website">
                {t('events.createEvents.fields.contactWebsite')}
              </Form.Label>
              <InputGroup className="mb-3" controlId="contact_website">
                <InputGroup.Text id="contact_website">
                  <span className="bi-globe"></span>
                </InputGroup.Text>
                <Form.Control
                  id="contact_website"
                  aria-describedby="contact_website"
                  type="text"
                  name="contact_website"
                  placeholder={t(
                    'events.createEvents.fields.contactWebsitePlaceholder'
                  )}
                  isInvalid={!!errors.contact_website}
                  {...register('contact_website', { required: false })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_website && errors.contact_website.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col md="6">
              <Form.Label htmlFor="contact_phone">
                {t('events.createEvents.fields.contactPhone')}
              </Form.Label>
              <InputGroup className="mb-3" controlId="contact_phone">
                <InputGroup.Text id="contact_phone">
                  <span className="bi-telephone"></span>
                </InputGroup.Text>
                <Form.Control
                  id="contact_phone"
                  aria-describedby="contact_phone"
                  type="text"
                  name="contact_phone"
                  placeholder={t(
                    'events.createEvents.fields.contactPhonePlaceholder'
                  )}
                  isInvalid={!!errors.contact_phone}
                  {...register('contact_phone', { required: false })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_phone && errors.contact_phone.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col md="6">
              <Form.Label htmlFor="contact_email">
                {t('events.createEvents.fields.contactEmail')}
              </Form.Label>
              <InputGroup className="mb-3" controlId="contact_email">
                <InputGroup.Text id="contact_email">
                  <span className="bi-mailbox"></span>
                </InputGroup.Text>
                <Form.Control
                  id="contact_email"
                  aria-describedby="contact_email"
                  type="text"
                  name="contact_email"
                  placeholder={t(
                    'events.createEvents.fields.contactEmailPlaceholder'
                  )}
                  isInvalid={!!errors.contact_email}
                  {...register('contact_email', { required: false })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_email && errors.contact_email.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col md="6">
              <Form.Label htmlFor="contact_facebook">
                {t('events.createEvents.fields.contactFacebook')}
              </Form.Label>
              <InputGroup className="mb-3" controlId="contact_facebook">
                <InputGroup.Text id="contact_facebook">
                  <span className="bi-facebook"></span>
                </InputGroup.Text>
                <Form.Control
                  id="contact_facebook"
                  aria-describedby="contact_facebook"
                  type="text"
                  name="contact_facebook"
                  placeholder={t(
                    'events.createEvents.fields.contactFacebookPlaceholder'
                  )}
                  isInvalid={!!errors.contact_facebook}
                  {...register('contact_facebook', { required: false })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_facebook && errors.contact_facebook.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col md="6">
              <Form.Label htmlFor="contact_instagram">
                {t('events.createEvents.fields.contactInstagram')}
              </Form.Label>
              <InputGroup className="mb-3" controlId="contact_instagram">
                <InputGroup.Text id="contact_instagram">
                  <span className="bi-instagram"></span>
                </InputGroup.Text>
                <Form.Control
                  id="contact_instagram"
                  aria-describedby="contact_instagram"
                  type="text"
                  name="contact_instagram"
                  placeholder={t(
                    'events.createEvents.fields.contactInstagramPlaceholder'
                  )}
                  isInvalid={!!errors.contact_instagram}
                  {...register('contact_instagram', { required: false })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_instagram && errors.contact_instagram.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            <Col md="6">
              <Form.Label htmlFor="country">
                {t('events.createEvents.fields.country')}
              </Form.Label>
              <Select
                options={countriesOptions}
                className="country-select-control"
                placeholder={t('events.createEvents.fields.country')}
                onChange={country => {
                  setValue('country', country.value);
                }}
              />
            </Col>

            <Col md="12">
              <div className="border-dashed-bottom"></div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.information')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="information">
                <MyCKEditor
                  onEditorChange={data =>
                    setValue('information', data, { shouldValidate: true })
                  }
                  {...register('information', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.prizes')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="prizes">
                <MyCKEditor
                  onEditorChange={data =>
                    setValue('prizes', data, { shouldValidate: true })
                  }
                  {...register('prizes', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.categories')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="categories">
                <MyCKEditor
                  onEditorChange={data =>
                    setValue('categories', data, { shouldValidate: true })
                  }
                  {...register('categories', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.location')}
        </Card.Header>
        <Card.Body className="bg-light">
          <LoadScript googleMapsApiKey={key} libraries={lib}>
            <Row className="gx-2 gy-3">
              <Col md="8">
                <GoogleMap
                  center={currentLocation}
                  zoom={10}
                  onLoad={onMapLoad}
                  mapContainerStyle={{ height: '400px', width: '100%' }}
                >
                  {markers.map((mark, index) => (
                    <Marker key={index} position={mark} />
                  ))}
                </GoogleMap>
              </Col>
              <Col md="4" rowSpan={2}>
                <Form.Group controlId="locationLat">
                  <Form.Label>{t('events.createEvents.fields.lat')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="locationLat"
                    placeholder={t('events.createEvents.fields.lat')}
                    {...register('locationLat', { required: false })}
                  />
                </Form.Group>
                <Form.Group controlId="locationLng">
                  <Form.Label>{t('events.createEvents.fields.lng')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="locationLng"
                    placeholder={t('events.createEvents.fields.lng')}
                    {...register('locationLng', { required: false })}
                  />
                </Form.Group>
              </Col>
            </Row>
          </LoadScript>
        </Card.Body>
      </Card>
    </>
  );
};

EventDetails.propTypes = {
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired
};
export default EventDetails;
