const round = {
    bracketMatchesMatchWinner: null,
    matchId: 0,
    academy: {
        id: 1,
        name: "Site"
    },
    participant1: {
        id: 0,
        name: "Participant",
        country: "mockFlag",
        academy: {
            id: 1,
            name: "Site"
        }
    },
    participant2: {
        id: 0,
        name: "Participant",
        country: "mockFlag",
        academy: {
            id: 1,
            name: "Site"
        }
    },
    matchType: "Single Elimination",
    matchGender: "Unknown",
    giNogi: 1,
    seniority: "Unknown",
    weight: "Unknown",
    duration: "Unknown",
    connectionId: null,
    matchEndtime: null,
    matchDatetime: "Unknown",
    matchTimeZone: "UTC-6",
    match_result: {
        match_winner: null,
        match_loser: null,
        result: null
    }
};

const mockBronze = [
    round
];
const mockFinal = [
    round
];

const mockRound3 = [
    round,
    round
];

const mockRound2_8 = [
    round,
    round
];

const mockRound2_16 = [
    round,
    round,
    round,
    round
];

const mockRound1 = [
    round,
    round,
    round,
    round
];



export { mockFinal, mockRound2_8, mockRound2_16, mockRound3, mockBronze, mockRound1 }
