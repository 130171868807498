/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import BracketDetail from 'components/dashboards/brackets/bracket-round';
import getBracket from 'api/brackets/get-bracket';
import { Alert, Placeholder, Card, Button, Spinner } from 'react-bootstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import updateBracket, {
  updateBracketStatus
} from 'api/brackets/update-bracket';
import {
  mockFinal,
  mockRound3,
  mockRound2_8,
  mockRound2_16,
  mockBronze,
  mockRound1
} from 'api/brackets/mock_data';

import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import { connect } from 'react-redux';

import corner1 from 'assets/img/icons/spot-illustrations/corner-5.png';
import ParticipantAvatar from './participantAvatar';
import ButtonAddParticipant from './button-add-participant/ButtonAddParticipant';
import ButtonClearMatches from './ButtonClearMatches';
import { every, first, find, some, get, isEmpty, lowerCase } from 'lodash';

import friendlyUrl from 'friendly-url';


import { toast } from 'react-toastify';
import getEventDetail from 'api/events/get-event';
import getPublicEventDetail from 'api/events/get-public-event-detail';

const mapStateToProp = state => ({
  isAcademy: state.auth.isAcademy,
  signedInAcademyId: state.auth.academyId,
});

const matchToRoundSeed = match => ({
  id: match.matchId,
  date: match.matchDatetime,
  bracketUUID: match.bracketUUID,
  bracketId: match.bracketId,
  round: match.round,
  matchPosition: match.matchPosition,
  academy: {
    id: match.academy.id,
    name: match.academy.name
  },
  matchResults: JSON.parse(match.match_result.result),
  matchType: match.matchType,
  matchWinnerId: match.matchWinnerId,
  matchGender: match.matchGender,
  giNogi: match.giNogi,
  seniority: match.seniority,
  weight: match.weight,
  duration: match.duration,
  connectionId: match.connectionId,
  matchEndtime: match.matchEndtime,
  matchDatetime: match.matchDatetime,
  matchTimeZone: match.matchTimeZone,
  teams: [
    {
      name: match.participant1.name,
      id: match.participant1.id,
      country: match.participant1.country,
      academy: {
        id: match.participant1.academy.id,
        name: match.participant1.academy.name
      },
      isWinner:
        match.match_result.match_winner === match.participant1.id ?? false,
      points:
        match.match_result.result !== null
          ? match.match_result.match_winner === match.participant1.id
            ? JSON.parse(match.match_result.result).winner.points
            : JSON.parse(match.match_result.result).loser.points
          : 0
    },
    {
      name: match.participant2.name,
      id: match.participant2.id,
      country: match.participant2.country,
      academy: {
        id: match.participant2.academy.id,
        name: match.participant2.academy.name
      },
      isWinner:
        match.match_result.match_winner === match.participant2.id ?? false,
      points:
        match.match_result.result !== null
          ? match.match_result.match_winner === match.participant2.id
            ? JSON.parse(match.match_result.result).winner.points
            : JSON.parse(match.match_result.result).loser.points
          : 0
    }
  ]
});


const Bracket = ({ isAcademy, signedInAcademyId }) => {
  const [roundData, setRoundData] = useState([]);
  const [bracketHeader, setBracketHeader] = useState({});
  const [errorHandledBracket, setErrorHandledBracket] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetch, setRefetch] = useState(false);
  const [sendData, setSendData] = useState(false);
  const [participantsIds, setParticipantsIds] = useState([]);
  const [eventUuid, setEventUuid] = useState('');
  const [bracketDataInfo, setBracketDataInfo] = useState({});
  const [eventInfo, setEventInfo] = useState({});

  const { academyId, bracketUUID, eventID } = useParams();
  const [showRemainingUsers, setShowRemainingUsers] = useState(false);

  const isCreatorAcademy = isAcademy && eventInfo?.event?.academy_id == signedInAcademyId;

  //check if any match in the Bracket has a match_winner (we don't count 'Bye' as a match_winner)
  //we use this to determine if we want to hide the 'Clear Matches' button
  //as we can't clear the Matches when a Bracket has winners already
  const doesAnyMatchInTheBracketHaveAWinner = some((get(find(eventInfo?.event?.brackets, (m) => m.bracketId === first(bracketDataInfo).bracketId), "matches") || []),
    //checking if there is a match_winner
    (b) => b.match_winner != null &&
      //excluding "Bye"
      every([b.participant2_name, b.participant1_name], (m) => lowerCase(m) !== "bye"));



  //check if RoundTwo/BronzeRound has started
  //we want to hide the 'Clear Matches' button when the Round Two / Bronze Round matches have started
  const hasRoundTwoOrBronzeRoundStarted = !isEmpty(get(get(first(bracketDataInfo), 'bronzeRound'), "matches")) || !isEmpty(get(get(first(bracketDataInfo), 'round2'), "matches"))

  const { t } = useTranslation();
  const location = useLocation();


  const getRoundData = async () => {
    setIsLoading(true);
    const bracketData = await getBracket(
      academyId,
      bracketUUID,
      location.pathname.includes('public-events')
    );

    setBracketDataInfo(bracketData);

    if (bracketData !== null) {
      const eventDateFixed = moment(Date.parse(bracketData[0]?.eventDate ?? ""));

      const ids = bracketData[0]?.participants_ids?.split(',') ?? [];
      const idFormat = ids.map(id => id.trim());
      setParticipantsIds(idFormat);
      setEventUuid(bracketData[0]?.eventUUID ?? '');

      const bracketEventInfo = location.pathname.includes('dashboard') ? await getEventDetail(academyId, bracketData[0]?.eventUUID || "") : await getPublicEventDetail(bracketData[0]?.eventUUID || "");
      setEventInfo(bracketEventInfo);

      setBracketHeader({
        ...bracketHeader,
        bracketTitle: bracketData[0]?.description,
        bracketType: bracketData[0]?.type,
        eventName: bracketData[0]?.eventName || bracketEventInfo?.title || "",
        participants: bracketData[0]?.participants_names,
        listParticipants: bracketData[0]?.participants_names
          ? bracketData[0]?.participants_names?.split(',')
          : [],
        avatars: bracketData[0]?.participantsAvatarUrl ?? [],
        ids: bracketData[0]?.participants_ids?.split(',') ?? [],
        area: bracketData[0]?.mat,
        eventDate: eventDateFixed
          .locale(localStorage.getItem('i18nextLng'))
          .format('LLLL'),
        gender: bracketData[0]?.gender,
        seniority: bracketData[0]?.seniority,
        weight: bracketData[0]?.weight,
        duration: bracketData[0]?.duration,
        day: eventDateFixed
          .locale(localStorage.getItem('i18nextLng'))
          .format('D'),
        month: eventDateFixed
          .locale(localStorage.getItem('i18nextLng'))
          .format('MMM')
      });
      const roundNames = Object.keys(bracketData[0] || {}).filter(
        key => key.startsWith('round') && key !== 'bronzeRound'
      );

      const rounds = roundNames.map(name => {
        return {
          next:
            bracketData[0]?.participants_names?.split(',').length === 3 ?
              999
              :
              roundNames.indexOf(name) === roundNames.length - 1 ?
                998
                :
                parseInt(name.replace('round', '')) + 1,
          title: t('brackets.round') + name.replace('round', ' '),
          number: name.replace('round', ''),
          seeds: getMocksByRount(bracketData, name),

          isDataFake: bracketData[0][name].matches.length == 0,
          participantsNumber: bracketData[0]?.participants_names?.split(',').length
        };
      });

      if (bracketData[0]?.final) {
        rounds.push({
          next: 0,
          title: 'Final',
          number: (rounds.length + 1).toString(),
          seeds:
            bracketData[0]?.final?.matches.length > 0
              ? bracketData[0]?.final?.matches?.map(matchToRoundSeed)
              : mockFinal.map(matchToRoundSeed),
          isDataFake: bracketData[0]?.final.matches.length == 0,
          participantsNumber: bracketData[0]?.participants_names?.split(',').length
        });
      }
      if (bracketData[0]?.bronzeRound) {
        rounds.push({
          next: bracketData[0]?.participants_names?.split(',').length === 3
            ?
            998
            :
            0,
          title: t('brackets.bronze'),
          number: (rounds.length + 1).toString(),
          seeds:
            bracketData[0]?.bronzeRound?.matches.length > 0
              ? bracketData[0]?.bronzeRound?.matches?.map(matchToRoundSeed)
              : mockBronze.map(matchToRoundSeed),
          isDataFake: bracketData[0]?.bronzeRound.matches.length == 0,
          participantsNumber: bracketData[0]?.participants_names?.split(',').length
        });
      }
      setRoundData(rounds);
    } else {
      setErrorHandledBracket(true);
    }
  };

  const getMocksByRount = (bracketData, name) => {

    if (bracketData[0][name].matches.length > 0) {
      return bracketData[0][name].matches.map(matchToRoundSeed)
    }
    else if (bracketData[0]?.participants_names?.split(',').length < 5 || parseInt(name.replace('round', '')) === 2) {
      if (bracketData[0]?.participants_names?.split(',').length <= 8)
        return mockRound2_8.map(matchToRoundSeed)
      if (bracketData[0]?.participants_names?.split(',').length > 8)
        return mockRound2_16.map(matchToRoundSeed)
    }
    else if (bracketData[0]?.participants_names?.split(',').length < 8 || parseInt(name.replace('round', '')) === 3) {
      return mockRound3.map(matchToRoundSeed)
    }
    else {
      return mockRound1.map(matchToRoundSeed)
    }
  }

  useEffect(() => {
    getRoundData().then(v => setIsLoading(false));

  }, [t, isRefetch]);

  useEffect(() => {
    updateStatusFisnihBracket();
  }, [bracketDataInfo, roundData]);

  const toggleRemainingUsers = () => {
    setShowRemainingUsers(!showRemainingUsers);
  };

  const getAvatarProfile = () => {
    const visibleUsers = bracketHeader.listParticipants.slice(0, 6);
    const remainingUsers = bracketHeader.listParticipants.slice(visibleUsers.length)

    return (
      <>
        {visibleUsers.map((avatar, index) => (
          <Col className='mt-1 col-auto' style={{ width: '60px' }}>
            <ParticipantAvatar
              setRefetch={setRefetch}
              refetch={isRefetch}
              key={`i--9${index}`}
              id={bracketHeader.ids[index]}
              avatar={bracketHeader.avatars[index]}
              name={bracketHeader.listParticipants[index]}
              isCreatorAcademy={isCreatorAcademy}
            />
          </Col>
        ))}

        {showRemainingUsers &&
          remainingUsers.map((avatar, index) => (
            <Col className='mt-1 col-auto' style={{ width: '60px' }}>
              <ParticipantAvatar
                setRefetch={setRefetch}
                refetch={isRefetch}
                key={`i--9${visibleUsers.length + index}`}
                id={bracketHeader.ids[visibleUsers.length + index]}
                avatar={bracketHeader.avatars[visibleUsers.length + index]}
                name={bracketHeader.listParticipants[visibleUsers.length + index]}
              isCreatorAcademy={isCreatorAcademy}
              />
            </Col>
          ))
        }

        {remainingUsers.length > 0 && (
          <Col className='mt-3 col-auto'>
            <Button
              className='ms-3'
              variant="falcon-primary"
              size="sm"
              onClick={toggleRemainingUsers}>
              {showRemainingUsers ? `-${remainingUsers.length}` : `+${remainingUsers.length}`}
              <FontAwesomeIcon icon="user" className="ms-2 fs--1" />
            </Button>
          </Col>
        )}

      </>
    );
  }

  const onGenerateRound = async round => {
    setSendData(true);
    const data = {
      academyID: academyId,
      bracketUUID,
      eventID: eventID,
      round
    };
    const wasUpdated = await updateBracket(data);
    if (wasUpdated) {
      setSendData(false);
      window.location.reload();
    }
  };

  const message = errorHandledBracket == true ? t('brackets.messageError') : '';

  const showButtonGenerateFirstRound = () => {
    if (roundData.length > 0) {
      if (
        roundData[0].isDataFake &&
        bracketHeader.participants?.split(',').length > 0
      ) {
        return generateButton();
      }
      else if (roundData[2]?.isDataFake &&
        bracketHeader.participants?.split(',').length === 3) {
        return bronzeButton();
      }
      else {
        //If exist a final with bracket
        if (roundData.some(round => round.title === 'Final' && !round.isDataFake)) {
          return;
        }

        return finalButton();
      }
    }
  };

  const updateStatusFisnihBracket = async () => {
    //if bracket is null
    if (!first(bracketDataInfo)) {
      return null;
    }

    //if the bracket hasn't started yet
    //then return null
    if (!first(bracketDataInfo)?.startTime) {
      return null;
    }

    //if the bracket has ended
    //then return null
    if (!!first(bracketDataInfo)?.endTime) {
      return null;
    }

    if (roundData.length === 0) {
      return null;
    }

    //check if every match has a result.
    const allMatchesHaveMatchResults = every(roundData, round => {
      return every(round.seeds, seed => {
        return !!seed.matchResults;
      });
    });

    //if not every match has a result,
    //then return null
    if (!allMatchesHaveMatchResults) {
      return null;
    }

    try {

      await updateBracketStatus({
        bracketInfo: first(bracketDataInfo),
        time: 'endTime'
      });

      return toast.success(t('brackets.finishBracketsSuccessToast'), {
        autoClose: 2000
      });

    } catch (error) {
    } finally {
      return
    }
  }


  const generateButton = () => {
    return !sendData ? (
      <Button
        variant="falcon-primary"
        size="sm"
        onClick={
          !sendData
            ? () =>
              onGenerateRound(
                bracketHeader.participants?.split(',').length < 3 ? 998 : 1
              )
            : () => { }
        }
      >
        {'  '}
        {t('brackets.generateRounds')}
        <FontAwesomeIcon icon="chevron-right" className="ms-2 fs--1" />
      </Button>
    ) : (
      <Button variant="primary" disabled>
        <Spinner animation="border" size="sm" />
        {'  '}
        {t('brackets.generateRounds')}
      </Button>
    );
  }

  const bronzeButton = () => {
    //Filtering only Round titles
    const roundFilter = roundData.filter(round => round.next !== 0);
    for (const key in roundFilter) {
      //The seeds must be greater than 0
      // every seed must have at least ONE winner
      if (
        !roundFilter[key].isDataFake > 0 &&
        roundFilter[key].seeds.every(
          seed => seed.teams[0].isWinner || seed.teams[1].isWinner
        )
      )
        if (
          roundFilter.some(
            round =>
              round.title == `${t('brackets.round')} ${roundFilter[key].next}` && round.isDataFake
          ) || roundFilter[key].next === 999
        ) {
          // if the next is a Final or
          // if the next round is'nt a final, so, these round must be have the seed in 0
          return !sendData ? (
            <Button
              className="ms-3 "
              variant="falcon-primary"
              size="sm"
              onClick={
                !sendData
                  ? () => onGenerateRound(roundFilter[key].next)
                  : () => { }
              }
            >
              {t('brackets.startRound')}{' '}
              {roundFilter[key].next == 999
                ? t('brackets.bronze')
                : roundFilter[key].next}
              <FontAwesomeIcon
                icon="chevron-right"
                className="ms-2 fs--1"
              />
            </Button>
          ) : (
            <Button variant="primary" disabled>
              <Spinner animation="border" size="sm" />
              {'  '}
              {t('brackets.startRound')}{' '}
              {roundFilter[key].next == 999
                ? t('brackets.bronze')
                : roundFilter[key].next}
            </Button>
          );
        }
    }
  }

  const finalButton = () => {
    //Filtering only Round titles
    const roundFilter = roundData.filter(round => round.next !== 0);

    for (const key in roundFilter) {
      //The seeds must be greater than 0
      // every seed must have at least ONE winner
      if (
        !roundFilter[key].isDataFake > 0 &&
        roundFilter[key].seeds.every(
          seed => seed.teams[0].isWinner || seed.teams[1].isWinner
        )
      )
        if (
          roundFilter.some(
            round =>
              round.title ==
              `${t('brackets.round')} ${roundFilter[key].next}` &&
              round.isDataFake
          ) ||
          roundFilter[key].next === 998
        ) {
          // if the next is a Final or
          // if the next round is'nt a final, so, these round must be have the seed in 0
          return !sendData ? (
            <Button
              className="ms-3 "
              variant="falcon-primary"
              size="sm"
              onClick={
                !sendData
                  ? () => onGenerateRound(roundFilter[key].next)
                  : () => { }
              }
            >
              {t('brackets.startRound')}{' '}
              {roundFilter[key].next == 998
                ? 'Final'
                : roundFilter[key].next}
              <FontAwesomeIcon
                icon="chevron-right"
                className="ms-2 fs--1"
              />
            </Button>
          ) : (
            <Button variant="primary" disabled>
              <Spinner animation="border" size="sm" />
              {'  '}
              {t('brackets.startRound')}{' '}
              {roundFilter[key].next == 998
                ? 'Final'
                : roundFilter[key].next}
            </Button>
          );
        }
    }
  }

  const cardHeaderEventDetails = {
    day: bracketHeader?.day,
    month: bracketHeader?.month
  };

  return (
    <div className="brackets-page">
      {isLoading ? (
        <Card
          style={{ width: '100%', height: 200 }}
          className="overflow-hidden"
        >
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow" className="mb-2">
              <Placeholder xs={4} />
            </Placeholder>
            <Placeholder as={Card.Title} animation="glow" className="mb-2">
              <Placeholder xs={4} />
            </Placeholder>
            <div className="row">
              {[1, 2, 3].map(item => (
                <div className="col-2" key={`pkd-23n32-${item}`}>
                  <div className="">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </div>
                  <div className="">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </div>
                </div>
              ))}
              <div className="col-6">
                <div className="">
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </div>
                <div className="">
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </div>
              </div>

              {[1, 2, 3].map(item => (
                <div className="col-2 mt-2" key={`p-33cw-w32-${item}`}>
                  <div className="">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </div>
                  <div className="">
                    <Placeholder as={Card.Text} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </div>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      ) : (
        ''
      )}

      {bracketHeader && !isLoading && (
        <Card className="h-100" body={false}>
          <Background image={corner1} className="rounded-soft bg-card" />
          <Card.Header className="z-index-1 pt-3 pb-0">
            <Col>
              <Flex>
                {bracketHeader?.month && (
                  <Calendar {...cardHeaderEventDetails} />
                )}
                <div className="fs--1 ms-3 flex-1">

                  <Link to={location.pathname.includes('dashboard')
                    ? `/dashboard/events/event-detail/${academyId}/${eventUuid}`
                    : `/public-events/${friendlyUrl(bracketHeader.eventName || t('brackets.informationBracket'))}/${eventUuid}`}
                  >
                    <h3 className="fs-2 m-0 text-capitalize text-primary">
                      {(bracketHeader.eventName || t('brackets.informationBracket'))}
                    </h3>
                  </Link>
                  <p className="mb-1 fs--1 text-capitalize">
                    {bracketHeader.eventDate}
                  </p>
                  <span className="fs--1 fw-semi-bold">
                    {bracketHeader.bracketTitle}
                  </span>
                </div>
              </Flex>
            </Col>
          </Card.Header>
          <Card.Body className="mt-2 g-0 pt-2">
            <Row className="g-2 h-100 align-items-start">
              <Col xs={4} sm={4} md={2}>
                <Col className="p-1">
                  <Flex className="position-relative">
                    <div className="flex-1">
                      <h6 className="text-800 mb-0">{t('brackets.area')}</h6>
                      <p className="mb-0 fs--2 badge rounded-pill badge-soft-primary ">
                        {bracketHeader.area}
                      </p>
                    </div>
                  </Flex>
                </Col>
                <Col className="p-1">
                  <Flex className="position-relative">
                    <div className="flex-1">
                      <h6 className="text-800 mb-0">{t('brackets.gender')}</h6>
                      <p className="mb-0 fs--2 badge rounded-pill badge-soft-primary ">
                        {bracketHeader.gender}
                      </p>
                    </div>
                  </Flex>
                </Col>
              </Col>
              <Col xs={4} sm={4} md={2}>
                <Col className="p-1">
                  <Flex className="position-relative">
                    <div className="flex-1">
                      <h6 className="text-800 mb-0">
                        {t('brackets.seniority')}
                      </h6>
                      <p className="mb-0 fs--2 badge rounded-pill badge-soft-primary ">
                        {bracketHeader.seniority}
                      </p>
                    </div>
                  </Flex>
                </Col>
                <Col className="p-1">
                  <Flex className="position-relative">
                    <div className="flex-1">
                      <h6 className="text-800 mb-0">{t('brackets.weight')}</h6>
                      <p className="mb-0 fs--2 badge rounded-pill badge-soft-primary ">
                        {bracketHeader.weight}
                      </p>
                    </div>
                  </Flex>
                </Col>
              </Col>
              <Col xs={4} sm={4} md={2}>
                <Col className="p-1">
                  <Flex className="position-relative">
                    <div className="flex-1">
                      <h6 className="text-800 mb-0">
                        {t('brackets.duration')}
                      </h6>
                      <p className="mb-0 fs--2 badge rounded-pill badge-soft-primary ">
                        {bracketHeader.duration}
                      </p>
                    </div>
                  </Flex>
                </Col>
                <Col className="p-1">
                  <Flex className="position-relative">
                    <div className="flex-1">
                      <h6 className="text-800 mb-0">
                        {t('brackets.bracketType')}
                      </h6>
                      <p className="mb-0 fs--2 badge rounded-pill badge-soft-primary ">
                        Single Elimination
                      </p>
                    </div>
                  </Flex>
                </Col>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Flex className="position-relative">
                  <div className="flex-1">
                    <h6 className="text-800 mb-0">
                      {t('brackets.participants')}
                    </h6>
                    <Row className="">
                      {bracketHeader.listParticipants && getAvatarProfile()}
                    </Row>
                    {isCreatorAcademy &&
                      (roundData.length == 0 || roundData[0]?.isDataFake) && (
                        <Col className="mt-2">
                          <ButtonAddParticipant
                            setRefetch={setRefetch}
                            isRefetch={isRefetch}
                            participantsIds={participantsIds}
                            eventUuid={eventUuid}
                          />
                        </Col>
                      )}


                    {isCreatorAcademy && !doesAnyMatchInTheBracketHaveAWinner && !hasRoundTwoOrBronzeRoundStarted && roundData.length > 0 && !roundData[0].isDataFake && (
                      <Col className="mt-2">
                        <ButtonClearMatches
                          setRefetch={setRefetch}
                          isRefetch={isRefetch}
                          academyId={academyId}
                          bracketUuid={bracketUUID}
                          eventUuid={eventUuid}
                        />
                      </Col>
                    )}
                  </div>
                </Flex>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      <div className="mt-3 mb-4">
        {isCreatorAcademy && showButtonGenerateFirstRound()}
      </div>

      {roundData.length > 0 && !roundData[0].isDataFake ? (
        <BracketDetail
          bracketDataInfo={bracketDataInfo}
          matches={roundData}
          academyId={academyId}
          isAcademy={isAcademy}
        />
      ) : (
        ''
      )}

      {message ? (
        <Alert key="danger" variant="danger">
          {message}
        </Alert>
      ) : (
        ''
      )}
    </div>
  );
};

export default connect(mapStateToProp)(Bracket);
