import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Follower from './Follower';
import { useTranslation } from 'react-i18next';

const Followers = ({ totalFollowers, followers, academy }) => {
  const { t } = useTranslation();
  
  return (
    <Card className="p-0 card-followers">
      <Card.Header >
        <Flex justifyContent="end"> 
          <Link to="/" className="font-sans-serif" hidden>
            {t('profile.allMembers')}
          </Link>
        </Flex>
      </Card.Header>
      <Card.Body className="bg-white px-1 pb-1 pt-0 fs--1">
        <Row className="gx-0 gy-1 text-center d-flex justify-content-center">
          {followers.map(follower => (
            <Col key={follower.id} xs={6} md={4} lg={2} xxl={2}>
              <Follower follower={follower} academy={academy}/>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

Followers.propTypes = {
  totalFollowers: PropTypes.number,
  followers: PropTypes.arrayOf(Follower.propTypes.follower),
  academy: PropTypes.string
};

export default Followers;
