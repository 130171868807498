import { useState, useEffect, useCallback } from 'react';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Card, Form, Button, Fade } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getAcademy from 'api/academy/get-academy';
import { format } from 'react-string-format';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

const EventOtherInfo = ({ academyId, errors, register, control }) => {
  const [academyName, setAcademyName] = useState('n/a');
  const [isMakeEventPublicBtnVisible, setIsMakeEventPublicBtnVisible] =
    useState(false);

  const fetchAcademyName = useCallback(data => {
    setAcademyName(data.name);
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getAcademy(`${academyId}`);
      fetchAcademyName(data[0]);
    })();
  }, [fetchAcademyName]);

  const { t } = useTranslation();
  const organizerOptions = [
    { value: '1', label: ' Massachusetts Institute of Technology' },
    { value: '2', label: 'University of Chicago' },
    { value: '3', label: 'GSAS Open Labs At Harvard' },
    { value: '4', label: 'California Institute of Technology' }
  ];
  const sponsorsOptions = [
    { value: '1', label: 'Microsoft Corporation' },
    { value: '2', label: 'Technext Limited' },
    { value: '3', label: ' Hewlett-Packard' }
  ];
  const tagOptions = [
    { value: '1', label: 'Concert' },
    { value: '2', label: 'New Year' },
    { value: '3', label: 'Party' }
  ];
  return (
    <Card>
      <Card.Header as="h5">
        {t('events.createEvents.eventOtherInfoTitle')}
      </Card.Header>
      <Card.Body className="bg-light">
        <div className="border-dashed-bottom my-3"></div>
        <h6>{t('events.createEvents.listingPrivacy')}</h6>

        <Form.Check
          className="mb-3 createEvent_makeEventPublic_row"
          id="publicPage"
        >
          <Form.Check.Input
            type="radio"
            value="publicPage"
            name="privacy"
            disabled={true}
            {...register(`privacy`)}
          />
          <div
            className="createEvent_makeEventPublic_row_full_width"
            onMouseEnter={() => {
              setIsMakeEventPublicBtnVisible(true);
            }}
            onMouseLeave={() => {
              setIsMakeEventPublicBtnVisible(false);
            }}
          >
            <div className="createEvent_makeEventPublic_row_full_width">
              <div className="createEvent_makeEventPublic_row createEvent_makeEventPublic_row_full_width createEvent_makeEventPublic_row_justify_between">
                <Form.Check.Label className="ms-2 mb-0 form-label fw-bold">
                  {t('events.createEvents.options.publicEvent')}
                </Form.Check.Label>

                <Fade in={isMakeEventPublicBtnVisible}>
                  <Button
                    className={classNames(
                      'rounded-pill border bg-primary createEvent_makeEventPublic_buyCreditsBtn'
                    )}
                    size="sm"
                    as={Link}
                    target="_blank"
                    to={'/dashboard/events/buy-credits'}
                  >
                    {t('events.createEvents.buyCredits.makeEventPublicBtn')}
                  </Button>
                </Fade>
              </div>
              <div className="form-text mt-0 ps-3 ms-2 ">
                {t('events.createEvents.publicEventLabel')}
              </div>
            </div>
          </div>
        </Form.Check>
        <Form.Check className="mb-3" id="privatePage">
          <Form.Check.Input
            type="radio"
            value="privatePage"
            name="privacy"
            defaultChecked
            {...register(`privacy`)}
          />
          <Form.Check.Label className="ms-2 mb-0 form-label fw-bold">
            {t('events.createEvents.options.privateEvent')}
          </Form.Check.Label>
          <div className="form-text mt-0 ps-3 ms-2 ">
            {format(t('events.createEvents.privateEventLabel'), academyName)}
          </div>
        </Form.Check>
        <div className="border-dashed-bottom my-3"></div>
      </Card.Body>
    </Card>
  );
};
EventOtherInfo.propTypes = {
  academyId: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired
};

export default EventOtherInfo;
