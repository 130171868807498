import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Spinner
} from 'react-bootstrap';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import StatusComponent from '../status/status_component';
import { useState } from 'react';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ActionComponent = ({
  uuid,
  active,
  setRefetchUser,
  showAthleteSubscriptionModal,
  width
}) => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [userUuid, setUserUuid] = useState('');
  const [activeNumber, setActiveNumber] = useState(0);
  const breakPointWidth = useBreakpoints().width;

  const showConfirmQuestion = async (userUuid, activeNumber) => {
    setUserUuid(userUuid);
    setActiveNumber(activeNumber);
    setShowQuestion(true);
  };

  return (
    <>
      {breakPointWidth < 1000 ? (
        <Flex justifyContent="center" alignItems="center">
          <ActionMobileComponent
            uuid={uuid}
            userUuid={userUuid}
            activeNumber={activeNumber}
            setShowQuestion={setShowQuestion}
            showQuestion={showQuestion}
            setRefetchUser={setRefetchUser}
            active={active}
            showConfirmQuestion={showConfirmQuestion}
            showAthleteSubscriptionModal={showAthleteSubscriptionModal}
          />
        </Flex>
      ) : (
        <ActionWebComponent
          uuid={uuid}
          userUuid={userUuid}
          activeNumber={activeNumber}
          setShowQuestion={setShowQuestion}
          showQuestion={showQuestion}
          setRefetchUser={setRefetchUser}
          active={active}
          showAthleteSubscriptionModal={showAthleteSubscriptionModal}
          showConfirmQuestion={showConfirmQuestion}
        />
      )}
    </>
  );
};

export default ActionComponent;

const ActionMobileComponent = ({
  uuid,
  userUuid,
  activeNumber,
  setShowQuestion,
  showQuestion,
  setRefetchUser,
  active,
  showConfirmQuestion,
  showAthleteSubscriptionModal
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="falcon-primary" size="sm">
        <FontAwesomeIcon icon={['fa', 'caret-down']} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="">
        <Dropdown.Item
          onClick={() => history.push(`/dashboard/user/view/${uuid}`)}
        >
          <Flex justifyContent="start" alignItems="center" className="ms-1">
            <FontAwesomeIcon
              className="text-primary me-2"
              icon={['fas', 'eye']}
            />
            <strong>{t('users.table.actions.profile')}</strong>
          </Flex>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            showAthleteSubscriptionModal();
          }}
        >
          <Flex justifyContent="start" alignItems="center" className="ms-1">
            <FontAwesomeIcon icon="fas fa-dollar-sign" />
          </Flex>
        </Dropdown.Item>
        <StatusComponent
          userUuid={userUuid}
          activeNumber={activeNumber}
          setShowQuestion={setShowQuestion}
          showQuestion={showQuestion}
          setRefetchUser={setRefetchUser}
          uuid={uuid}
          active={active}
          childComponent={
            <OverlayTrigger
              overlay={
                <Tooltip id={uuid + active}>
                  <div
                    className={active === 0 ? 'text-danger' : 'text-success'}
                  >
                    <strong>
                      {' '}
                      {active === 1
                        ? t('users.table.actions.activated')
                        : t('users.table.actions.deactivated')}
                    </strong>
                  </div>
                  <Divider />

                  <p>{t('users.table.actions.warning')}</p>
                </Tooltip>
              }
            >
              <Dropdown.Item
                onClick={() => showConfirmQuestion(uuid, active === 0 ? 1 : 0)}
              >
                <Flex
                  justifyContent="start"
                  alignItems="center"
                  className="ms-1"
                >
                  {active === 0 ? (
                    <div
                      className="bg bg-danger rounded-circle me-2"
                      style={{ width: 10, height: 10 }}
                    ></div>
                  ) : (
                    <div
                      className="bg bg-success rounded-circle me-2"
                      style={{ width: 10, height: 10 }}
                    ></div>
                  )}

                  <strong>{t('users.table.actions.changeStatus')}</strong>
                </Flex>
              </Dropdown.Item>
            </OverlayTrigger>
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
const ActionWebComponent = ({
  uuid,
  userUuid,
  activeNumber,
  setShowQuestion,
  showQuestion,
  setRefetchUser,
  active,
  showConfirmQuestion,
  showAthleteSubscriptionModal
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-evenly align-items-center">
      <Link className="btn-view" to={`/dashboard/user/view/${uuid}`}>
        <FontAwesomeIcon className="text-primary" icon={['fas', 'eye']} />
      </Link>

      <FontAwesomeIcon
        icon="fas fa-dollar-sign"
        className="cursor-pointer"
        onClick={() => {
          showAthleteSubscriptionModal();
        }}
      />

      <StatusComponent
        userUuid={userUuid}
        activeNumber={activeNumber}
        setShowQuestion={setShowQuestion}
        showQuestion={showQuestion}
        setRefetchUser={setRefetchUser}
        uuid={uuid}
        active={active}
        childComponent={
          <OverlayTrigger
            overlay={
              <Tooltip id={uuid + active}>
                <div className={active === 0 ? 'text-danger' : 'text-success'}>
                  <strong>
                    {' '}
                    {active === 1
                      ? t('users.table.actions.activated')
                      : t('users.table.actions.deactivated')}
                  </strong>
                </div>
                <Divider />

                <p>{t('users.table.actions.warning')}</p>
              </Tooltip>
            }
          >
            {active === 0 ? (
              <div
                onClick={() => showConfirmQuestion(uuid, 1)}
                className="bg bg-danger rounded-circle me-2"
                style={{ width: 10, height: 10 }}
              ></div>
            ) : (
              <div
                onClick={() => showConfirmQuestion(uuid, 0)}
                className="bg bg-success rounded-circle me-2"
                style={{ width: 10, height: 10 }}
              ></div>
            )}
          </OverlayTrigger>
        }
      />
    </div>
  );
};
