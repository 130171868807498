import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from 'components/common/ConfirmButton';
import { Link } from 'react-router-dom';

const EventHeader = ({
  disabled,
  handleUpdateEvent,
  eventId,
  eventUUID,
  academyId,
  isPrivate,
  isPublish,
  eventType,
  event_end_date
}) => {
  const { t } = useTranslation();
  const [generateMatch, seGenerateMatch] = useState(false);
  const [publishEvent, setPublishEvent] = useState(false);

  const handlePublishEvent = async () => {
    setPublishEvent(true);
    await handleUpdateEvent({
      eventId: eventId,
      academyId: academyId,
      publishEvent: true
    });

  };

  const handleGenerateEventBrackets = async () => {
    seGenerateMatch(true);
    await handleUpdateEvent({
      eventId: eventId,
      academyId: academyId,
      generateBrackets: true
    });
  };

  const handleFinishEvent = async () => {
    await handleUpdateEvent({
      eventId: eventId,
      academyId: academyId,
      finishEvent: true
    });
  };
  const validatePublicEvent = () =>{
    if(!isPrivate && !isPublish){
      return true;
    }
    return false;
    
  }

  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0"> {t('events.editEvents.title')}</h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              as={Link}
              to={`/dashboard/events/event-detail/${academyId}/${eventUUID}`}
            >
              {t('events.editEvents.backToView')}
            </Button>

            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="submit"
              disabled={disabled}
            >
              {t('events.createEvents.save')}
            </Button>

            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={handleGenerateEventBrackets}
              type="button"
              disabled={disabled}
            >
              {!generateMatch
                ? t('events.editEvents.generateBracketButton')
                : t('events.editEvents.loading')}
            </Button>

            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              onClick={handlePublishEvent}
              type="button"
              hidden={isPrivate || isPublish}
              disabled={disabled}
            >
              {!publishEvent
                ? t('events.editEvents.publishEventButton')
                : t('events.editEvents.loading')}
            </Button>

            <ConfirmButton
              confirmPopoverPlacement="left"
              buttonContent={
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="me-2"
                  type="button"
                  disabled={disabled || event_end_date !== null}
                  hidden={validatePublicEvent()}
                >
                  {t('events.editEvents.finishEventButton')}
                </Button>
              }
              confirmTitle={t('events.editEvents.confirmFinishEvent.title')}
              confirmDescription={t(
                'events.editEvents.confirmFinishEvent.description'
              )}
              confirmActionBtnText={t(
                'events.editEvents.confirmFinishEvent.buttonText'
              )}
              onConfirmAction={handleFinishEvent}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

EventHeader.propTypes = {
  disabled: PropTypes.bool.isRequired
};
export default EventHeader;
