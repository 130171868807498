import { Card } from 'react-bootstrap';
import EventBracketItem from './EventBracketItem';
import { useTranslation } from 'react-i18next';

const EventBrackets = ({ eventInfo }) => {
  const { t } = useTranslation();

  const { brackets, event } = eventInfo;

  return (
    <Card className="my-3">
      <Card.Header as="h5">
        {t('events.eventDetail.eventBrackets.title')}
      </Card.Header>
      <Card.Body className="bg-light">
        {!brackets.length && (
          <p className="my-2">{t('events.eventDetail.blankInfo.brackets')}</p>
        )}

        {brackets.map((item, index) => (
          <EventBracketItem {...item} key={index} eventId={event.id} index={index} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default EventBrackets;
