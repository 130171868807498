import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Row,
  Button,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState } from "react";
import { isEmpty } from "lodash";
import { toast } from 'react-toastify';


import IconButton from 'components/common/IconButton';
import { updateEventEntry } from 'api/events/update-entry';

const EventEntryItem = ({
  event,
  uuid,
  name,
  description,
  price,
  currency,
  gender,
  gi_nogi,
  seniority,
  exists,
  handleRemove,
  handleChange
}) => {
  const { t } = useTranslation();

  const [processingUpdate, setProcessingUpdate] = useState(false);
  const [didUserUpdateEntry, setDidUserUpdateEntry] = useState(false);

  const academyInfo = useSelector(state => state.auth);
  const { isAcademy, academyId } = academyInfo;

  const isCreatorAcademy = isAcademy && event?.academy_id === academyId;
  const isInvalidForEdit = isEmpty(name) || price < 0;

  const handleFieldChange = (name, value) => {
    handleChange(uuid, name, value);
    setDidUserUpdateEntry(true);
  };

  const handleUpdateEntry = async () => {

    try {
      setProcessingUpdate(true);

      const response = await updateEventEntry({
        currency,
        description: description || " ",
        gender,
        gi_nogi,
        eventEntryId: uuid,
        id: event?.id,
        name,
        price,
        seniority
      });

      if (response.data.statusCode === 200) {
        toast.success(response.data.body);
        setDidUserUpdateEntry(false);
      } else {
        toast.error(response.data.body);
      }
    } catch (error) {
    }
    finally {
      setProcessingUpdate(false);
    }
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        {t('events.editEvents.confirmDelete.title')}
      </Popover.Header>
      <Popover.Body>
        <p>{t('events.editEvents.confirmDelete.description')}</p>
        <Button
          onClick={() => {
            handleRemove(uuid, exists);
            document.body.click(); //to close the popover by clicking outside..
          }}
          variant="danger"
        >
          {t('events.editEvents.confirmDelete.buttonText')}
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">


          {exists && isCreatorAcademy && didUserUpdateEntry && <IconButton
            className="me-3"
            onClick={handleUpdateEntry}
            disabled={isInvalidForEdit || processingUpdate}
            variant="falcon-default"
            size="sm"
            icon="pencil-alt"
            transform="shrink-3"
          >
            {t('events.createEvents.updateEntry')}
          </IconButton>}


          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            rootClose={true}
          >
            <Button size="sm" variant="link" className="p-0">
              <FontAwesomeIcon className="text-danger" icon="times-circle" />
            </Button>
          </OverlayTrigger>
        </div>

        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.name')}
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                disabled={!isCreatorAcademy}
                value={name}
                placeholder={t(
                  'events.createEvents.entries.fields.namePlaceholder'
                )}
                onChange={e => handleFieldChange('name', e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.description')}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                disabled={!isCreatorAcademy}
                value={description}
                name="description"
                placeholder={t(
                  'events.createEvents.entries.fields.descriptionPlaceholder'
                )}
                onChange={e => handleFieldChange('description', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.price')}
              </Form.Label>
              <Form.Control
                type="number"
                name="price"
                disabled={!isCreatorAcademy}
                value={price}
                placeholder={t(
                  'events.createEvents.entries.fields.pricePlaceholder'
                )}
                onChange={e =>
                  handleFieldChange('price', parseFloat(e.target.value ?? '0'))
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="currency" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.currency')}
              </Form.Label>
              <Form.Select
                aria-label="currency"
                name="currency"
                value={currency}
                disabled={!isCreatorAcademy}
                onChange={e => handleFieldChange('currency', e.target.value)}
                required
              >
                <option selected value="USD">
                  USD Dollar
                </option>
                <option value="CRC">CRC Colones</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="gender" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.gender')}
              </Form.Label>
              <Form.Select
                aria-label="gender"
                name="gender"
                value={gender}
                disabled={!isCreatorAcademy}
                onChange={e => handleFieldChange('gender', e.target.value)}
                required
              >
                <option selected value="Male">
                  {t('createMatch.fieldGenderValue1')}
                </option>
                <option value="Female">
                  {t('createMatch.fieldGenderValue2')}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="gi_nogi" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.gi_nogi')}
              </Form.Label>
              <Form.Select
                aria-label="gi_nogi"
                name="gi_nogi"
                value={gi_nogi}
                disabled={!isCreatorAcademy}
                onChange={e => handleFieldChange('gi_nogi', e.target.value)}
                required
              >
                <option selected value="1">
                  {t('events.createEvents.entries.fields.gi')}
                </option>
                <option value="0">
                  {t('events.createEvents.entries.fields.nogi')}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="seniority" className="mb-3">
              <Form.Label>
                {t('events.createEvents.entries.fields.seniority')}
              </Form.Label>
              <Form.Select
                aria-label="seniority"
                name="seniority"
                disabled={!isCreatorAcademy}
                value={seniority}
                onChange={e => handleFieldChange('seniority', e.target.value)}
                required
              >
                <option selected value="Juvenile">
                  {t('createMatch.fieldSeniorityValue2')}
                </option>
                <option value="Adult">
                  {t('createMatch.fieldSeniorityValue1')}
                </option>
                <option value="Master">
                  {t('createMatch.fieldSeniorityValue3')}
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EventEntryItem;

EventEntryItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  gi_nogi: PropTypes.number.isRequired,
  seniority: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired
};
