import { Card } from 'react-bootstrap';
import { Col, Form, Row, Button } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import Select from 'react-select';
import { map, range, capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { useEffect } from 'react';

const EntryRegistration = ({
  entryInfo,
  handleFormChange,
  errors,
  emptySelectFieldsWhenGenderChanges,
  formData
}) => {
  const { entry, eventBrackets } = entryInfo;
  const { t } = useTranslation();

  /**
   * As user selects Gender, filter for Seniority
   * @returns
   */
  const seniorityAvailableBrackets = () => {
    let availableBrackets = eventBrackets;

    if (formData.gender) {
      availableBrackets = availableBrackets.filter(
        m => m.gender === formData.gender
      );
    }

    return availableBrackets;
  };

  /**
   * As user selects Seniority, filter for Belt
   * @returns
   */
  const beltAvailableBrackets = () => {
    let availableBrackets = seniorityAvailableBrackets();

    if (formData.seniority) {
      availableBrackets = availableBrackets.filter(
        m => m.seniority === formData.seniority
      );
    }

    return availableBrackets;
  };

  /**
   * As user selects Belt, filter for Weight
   * @returns
   */

  const weightAvailableBrackets = () => {
    let availableBrackets = beltAvailableBrackets();

    if (formData.belt) {
      availableBrackets = availableBrackets.filter(
        m => m.belt === formData.belt
      );
    }

    return availableBrackets;
  };

  const seniorityOptions = uniq(
    seniorityAvailableBrackets().map(m => m.seniority)
  );
  const beltOptions = uniq(beltAvailableBrackets().map(m => m.belt));
  const weightOptions = uniq(weightAvailableBrackets().map(m => m.weight)).filter(m => m !== "Absolut");

  /**
   * When the gender changes, empty the other selected values..
   */
  useEffect(() => {
    emptySelectFieldsWhenGenderChanges();
  }, [formData.gender]);

  const dangerStyles = {
    border: '1px solid',
    borderColor: '#fb6340',
    borderRadius: '4px'
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
          <Col md="12">
            <h5 className="fs-2 me-1">
              <span className="text-primary">
                {entry.name}{' '}
                {t('events.eventDetail.entryDetail.registrationLabel')}
              </span>
              <small className="mx-2">
                <SoftBadge pill bg="primary">
                  {entry.price} {entry.currency}
                </SoftBadge>
              </small>
            </h5>
            <p className="text-1000 mb-0">{entry.description}</p>
          </Col>
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Form.Group as={Col} lg={6} controlId="gender" className="mb-3">
              <Form.Label>{t('createMatch.fieldLabel4')}</Form.Label>
              <Select
                aria-label="gender"
                name="gender"
                value={
                  formData.gender
                    ? formData.gender === 'Male'
                      ? {
                          label: t('createMatch.fieldGenderValue1'),
                          value: 'Male'
                        }
                      : {
                          label: t('createMatch.fieldGenderValue2'),
                          value: 'Female'
                        }
                    : null
                }
                classNamePrefix="react-select"
                options={map(
                  [
                    { label: t('createMatch.fieldGenderValue1'), val: 'Male' },
                    { label: t('createMatch.fieldGenderValue2'), val: 'Female' }
                  ],
                  option => {
                    return {
                      value: option.val,
                      label: option.label
                    };
                  }
                )}
                onChange={selectedOption =>
                  handleFormChange('gender', selectedOption.value)
                }
                styles={{
                  container: provided => {
                    return errors.gender
                      ? {
                          ...provided,
                          ...dangerStyles
                        }
                      : provided;
                  }
                }}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="seniority" className="mb-3">
              <Form.Label>{t('createMatch.fieldLabel5')}</Form.Label>
              <Select
                aria-label="seniority"
                name="seniority"
                value={
                  formData.seniority
                    ? {
                        label: capitalize(formData.seniority),
                        value: formData.seniority
                      }
                    : null
                }
                options={map(seniorityOptions, val => {
                  return {
                    value: val,
                    label: val
                  };
                })}
                classNamePrefix="react-select"
                onChange={selectedOption =>
                  handleFormChange('seniority', selectedOption.value)
                }
                required
                styles={{
                  container: provided => {
                    return errors.seniority
                      ? {
                          ...provided,
                          ...dangerStyles
                        }
                      : provided;
                  }
                }}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} className="mb-3" controlId="belt">
              <Form.Label>{t('events.createEvents.fields.belt')}</Form.Label>
              <Select
                options={map(beltOptions, val => {
                  return {
                    value: val,
                    label: capitalize(val)
                  };
                })}
                value={
                  formData.belt
                    ? {
                        label: capitalize(formData.belt),
                        value: formData.belt
                      }
                    : null
                }
                classNamePrefix="react-select"
                placeholder={t('events.createEvents.fields.beltPlaceholder')}
                styles={{
                  container: provided => {
                    return errors.belt
                      ? {
                          ...provided,
                          ...dangerStyles
                        }
                      : provided;
                  }
                }}
                onChange={selectedOption =>
                  handleFormChange('belt', selectedOption.value)
                }
                name="belt"
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} className="mb-3" controlId="weight">
              <Form.Label>{t('createMatch.fieldLabel6')}</Form.Label>
              <Select
                options={map(weightOptions, val => {
                  return {
                    value: val,
                    label: val
                  };
                })}
                placeholder={t('createMatch.fieldLabel6')}
                classNamePrefix="react-select"
                onChange={selectedOption =>
                  handleFormChange('weight', selectedOption.value)
                }
                value={
                  formData.weight
                    ? {
                        label: capitalize(formData.weight),
                        value: formData.weight
                      }
                    : null
                }
                name="weight"
                styles={{
                  container: provided => {
                    return errors.weight
                      ? {
                          ...provided,
                          ...dangerStyles
                        }
                      : provided;
                  }
                }}
              />
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

EntryRegistration.propTypes = {};

export default EntryRegistration;
