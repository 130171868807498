/**
 * For unknown reason, submitting the Paypal detail to the bracket_participant PUT API endpoint returned 'Invalid JSON text: '
 * when the captures key value was present
 * Removing the key 'captures' allowed the paypal information to save successfully
 * Below code does that:
 */
export const sanitizePaypalResponse = paypalDetail => {
  return JSON.parse(
    JSON.stringify(paypalDetail, (key, value) => {
      if (key !== 'captures') {
        return value;
      }
    })
  );
};
