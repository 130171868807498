import { useEffect, useState } from 'react';
import { Card, Form, Image } from 'react-bootstrap';
import eventBanner from 'assets/img/generic/13.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const EventBanner = () => {

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
    });

  const { t } = useTranslation();

  const [photo, setPhoto] = useState({path: eventBanner, preview: null });

  const updatePhoto =(file) => {
    console.info('updatePhoto', file);
    if(file){
      setPhoto({path: eventBanner, preview: URL.createObjectURL(file) });
    }
  }

  useEffect(() => {
    fetch(eventBanner)
    .then(res => res.blob()) // Gets the response and returns it as a blob
    .then(blob => {
      // Here's where you get access to the blob
      // And you can use it for whatever you want
      // Like calling ref().put(blob)

      // Here, I use it to make an image appear on the page
      let objectURL = URL.createObjectURL(blob);
      setPhoto({path: eventBanner, preview: objectURL});
  });
}, []);

  return (
    <Card className="cover-image">
      <Image src={photo.preview} alt={photo.path} className="card-img-top" />
    </Card>
  );
};

export default EventBanner;
