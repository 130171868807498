import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import EventBracketItem from './EventBracketItem';
import { useTranslation } from 'react-i18next';
import deleteBracket from 'api/brackets/delete-bracket';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import insertBrackets from 'api/brackets/insert-brackets';
import { BracketContext } from 'context/Context';

const generateUUID = function () {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const EventBrackets = ({
  disabled,
  setValue,
  defaultItems,
  academyId,
  eventId
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(defaultItems);
  const [weight1, setWeight1] = useState(null);
  const [weightList, setWeightList] = useState([]);
  const [addedBrackets, setAddedBrackets] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const handleAddBracket = () => {
    const bracket = {
      uuid: generateUUID(),
      mat: 1,
      gender: 'Male',
      gi_nogi: 1,
      weights: [],
      seniority: 'Juvenile',
      duration: '5:00',
      belt: 'white'
    };
    setAddedBrackets([...addedBrackets, bracket]);
  };

  const destructBrackets = brackets => {
    const res = [];
    for (let i = 0; i < brackets.length; i++) {
      const bracket = brackets[i];
      bracket.weights.forEach(w => {
        const val = { ...bracket, weight: w };
        delete val.weights;
        res.push(val);
      });
    }
    return res;
  };

  const handleWeightBadgeRemove = weight => {
    const res = [];
    for (let i = 0; i < addedBrackets.length; i++) {
      const bracket = addedBrackets[i];
      bracket.weights = bracket.weights.filter(w => w != weight);
    }
  };

  const handleSaveBracket = async () => {
    var bracketsToInsert = destructBrackets(addedBrackets);
    const existingItems = items.map(item => ({ weight: item.weight, gender: item.gender, gi_nogi: item.gi_nogi, seniority: item.seniority, belt: item.belt }));
    const repeatedItems = bracketsToInsert.filter(bracket => existingItems.some(item => item.weight === bracket.weight && item.gender === bracket.gender && item.gi_nogi === bracket.gi_nogi && item.seniority === bracket.seniority && item.belt === bracket.belt));

    if(repeatedItems.length == 0) {
      const response = await insertBrackets({
        academyID: academyId,
        brackets: bracketsToInsert,
        eventId: eventId
      });
      if (
        response.data.errorMessage &&
        response.data.errorMessage.includes('Duplicate entry')
      ) {
        toast.error(t('events.editEvents.bracketDuplicateError'));
        return;
      }

      if (response.status === 200 && !response.data.errorMessage) {
        toast.success(t('events.editEvents.bracketInsertSuccess'));
        if (response.data.body.length > 0) {
          setItems([
            ...items,
            ...response.data.body.map(m => {
              return { ...m, exists: true };
            })
          ]);
        }
        setAddedBrackets([]);
      } else {
        toast.error(t('events.editEvents.bracketInsertError'));
      }
    }else{
      toast.error(t('events.editEvents.bracketDuplicateError'));
        return;
    }
  };

  const handleRemoveItem = async (uuid, exists = false) => {
    let removeItem = items.filter(item => item.uuid !== uuid);
    if (exists) {
      const success = await deleteBracket({
        academyID: academyId,
        bracketUUID: uuid
      });
      if (success) {
        toast.success(t('events.editEvents.bracketDeleteSuccess'));

        //only remove from the local state when it's deleted successfully from the backend API.
        setItems(removeItem);
      } else {
        toast.error(t('events.editEvents.bracketParticipantsError'));
      }
    } else {
      let removeAddedBrackets = addedBrackets.filter(
        item => item.uuid !== uuid
      );
      setItems(removeItem);
      setAddedBrackets(removeAddedBrackets);
    }
  };

  const handleChange = (uuid, name, value) => {
    const newItems = [...addedBrackets];
    const idx = newItems.findIndex(x => x.uuid == uuid);
    const updatedItem = { ...newItems[idx], [name]: value };
    setAddedBrackets([
      ...newItems.slice(0, idx),
      updatedItem,
      ...newItems.slice(idx + 1)
    ]);
  };

  useEffect(() => {
    setValue('brackets', items, { shouldValidate: true });
  }, [items]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {t('events.createEvents.eventBrackets')}
      </Card.Header>
      <Card.Body className="bg-light">
        <BracketContext.Provider
          value={{ weight1, setWeight1, weightList, setWeightList, items }}
        >
          {[...items, ...addedBrackets].map((item, index) => (
            <EventBracketItem
              {...item}
              key={index}
              index={index}
              handleRemove={handleRemoveItem}
              handleChange={handleChange}
              clearBracketWeightPills={addedBrackets.length == 0}
              setDisableButton={setDisableButton}
              handleWeightBadgeRemove={handleWeightBadgeRemove}
            />
          ))}
        </BracketContext.Provider>

        <div className="d-flex justify justify-content-between">
          <IconButton
            disabled={disabled}
            onClick={handleAddBracket}
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
          >
            {t('events.createEvents.addBracket')}
          </IconButton>

          <Button
            onClick={handleSaveBracket}
            disabled={disableButton || disabled}
            variant="falcon-default"
            size="sm"
            transform="shrink-3"
          >
            {t('events.editEvents.saveBracket')}
            <FontAwesomeIcon icon={'check-circle'} className="mx-2" />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

EventBrackets.propTypes = {
  setValue: PropTypes.func
};

export default EventBrackets;
