import { useState, useEffect } from 'react';
import { Col, Row, Alert, Form } from 'react-bootstrap';
import EntryRegistration from './EntryRegistration';
import PaymentMethod from './PaymentMethod';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getEventEntryDetail from 'api/events/get-event-entry';
import UserRegistrations from './UserRegistrations';
import postBracketParticipant, {
  putBracketParticipant
} from 'api/events/post-bracket-participant';
import { sanitizePaypalResponse } from 'constants/paypal';
import moment from 'moment';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  userId: state.auth.userId,
  entryInfo: {
    entryAcademyId: state.auth.eventEntryRegisterInfo.entryAcademyId,
    eventId: state.auth.eventEntryRegisterInfo.eventId,
    entryId: state.auth.eventEntryRegisterInfo.entryId,
    eventUUID: state.auth.eventEntryRegisterInfo.eventUUID,
  }
});

const EntryDetail = ({ academyId: currentUserAcademyId, uuid, userId, entryInfo: { entryAcademyId, eventId, entryId, eventUUID } }) => {

  const history = useHistory();
  const { t } = useTranslation();
  const { status } = useParams();
  const [eventEntryInfo, setEventEntryInfo] = useState(null);
  const [loading, isLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [selectedBracket, setSelectedBracket] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [_, payPalDispatch] = usePayPalScriptReducer();

  const academyId = entryAcademyId ?? currentUserAcademyId;

  const [formData, setFormData] = useState({
    belt: '',
    gender: '',
    seniority: '',
    weight: '',
    email: '',
    country: '',
    firstName: '',
    lastName: '',
    companyName: '',
    vatNr: '',
    streetName: '',
    houseNumber: '',
    addition: '',
    postalCode: '',
    city: '',
    phoneNumber: ''
  });

  const freeFormSchema = yup.object().shape({
    belt: yup.string().required(),
    gender: yup.string().required(),
    seniority: yup.string().required(),
    weight: yup.string().required()
  });

  const formSchema = yup.object().shape({
    belt: yup.string().required(),
    gender: yup.string().required(),
    seniority: yup.string().required(),
    weight: yup.string().required(),
    country: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    streetName: yup.string().required(),
    houseNumber: yup.string().required(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    phoneNumber: yup.string().required(),
    email: yup.string().email().required()
  });

  const loadData = () => {
    (async () => {
      const data = await getEventEntryDetail({
        status,
        eventId,
        eventEntryId: entryId,
        eventUUID: eventUUID,
        userId: academyId,
        userUUID: uuid
      });
      setEventEntryInfo(data);
      isLoading(false);
      setRefreshData(false);
    })();
  };

  useEffect(() => {
    loadData();
    payPalDispatch({
      type: 'setLoadingStatus',
      value: 'pending'
    });
  }, [eventId, entryId, refreshData]);

  const handleFormChange = (inputKey, inputValue) => {
    const updatedFormData = { ...formData, [inputKey]: inputValue };
    setFormData(updatedFormData);
  };

  const emptySelectFieldsWhenGenderChanges = () => {
    const updatedFormData = {
      ...formData,
      seniority: '',
      belt: '',
      weight: ''
    };
    setFormData(updatedFormData);
  };

  const handleCompletePayment = async (paymentDetails, existingEntryBracket = null, existingEntryId = null) => {
    try {
      const { gender, belt, seniority, weight, ...billingDetails } = formData;

      const result = await putBracketParticipant({
        confirmed: 1,
        bracket_id: existingEntryBracket || selectedBracket.bracketId,
        academy_id: Number(academyId),
        event_entry_id: existingEntryId ? Number(existingEntryId) : Number(entryId),
        event_id: Number(eventId),
        user_id: Number(userId),
        payment_info: {
          payPal_info: sanitizePaypalResponse(paymentDetails),
          billing_info: {
            ...billingDetails
          }
        }
      });

      if (result.statusCode === 200) {
        toast.success(t('events.eventDetail.entryDetail.paymentSuccessToast'), {
          autoClose: 2000
        });

        history.push('/dashboard/profile');

        setDisplayPayment(true);
      } else {
        toast.error(result.body);

        toast.dismiss();
        setProcessing(false);
      }
    } catch (e) {
      setProcessing(false);
      toast.error(t('events.eventDetail.entryDetail.paymentErrorToast'));
      toast.dismiss();
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    const bracket = eventEntryInfo.eventBrackets.find(
      m =>
        m.gender === formData.gender &&
        m.seniority === formData.seniority &&
        m.weight === formData.weight &&
        m.belt === formData.belt
    );

    //use the free schema instead when the price is free..
    (Number(eventEntryInfo.entry.price) ? formSchema : freeFormSchema)
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setErrors({});

        try {
          if (!bracket) {
            toast.error(
              t('events.eventDetail.entryDetail.bracketNotFoundError'),
              { autoClose: 2000 }
            );
            return;
          }

          setProcessing(true);
          setSelectedBracket(bracket);

          const result = await postBracketParticipant({
            academy_id: Number(academyId),
            confirmed: Number(eventEntryInfo.entry.price) ? 0 : 1, //when entry is free, auto confirm the registration
            event_entry_id: Number(entryId),
            event_id: Number(eventId),
            gender: formData.gender,
            gi_nogi: bracket.gi_nogi,
            seniority: formData.seniority,
            user_id: userId,
            weight: formData.weight,
            belt: formData.belt,
            dateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          });

          if (result.statusCode === 200) {
            //Display Different message if the entry is free..
            toast.success(
              Number(eventEntryInfo.entry.price)
                ? t('events.eventDetail.entryDetail.registrationSuccessToast')
                : t(
                  'events.eventDetail.entryDetail.freeRegistrationSuccessToast'
                )
            );
            toast.dismiss();

            //if the price is free..
            //get to the user profile..
            if (!Number(eventEntryInfo.entry.price)) {
              history.push('/dashboard/profile');
              return;
            }

            setDisplayPayment(true);
            setRefreshData(true);

          } else {
            //if the response from the api is about a 'Duplicate Entry'
            if ((result.body || '').includes('Duplicate entry')) {
              toast.error(
                t('events.eventDetail.entryDetail.duplicateBracketErrorToast'),
                { autoClose: 2000 }
              );
            } else {
              toast.error(result.body);
            }

            setTimeout(() => {
              toast.dismiss();
            }, 2000);

            setProcessing(false);
          }
        } catch (e) {
          setProcessing(false);
          toast.error(
            t('events.eventDetail.entryDetail.registrationErrorToast')
          );
          setTimeout(() => {
            toast.dismiss();
          }, 2000);
        }
      })
      .catch(err => {
        if (err.name === 'ValidationError') {
          let errs = {};
          err.inner.flatMap(e => {
            errs[e.path] = e.errors;
          });
          setErrors(errs);
        }
      });
  };

  const PaymentButton = ({ existingEntryBracket = null, existingEntryId = null }) => {
    return (
      <>
        <PayPalButtons
          fundingSource="paypal"
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: eventEntryInfo.entry.price
                  }
                }
              ]
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
              handleCompletePayment(details, existingEntryBracket, existingEntryId);
            });
          }}
        />
      </>
    )
  }

  return (
    <>
      {loading && (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      )}
      {eventEntryInfo && (
        <Form onSubmit={handleFormSubmit}>
          <Row className="g-3">
            <Col xl={{ span: 4, order: 1 }}>
              <UserRegistrations
                entryInfo={eventEntryInfo}
                paymentButton={PaymentButton}
              />
            </Col>
            <Col xl={8}>
              <EntryRegistration
                entryInfo={eventEntryInfo}
                formData={formData}
                handleFormChange={handleFormChange}
                emptySelectFieldsWhenGenderChanges={
                  emptySelectFieldsWhenGenderChanges
                }
                errors={errors}
              />

              <PaymentMethod
                entryInfo={eventEntryInfo}
                formData={formData}
                handleFormChange={handleFormChange}
                errors={errors}
                processing={processing}
                PaymentButton={PaymentButton}
                displayPayment={displayPayment}
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default connect(mapStateToProp)(EntryDetail);
