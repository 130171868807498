import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

export const LONG_FORM_DAYS = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thurs: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
};

type CreateActivityArgs = {
  activityName: string;
  academyId: string;
  startDate: string;
  endDate: string;
  days: [
    {
      dayOfWeek: string;
      time: string;
      instructor: string;
      availableSpaces: number;
    }
  ];
};

type UpdateActivityArgs = {
  activityID: string;
  activityUUID: string;
  activityName: string;
  academyId: string;
  startDate: string;
  endDate: string;
  days: [
    {
      action: 'delete' | 'insert' | 'update';
      activityMetaID: number;
      dayOfWeek: string;
      time: string;
      instructor: string;
      availableSpaces: number;
    }
  ];
};

const createNewActivity = async (data: CreateActivityArgs) => {
  let response = await axios.post(`${apiRoot}/activities`, data);
  return response;
};

export const updateActivity = async (data: UpdateActivityArgs) => {
  let response = await axios.put(`${apiRoot}/activities`, data);
  return response;
};

export const deactivateActivity = async (data: UpdateActivityArgs) => {
  let response = await axios.put(`${apiRoot}/activity`, {
    ...data
  });
  return response;
};

export default createNewActivity;
