import React, { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import TooltipBadge from 'components/common/TooltipBadge';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

const mapStateToProp = state => ({
  avatar: state.auth.isAcademy
});

const AccountSettings = ({
  visibility,
  handleVisibilityChanges,
  formData,
  setFormData
}) => {
  const isAcademy = useSelector(state => state.auth.isAcademy);
  const { t } = useTranslation();

  const handleChange = e => {
    if (e.target.type === 'radio') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked
      });
      handleVisibilityChanges(e.target.name == 'public' ? 1 : 0, true);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      is_profile_visible: isAcademy || visibility === 1 ? 1 : 0
    });
  }, [visibility]);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={t('accountSettings.title')} />
      <Card.Body className="bg-light">
        <div>
          <h6 className="fw-bold">
            {t('accountSettings.question1')}
            <TooltipBadge
              tooltip={t('accountSettings.question1Tooltip')}
              icon="question-circle"
            />
          </h6>
          <div className="ps-2 mb-2">
            <Form.Check
              type="radio"
              id="profile-everyone"
              label={t('accountSettings.everyone')}
              className="form-label-nogutter"
              value="everyone"
              name="public"
              onChange={handleChange}
              checked={formData.is_profile_visible === 1}
            />
            <Form.Check
              type="radio"
              id="profile-members"
              label={t('accountSettings.onlyAcademy')}
              className="form-label-nogutter"
              value="only-academy"
              name="private"
              onChange={handleChange}
              checked={formData.is_profile_visible === 0}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProp)(AccountSettings);
