import { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Col, Form, Row, InputGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MyCKEditor from './MyCKEditor';
import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  LoadScript
} from '@react-google-maps/api';
import DefaultDateTimePicker from 'components/common/DateTimePicker';
import { Controller } from 'react-hook-form';
import { flatten } from 'lodash';

import { EventParticipantsList } from './EventParticipantsList';

import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useParticipants } from '../hooks/useParticipants';

let markerArray = [];
const lib = ['places'];
const key = process.env.REACT_APP_GOOGLE_API_KEY;

const EventDetails = ({
  disabled,
  errors,
  register,
  setValue,
  getValues,
  trigger,
  control,
  eventInfo,
  defaultFormData,
  handleRefreshData
}) => {
  const [formData, setFormData] = useState(defaultFormData);
  const { t } = useTranslation();
  const countriesOptions = useMemo(() => countryList().getData(), []);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);

  const [isParticipantsSectionOpen, setIsParticipantsSectionOpen] =
    useState(false);

  const onMapLoad = useCallback(map => {
    setMap(map);
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentLocation(pos);
      }
    );

    const google = window.google;
    google.maps.event.addListener(map, 'bounds_changed', () => {
      setBounds(map.getBounds());
    });
  }, []);

  const onSBLoad = useCallback(searchBox => {
    setSearchBox(searchBox);
  }, []);

  const onPlacesChanged = () => {
    markerArray = [];
    let results = searchBox.getPlaces();
    if (results.length == 1) {
      for (let i = 0; i < results.length; i++) {
        let place = results[i].geometry.location;
        setValue('locationLat', place.lat(), { shouldValidate: true });
        setValue('locationLng', place.lng(), { shouldValidate: true });
        setValue(
          'location',
          `https://www.google.com/maps/search/?api=1&query=${place.lat()},${place.lng()}`,
          { shouldValidate: true }
        );
        markerArray.push(place);
      }
      setMarkers(markerArray);
    }
  };

  const checkKeyDown = e => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const allParticipants = useParticipants(eventInfo);

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5" className="d-flex justify-content-between">
          {isParticipantsSectionOpen
            ? t('events.editEvents.participantsBtn')
            : t('events.createEvents.eventDetails')}

          <Button
            size="sm"
            variant="falcon-default"
            className="me-2"
            onClick={() => {
              setIsParticipantsSectionOpen(prev => !prev);
            }}
            type="button"
            disabled={disabled}
          >
            {isParticipantsSectionOpen
              ? t('events.createEvents.eventDetails')
              : t('events.editEvents.participantsBtn')}
          </Button>
        </Card.Header>
        <Card.Body className="bg-light">
          {isParticipantsSectionOpen ? (
            <EventParticipantsList
              allParticipants={allParticipants}
              handleRefreshData={handleRefreshData}
            />
          ) : (
            <Row className="gx-2 gy-3">
              <Col md="12">
                <Form.Group controlId="name">
                  <Form.Label>
                    {t('events.createEvents.fields.eventTitle')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder={t(
                      'events.createEvents.fields.eventTitlePlaceholder'
                    )}
                    isInvalid={!!errors.name}
                    {...register('name', { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="event_date">
                  <Form.Label>
                    {t('events.createEvents.fields.eventDate')}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="event_date"
                    render={() => (
                      <DefaultDateTimePicker
                        isInvalid={!!errors.event_date}
                        errorMessage={
                          errors.event_date ? errors.event_date.message : ''
                        }
                        value={formData.event_date}
                        onValueChange={newDate => {
                          handleChange('event_date', newDate);
                          setValue('event_date', newDate, {
                            shouldValidate: true
                          });
                        }}
                      />
                    )}
                    {...register('event_date', {
                      required: true,
                      validate: newValue => {
                        const value = getValues('event_date');
                        const result = moment(value);

                        return (
                          result ||
                          t(
                            'events.createEvents.fields.event_dateValidationMessage'
                          )
                        );
                      }
                    })}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="timezone">
                  <Form.Label>
                    {t('events.createEvents.fields.timeZone')}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="timezone"
                    isInvalid={!!errors.timezone}
                    {...register('timezone', { required: true })}
                  >
                    {timezones.map(item => (
                      <option
                        value={`${item.offset}`}
                        key={`${item.offset}/${item.name}`}
                      >
                        {`${item.offset}/${item.name}`}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.timezone && errors.timezone.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="early_registration_final_date">
                  <Form.Label>
                    {t('events.createEvents.fields.earlyRegistrationDate')}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="early_registration_final_date"
                    render={() => (
                      <DefaultDateTimePicker
                        isInvalid={!!errors.early_registration_final_date}
                        errorMessage={
                          errors.early_registration_final_date
                            ? errors.early_registration_final_date.message
                            : ''
                        }
                        value={formData.early_registration_final_date}
                        onValueChange={async newDate => {
                          handleChange(
                            'early_registration_final_date',
                            newDate
                          );
                          setValue('early_registration_final_date', newDate, {
                            shouldValidate: true
                          });
                          let newEventDate = moment(newDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          );
                          await trigger('event_date');
                          await trigger('normal_registration_final_date');
                          await trigger('late_registration_final_date');
                        }}
                      />
                    )}
                    {...register('early_registration_final_date', {
                      required: false,
                      validate: newValue => {
                        if (newValue) {
                          const value = getValues(
                            'early_registration_final_date'
                          );
                          const dt1 = getValues(
                            'normal_registration_final_date'
                          );
                          const dt2 = getValues('late_registration_final_date');

                          const result =
                            moment(value).isBefore(moment(dt1)) &&
                            moment(value).isBefore(moment(dt2));

                          return (
                            result ||
                            t(
                              'events.createEvents.fields.earlyRegDateValidationMessage'
                            )
                          );
                        }
                      }
                    })}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="normal_registration_final_date">
                  <Form.Label>
                    {t('events.createEvents.fields.normalRegistrationDate')}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="normal_registration_final_date"
                    render={() => (
                      <DefaultDateTimePicker
                        isInvalid={!!errors.normal_registration_final_date}
                        errorMessage={
                          errors.normal_registration_final_date
                            ? errors.normal_registration_final_date.message
                            : ''
                        }
                        value={formData.normal_registration_final_date}
                        onValueChange={async newDate => {
                          handleChange(
                            'normal_registration_final_date',
                            newDate
                          );
                          setValue('normal_registration_final_date', newDate, {
                            shouldValidate: true
                          });
                          let newEventDate = moment(newDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          );
                          await trigger('event_date');
                          await trigger('early_registration_final_date');
                          await trigger('late_registration_final_date');
                        }}
                      />
                    )}
                    {...register('normal_registration_final_date', {
                      required: false,
                      validate: newValue => {
                        if (newValue) {
                          const value = getValues(
                            'normal_registration_final_date'
                          );
                          const dt1 = getValues(
                            'early_registration_final_date'
                          );
                          const dt2 = getValues('late_registration_final_date');

                          const result =
                            moment(value).isAfter(moment(dt1)) &&
                            moment(value).isBefore(moment(dt2));

                          return (
                            result ||
                            t(
                              'events.createEvents.fields.normalRegDateValidationMessage'
                            )
                          );
                        }
                      }
                    })}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group controlId="late_registration_final_date">
                  <Form.Label>
                    {t('events.createEvents.fields.lateRegistrationDate')}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="late_registration_final_date"
                    render={() => (
                      <DefaultDateTimePicker
                        isInvalid={!!errors.late_registration_final_date}
                        errorMessage={
                          errors.late_registration_final_date
                            ? errors.late_registration_final_date.message
                            : ''
                        }
                        value={formData.late_registration_final_date}
                        onValueChange={async newDate => {
                          handleChange('late_registration_final_date', newDate);
                          setValue('late_registration_final_date', newDate, {
                            shouldValidate: true
                          });
                          let newEventDate = moment(newDate).format(
                            'YYYY-MM-DD HH:mm:ss'
                          );
                          await trigger('event_date');
                          await trigger('early_registration_final_date');
                          await trigger('normal_registration_final_date');
                        }}
                      />
                    )}
                    {...register('late_registration_final_date', {
                      required: false,
                      validate: newValue => {
                        if (newValue) {
                          const startDate = getValues('event_date');
                          const value = getValues(
                            'late_registration_final_date'
                          );
                          const dt1 = getValues(
                            'early_registration_final_date'
                          );
                          const dt2 = getValues(
                            'normal_registration_final_date'
                          );

                          const result =
                            moment(value).isAfter(moment(dt1)) &&
                            moment(value).isAfter(moment(dt2)) &&
                            moment(value).isBefore(moment(startDate));

                          return (
                            result ||
                            t(
                              'events.createEvents.fields.lateRegDateValidationMessage'
                            )
                          );
                        }
                      }
                    })}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Label htmlFor="contact_website">
                  {t('events.createEvents.fields.contactWebsite')}
                </Form.Label>
                <InputGroup className="mb-3" controlId="contact_website">
                  <InputGroup.Text id="contact_website">
                    <span className="bi-globe"></span>
                  </InputGroup.Text>
                  <Form.Control
                    id="contact_website"
                    aria-describedby="contact_website"
                    type="text"
                    name="contact_website"
                    placeholder={t(
                      'events.createEvents.fields.contactWebsitePlaceholder'
                    )}
                    isInvalid={!!errors.contact_website}
                    {...register('contact_website', { required: false })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_website && errors.contact_website.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>

              <Col md="6">
                <Form.Label htmlFor="contact_phone">
                  {t('events.createEvents.fields.contactPhone')}
                </Form.Label>
                <InputGroup className="mb-3" controlId="contact_phone">
                  <InputGroup.Text id="contact_phone">
                    <span className="bi-telephone"></span>
                  </InputGroup.Text>
                  <Form.Control
                    id="contact_phone"
                    aria-describedby="contact_phone"
                    type="text"
                    name="contact_phone"
                    placeholder={t(
                      'events.createEvents.fields.contactPhonePlaceholder'
                    )}
                    isInvalid={!!errors.contact_phone}
                    {...register('contact_phone', { required: false })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_phone && errors.contact_phone.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>

              <Col md="6">
                <Form.Label htmlFor="contact_email">
                  {t('events.createEvents.fields.contactEmail')}
                </Form.Label>
                <InputGroup className="mb-3" controlId="contact_email">
                  <InputGroup.Text id="contact_email">
                    <span className="bi-mailbox"></span>
                  </InputGroup.Text>
                  <Form.Control
                    id="contact_email"
                    aria-describedby="contact_email"
                    type="text"
                    name="contact_email"
                    placeholder={t(
                      'events.createEvents.fields.contactEmailPlaceholder'
                    )}
                    isInvalid={!!errors.contact_email}
                    {...register('contact_email', { required: false })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_email && errors.contact_email.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>

              <Col md="6">
                <Form.Label htmlFor="contact_facebook">
                  {t('events.createEvents.fields.contactFacebook')}
                </Form.Label>
                <InputGroup className="mb-3" controlId="contact_facebook">
                  <InputGroup.Text id="contact_facebook">
                    <span className="bi-facebook"></span>
                  </InputGroup.Text>
                  <Form.Control
                    id="contact_facebook"
                    aria-describedby="contact_facebook"
                    type="text"
                    name="contact_facebook"
                    placeholder={t(
                      'events.createEvents.fields.contactFacebookPlaceholder'
                    )}
                    isInvalid={!!errors.contact_facebook}
                    {...register('contact_facebook', { required: false })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_facebook && errors.contact_facebook.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>

              <Col md="6">
                <Form.Label htmlFor="contact_instagram">
                  {t('events.createEvents.fields.contactInstagram')}
                </Form.Label>
                <InputGroup className="mb-3" controlId="contact_instagram">
                  <InputGroup.Text id="contact_instagram">
                    <span className="bi-instagram"></span>
                  </InputGroup.Text>
                  <Form.Control
                    id="contact_instagram"
                    aria-describedby="contact_instagram"
                    type="text"
                    name="contact_instagram"
                    placeholder={t(
                      'events.createEvents.fields.contactInstagramPlaceholder'
                    )}
                    isInvalid={!!errors.contact_instagram}
                    {...register('contact_instagram', { required: false })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_instagram &&
                      errors.contact_instagram.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>

              <Col md="6">
                <Form.Label htmlFor="country">
                  {t('events.createEvents.fields.country')}
                </Form.Label>
                <Select
                  options={countriesOptions}
                  className="country-select-control"
                  value={{
                    value: formData.country || '',
                    label:
                      countriesOptions.find(m => m.value === formData.country)
                        ?.label || ''
                  }}
                  placeholder={t('events.createEvents.fields.country')}
                  onChange={country => {
                    handleChange('country', country.value);
                    setValue('country', country.value);
                  }}
                />
              </Col>

              <Col md="12">
                <div className="border-dashed-bottom"></div>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.information')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="information">
                <MyCKEditor
                  defaultValue={defaultFormData.information}
                  onEditorChange={data =>
                    setValue('information', data, { shouldValidate: true })
                  }
                  {...register('information', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.prizes')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="prizes">
                <MyCKEditor
                  defaultValue={defaultFormData.prizes}
                  onEditorChange={data =>
                    setValue('prizes', data, { shouldValidate: true })
                  }
                  {...register('prizes', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.categories')}
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="categories">
                <MyCKEditor
                  defaultValue={defaultFormData.categories}
                  onEditorChange={data =>
                    setValue('categories', data, { shouldValidate: true })
                  }
                  {...register('categories', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header as="h5">
          {t('events.createEvents.fields.location')}
        </Card.Header>
        <Card.Body className="bg-light">
          <LoadScript googleMapsApiKey={key} libraries={lib}>
            <Row className="gx-2 gy-3">
              <Col md="8">
                <GoogleMap
                  center={currentLocation}
                  zoom={10}
                  onLoad={onMapLoad}
                  mapContainerStyle={{ height: '400px', width: '100%' }}
                >
                  {markers.map((mark, index) => (
                    <Marker key={index} position={mark} />
                  ))}
                </GoogleMap>
              </Col>
              <Col md="4" rowSpan={2}>
                <Form.Group controlId="locationLat">
                  <Form.Label>{t('events.createEvents.fields.lat')}</Form.Label>
                  <Form.Control
                    defaultValue={formData.locationLat}
                    type="text"
                    name="locationLat"
                    placeholder={t('events.createEvents.fields.lat')}
                    {...register('locationLat', { required: false })}
                  />
                </Form.Group>
                <Form.Group controlId="locationLng">
                  <Form.Label>{t('events.createEvents.fields.lng')}</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={formData.locationLng}
                    name="locationLng"
                    placeholder={t('events.createEvents.fields.lng')}
                    {...register('locationLng', { required: false })}
                  />
                </Form.Group>
              </Col>
            </Row>
          </LoadScript>
        </Card.Body>
      </Card>
    </>
  );
};

EventDetails.propTypes = {
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired
};
export default EventDetails;
