import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Placeholder } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar.png'
import insertParticipantBracket from 'api/brackets/insert-participant';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Participant = ({ participant, eventId, academyID, bracketUuid, isAdded }) => {
  const { user_id: userId, userName, academyName } = participant;

  const [added, setAdded] = useState(isAdded);
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();

  const _insertParticipantBracket = async () => {
    setIsSending(true);
    const data = {
      eventId: eventId,
      academyID: academyID,
      bracketUuid: bracketUuid,
      userId: userId,
    }
    const res = await insertParticipantBracket(data);

    setIsSending(false);

    if (res.isSuccess) {
      setAdded(true);
    } else {
      toast.error(res.message);
    }
  }

  return (
    <>
      <div
        className="bg-white dark__bg-1100 p-3 h-100 d-flex flex-column align-items-center justify-content-between">


        <Image
          thumbnail
          fluid
          roundedCircle
          className="mb-3 shadow-sm participant-avatar-img"
          src={participant.avatarUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = avatar;
          }}
          
        />

        <h6 className="mb-1">
          {userName}
        </h6>
        <small className="fs--2 m-1">
          {academyName}
        </small>

        {added ? <Button
          variant='success'
          size='sm'
        >
          <FontAwesomeIcon icon={'check'} />
          {'  '}

          {t('bracketAddParticipant.added')}
        </Button> : <Button
          disabled={isSending}
          variant='primary'
          onClick={() => _insertParticipantBracket()}
          size='sm'
        > {isSending ?
          <>
            <FontAwesomeIcon icon={'spinner'} />
            {'  '}
            {t('bracketAddParticipant.adding')}</>
          :
          <>
            <FontAwesomeIcon icon={'plus'} />
            {'  '}
            {t('bracketAddParticipant.add')}
          </>
          }
        </Button>
        }
      </div>


    </>
  );
};

export default Participant;
