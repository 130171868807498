import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useBreakpoints';

import moment from 'moment';

const FilterBrackets = ({ Data, setData }) => {
  const [_, setSearchInputValue] = useState('');
  const [isSpecific] = useState(true);
  const { t } = useTranslation();

  const breakPointWidth = useBreakpoints().width;
  const typeScreen =
    breakPointWidth > 1150
      ? 'desktop'
      : breakPointWidth < 768
      ? 'mobile'
      : 'tablet';

  const filterData = (value, specific) => {
    if (value.trim() != '') {
      const dataFilter = Data.filter(item => {
        const keyValues = value.split(' ');
        const objectiveWords =
          item.description +
          ' ' +
          item.seniority +
          ' ' +
          item.type +
          ' ' +
          item.weight;

        var existKey = keyValues?.map(data =>
          data.trim() == ''
            ? isSpecific
            : objectiveWords
                ?.trim()
                .toLowerCase()
                .includes(data.trim().toLowerCase())
        );
        return specific === true
          ? existKey?.every(item => item === true)
          : existKey?.some(item => item === true);
      });

      setData(dataFilter);
    } else {
      setData(Data);
    }
  };
  const search = async target => {
    setSearchInputValue(target.value);
    filterData(target.value, isSpecific);
  };
  return (
    <>
      <InputGroup
        className={
          typeScreen === 'mobile'
            ? 'w-100 mt-3'
            : typeScreen === 'tablet'
            ? 'w-75 mt-3'
            : 'w-50 mt-3'
        }
      >
        <InputGroup.Text className="bg bg-primary">
          <FontAwesomeIcon icon="search" className="text-light" />
        </InputGroup.Text>

        <FormControl
          type="search"
          placeholder={t('events.eventDetail.tabs.findMatches')}
          aria-label="Search"
          className=" search-input shadow-none"
          onChange={({ target }) => {
            search(target);
          }}
        />
      </InputGroup>
    </>
  );
};
export default FilterBrackets;
