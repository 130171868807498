const windowHeight = window.innerHeight;

const handleNavbarTransparency = () => {
  const scrollTop = window.scrollY;
  let alpha = (scrollTop / windowHeight) * 2;
  alpha >= 1 && (alpha = 1);
  document.getElementsByClassName(
    'navbar-theme'
  )[0].style.backgroundColor = "rgb(11, 23, 39)";
};

export default handleNavbarTransparency;
