import { useEffect, useState } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';

import NavbarStandard from '../landing/NavbarStandard';
import Section from 'components/common/Section';
import Cta from '../landing/Cta';
import FooterStandard from '../landing/FooterStandard';
import EventDetailAside from '../../dashboards/events/event-detail/EventDetailAside';
import EventDetailContent from '../../dashboards/events/event-detail/EventDetailContent';
import EventDetailHeader from '../../dashboards/events/event-detail/EventDetailHeader';
import EventBrackets from '../../dashboards/events/event-detail/EventBrackets';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import getPublicEventDetail from 'api/events/get-public-event-detail';

import { useBreakpoints } from '../../../hooks/useBreakpoints';

const PublicEventDetail = () => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);

  const breakPointWidth = useBreakpoints().width;
  const isMobile = breakPointWidth < 768 ? true : false;

  const loadData = () => {
    (async () => {
      const data = await getPublicEventDetail(eventId);
      setEventInfo(data);
      isLoading(false);
    })();
  };

  useEffect(() => {
    loadData();
  }, [eventId]);

  return (
    <div className='publicEventsSection'>
      <NavbarStandard />

      <Section className="bg-dark light p-0" position="center bottom" overlay>
        <Row className="justify-content-center align-items-center">
          {loading && (
            <Alert variant="default" className="p-5">
              <h4 className="alert-heading">{t('publicEvents.loading')}</h4>
            </Alert>
          )}

          {eventInfo && (
            <div>
              <EventDetailHeader eventInfo={eventInfo} isPublic />
            </div>
          )}
        </Row>
      </Section>

      {eventInfo && <>
        <Section bg="light" className="pt-4">
          <Row className="g-3" xs={1} md={2} lg={2}>
            <Col xs={{ order: 1 }} lg={{ order: 1 }} >
              <EventDetailContent eventInfo={eventInfo} handleRefresh={loadData} />
              {!isMobile ? <EventBrackets eventInfo={eventInfo} /> : ""}
            </Col>
            {
              isMobile ?
                <Col xs={{ order: 'last' }} md={{ order: 'last' }}>
                  <EventBrackets eventInfo={eventInfo} />
                </Col>
                : ""
            }
            <Col xs={{ order: 2 }} md={{ order: 2 }}>
              <EventDetailAside eventInfo={eventInfo} />
            </Col>
          </Row>
        </Section>
      </>
      }

      <Cta />
      <FooterStandard />
    </div>
  );
};

export default PublicEventDetail;
