import axios from 'axios';
import moment from 'moment';

const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsUpdateBracket = {
  academyID: number;
  bracketUUID: string;
  eventID: number;
  round: number;
};
const updateBracket = async (data: ArgsUpdateBracket): Promise<boolean> => {
  try {
    const response = await axios.put(`${apiRoot}/bracket`, {
      academyID: data.academyID,
      bracketUUID: data.bracketUUID,
      eventID: data.eventID,
      round: data.round
    });

    return response.status == 200;
  } catch (e) {
    return false;
  }
};

type ArgsUpdateBracketStatus = {
  bracketInfo: any;
  time: 'startTime' | 'endTime';
};
export const updateBracketStatus = async (data: ArgsUpdateBracketStatus) => {
  try {
    if (!data.bracketInfo) {
      return;
    }

    const payload = {
      bracketUUID: data.bracketInfo.bracketUUID,
      [data.time]: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    };
    const response = await axios.put(`https://u2196spawb.execute-api.us-east-1.amazonaws.com/prod/bracket-status`, payload);

    return true;
  } catch (e) {
    return false;
  }
};

export default updateBracket;
