import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { includes, split, lowerCase } from 'lodash';
import { putBracketParticipant } from 'api/events/post-bracket-participant';
import { useTranslation } from 'react-i18next';
import "./EditParticipantWeight.css";

export const EditParticipantWeight = ({
  bracket,
  handleRefreshData,
  participantIndex,
  selectedEditParticipantWeightIndex,
  handleSelectedEditParticipantWeightIndex
}) => {
  const { t } = useTranslation();

  const [weightValue, setWeightValue] = useState(0);
  const [weightMetric, setWeightMetric] = useState('kg');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const { weight } = bracket;

    const selectedMetric = includes(weight, 'kg') ? 'kg' : 'lbs';
    const selectedWeight = split(weight, selectedMetric);

    setWeightMetric(selectedMetric);
    setWeightValue(Number(selectedWeight[0]));
  }, [bracket]);

  const handleUpdate = async () => {
    if (isProcessing || weightValue == 0) {
      return;
    }

    setIsProcessing(true);

    const {
      eventId,
      bracketId,
      userId,
      academyId,
      eventEntryId,
      confirmed,
      paymentInfo,
      manualPaymentInfo
    } = bracket;

    await putBracketParticipant({
      confirmed: confirmed,
      bracket_id: Number(bracketId),
      academy_id: Number(academyId),
      event_entry_id: Number(eventEntryId),
      event_id: Number(eventId),
      user_id: Number(userId),
      weight: `${weightValue}${weightMetric}`,
      manual_payment_info: manualPaymentInfo,
      payment_info: paymentInfo || {}
    });

    await handleRefreshData();

    handleSelectedEditParticipantWeightIndex(false);
    setIsProcessing(false);
  };

  if (participantIndex !== selectedEditParticipantWeightIndex) {
    const { weight } = bracket;

    return (
      <div className="d-flex flex-column">
        <span className={`px-3 ${bracket.weight == null ? "event-participants-null-weight" : "event-participants-weight-display"}`}>{(lowerCase(weight) === "none" ? "-" : weight) || "-"}</span>
        <FontAwesomeIcon
          icon={'pencil-alt'}
          className="me-1 cursor-pointer"
          onClick={() => {
            handleSelectedEditParticipantWeightIndex(participantIndex);
          }}
        />
      </div>
    );
  }

  return (
    <div className="event-participants-weight-cell">
      <Form.Control
        placeholder={t('createMatch.fieldLabel6')}
        value={weightValue}
        type='number'
        className="me-1 max-width-1 event-participants-weight-edit-input"
        onChange={event => {
          //restrict weight input to 2 decimal places
          let value = event.target.value;
          value = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value
          setWeightValue(Number(value).toString().replace(/[^0-9.]/g, ''));
        }}
      />


      <Form.Select
        value={weightMetric}
        className="max-width-1 mt-1 event-participants-weight-edit-input"
        onChange={event => {
          setWeightMetric(event.target.value);
        }}
      >
        <option value={'kg'}>
          {t('events.editEvents.participantsTable.kgField')}
        </option>
        <option value={'lbs'}>
          {t('events.editEvents.participantsTable.lbsField')}
        </option>
      </Form.Select>

      <div className='mx-auto'>

        {!isProcessing &&
          <FontAwesomeIcon
            icon="times"
            className="mt-2 event-participants-weight-close-btn cursor-pointer"
            onClick={() => {
              handleSelectedEditParticipantWeightIndex(false);
            }}
          />
        }

        <FontAwesomeIcon
          spin={isProcessing}
          icon={isProcessing ? 'spinner' : 'check'}
          className="mt-2 cursor-pointer mx-auto"
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};
