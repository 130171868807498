import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import EventEntryItem from './EventEntryItem';
import { useTranslation } from 'react-i18next';
import deleteEntry from 'api/events/update-entry';
import { toast } from 'react-toastify';
import { some, find } from "lodash";

const generateUUID = function () {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const EventEntries = ({ disabled, setValue, defaultItems, eventId, event }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(defaultItems);
  const [addedEntries, setAddedEntries] = useState([]);

  const handleAddEntry = () => {
    setAddedEntries([
      ...addedEntries,
      {
        uuid: generateUUID(),
        name: '',
        description: '',
        price: 0.0,
        currency: 'USD',
        gender: 'Male',
        gi_nogi: 1,
        seniority: 'Juvenile'
      }
    ]);
  };

  const handleRemoveItem = async (uuid, exists = false) => {
    if (exists) {

      //check if any brackets has a participant that registered on this entry.. 
      if ((event.brackets || []).some(m => ((m.participants || []).map(m => m.event_entry_id).includes(uuid)))) {
        toast.error(t('events.editEvents.entryParticipantsError'));
        document.body.click(); //to close the popover by clicking outside..
        return;
      }

      const success = await deleteEntry({
        eventEntryId: uuid,
        eventId: eventId
      });
      if (success) {
        toast.success(t('events.editEvents.entryDeleteSuccess'));

        //only remove from the local state when it's deleted successfully from the backend API.
        setItems(items.filter(item => item.uuid !== uuid));
        setAddedEntries(addedEntries.filter(item => item.uuid !== uuid));
      } else {
        toast.error(t('events.editEvents.entryDeleteError'));
      }
    } else {
      setItems(items.filter(item => item.uuid !== uuid));
      setAddedEntries(addedEntries.filter(item => item.uuid !== uuid));
    }
  };

  const handleChange = (uuid, name, value) => {
    const isExistingItem = !!find(items, (m) => m.uuid === uuid);
    const newItems = isExistingItem ? [...items] : [...addedEntries];
    const updateItems = isExistingItem ? setItems : setAddedEntries;


    const idx = newItems.findIndex(x => x.uuid == uuid);
    const updatedItem = { ...newItems[idx], [name]: value };
    updateItems([
      ...newItems.slice(0, idx),
      updatedItem,
      ...newItems.slice(idx + 1)
    ]);
  };

  useEffect(() => {
    setValue('entries', addedEntries, { shouldValidate: true });
  }, [addedEntries]);

  //check if there are negative prices for new entries
  const isThereNegativePricesForAnyEntry = some(addedEntries, (m) => m.price < 0);

  return (
    <Card className="my-3">
      <Card.Header as="h5">{t('events.createEvents.eventEntries')}</Card.Header>
      <Card.Body className="bg-light event-form-entries-inner-container">
        {[...items, ...addedEntries].map((item, index) => (
          <EventEntryItem
            {...item}
            key={index}
            index={index}
            event={event}
            handleRemove={handleRemoveItem}
            handleChange={handleChange}
          />
        ))}

        <IconButton
          onClick={handleAddEntry}
          disabled={isThereNegativePricesForAnyEntry || disabled} //disable "Add" btn when negative prices exist
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
        >
          {t('events.createEvents.addEntry')}
        </IconButton>
      </Card.Body>
    </Card>
  );
};

EventEntries.propTypes = {
  setValue: PropTypes.func
};

export default EventEntries;
