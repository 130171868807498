import { useState, useEffect } from 'react';
import { Col, Form, Row, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActivityHeader from './ActivityHeader';
import ActivityDetails from './ActivityDetails';
import ActivityFooter from './ActivityFooter';
import {
  deactivateActivity,
  LONG_FORM_DAYS,
  updateActivity
} from 'api/activities/create-activity';
import moment from 'moment';
import {
  values,
  filter,
  map,
  uniqBy,
  keys,
  isEmpty,
  find,
  findKey,
  includes,
  size,
  min
} from 'lodash';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import getActivities, { getActivity } from 'api/activities/get-activities';
import ActivityBanner from './ActivityBanner';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  isAcademy: state.auth.isAcademy,
  activityId: state.auth.activityId
});

const EditActivity = ({ activityId, academyId, uuid, isAcademy }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    setError,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {}
  });

  const { t } = useTranslation();
  const [updatingActivity, setUpdatingActivity] = useState(false);

  const [activityInfo, setActivityInfo] = useState(null);
  const [activityDays, setActivityDays] = useState([]);
  const [originalDaysPayload, setOriginalDaysPayload] = useState({});

  const [loading, setLoading] = useState(true);
  const [isProcessingDeactivation, setIsProcessingDeactivation] =
    useState(false);

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null
  });

  const [selectedDays, setSelectedDays] = useState([]);

  const isFormValid =
    isEmpty(keys(errors)) && isValid && !isEmpty(getValues().selectedDays);

  const handleDeactivateActivity = async () => {
    setIsProcessingDeactivation(true);

    const payload = {
      activityID: activityInfo.id,
      activityUUID: activityInfo.uuid,
      academyId: academyId,
      changeStatus: Number(!Boolean(activityInfo.status))
    };

    try {
      await deactivateActivity(payload);
      toast.success(
        activityInfo.status == 0
          ? t('activities.edit.activateSuccessToast')
          : t('activities.edit.deactivateSuccessToast'),
        {
          onClose: () => {
            window.location.href = `/dashboard/activities/`;
          }
        }
      );
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    } catch (error) {
    } finally {
      setIsProcessingDeactivation(false);
    }
  };

  const onSubmit = async data => {
    setUpdatingActivity(true);

    const payload = {
      activityID: activityInfo.id,
      activityUUID: activityInfo.uuid,
      academyId: academyId,
      activityName: data['name'],
      startDate: moment(data['startDate']).format(),
      endDate: moment(data['endDate']).format(),
      days: uniqBy(
        [
          ...filter(
            map(keys(originalDaysPayload), day => {
              //if the day exist in the days from the Form...
              //we're good...
              if (includes(data['selectedDays'], day)) {
                return null;
              }

              //if it doesn't exist in the days from the Form,
              //it means it's been deleted
              return {
                action: 'delete',
                activityMetaID: originalDaysPayload[day].activityMetaId,
                dayOfWeek: LONG_FORM_DAYS[day],
                time: moment(originalDaysPayload[day].activityTime).format(
                  'HH:mm'
                ),
                instructor: originalDaysPayload[day].instructorName,
                availableSpaces: originalDaysPayload[day].availableSpots
              };
            }),
            m => m
          ), //remove nulls with the filter..
          ...map(data['selectedDays'], day => {
            //default to insert
            let action = 'insert';

            //if the day exists in the original payload,
            //change action to update
            if (includes(keys(originalDaysPayload), day)) {
              action = 'update';
            }

            return {
              action: action,
              activityMetaID:
                action === 'insert' ? 0 : data['days'][day].activityMetaId, //use 0 as metaID for inserted days...
              dayOfWeek: LONG_FORM_DAYS[day],
              time: moment(data['days'][day].activityTime).format('HH:mm'),
              instructor: data['days'][day].instructorName,
              availableSpaces: data['days'][day].availableSpots
            };
          })
        ],
        m => m.dayOfWeek
      )
    };

    try {
      const result = await updateActivity(payload);
      toast.success(t('activities.edit.successToast'), {
        onClose: () => {
          window.location.href = `/dashboard/activities/`;
        }
      });
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    } catch (error) {
    } finally {
      setUpdatingActivity(false);
    }
  };

  const getActivityData = async () => {
    const { activity: days } = await getActivity({
      academyId,
      activityId: activityId
    });

    const firstDay = isEmpty(days) ? {} : days[0];

    const activity = {
      id: firstDay.id,
      uuid: firstDay.uuid,
      name: firstDay.name || '',
      startDate: firstDay.startDate || '',
      endDate: firstDay.endDate || '',
      status: firstDay.status 

    };

    setActivityInfo(activity);
    setActivityDays(days);

    return { activity, days };
  };

  const loadData = () => {
    (async () => {
      try {
        const data = await getActivityData();

        if (!data || !data.activity || !data.days) {
          return;
        }

        setValue('name', data.activity.name);
        setValue('startDate', data.activity.startDate);
        setValue('endDate', data.activity.endDate);

        const daysPayload = {};

        map(data.days, day => {
          const dayShortForm = findKey(LONG_FORM_DAYS, m => m == day.dayOfWeek);
          daysPayload[dayShortForm] = {
            activityMetaId: day.activityMetaID,
            activityTime: moment(day.time, 'HH:mm:ss').toDate(),
            instructorName: day.instructor,
            availableSpots: day.availableSpaces
          };
        });

        setOriginalDaysPayload(daysPayload);

        setFormData({
          startDate: new Date(data.activity.startDate),
          endDate: new Date(data.activity.endDate),
          days: { ...daysPayload }
        });

        setSelectedDays(keys(daysPayload));

        setValue('days', daysPayload);
        setValue('selectedDays', keys(daysPayload));

        setLoading(false);
      } catch (error) {}
    })();
  };

  useEffect(() => {
    loadData();
  }, [activityId]);

  return (
    <>
      {loading && (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      )}

      {activityInfo && (
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row className="g-3">
            <Col xs={12}>
              <ActivityHeader
                title={activityInfo.name}
                activityStatus={activityInfo.status}
                disabled={!isFormValid || updatingActivity}
                handleDeactivateActivity={handleDeactivateActivity}
                isProcessing={isProcessingDeactivation}
              />
            </Col>
            <Col md="12">
              <ActivityBanner
                onDone={getActivityData}
                activityBanner={activityInfo.activityAvatar || ''}
                uuid={activityInfo.uuid || ''}
              />
            </Col>

            <Col xs={12}>
              <ActivityDetails
                uuid={activityInfo.uuid || ''}
                errors={errors}
                register={register}
                setValue={setValue}
                control={control}
                getValues={getValues}
                trigger={trigger}
                formData={formData}
                setFormData={setFormData}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                setError={setError}
              />
            </Col>

            <Col>
              <ActivityFooter disabled={!isFormValid || updatingActivity} />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default connect(mapStateToProp)(EditActivity);
