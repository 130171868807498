/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import schema from '../schemas/new-user-registration';
import { useHistory } from 'react-router-dom';

import CountrySelect from 'react-bootstrap-country-select';

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';

import { createUser, getPublicProfile } from 'api/user';

import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { isEmpty, lowerCase, map, upperCase } from 'lodash';

import Select from 'react-select';
import countryList from 'react-select-country-list';
import getAcademiesFromCountry from 'api/user/get-academies-country';

const PublicUserRegistrationForm = ({ hasLabel, academyId, country, userId }) => {
  
  const history = useHistory();
  const [registrationError, setRegistrationError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingAcademies, setLoadingAcademies] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [academiesOptions, setAcademiesOptions] = useState([]);
  const [selectedAcademy, setSelectedAcademy] = useState(null);

  const { t } = useTranslation();

  useEffect(async() =>  {
    preLoadAcademies(academyId, country);
  }, []);

  const formik = useFormik({
    initialValues: {
      academyId: academyId? {value: academyId}: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      country: country? {id: country}: null
    },
    validationSchema: schema,
    onSubmit: async (values, actions) => {
      setIsSubmitting(true);
      setRegistrationError(null);
      try {
        await createUser({
          ...values,
          userUUID: '',
          country: upperCase(values?.country?.id),
          academyId: values?.academyId?.value
        });
        setIsSubmitting(false);
        actions.resetForm();
        toast.success(t('userRegistration.successToast'), {
          autoClose: 2000
        });

        const eventEntryURL = window.localStorage.getItem('entryRegistrationURL');
        if(eventEntryURL){
          window.localStorage.removeItem('entryRegistrationURL');
          window.location = eventEntryURL;
          return;
        }

        history.push('/');
      } catch (e) {
        setIsSubmitting(false);
        toast.error(
          <>
            <strong>{e.message}</strong>
          </>,
          {
            autoClose: 2000
          }
        );
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
      }
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setValues } =
    formik;

  const handleChange = (selectedOption) => {
    setSelectedAcademy(selectedOption);
  }; 

  const preLoadAcademies = async(academyId, country)=>{
    if(academyId && country){
      try {
        setLoadingAcademies(true);
        const data = await getAcademiesFromCountry(
          lowerCase(country || '')
        );
        if (data && !isEmpty(data)) {
          setAcademiesOptions(
            map(data, a => {
              return {
                label: a.name,
                value: a.uuid
              };
            })
          );

          let academy = data.filter(element => element.uuid === academyId );
          setSelectedAcademy({
            value: academy[0]?.uuid,
            label: academy[0]?.name
          });
        }
      } finally {
        setLoadingAcademies(false);
      }
    }
  }
  const loadData = country => {
    (async () => {
      try {
        setLoadingAcademies(true);

        const data = await getAcademiesFromCountry(
          lowerCase(country?.id || '')
        );
        if (data && !isEmpty(data)) {
          setAcademiesOptions(
            map(data, a => {
              return {
                label: a.name,
                value: a.uuid
              };
            })
          );
        }
      } finally {
        setLoadingAcademies(false);
      }
    })();
  };

  useDebounce(
    () => {
      if (selectedCountry) {
        loadData(selectedCountry);
      }
    },
    500,
    [selectedCountry]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && (
            <Form.Label>{t('userRegistration.fields.firstName')}</Form.Label>
          )}
          <Form.Control
            placeholder={
              !hasLabel ? t('userRegistration.fields.firstNamePlaceholder') : ''
            }
            {...getFieldProps('firstName')}
            name="firstName"
            type="text"
            isInvalid={Boolean(touched.firstName && errors.firstName)}
          />
          <Form.Text className="text-danger">
            {touched.firstName && errors.firstName}
          </Form.Text>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && (
            <Form.Label>{t('userRegistration.fields.lastName')}</Form.Label>
          )}
          <Form.Control
            placeholder={
              !hasLabel ? t('userRegistration.fields.lastNamePlaceholder') : ''
            }
            {...getFieldProps('lastName')}
            name="lastName"
            type="text"
            isInvalid={Boolean(touched.lastName && errors.lastName)}
          />
          <Form.Text className="text-danger">
            {touched.lastName && errors.lastName}
          </Form.Text>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>{t('userRegistration.fields.email')}</Form.Label>
        )}
        <Form.Control
          placeholder={
            !hasLabel ? t('userRegistration.fields.emailPlaceholder') : ''
          }
          {...getFieldProps('email')}
          name="email"
          type="email"
          isInvalid={Boolean(touched.email && errors.email)}
        />
        <Form.Text className="text-danger">
          {touched.email && errors.email}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>{t('userRegistration.fields.password')}</Form.Label>
        )}
        <Form.Control
          placeholder={
            !hasLabel ? t('userRegistration.fields.passwordPlaceholder') : ''
          }
          {...getFieldProps('password')}
          name="password"
          type="password"
          isInvalid={Boolean(touched.password && errors.password)}
        />
        <Form.Text className="text-danger">
          {touched.password && errors.password}
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && (
          <Form.Label>{t('userRegistration.fields.country')}</Form.Label>
        )}

        <CountrySelect
          flags={true}
          flush={true}
          value={values.country}
          placeholder={t('userRegistration.fields.countryPlaceholder')}
          onChange={value => {
            //when selecting a new country, clear the selected academy
            setValues({ ...values, country: value, academyId: '' });
            setSelectedCountry(value);

            //clear the academy options for new ones to be added..
            setAcademiesOptions([]);
          }}
        />

        <Form.Text className="text-danger">
          {touched.country && errors.country}
        </Form.Text>
      </Form.Group>

      { (selectedCountry && !isEmpty(academiesOptions)) || academyId ?(
        <Form.Group className="mb-3">
          {hasLabel && (
            <Form.Label>{t('userRegistration.fields.academy')}</Form.Label>
          )}

          <Select
            options={academiesOptions}
            value={selectedAcademy}
            placeholder={t('userRegistration.fields.academy')}
            onChange={value => {
              setValues({ ...values, academyId: value });
              handleChange(value);
            }}
          />

          <Form.Text className="text-danger">
            {touched.academyId && errors.academyId}
          </Form.Text>
        </Form.Group>
      ): ('')}

      {registrationError && (
        <Alert variant={'danger'} style={{ padding: 8 }}>
          {registrationError}
        </Alert>
      )}

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            isSubmitting ||
            !formik.isValid ||
            !values.academyId ||
            !values.country
          }
        >
          {isSubmitting || loadingAcademies ? (
            <Spinner
              animation="grow"
              style={{ width: 15, height: 15, marginTop: 4 }}
            />
          ) : (
            t('userRegistration.submitButton')
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default PublicUserRegistrationForm;
