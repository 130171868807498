/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { getUserMatches } from 'api/user';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import TableListComponent from 'components/utils/shared/table/table';
import { Button } from 'react-bootstrap';
import { dispatchControlMatchInfo } from 'dux/auth/action-thunks/event-thunk';

const getMatchDuration = duration => {
  return moment.utc(parseInt(duration) * 1000).format('mm:ss');
};

const mapStateToProp = state => ({
  academyId: state.auth.academyId
});
const Matches = ({ academyId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const columns = [
    {
      accessor: 'participants',
      Header: t('matchesColumns.column2'),
      Cell: rowData => {
        const { participant1, participant2 } = rowData.row.original;
        return (
          <>
            {participant1.name} VS {participant2.name}
          </>
        );
      }
    },
    {
      accessor: 'details',
      Header: t('matchesColumns.column3'),
      Cell: rowData => {
        const { matchType, matchGender, giNogi, seniority, weight, duration } =
          rowData.row.original;
        return (
          <>
            {matchType}
            {matchGender && ` / ${matchGender}`}
            {(giNogi || giNogi === 0) && ` / ${giNogi === 1 ? 'Gi' : 'NoGi'}`}
            {seniority && ` / ${seniority}`}
            {weight && ` / ${weight}`}
            {duration && ` / ${getMatchDuration(duration)}`}
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: t('matchesColumns.column4'),
      Cell: rowData => {
        const { matchDatetime } = rowData.row.original;
        return (
          <>{matchDatetime && moment(matchDatetime).format('MM-DD-YYYY')}</>
        );
      }
    },
    {
      accessor: 'action',
      Header: t('matchesColumns.column5'),
      Cell: rowData => {
        const { matchId, matchEndtime, academy } = rowData.row.original;
        return (
          <>
            {matchEndtime ? (
              <Link
                className="btn-view"
                to={`/dashboard/match-result/${academy.id}/${matchId}`}
              >
                <FontAwesomeIcon icon={['fas', 'eye']} />
              </Link>
            ) : (
              <button
                className="btn btn-play match-play-btn"
                onClick={() => {
                  dispatch(dispatchControlMatchInfo({
                    controlMatchAcademyId: academyId,
                    matchId: matchId,
                  }))
                  history.push(`/control-match/`)
                }}
              >
                <FontAwesomeIcon icon={['fas', 'play']} />
              </button>
            )}
          </>
        );
      }
    }
  ];
  const { userId } = useParams();
  useEffect(() => {
    (async () => {
      let matches = await getUserMatches(academyId, userId);
      if (matches) {
        matches = matches.filter(match => {
          return match.matchEndtime !== null;
        });
        setIsLoading(false);
        setData(matches);
      }
    })();
  }, []);
  return (
    <>
      <TableListComponent
        columns={columns}
        data={data || []}
        handleDeleteMatch={() => { }}
        isLoading={isLoading}
      />

    </>
  );
};
export default connect(mapStateToProp)(Matches);
