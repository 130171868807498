import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsInsertBrackets = {
  eventId: number;
  academyID: number;
  brackets: Array<any>;
};
const insertBrackets = async (data: ArgsInsertBrackets): Promise<any> => {
  try {
   
    const response = await axios.post(`${apiRoot}/bracket`, {
      event_id: data.eventId,
      academy_id: data.academyID,
      brackets: data.brackets
    });
    return response;
  } catch (e) {
    return false;
  }
};
export default insertBrackets;
