import getSubscriptionInfo from 'api/subscription/get-subscription';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { capitalize, find } from 'lodash';

import { Card, Col, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BillingDetails from './BillingDetails';
import BillingHeader from './BillingHeader';
import { useParams } from 'react-router-dom';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid
});

const EventCreditCheckout = ({ academyId, uuid }) => {
  const { t } = useTranslation();

  const [selectedCredits, setSelectedCredits] = useState('1');

  return (
    <>
      <div>
        <BillingHeader selectedCredits={selectedCredits} />
        <Row className="g-3">
          <Col lg={12} className="pe-lg-2 mb-3">
            <Card className="h-100">
              <Card.Header>
                <h5 className="mb-0">
                  {t('events.createEvents.buyCredits.checkoutTitle')}
                </h5>
              </Card.Header>
              <Card.Body className="bg-light">
                <BillingDetails
                  selectedCredits={selectedCredits}
                  setSelectedCredits={setSelectedCredits}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default connect(mapStateToProp)(EventCreditCheckout);
