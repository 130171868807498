import React from 'react';
import { Col, Row } from 'react-bootstrap';
import RegistrationForm from 'components/authentication/RegistrationForm';
import { useTranslation } from 'react-i18next';

const Registration = props => {

  const { t } = useTranslation();

  return (
    <>
      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="modalLabel">{t('userRegistration.title')}</h5>
        </Col>
        <Col xs="auto">
        </Col>
      </Row>
      <RegistrationForm {...props} />
    </>
    );
};

export default Registration;
