import { useState, useEffect } from 'react';
import EventDetails from './EventDetails';
import EventBrackets from './EventBrackets';
import EventHeader from './EventHeader';
import EventFooter from './EventFooter';
import EventEntries from './EventEntries';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import EventOtherInfo from './EventOtherInfo';
import EventBanner from './EventBanner';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import getEventDetail from 'api/events/get-event';
import updateEvent from 'api/events/update-event';
import moment from 'moment';
import { replace, some } from 'lodash';
import { checkIfAcademyIsPremium } from 'api/academy/get-academy';

const mapStateToProp = state => ({
  academyId: state.auth.eventEdit.eventAcademyId,
  id: state.auth.eventEdit.eventUUID,
});

const EditEvent = ({ academyId, id }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    setError,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      brackets: []
    }
  });

  const { t } = useTranslation();

  const [saving, setSaving] = useState(false);

  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);

  const handleUpdateEvent = async data => {
    try {
      //check if there are negative prices for new entries
      const isThereNegativePricesForAnyEntry = some(
        data.entries,
        m => m.price < 0
      );
      if (isThereNegativePricesForAnyEntry) {
        toast.error(t('events.createEvents.negativePricePlanErrorToast'));
        return;
      }

      data.location = `${data.locationLat},${data.locationLng}`;
      //if the academy is on the free plan, check if the existing entries or the new entries have prices greater than zero, and show an error if so...
      if (
        !(await checkIfAcademyIsPremium(academyId)) &&
        (some(data.entries, m => m.price > 0) ||
          some(eventInfo.entries, m => m.price > 0))
      ) {
        toast.error(t('events.createEvents.premiumPlanErrorToast'));
        return;
      }

      setSaving(true);

      let result = await updateEvent(data);
      if (result.statusCode === 200) {
        toast.success(
          replace(result.body, /["']/g, '') ||
          t('events.editEvents.eventUpdateSuccess'),
          {
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            onClose: () => {
              window.location.href = `/dashboard/events/event-detail/${academyId}/${id}`;
            }
          }
        );
      } else {
        toast.error(result.body);
        setSaving(false);
      }
    } catch (error) {
      setSaving(false);
      toast.error(t('events.editEvents.eventUpdateError'));
    }
  };

  const onSubmit = async data => {
    data['private'] = data.privacy === 'privatePage' ? 1 : 0;

    const { brackets, entries, ...restEvent } = eventInfo.event; //remove the brackets and entries key from the event
    const {
      brackets: brackets1,
      name,
      event_date,
      early_registration_final_date,
      normal_registration_final_date,
      late_registration_final_date,
      ...restData
    } = data;

    const updatedEvent = {
      ...restEvent,
      ...restData,
      name: replace(name, /'/g, ''), //remove apostrophe from the name if existing, as it causes an error in the api endpoint..
      event_date: moment(event_date).format('YYYY-MM-DD HH:mm:ss'),
      early_registration_final_date: early_registration_final_date
        ? moment(early_registration_final_date).format('YYYY-MM-DD HH:mm:ss')
        : '',
      normal_registration_final_date: normal_registration_final_date
        ? moment(normal_registration_final_date).format('YYYY-MM-DD HH:mm:ss')
        : '',
      late_registration_final_date: late_registration_final_date
        ? moment(late_registration_final_date).format('YYYY-MM-DD HH:mm:ss')
        : ''
    };

    await handleUpdateEvent(updatedEvent);
  };

  const getEventData = async () => {
    const data = await getEventDetail(academyId, id);
    setEventInfo(data);
    return data;
  };

  const loadData = () => {
    (async () => {
      const data = await getEventData();
      setValue('name', data.event.name);

      setValue(
        'privacy',
        data.event.private === 1 ? 'privatePage' : 'publicPage'
      );
      setValue('information', data.information);
      setValue('prizes', data.prizes);
      setValue('categories', data.categories);

      setValue('timezone', data.event.timezone);
      setValue('contact_website', data.event.contact_website);
      setValue('contact_phone', data.event.contact_phone);
      setValue('contact_email', data.event.contact_email);
      setValue('contact_facebook', data.event.contact_facebook);
      setValue('contact_instagram', data.event.contact_instagram);

      setValue(
        'event_date',
        data.event.event_date
          ? moment(new Date(data.event.event_date)).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          : ''
      );

      setValue(
        'early_registration_final_date',
        data.event.early_registration_final_date
          ? moment(new Date(data.event.early_registration_final_date)).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          : ''
      );
      setValue(
        'normal_registration_final_date',
        data.event.early_registration_final_date
          ? moment(new Date(data.event.normal_registration_final_date)).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          : ''
      );
      setValue(
        'late_registration_final_date',
        data.event.early_registration_final_date
          ? moment(new Date(data.event.late_registration_final_date)).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          : ''
      );

      isLoading(false);
    })();
  };

  useEffect(() => {
    loadData();
  }, [id]);

  return (
    <>
      {loading && (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      )}

      {eventInfo && (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          autoComplete="off"
        >
          <Row className="g-3">
            <Col xs={12}>
              <EventHeader
                eventId={eventInfo.event.id}
                eventUUID={eventInfo.event.uuid}
                academyId={academyId}
                isPrivate={eventInfo.private}
                isPublish={eventInfo.eventStarted}
                eventType={eventInfo.eventType}
                disabled={saving}
                handleUpdateEvent={handleUpdateEvent}
                event_end_date={eventInfo.event_end_date}
              />
            </Col>
            <Col xs={12}>
              <EventBanner uuid={eventInfo.id} image={eventInfo.image} />
            </Col>
            <Col lg={8}>
              <EventDetails
                {...register('event_date', { required: true })}
                disabled={saving}
                errors={errors}
                register={register}
                setValue={setValue}
                control={control}
                getValues={getValues}
                trigger={trigger}
                setError={setError}
                eventInfo={eventInfo}
                handleRefreshData={getEventData}
                defaultFormData={{
                  event_date: new Date(eventInfo.event.event_date),
                  early_registration_final_date: eventInfo.event
                    .early_registration_final_date
                    ? new Date(eventInfo.event.early_registration_final_date)
                    : '',
                  normal_registration_final_date: eventInfo.event
                    .normal_registration_final_date
                    ? new Date(eventInfo.event.normal_registration_final_date)
                    : '',
                  late_registration_final_date: eventInfo.event
                    .late_registration_final_date
                    ? new Date(eventInfo.event.late_registration_final_date)
                    : '',
                  information: eventInfo.event.information,
                  prizes: eventInfo.event.prizes,
                  categories: eventInfo.event.categories,
                  country: eventInfo.event.country || '',
                  locationLat: eventInfo.event.locationLat || '',
                  locationLng: eventInfo.event.locationLng || ''
                }}
              />
              <EventBrackets
                {...register('brackets', { required: false })}
                disabled={saving}
                setValue={setValue}
                eventId={eventInfo.event.id}
                academyId={academyId}
                defaultItems={(eventInfo.event.brackets || []).map(bracket => {
                  return {
                    uuid: bracket.bracketUUID,
                    participants: (bracket.participants || []).length,
                    mat: bracket.mat,
                    gender: bracket.gender,
                    gi_nogi: bracket.gi_nogi,
                    seniority: bracket.seniority,
                    weight: bracket.weight,
                    duration: bracket.duration,
                    belt: bracket.belt,
                    academyId: bracket.academy_id,
                    exists: true //for existing brackets that can't be edited, we'd disable the input based on this prop.
                  };
                })}
              />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <EventOtherInfo
                  eventInfo={eventInfo}
                  academyId={academyId}
                  errors={errors}
                  register={register}
                  control={control}
                />
                <EventEntries
                  {...register('entries', { required: false })}
                  disabled={saving}
                  setValue={setValue}
                  event={eventInfo.event}
                  eventId={eventInfo.event.id}
                  defaultItems={(eventInfo.event.entries || []).map(entry => {
                    return {
                      uuid: entry.id,
                      name: entry.name,
                      description: entry.description,
                      price: entry.price,
                      currency: entry.currency,
                      gender: entry.gender,
                      gi_nogi: entry.gi_nogi,
                      seniority: entry.seniority,
                      exists: true //for existing entries
                    };
                  })}
                />
              </div>
            </Col>
            <Col>
              <EventFooter
                eventUUID={eventInfo.event.uuid}
                academyId={academyId}
                disabled={saving}
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default connect(mapStateToProp)(EditEvent);
