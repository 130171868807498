/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, InputGroup } from 'react-bootstrap';
import getProfile from 'api/user/get-profile';
import updateProfile from 'api/user/update-profile';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CountrySelect from 'react-bootstrap-country-select';
import Select from 'react-select';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import range from 'lodash/range';
import DefaultDateTimePicker from 'components/common/DateTimePicker';
import { Controller } from 'react-hook-form';
import './use-profile-field.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

let schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  weight: yup.string().required(),
  belt: yup.string().required(),
  age: yup.string().required(),
  instagram: yup.string().required(),
  facebook: yup.string().required(),
  country: yup.string().required(),
  birthday: yup.date().required()
});

const UserProfileFields = ({ uuid, formData, setFormData, visibilityChanged, handleVisibilityChanges }) => {
  const baseUrl = 'https://www.instagram.com/';
  const facebookUrl = 'https://www.facebook.com/';
  const academyId = useSelector(state => state.auth.academyId);
  const [submitButton, setSubmitButton] = useState(false);
  const [weightNumber, setWeightNumber] = useState('');
  const [weightUnit, setWeightUnit] = useState('');
  const [birthDay, setBirthDay] = useState();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const data = await getProfile(uuid);
      if (data) {
        debugger
        setFormData({
          birthday: data[0]?.birthday,
          name: data[0]?.name,
          email: data[0]?.email,
          weight: data[0]?.weight,
          belt: data[0]?.belt,
          age: data[0]?.age,
          facebook: data[0]?.facebook
            ? data[0]?.facebook.substr(
                facebookUrl.length,
                data[0]?.facebook.length
              )
            : '',
          instagram: data[0]?.instagram
            ? data[0]?.instagram?.substr(baseUrl.length, data[0].instagram.length)
            : '',
          country: data[0]?.country,
          academyId
        });
        setBirthDay(
          data[0]?.birthday !== '' ? new Date(data[0]?.birthday) : new Date()
        );
        setWeightNumber(data[0]?.weight?.slice(0, -2));
        setWeightUnit(data[0]?.weight?.slice(-2));
        handleVisibilityChanges(data[0]?.is_profile_visible, false)
      }
    })();
  }, []);

  useEffect(async () => {
    if(visibilityChanged){
      setSubmitButton(await schema.isValid(formData));
    }
  }, [visibilityChanged]);

  const handleChange = async e => {
    const updatedData = {
      ...formData,
      [e.target.name]: e.target.value
    };
    setFormData(updatedData);
    setSubmitButton(await schema.isValid(updatedData));
  };

  const handleWeightChange = async e => {
    const updatedData = {
      ...formData,
      [e.target.name]: `${e.target.value}${weightUnit}`
    };
    setFormData(updatedData);
    setWeightNumber(e.target.value);
    setSubmitButton(await schema.isValid(updatedData));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    formData.instagram = baseUrl + formData.instagram;
    setSubmitButton(await schema.isValid(formData));
    formData.facebook = facebookUrl + formData.facebook;
    setSubmitButton(await schema.isValid(formData));
    try {
      await updateProfile(uuid, formData);
      toast.success(t('profile.userForm.successToast'), {
        autoClose: 2000
      });
    } catch (e) {
      toast.error(t('profile.userForm.errorToast'), { autoClose: 2000 });
    }
    history.push('/dashboard/profile');
  };

  const handleCountryFlag = async e => {
    let updatedData = {
      ...formData,
      country: e
    };
    if (e && e.alpha2) {
      updatedData = {
        ...formData,
        country: e.alpha2
      };
    }
    setSubmitButton(await schema.isValid(updatedData));
    setFormData(updatedData);
  };

  const handleSelectChange = name => async e => {
    const updatedData = {
      ...formData,
      [name]: e.value
    };
    setFormData(updatedData);
    setSubmitButton(await schema.isValid(updatedData));
  };

  const onValueChange = async birthday => {
    const year = new Date(birthday).getFullYear();
    const currentYear = new Date().getFullYear();
    const updatedData = {
      ...formData,
      birthday,
      age: currentYear - year
    };
    setFormData(updatedData);
    setSubmitButton(await schema.isValid(updatedData));
  };

  const verifyInstaHandle = () => {
    const instaUrlRegex = /^[a-zA-Z0-9_]{1,30}$/;
    if (!instaUrlRegex.test(formData.instagram)) {
      setFormData({ ...formData, instagram: '' });
      toast.error('Please enter valid Instagram ID', { autoClose: 2000 });
      setSubmitButton(false);
    }
  };

  const verifyFacbookHandle = () => {
    const fbUrlRegex = /^[a-zA-Z0-9\.]{5,}$/;
    if (!fbUrlRegex.test(formData.facebook)) {
      setFormData({ ...formData, facebook: '' });
      toast.error('Please enter valid Facbook ID', { autoClose: 2000 });
      setSubmitButton(false);
    }
  };

  return (
    <Card>
      <FalconCardHeader title={t('profile.editProfile')} />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="firstName">
              <Form.Label>{t('profile.userForm.name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('profile.userForm.name')}
                value={formData.name}
                name="name"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>{t('profile.userForm.email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('profile.userForm.email')}
                value={formData.email}
                name="email"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="age">
              <Form.Label>{t('profile.userForm.birthday')}</Form.Label>
              <div className="date-wrapper position-relative">
                <DefaultDateTimePicker
                  value={birthDay}
                  minDate={new Date(2000)}
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect={false}
                  onValueChange={date => {
                    setBirthDay(date);
                    let newEventDate = date
                      ? moment(date).format('YYYY-MM-DD')
                      : '';
                    onValueChange(newEventDate);
                  }}
                />
              </div>
            </Form.Group>
          </Row>
          <Form.Group>
            <Form.Label>{t('profile.userForm.weight')}</Form.Label>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} sm={9}>
                <Form.Control
                  type="number"
                  placeholder={t('profile.userForm.weight')}
                  value={weightNumber}
                  name="weight"
                  onChange={handleWeightChange}
                />
              </Form.Group>
              <Form.Group as={Col} sm={3}>
                <Select
                  options={[
                    {
                      value: 'kg',
                      label: 'kg'
                    },
                    {
                      value: 'lb',
                      label: 'lb'
                    }
                  ]}
                  value={{
                    value: weightUnit,
                    label: weightUnit
                  }}
                  onChange={async e => {
                    const updatedData = {
                      ...formData,
                      weight: `${weightNumber}${e.value}`
                    };
                    setFormData(updatedData);
                    setWeightUnit(e.value);
                    setSubmitButton(await schema.isValid(updatedData));
                  }}
                  placeholder={t('profile.userForm.weight')}
                  classNamePrefix="react-select"
                  name="unit"
                />
              </Form.Group>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('profile.userForm.belt')}</Form.Label>
            <Select
              options={[
                {
                  value: t('profile.beltItems.item1'),
                  label: t('profile.beltItems.item1')
                },
                {
                  value: t('profile.beltItems.item2'),
                  label: t('profile.beltItems.item2')
                },
                {
                  value: t('profile.beltItems.item3'),
                  label: t('profile.beltItems.item3')
                },
                {
                  value: t('profile.beltItems.item4'),
                  label: t('profile.beltItems.item4')
                },
                {
                  value: t('profile.beltItems.item5'),
                  label: t('profile.beltItems.item5')
                }
              ]}
              defaultValue={{ value: formData.belt, label: formData.belt }}
              value={{ value: formData.belt, label: formData.belt }}
              placeholder={t('profile.userForm.belt')}
              classNamePrefix="react-select"
              onChange={handleSelectChange('belt')}
              name="belt"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('profile.userForm.country')}</Form.Label>
            <CountrySelect
              value={formData.country}
              placeholder={t('profile.userForm.country')}
              name="country"
              onChange={handleCountryFlag}
            />
          </Form.Group>
          <Row className="g-3">
            <h5 className="py-3 mb-0 border-top">
              {t('profile.socialProfile')}
            </h5>
            <Form.Group controlId="intro">
              <Form.Label>{t('profile.academyForm.instagram')}</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>{baseUrl}</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder={t('profile.academyForm.intagramUser')}
                  value={formData.instagram}
                  name="instagram"
                  onChange={handleChange}
                  onBlur={verifyInstaHandle}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="intro">
              <Form.Label>{t('profile.userForm.facebook')}</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>{facebookUrl}</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder={t('profile.academyForm.intagramUser')}
                  value={formData.facebook}
                  name="facebook"
                  onChange={handleChange}
                  onBlur={verifyFacbookHandle}
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <div className="text-end">
            <Button variant="primary" type="submit" disabled={!submitButton}>
              {t('profile.userForm.buttonText')}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default UserProfileFields;
