/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Registration from 'components/authentication/simple/Registration';
import { Link, useParams } from 'react-router-dom';
import AcademyRegistrationForm from 'components/authentication/AcademyRegistrationForm';
import IconAlert from 'components/common/IconAlert';

import { useTranslation } from 'react-i18next';
import { CgGym } from 'react-icons/cg';

const NewAcademyRegistration = ({ match }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document
      .getElementsByTagName('main')[0]
      .classList.add('registrationMain');
    return () => {
      document
        .getElementsByTagName('main')[0]
        .classList.remove('registrationMain');
    };
  }, []);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Card>
            <Card.Body className="px-4 px-sm-5 py-2 py-sm-4">
              <div className="mb-3">
                <div className="registration-icon-border">
                  <CgGym size={40} color='black' />
                </div>
              </div>

              <AcademyRegistrationForm />
            </Card.Body>
          </Card>

          <Col className="mt-3">
            <IconAlert variant="warning" hideIcon={true}>
              <p className="mb-2 text-font-xs">
                {t('academyRegistration.warningText')}
              </p>
              <p className="mb-0 text-font-xs">
                {t('academyRegistration.warningAthleteSignup')}{' '}
                <span>
                  <Link to="/athlete-registration">
                    <span>
                      {t('academyRegistration.warningAthleteSignupBtn')}
                    </span>
                  </Link>
                </span>
              </p>
            </IconAlert>
          </Col>
        </Col>
      </Row>
    </Section>
  );
};

export default NewAcademyRegistration;
