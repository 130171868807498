import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const ModalQuestionStatus = (
  {
    onYes,
    onNot,
    question,
    show,
    onHide,
  }
) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide}>
      <div className='d-flex justify-content-end m-3'>
        <FontAwesomeIcon
          icon="xmark"
        />
      </div>
      <Modal.Body><h5>{question}</h5></Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onNot}>
          {t('bracketModal.option1')}
        </Button>
        <Button variant="primary" onClick={onYes}>
          {t('bracketModal.option2')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalQuestionStatus;