import updateStatusUser from 'api/user/update_status_user';
import ModalQuestionStatus from './modal_question';
import { useTranslation } from 'react-i18next';


const StatusComponent = ({ userUuid, activeNumber, active, setRefetchUser, childComponent, setShowQuestion, showQuestion }) => {
  const { t } = useTranslation();
  const onHandledUserStatus = async () => {
    var data = {
      "uuid": userUuid,
      "active": activeNumber,
      "language": localStorage.getItem('i18nextLng')
    };
    await updateStatusUser(data);
    setRefetchUser(userUuid + activeNumber);
    setShowQuestion(false);
  }

  return (
    <div
      style={{ cursor: 'pointer' }}
    >
      <ModalQuestionStatus
        show={showQuestion}
        onHide={() => setShowQuestion(false)}
        onYes={() => {
          onHandledUserStatus();
          setShowQuestion(false);
        }
        }
        onNot={() => {
          setShowQuestion(false);
        }}
        question={
          (active === 0)
            ? t('users.table.actions.questionActivated')
            : t('users.table.actions.questionDeactivated')
        }
      />
      {childComponent}
    </div>
  );
}

export default StatusComponent;