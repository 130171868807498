const ACTION_TYPE = {
  NORMAL: 'slickcomp/auth/NORMAL',
  LOG_ON: 'slickcomp/auth/LOG_ON',
  LOGGED_IN: 'slickcomp/auth/LOGGED_IN',
  LOGIN_FAILED: 'slickcomp/auth/LOGIN_FAILED',
  SET_AVATAR: 'slickcomp/auth/SET_AVATAR',
  SET_ACTIVITY_ID: 'slickcomp/activity/SET_ACTIVITY_ID',
  SET_EVENT_EDIT: 'slickcomp/events/SET_EVENT_EDIT',
  SET_CONTROL_MATCH: 'slickcomp/events/SET_CONTROL_MATCH',
  SET_MATCH_INFO: 'slickcomp/events/SET_MATCH_INFO',
  SET_EVENT_ENTRY_INFO: 'slickcomp/events/entry/SET_EVENT_ENTRY_INFO',
};

export default ACTION_TYPE;
