import axios from 'axios';
import moment from 'moment';

const apiRoot = process.env.REACT_APP_API_ROOT;

type Args = {
  upcoming: number;
  gameType: number;
  startDate: string;
  endDate: string;
  countries: Array<string>;
};

const getPublicEvents = async ({
  upcoming,
  gameType,
  startDate,
  endDate,
  countries
}: Args) => {
  const parameters = `upcoming=${upcoming}&type=${gameType}
  ${startDate ? `&startDate=${moment(startDate).format('yyyy-MM-DD')}` : ''}
  ${endDate ? `&endDate=${moment(endDate).format('yyyy-MM-DD')}` : ''}
  ${countries.length ? `&countries=${countries.join(',')}` : ''}`;

  let data = await (
    await axios.get(`${apiRoot}/public-events?${parameters}`)
  ).data;

  return decodeEvents(data as any[]);
};

const decodeEvents = (data: any[]) => {
  return (data ?? []).reduce((result: any[], el: any) => {
    const transformed = {
      id: el.eventUUID,
      title: el.eventName,
      organizer: 'Default',
      country: el.country,
      eventDate: el.eventDate,
      image: el.avatarUrl || ''
    };
    result.push(transformed);
    return result;
  }, []);
};

export default getPublicEvents;
