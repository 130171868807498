import {
  Col,
  Form,
  Row,
  Button,
  OverlayTrigger,
  Popover,
  Badge
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { map, range } from 'lodash';
import { toast } from 'react-toastify';
import { useContext, useState, useRef, useEffect } from 'react';
import { BracketContext } from 'context/Context';
const EventBracketItem = ({
  uuid,
  mat,
  gender,
  gi_nogi,
  seniority,
  weight,
  duration,
  belt,
  exists,
  participants,
  handleRemove,
  handleChange,
  clearBracketWeightPills,
  setDisableButton,
  handleWeightBadgeRemove
}) => {
  
  const { t } = useTranslation();
  const [pill, setPill] = useState(false);
  const weightRef = useRef(),
    metricRef = useRef(),
    signRef = useRef();
  const [absolut, setAbsolut] = useState(false);
  const [weightDisable, setWeightDisable] = useState(false);
  const [weights, setWeights] = useState([]);
  const { weight1, setWeight1, weightList, setWeightList, items } =
    useContext(BracketContext);
    
  useEffect(() => {
    if (weights.length == 0) setDisableButton(true);
    else {
      setDisableButton(false);
    }
  }, [weights]);

  useEffect(() => {
    if (weight1 != null) {
      metricRef?.current?.value != undefined &&
        (metricRef.current.value = weight1);
    } else {
    }
    if (absolut) {
      setWeight1(metricRef && metricRef.current.value);
    } else if (!absolut && weightList.length == 0) {
      setWeight1(null);
    }
  }, [weight1, absolut]);

  useEffect(() => {
    if (clearBracketWeightPills) {
      setWeights([]);
      setPill(false);
    }
  }, [clearBracketWeightPills]);

  const handleFieldChange = (name, value) => {
    handleChange(uuid, name, value);
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        {t('events.editEvents.confirmDelete.title')}
      </Popover.Header>
      <Popover.Body>
        <p>{t('events.editEvents.confirmDelete.description')}</p>
        <Button
          variant="danger"
          onClick={() => {
            if (participants > 0) {
              toast.error(t('events.editEvents.bracketParticipantsError'));
              document.body.click(); //to close the popover by clicking outside..
              return;
            }

            handleRemove(uuid, exists);
            document.body.click(); //to close the popover by clicking outside..
          }}
        >
          {t('events.editEvents.confirmDelete.buttonText')}
        </Button>
      </Popover.Body>
    </Popover>
  );

  const checkUnique = w => {
    const found = items.filter(i => i.weight == w);
    if (found.length > 0) return false;
    return true;
  };

  const addPill = (val = null) => {
    setPill(true);
    setWeight1(metricRef.current.value);
    const obj = val
      ? `Absolut`
      : `${signRef.current.value}${Number(weightRef.current.value).toFixed(2).replace(/[^0-9.]/g, '')}${metricRef.current.value}`;
    if (obj != '' && weights.indexOf(obj) == -1) {
      setWeights(prev => {
        const result = [...prev, obj];
        handleFieldChange('weights', result);
        return result;
      });
      setWeightList([...weightList, obj]);
    }
    weightRef.current.value = '';
  };

  function handleKeyDown(event) {
    if (event.key === "e") {
      event.preventDefault();
    }
  }
  
  const handleWeightSelect = () => {
    if (
      metricRef?.current?.value != undefined &&
      weightRef?.current?.value != undefined &&
      signRef?.current?.value != undefined &&
      metricRef.current.value != '' &&
      signRef.current.value != '' &&
      weightRef.current.value != ''
    ) {
      addPill();
    }
  };

  const handleAbsolut = e => {
    
    setAbsolut(true);
    if (e.target.checked && weights.indexOf('Absolut') == -1) {
      addPill('Absolut');
    } else {
      handleBadgeRemove('Absolut');
    }
  };

  const handleBadgeRemove = weight => {
    setAbsolut(false);
    setWeightList(prev => {
      const filtered = prev.filter(w => w != weight);
      if (filtered.length == 0 && !absolut) {      
        setWeight1(null);
      }
      return filtered;
    });

    setWeights(prev => {
      const result = prev.filter(w => w != weight);
      handleFieldChange('weights', result);
      return result;
    });
    handleWeightBadgeRemove(weight);
  };

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        <div className="position-absolute end-0 top-0 mt-2 me-3 z-index-1">
          <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={popover}
            rootClose={true}
          >
            <Button size="sm" variant="link" className="p-0">
              <FontAwesomeIcon className="text-danger" icon="times-circle" />
            </Button>
          </OverlayTrigger>
        </div>

        <div className="gx-2 gy-3">
          <Row xs={1} md={4} lg={4}>
            <Col md={4} lg={4}>
              <Form.Group controlId="gender" className="mb-3">
                <Form.Label>{t('createMatch.fieldLabel4')}</Form.Label>
                <Form.Select
                  aria-label="gender"
                  name="gender"
                  disabled={exists}
                  value={gender}
                  onChange={e => handleFieldChange('gender', e.target.value)}
                  required
                >
                  <option selected value="Male">
                    {t('createMatch.fieldGenderValue1')}
                  </option>
                  <option value="Female">
                    {t('createMatch.fieldGenderValue2')}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group controlId="seniority" className="mb-3">
                <Form.Label>{t('createMatch.fieldLabel5')}</Form.Label>
                <Form.Select
                  aria-label="seniority"
                  name="seniority"
                  value={seniority}
                  disabled={exists}
                  onChange={e => handleFieldChange('seniority', e.target.value)}
                  required
                >
                  <option selected value="Juvenile">
                    {t('createMatch.fieldSeniorityValue2')}
                  </option>
                  <option value="Adult">
                    {t('createMatch.fieldSeniorityValue1')}
                  </option>
                  <option value="Master">
                    {t('createMatch.fieldSeniorityValue3')}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group controlId="gi_nogi" className="mb-3">
                <Form.Label>Gi / NoGi</Form.Label>
                <Form.Select
                  aria-label="gi_nogi"
                  name="gi_nogi"
                  value={gi_nogi}
                  disabled={exists}
                  onChange={e => handleFieldChange('gi_nogi', e.target.value)}
                  required
                >
                  <option selected value="1">
                    Gi
                  </option>
                  <option value="0">NoGi</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group className="mb-3" controlId="weight">
                <Form.Label>{t('createMatch.fieldLabel6')}</Form.Label>

                <Form.Select
                  aria-label="weight"
                  name="weight"
                  value={weight}
                  disabled={exists}
                  onChange={e => handleFieldChange('weight', e.target.value)}
                  required
                >
                  {map(range(45, 121), val => {
                    return (
                      <option
                        selected
                        value={
                          weight.slice(-2) == 'kg'
                            ? `${weight.slice(0, 1)}${val}kg`
                            : `${weight.slice(0, 1)}${val}lb`
                        }
                      >
                        {weight.slice(-2) == 'kg'
                          ? `${weight.slice(0, 1)}${val}kg`
                          : `${weight.slice(0, 1)}${val}lb`}
                      </option>
                    );
                  })}
                  <option selected value={'Absolut'}>
                    {t('createMatch.absolutLabel')}
                  </option>
                </Form.Select>
              </Form.Group>
            </Col> */}
            <Col md={12} lg={12}>
              <Form.Group className="mb-3" controlId="weight">
                <Form.Label>{t('createMatch.fieldLabel6')}</Form.Label>
                <Row>
                  <Col xs={2} md={2}>
                    <Form.Select
                      placeholder={t('createMatch.positiveOrNegative')}
                      name="sign"
                      disabled={exists}
                      {...(!weight
                        ? { ref: signRef }
                        : { value: weight && weight.slice(0, 1) })}
                    >
                      <option value="-" selected>
                        -
                      </option>
                      <option value="+">+</option>
                    </Form.Select>
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Control
                      name="weight"
                      type={
                        weight &&
                        weight.toLowerCase() == 'Absolut'.toLowerCase()
                          ? 'text'
                          : 'number'
                      }
                      disabled={exists != undefined ? exists : weightDisable}
                      {...(!weight
                        ? { ref: weightRef }
                        : weight &&
                          weight.toLowerCase() == 'Absolut'.toLowerCase()
                        ? { value: weight }
                        : { value: weight && weight.slice(1, -2) })}
                      onBlur={handleWeightSelect}
                      onKeyDown={handleKeyDown}
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    <Form.Select
                      placeholder={t('createMatch.weight')}
                      name="metric"
                      {...(!weight
                        ? { ref: metricRef }
                        : { value: weight && weight.slice(-2) })}
                      disabled={items.length > 0 || weightList.length > 0? true : false}
                    >
                      <option value="kg" selected>
                        kg
                      </option>
                      <option value="lb">lb</option>
                    </Form.Select>
                  </Col>
                  <Col xs={1} md={1}>
                    <Form.Check
                      type="checkbox"
                      label="Absolut"
                      checked={
                        weight != undefined
                          ? weight.toLowerCase() === 'Absolut'.toLowerCase()
                          : absolut
                      }
                      disabled={exists != undefined ? exists : false }
                      onChange={handleAbsolut}
                    />
                  </Col>
                  {pill ? (
                    <Row style={{ margin: '0 -5px' }}>
                      {weights.map(weight => (
                        <Button
                          style={{ margin: '5px', width: 'auto' }}
                          vairant="primary"
                          onClick={() => handleBadgeRemove(weight)}
                        >
                          {weight} <Badge bg="secondary">x</Badge>
                        </Button>
                      ))}
                    </Row>
                  ) : (
                    ''
                  )}
                </Row>
              </Form.Group>
            </Col>

            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="duration">
                <Form.Label>{t('createMatch.fieldLabel7')}</Form.Label>

                <Form.Select
                  aria-label="duration"
                  name="duration"
                  value={duration}
                  disabled={exists}
                  onChange={e => handleFieldChange('duration', e.target.value)}
                  required
                >
                  {map(
                    [
                      { value: '3:00', label: '3:00' },
                      { value: '4:00', label: '4:00' },
                      { value: '5:00', label: '5:00' },
                      { value: '6:00', label: '6:00' },
                      { value: '7:00', label: '7:00' },
                      { value: '8:00', label: '8:00' },
                      { value: '9:00', label: '9:00' },
                      { value: '10:00', label: '10:00' }
                    ],
                    val => {
                      return (
                        <option selected value={val.value}>
                          {val.label}
                        </option>
                      );
                    }
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="belt">
                <Form.Label>{t('events.createEvents.fields.belt')}</Form.Label>
                <Form.Select
                  aria-label="belt"
                  name="belt"
                  value={belt}
                  disabled={exists}
                  onChange={e => handleFieldChange('belt', e.target.value)}
                  required
                >
                  {map(
                    [
                      { value: 'white', label: 'White' },
                      { value: 'blue', label: 'Blue' },
                      { value: 'purple', label: 'Purple' },
                      { value: 'brown', label: 'Brown' },
                      { value: 'black', label: 'Black' }
                    ],
                    val => {
                      return (
                        <option selected value={val.value}>
                          {val.label}
                        </option>
                      );
                    }
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} lg={4}>
              <Form.Group className="mb-3" controlId="mat">
                <Form.Label>
                  {t('events.createEvents.fields.bracketMats')}
                </Form.Label>
                <Form.Select
                  aria-label="mat"
                  name="mat"
                  value={mat}
                  disabled={exists}
                  placeholder={t(
                    'events.createEvents.fields.bracketMatsPlaceholder'
                  )}
                  onChange={e => handleFieldChange('mat', e.target.value)}
                  required
                >
                  {map(range(1, 11), val => {
                    return (
                      <option selected value={val}>
                        {t(t('events.createEvents.fields.bracketMats'))} {val}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default EventBracketItem;
