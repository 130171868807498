import { useEffect, useState } from 'react';
import { Card, Form, Image } from 'react-bootstrap';
import eventBanner from 'assets/img/generic/13.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useUpload } from 'react-use-upload';

const EventBanner = ({ uuid = '', image = '' }) => {
  const UPLOAD_URL = `${process.env.REACT_APP_API_IMAGES_ROOT}/slickcomp-images/event/${uuid}.png`;

  let [upload, { done, loading }] = useUpload(({ files }) => ({
    method: 'PUT',
    url: UPLOAD_URL,
    body: files[0],
    headers: { Authorization: window.localStorage.getItem('token') }
  }));

  const fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const { t } = useTranslation();

  const [photo, setPhoto] = useState({ path: null, preview: image });

  const updatePhoto = file => {
    console.info('updatePhoto', file);
    if (file) {
      setPhoto({ path: eventBanner, preview: URL.createObjectURL(file) });
      upload({ files: [file] });
    }
  };

  return (
    <Card className="cover-image">
      <Image
        src={photo.preview}
        alt={photo.path}
        onError={() => {
          setPhoto({ preview: eventBanner }); //when there's an error loading the image (e.g img doesn't exist, etc), default to the event banner...
        }}
        className="card-img-top"
      />
      <Form.Group controlId="formFile">
        <Form.Label className="cover-image-file-input">
          <FontAwesomeIcon icon="camera" className="me-2" />
          <span>{t('events.createEvents.coverImage')}</span>
        </Form.Label>
        <Form.Control
          type="file"
          className="d-none"
          onChange={e => updatePhoto(e.target.files[0])}
        />
      </Form.Group>
    </Card>
  );
};

export default EventBanner;
