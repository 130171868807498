import axios from 'axios';
import moment from 'moment';

const apiRoot = process.env.REACT_APP_API_ROOT;

type EntryRequestParams = {
  status: string;
  eventId: string;
  eventEntryId: string;
  eventUUID: string;
  userId: string;
  userUUID: string;
};

const getEventEntryDetail = async ({
  eventEntryId,
  eventId,
  eventUUID,
  userId,
  status,
  userUUID
}: EntryRequestParams) => {
  try {
    const response = await axios.get(
      `${apiRoot}/event-entry/${eventId}/${eventEntryId}`
    );

    const userRegistrationsResponse = await axios.get(
      `${apiRoot}/user-registrations/${userUUID}/${eventId}`
    );

    const eventResponse =
      status === 'public'
        ? await axios.get(`${apiRoot}/public-event/${eventUUID}`)
        : await axios.get(`${apiRoot}/event/${userId}/${eventUUID}`);

    if (response.data.length) {
      return {
        entry: response.data[0],
        eventBrackets: eventResponse.data[0].brackets || [],
        userRegistrations: userRegistrationsResponse.data || []
      };
    }
  } catch (e) {
    return null;
  }
};

export default getEventEntryDetail;
