import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import Hero from './Hero';
import NavbarStandard from '../landing/NavbarStandard';
import Events from './Events';
import Cta from '../landing/Cta';
import FooterStandard from '../landing/FooterStandard';
import getPublicEvents from 'api/events/get-public-events';
import moment from 'moment';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';

const PublicEvents = () => {
  const { t } = useTranslation();

  const [loading, isLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [formData, setFormData] = useState({
    upcoming: 1,
    gameType: 1,
    startDate: moment().format('yyyy/MM/DD'),
    endDate: moment().add(1, 'month').format('yyyy/MM/DD'),
    countries: []
  });

  const handleFormChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const loadData = data => {
    (async () => {
      isLoading(true);
      const eventsData = filter(await getPublicEvents(data), event =>
        moment(event.eventDate).isBetween(
          moment(data.startDate),
          moment(data.endDate),
          '[]'
        )
      );

      setEvents(eventsData);
      isLoading(false);
    })();
  };

  useDebounce(
    () => {
      loadData(formData);
    },
    1500,
    [formData]
  );

  useEffect(() => {
    isLoading(true);
  }, [formData]);

  return (
    <div className='publicEventsSection'>
      <NavbarStandard />
      <Hero
        formData={formData}
        setFormData={setFormData}
        handleChange={handleFormChange}
        handleNameFilter={setFilterText}
      />
      {loading ? (
        <Alert variant="default" className="p-5">
          <h4 className="alert-heading">{t('publicEvents.loading')}</h4>
        </Alert>
      ) : (
        <Events
          events={
            filterText
              ? events.filter(m =>
                  m.title
                    .toLowerCase()
                    .includes(filterText.trim().toLowerCase())
                )
              : events
          }
          formData={formData}
        />
      )}
      <Cta />
      <FooterStandard />
    </div>
  );
};

export default PublicEvents;
