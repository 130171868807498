import { Card, Col, Row } from 'react-bootstrap';
import Section from 'components/common/Section';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PublicUserRegistrationForm from 'components/authentication/PublicUserRegistrationForm';
import IconAlert from 'components/common/IconAlert';
import { MdOutlineSportsKabaddi } from 'react-icons/md';
import { useEffect } from 'react';

const PublicUserRegistration = ({ match }) => {
  const { t } = useTranslation();
  const academyId = match.params.academyId;
  const country = match.params.country;

  useEffect(() => {
    document.getElementsByTagName('main')[0].classList.add('registrationMain');
    return () => {
      document
        .getElementsByTagName('main')[0]
        .classList.remove('registrationMain');
    };
  }, []);

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Card>
            <Card.Body className="px-4 px-sm-5 py-2 py-sm-4">
              <div className="mb-3">
                <div className="registration-icon-border">
                  <MdOutlineSportsKabaddi size={40} color="black" />
                </div>
              </div>

              <Row className="align-items-center mb-2">
                <Col>
                  <h5 id="modalLabel">{t('userRegistration.title')}</h5>
                </Col>
                <Col xs="auto"></Col>
              </Row>
              <PublicUserRegistrationForm
                academyId={academyId}
                country={country}
              />
              <p>
                {t('userRegistration.athleteLoginLink')}{' '}
                <Link to="/login?athlete=true">Log in</Link>
              </p>
            </Card.Body>
          </Card>
          <Col className="mt-3">
            <IconAlert variant="warning" hideIcon={true}>
              <p className="mb-2 text-font-xs">
                {t('userRegistration.warningText')}
              </p>
              <p className="mb-0 text-font-xs">
                {t('userRegistration.warningLinkText')}{' '}
                <span>
                  <Link to="/academy-registration">
                    <span>
                      {t('academyRegistration.warningAthleteSignupBtn')}
                    </span>
                  </Link>
                </span>
              </p>
            </IconAlert>
          </Col>
        </Col>
      </Row>
    </Section>
  );
};

export default PublicUserRegistration;
