import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Col, Row, Alert, Button } from 'react-bootstrap';

import EventDetailHeader from './EventDetailHeader';

import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import getEventDetail from 'api/events/get-event';
import {
  filter,
  map,
  orderBy,
  upperCase,
  first,
  includes,
  compact
} from 'lodash';
import { BsTv } from 'react-icons/bs';
import Flex from 'components/common/Flex';
import { MdArrowBackIos } from 'react-icons/md';

import moment from 'moment';

const mapStateToProp = state => ({});

const BracketCard = ({ bracket, isHighlighted, index }) => {
  return (
    <div
      className={`border mb-1 bg-white p-1 rounded-1 matBracket-status 
        ${bracket.startTime != null ? 'matBracket-in-progress-status' : ''}
        ${bracket.endTime != null ? 'matBracket-completed-status' : ''}
        ${bracket.startTime != null && isHighlighted && bracket.endTime == null ? 'matBracket-in-progress-highlighted-status' : ''}
        ${bracket.endTime != null && isHighlighted ? 'matBracket-completed-highlighted-status' : ''}`
      }
    >
      <Flex>
        <div className="flex-1 position-relative ps-3">
          <h6>
            <div className="pt-1 d-flex justify-content-between">
              <span>{bracket.description}</span>
              <small className="">{'-'}</small>
            </div>
          </h6>
        </div>
      </Flex>
    </div>
  );
};

const MatDetail = ({ eventInfo, matId, isTVMode }) => {
  const { t } = useTranslation();

  const matBrackets = eventInfo
    ? orderBy(
      filter(
        eventInfo.brackets,
        bracket => Number(bracket.mat) === Number(matId)
      ),
      bracket => bracket.order || 0
    )
    : [];

  const topTwoBrackets = useMemo(() => {
    const sortedBracketsInStart = orderBy(
      filter(matBrackets, b => b.startTime != null && b.endTime == null),
      o => moment(o.startTime).format(),
      ['desc']
    );
    const sortedBracketsInEnd = orderBy(
      filter(matBrackets, b => b.startTime != null && b.endTime != null),
      o => moment(o.endTime).format(),
      ['desc']
    );

    //get the top 2 Brackets - 1. The last finished bracket... 2. the Current Bracket in Play
    return compact([first(sortedBracketsInEnd), first(sortedBracketsInStart)]);
  }, [matBrackets]);

  const bracketListRef = useRef();

  useEffect(() => {
    if (bracketListRef.current) {
      bracketListRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [matBrackets]);

  return (
    <>
      <Row className="container">
        <h4 className={isTVMode ? 'text-white' : ''}>
          {upperCase(t('events.eventDetail.mats.title'))} {matId}
        </h4>
      </Row>
      <Row
        className="container bracketsMatScheduleMatList"
        ref={bracketListRef}
      >
        <div className="bracketsMatScheduleTopTwoMatList" >
          {map(topTwoBrackets, (bracket, index) => {
            return <BracketCard key={index} isHighlighted={true} bracket={bracket} />;
          })}
        </div>

        {map(matBrackets, (bracket, index) => {
          if (
            includes(
              map(topTwoBrackets, m => m.bracketUUID),
              bracket.bracketUUID
            )
          ) {
            return null;
          }
          return <BracketCard key={index} bracket={bracket} />;
        })}
      </Row>
    </>
  );
};

export const MatSchedules = ({
  eventInfo,
  matId,
  academyId,
  eventId,
  isTVMode,
  isPublic
}) => {
  const { t } = useTranslation();

  const bracketMats = eventInfo?.bracketMats || [];

  return (
    <>
      {eventInfo && (
        <>
          <Row className="g-3 my-3">
            <Col lg={12}>
              <Row className="mx-1">
                <div className="d-flex justify-content-between">
                  <div></div>

                  {isTVMode ? (
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      as={Link}
                      to={
                        isPublic
                          ? `/public-events/mat/${academyId}/${eventId}/${matId}`
                          : `/dashboard/events/mat/${academyId}/${eventId}/${matId}`
                      }
                    >
                      <MdArrowBackIos /> {t('events.eventDetail.mats.back')}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      as={Link}
                      to={`/public-events/mat/tv/${academyId}/${eventId}/${matId}/${location.pathname.includes('dashboard') ? 0 : 1
                        }`}
                    >
                      <BsTv /> {t('events.eventDetail.mats.tvMode')}
                    </Button>
                  )}
                </div>
              </Row>
            </Col>

            <div className={`bracketsMatScheduleRow ${isTVMode ? "bracketsMatScheduleRow-TV" : ""}`}>
              {map(bracketMats, mat => {
                return (
                  <div className={`bracketsMatScheduleCol ${isTVMode ? "bracketsMatScheduleCol-TV" : ""}`} key={mat}>
                    <MatDetail
                      matId={mat}
                      eventInfo={eventInfo}
                      isTVMode={isTVMode}
                    />
                  </div>
                );
              })}
            </div>
          </Row>
        </>
      )}
    </>
  );
};

const MatScheduleDetail = () => {
  const { t } = useTranslation();
  const { id, academyId, matId } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);

  const loadData = () => {
    (async () => {
      const data = await getEventDetail(academyId, id);
      setEventInfo(data);
      isLoading(false);
    })();
  };

  useEffect(() => {
    loadData();
    const REFRESH_INTERVAL_RATE = 1 * 60 * 1000;
    const intervalId = setInterval(loadData, REFRESH_INTERVAL_RATE);
    return () => {
      clearInterval(intervalId);
    };
  }, [id]);

  if (loading) {
    return (
      <Alert variant="default" className="mb-0 rounded-0">
        <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
      </Alert>
    );
  }

  return (
    <>
      <EventDetailHeader eventInfo={eventInfo} titleAsLink={true} />
      <MatSchedules
        eventInfo={eventInfo}
        academyId={academyId}
        eventId={id}
        matId={matId}
      />
    </>
  );
};

export default connect(mapStateToProp)(MatScheduleDetail);
