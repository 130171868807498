import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type RegisterActivityArgs = {
  activityId: string;
  academyId: string;
  userId: string;
  activityDate: string;
  activityMetaId: string;
};

const registerUserIntoActivity = async (data: RegisterActivityArgs) => {
  let response = await axios.post(`${apiRoot}/user-activity`, data);
  return response;
};

export const removeUserFromActivity = async (data: RegisterActivityArgs) => {
  let response = await axios.delete(`${apiRoot}/user-activity`, {
    data: data
  });
  return response;
};

export default registerUserIntoActivity;
