import React from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';


import { CgGym } from 'react-icons/cg';
import { MdOutlineSportsKabaddi } from 'react-icons/md';



const ForgetPassword = () => {
  const { t } = useTranslation();
  const { userType } = useParams();

  const HeaderIcon = userType === "academy" ? CgGym : MdOutlineSportsKabaddi

  React.useEffect(() => {
    document
      .getElementsByTagName('main')[0]
      .classList.add('registrationMain');
    return () => {
      document
        .getElementsByTagName('main')[0]
        .classList.remove('registrationMain');
    };
  }, []);

  return (
    <div className="text-center">
      <div className="mb-3 d-flex">
        <div className="registration-icon-border center-margin-auto">
          <HeaderIcon size={40} color='black' />
        </div>
      </div>

      <h5 className="mb-0">{t('forgetPassword.forgotYourPassword')}</h5>
      <small>{t('forgetPassword.emailLabel')}</small>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
