/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Registration from 'components/authentication/simple/Registration';
import { useLocation, useParams } from 'react-router-dom';

import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import AthleteLoginForm from 'components/authentication/AthleteLoginForm';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTranslation } from 'react-i18next';

import { CgGym } from 'react-icons/cg';
import { MdOutlineSportsKabaddi } from 'react-icons/md';
import NavbarStandard from '../landing/NavbarStandard';

import { motion, LayoutGroup } from 'framer-motion';

import './sign-in.css';

const LoginPage = ({ match }) => {
  const { academyId, userId } = useParams();
  let athlete = new URLSearchParams(useLocation().search).get('athlete');
  const [key, setKey] = useState('academy');
  const { t } = useTranslation();

  useEffect(() => {
    athlete === 'true' && setKey('athlete');
  }, []);
  return (
    <Section
      className="bg-dark light py-0 loginSignInSection"
      position="center bottom"
      overlay
    >
      <NavbarStandard />

      <Row className="flex-center min-vh-100 py-6">
        <div className="login-block">
          <Flex justifyContent="between" alignItems="center" className="mb-2">
            <h5 className="login-header">
              {t('landingRightSideNavItems.loginTitle')}
            </h5>
          </Flex>
          <Tabs
            id="logiTabs"
            className="mb-3 login-nav-tabs"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Tab
              eventKey="academy"
              tabClassName="login-nav-link"
              title={
                <div>
                  <CgGym size={25} />
                  <span>{t('landingRightSideNavItems.tab1Item')}</span>
                </div>
              }
              theme="dark"
            >
              {key === 'academy' && (
                <motion.div
                  transition={{
                    tension: 190,
                    friction: 70,
                    mass: 0.4,
                    duration: 0.4
                  }}
                  initial={{
                    y: 50
                  }}
                  animate={{ y: 0 }}
                  className="login-tab-block"
                >
                  <LoginForm hasLabel={true} />
                </motion.div>
              )}
            </Tab>

            <Tab
              eventKey="athlete"
              tabClassName="login-nav-link"
              title={
                <div>
                  <MdOutlineSportsKabaddi size={25} />
                  <span>{t('landingRightSideNavItems.tab2Item')}</span>
                </div>
              }
              theme="dark"
            >
              {key === 'athlete' && (
                <motion.div
                  className="login-tab-block"
                  transition={{
                    tension: 190,
                    friction: 70,
                    mass: 0.4,
                    duration: 0.4
                  }}
                  initial={{
                    y: 50
                  }}
                  animate={{ y: 0 }}
                >
                  <AthleteLoginForm hasLabel={true} />
                </motion.div>
              )}
            </Tab>
          </Tabs>
        </div>
      </Row>
    </Section>
  );
};

export default LoginPage;
