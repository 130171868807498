import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsEventParticipants = {
    eventUuid: number;
    academyId: string;
};
const getEventParticipants = async (data: ArgsEventParticipants): Promise<[UserInfo?]> => {
  try {
    
    const response = await axios.get(`${apiRoot}/event-participants/${data.academyId}/${data.eventUuid}`);
    return response.data;
  } catch (e) {
    return  [];
  }
};
export default getEventParticipants;


interface UserInfo{
    user_id: number,
    userName: string,
    academyName: string
}
