import { useState, useEffect } from 'react';
import EventDetails from './EventDetails';
import EventBrackets from './EventBrackets';
import EventHeader from './EventHeader';
import EventFooter from './EventFooter';
import EventEntries from './EventEntries';
import { Col, Form, Row } from 'react-bootstrap';
import EventOtherInfo from './EventOtherInfo';
import EventBanner from './EventBanner';
import { useForm } from 'react-hook-form';
import postEvent from 'api/events/post-event';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { checkIfAcademyIsPremium } from 'api/academy/get-academy';

const mapStateToProp = state => ({
  academyId: state.auth.academyId
});

const CreateEvent = ({ academyId }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    setError,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      brackets: []
    }
  });
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);

  const destructBrackets = brackets => {
    const res = [];
    for (let i = 0; i < brackets.length; i++) {
      const bracket = brackets[i];
      bracket.weight.forEach(w => {
        res.push({ ...bracket, weight: w });
      });
    }
    return res;
  };

  const onSubmit = async data => {
  
    data.brackets = data.brackets ? destructBrackets(data.brackets) : [];
    data['academy_id'] = academyId;
    data['private'] = data.privacy === 'privatePage' ? 1 : 0;
    const location = `${data['locationLat']},${data['locationLng']}`;
    data['location'] = location;
    
    try {
      //check if there is any duplicate bracket
      if(data.brackets.some((item, index) => data.brackets.findIndex(obj => obj.weight === item.weight && obj.gender === item.gender && obj.gi_nogi === item.gi_nogi && obj.seniority === item.seniority && obj.belt === item.belt) !== index)) {
        toast.error(t('events.createEvents.bracketDuplicateError'));
        return;
      }
      //check if there are negative prices for new entries
      const isThereNegativePricesForAnyEntry = some(
        data.entries,
        m => m.price < 0
      );
      if (isThereNegativePricesForAnyEntry) {
        toast.error(t('events.createEvents.negativePricePlanErrorToast'));
        return;
      }

      //if the academy is on the free plan, show an error when saving entries with price greater than zero
      if (
        !(await checkIfAcademyIsPremium(academyId)) &&
        some(data.entries, m => m.price > 0)
      ) {
        toast.error(t('events.createEvents.premiumPlanErrorToast'));
        return;
      }

      setCreating(true);
      
      let result = await postEvent(data);
      if (result.statusCode === 200) {
        toast.success(t('events.createEvents.successToast'), {
          onClose: () => {
            window.location.href = `/dashboard/events/`;
          }
        });
      } else {
        toast.error(t('events.createEvents.errorToast'));
        setCreating(false);
      }
    } catch (e) {
      setCreating(false);
      toast.error(t('events.createEvents.errorToast'));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row className="g-3">
        <Col xs={12}>
          <EventHeader disabled={creating} />
        </Col>
        <Col xs={12}>
          <EventBanner />
        </Col>
        <Col lg={8}>
          <EventDetails
            {...register('event_date', { required: true })}
            errors={errors}
            register={register}
            setValue={setValue}
            control={control}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
          />
          <EventBrackets
            {...register('brackets', { required: false })}
            setValue={setValue}
          />
          <EventEntries
            {...register('entries', { required: false })}
            setValue={setValue}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <EventOtherInfo
              academyId={academyId}
              errors={errors}
              register={register}
              control={control}
            />
          </div>
        </Col>
        <Col>
          <EventFooter disabled={!isValid || creating} />
        </Col>
      </Row>
    </Form>
  );
};

export default connect(mapStateToProp)(CreateEvent);
