import axios from 'axios';
import moment from 'moment';

const apiRoot = process.env.REACT_APP_API_ROOT;

type Args = {
  userId: string;
  academyId: number;
  selectedDate?: string;
  isAcademy?: boolean;
};

type SingleActivityRequestArgs = {
  activityId: string;
  academyId: number;
};

const getActivities = async ({
  userId,
  academyId,
  selectedDate,
  isAcademy
}: Args) => {
  const date = selectedDate
    ? moment(selectedDate).format('yyyy-MM-DD')
    : moment(new Date()).format('yyyy-MM-DD');
  const dayOfWeek = selectedDate
    ? moment(selectedDate).format('dddd')
    : moment(new Date()).format('dddd');

  let data = await (
    await axios.get(
      `${apiRoot}/activities/${academyId}?selectedDate=${date}&dayOfWeek=${dayOfWeek}`
    )
  ).data;

  const userResponse = await axios.get(
    `${apiRoot}/${isAcademy ? 'academy' : 'user'}/${
      isAcademy ? academyId : userId
    }`
  );

  return {
    activities: decodeActivities(data as any[]),
    isUserActive: isAcademy ? true : !!userResponse.data[0]?.active
  };
};

export const getActivity = async ({
  activityId,
  academyId
}: SingleActivityRequestArgs) => {
  let data = await (
    await axios.get(`${apiRoot}/activity/${academyId}/${activityId}`)
  ).data;

  return {
    activity: data
  };
};

const decodeActivities = (data: any[]) => {
  return (data ?? []).reduce((result: any[], el: any) => {
    result = [...(result || []), ...el];
    return result;
  }, []);
};

export default getActivities;
