/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';

const mapStateToProp = state => state.auth;
const LandingRightSideNavItem = ({ loggedIn }) => {
  const { t } = useTranslation();
  return (
    <Nav navbar className="ms-auto">
      {!loggedIn && (
        <Nav.Link as={Link} to="/login" align="end" id="loginNavMenu">
          {t('landingRightSideNavItems.loginTitle')}
        </Nav.Link>
      )}

      {loggedIn && (
        <>
          <Nav.Item>
            <Nav.Link as={Link} to="/dashboard/profile">
              {t('landingRightSideNavItems.dashboardTitle')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
          <Nav.Link as={Link} to="/logout">
            {t('logoutText')}
          </Nav.Link>
         </Nav.Item>
        </>
        
      )}
    </Nav>
  );
};

export default connect(mapStateToProp)(LandingRightSideNavItem);
