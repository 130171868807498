/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, InputGroup } from 'react-bootstrap';
import getAcademy from 'api/academy/get-academy';
import updateProfile from 'api/user/update-profile';
import FalconCardHeader from 'components/common/FalconCardHeader';

import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { phone } from 'phone';


import CountrySelect from 'react-bootstrap-country-select';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { replace } from 'lodash';
import { useHistory } from 'react-router';


let schema = yup.object().shape({
  name: yup.string().required(),
  intro: yup.string().required(),
  instagram: yup.string().required()
});

const AcademyProfileFields = ({ id }) => {

  const baseInstagramUrl = 'https://www.instagram.com/'
  const baseFacebookURL = 'https://www.facebook.com/'

  const history = useHistory();


  const [uuid, setUuid] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    intro: '',
    adminName: '',
    country: '',
    cellphone: '',
    instagram: '',
    facebook: '',
    website: '',
  });


  useEffect(() => {
    (async () => {
      const data = await getAcademy(id);
      if (data) {
        setUuid(data[0].uuid);
        setFormData({
          name: data[0].name,
          email: data[0].email,
          intro: data[0].intro,
          adminName: data[0].adminName || '',
          country: data[0].country || '',
          cellphone: localStorage.getItem(data[0].email) || data[0].cellphone || '+506',
          website: data[0].website || '',
          instagram: replace(data[0].instagram, new RegExp(baseInstagramUrl, 'g'), ''),
          facebook: replace(data[0].facebook, new RegExp(baseFacebookURL, 'g'), ''),
        });
      }
    })();
  }, []);
  const [submitButton, setSubmitButton] = useState(false);
  const { t } = useTranslation();

  const handleChange = async (e, target = '') => {
    const updatedData = {
      ...formData,
      [e?.target?.name || target]: e?.target?.value || e?.id || "",
    };
    setFormData(updatedData);
    setSubmitButton(await schema.isValid(updatedData));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    formData.instagram = baseInstagramUrl + formData.instagram
    formData.facebook = baseFacebookURL + formData.facebook

    localStorage.setItem(formData.email, formData.cellphone);

    setSubmitButton(await schema.isValid(formData));
    try {
      await updateProfile(uuid, formData, true);
      toast.success(t('profile.academyForm.successToast'));
      setTimeout(() => {
        toast.dismiss();
        history.goBack();
      }, 3000);
    } catch (e) {
      toast.error(t('profile.academyForm.errorToast'), { autoClose: 2000 });
    }
  };

  return (
    <Card>
      <FalconCardHeader title={t('profile.editProfile')} />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="firstName">
              <Form.Label>{t('profile.academyForm.name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('profile.academyForm.name')}
                value={formData.name}
                name="name"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="email">
              <Form.Label>{t('profile.academyForm.email')}</Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                value={formData.email}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="intro">
              <Form.Label>{t('profile.academyForm.intro')}</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder={t('profile.academyForm.intro')}
                value={formData.intro}
                name="intro"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="adminName">
              <Form.Label>{t('profile.academyForm.adminName')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('profile.academyForm.adminName')}
                value={formData.adminName}
                name="adminName"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="cellphone">
              <Form.Label>{t('profile.academyForm.phone')}</Form.Label>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="CR"
                value={formData.cellphone}
                onChange={(val) => {
                  handleChange({ id: val }, 'cellphone')
                }} />
              {formData.cellphone && !phone(formData.cellphone || "").isValid &&
                <Form.Label className='text-danger'>{t('profile.academyForm.phoneInvalidMessage')}</Form.Label>}
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={12} controlId="country">
              <Form.Label>{t('profile.academyForm.country')}</Form.Label>
              <CountrySelect
                placeholder={t('academyRegistration.fields.countryPlaceholder')}
                flags={true}
                flush={true}
                value={formData.country}
                name="country"
                onChange={(e) => { handleChange(e, "country") }}
              />
            </Form.Group>
          </Row>


          <Row>

            <hr />
            <Form.Label className='mb-3'>{t("profile.academyForm.socialHeader")}</Form.Label>

            <Form.Group className="mb-2" controlId="instagram">
              <Form.Label >{t('profile.academyForm.instagram')}</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  {baseInstagramUrl}
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder={t('profile.academyForm.intagramUser')}
                  value={formData.instagram}
                  name="instagram"
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-2" controlId="facebook">
              <Form.Label >{t('profile.academyForm.facebook')}</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  {baseFacebookURL}
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder={t('profile.academyForm.facebookUsername')}
                  value={formData.facebook}
                  name="facebook"
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" as={Col} lg={12} controlId="website">
              <Form.Label>{t('profile.academyForm.website')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('profile.academyForm.website')}
                value={formData.website}
                name="website"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <div className="text-end">
            <Button variant="primary" type="submit" disabled={!submitButton || !phone(formData.cellphone || "").isValid}>
              {t('profile.academyForm.buttonText')}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AcademyProfileFields;
