import { Form } from 'react-bootstrap';
import paypal from 'assets/img/icons/icon-paypal-full.png';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sanitizePaypalResponse } from 'constants/paypal';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import {
  buyAcademyCredits,
  completePaymentForAcademyCredits
} from 'api/events/academy-credits';

const BillingDetails = ({ selectedCredits, setSelectedCredits }) => {
  const { t } = useTranslation();
  const { uuid } = useSelector(state => state.auth);
  const [purchaseCreditId, setPurchaseCreditId] = useState();
  const [_, payPalDispatch] = usePayPalScriptReducer();

  const CREDIT_PRICES = {
    1: 1,
    100: 95,
    200: 180
  };

  useEffect(() => {
    (async () => {
      const response = await buyAcademyCredits({
        academyUUID: uuid,
        price: CREDIT_PRICES[selectedCredits],
        quantity: selectedCredits
      });
      setTimeout(() => {
        setPurchaseCreditId(response.academy_credit_id);
        payPalDispatch({
          type: 'setLoadingStatus',
          value: 'pending'
        });
      }, 500);
    })();
  }, [selectedCredits]);

  const handleCompletePayment = async paymentDetails => {
    try {
      const result = await completePaymentForAcademyCredits({
        academyUUID: uuid,
        creditID: purchaseCreditId,
        paymentInfo: {
          payPal_info: sanitizePaypalResponse(paymentDetails)
        }
      });

      if (result.statusCode === 200) {
        toast.success(t('events.createEvents.buyCredits.successToast'), {
          onClose: () => {
            window.close();
          }
        });
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      toast.error(t('subscriptions.checkout.errorToast'), { autoClose: 2000 });
    }
  };

  return (
    <Form>
      <Form.Select
        value={selectedCredits}
        onChange={event => setSelectedCredits(event.currentTarget.value)}
        className="mb-3"
      >
        <option value="1">
          {t('events.createEvents.buyCredits.creditOptions.1')}
        </option>
        <option value="100">
          {t('events.createEvents.buyCredits.creditOptions.100')}
        </option>
        <option value="200">
          {t('events.createEvents.buyCredits.creditOptions.200')}
        </option>
      </Form.Select>

      <Form.Check type="radio" className="mb-0 form-check" id="paypal">
        <Form.Check.Input
          type="radio"
          id="paypal"
          name="billing"
          checked={true}
          value="paypal"
        />
        <Form.Check.Label className="mb-0 d-block">
          <img src={paypal} height="20" alt="paypal" />
        </Form.Check.Label>
      </Form.Check>
      <p className="fs--1 mb-4">{t('subscriptions.checkout.payWithPaypal')}</p>

      <h6 className="d-flex justify-content-between">
        <span>{t('subscriptions.checkout.dueToday')}</span>
        <span>
          {t(`events.createEvents.buyCredits.creditOptions.${selectedCredits}`)}
        </span>
      </h6>

      <hr />

      <div className="d-flex">
        {purchaseCreditId && (
          <div className="flex-grow-1">
            <PayPalButtons
              className="mx-auto"
              key={selectedCredits}
              fundingSource="paypal"
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: CREDIT_PRICES[selectedCredits]
                      }
                    }
                  ]
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(details => {
                  handleCompletePayment(details);
                });
              }}
              onError={error => {
                toast.error(t('subscriptions.checkout.errorToast'));
                toast.dismiss();
              }}
            />
          </div>
        )}
      </div>

      <div className="text-center mt-2"></div>
    </Form>
  );
};

export default BillingDetails;
