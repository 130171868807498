/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Section from 'components/common/Section';
import { connect } from 'react-redux';
import LastMatches from 'components/dashboards/academy/profile/LastMatches';
import getAcademy from 'api/academy/get-academy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { useTranslation } from 'react-i18next';
import AvatarUploader from 'components/common/AvatarUploader';
import ACTION_TYPE from '../../../../dux/auth/ACTION_TYPE';
import { getDevAPIRoot } from 'api/utils';
import { replace } from "lodash";
import { withHttp } from 'constants/withHttp';
import { Card, Row } from 'react-bootstrap';
import PieChart from 'components/common/pieChart/PieChart';
import friendlyUrl from 'friendly-url';

import IconButton from 'components/common/IconButton';
import { useAcademyCreditsFocus } from 'hooks/useAcademyCredits';


const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid
});

const Profile = ({ academyId, uuid, dispatch }) => {
  const [academyData, setAcademyData] = useState(null);
  const { t } = useTranslation();

  const emitAvatarChange = useCallback(async () => {
    const data = await getAcademy(academyId);
    if (data) {
      setAcademyData(data[0]);
      dispatch({
        type: ACTION_TYPE.SET_AVATAR,
        avatar: data[0].avatarUrl
      });
    }
  }, []);

  const loadData = async () => {
    const data = await getAcademy(academyId);
    if (data) {
      setAcademyData(data[0]);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useAcademyCreditsFocus({
    loadData
  });

  return (
    <>
      {academyData && (
        <>
          <Section bg="light" className="py-3 profile mb-4">
            <div className="profile-editor">
              <div className="img-holder">
                <Avatar src={academyData.avatarUrl || team3} />
                <AvatarUploader
                  url={`${getDevAPIRoot()}/development/slickcomp-images/academy/${uuid}.png`}
                  onDone={emitAvatarChange}
                />
              </div>
            </div>
            <div className="profile-details">
              <header className="profile-header">
                <div className="text">
                  {academyData.name && <h5>{academyData.name}</h5>}
                  <ul className="sub-items academy-profile-sub-items">
                    {academyData.createTime && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.memberSince')}:{' '}
                          {moment(academyData.createTime).format('DD/MM/YYYY')}
                        </span>
                      </li>
                    )}
                    {academyData.adminName && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.academyForm.adminName')}:{' '}
                          {academyData.adminName}
                        </span>
                      </li>
                    )}
                    {academyData.cellphone && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.academyForm.phone')}:{' '}
                          {localStorage.getItem(academyData.email) ||
                            academyData.cellphone}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="academy-edit-profile-container">
                  <Link
                    className="edit-profile"
                    to={`/dashboard/academy/edit/`}
                  >
                    {t('profile.editProfile')}
                  </Link>
                </div>
                <ul className="social academy-social-profile-divider">
                 <li>
                    <Link
                      to={`/academy/${friendlyUrl(academyData?.name)}/${
                        academyData?.uuid
                      }`}
                    >
                      <FontAwesomeIcon icon={'globe'} color="#5D6E82" />
                    </Link>
                  </li>
                  {academyData.facebook && (
                    <li>
                      <a
                        href={academyData.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'facebook']}
                          style={{
                            width: "20px",
                            height: "20px"
                          }}
                          color="#5D6E82"
                        />
                      </a>
                    </li>
                  )}
                  {academyData.instagram && (
                    <li>
                      <a
                        href={`https://instagram.com/${replace(academyData.instagram, new RegExp("https://www.instagram.com/", "g"), "") || "#"}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'instagram']}
                          color="#5D6E82"
                        />
                      </a>
                    </li>
                  )}
                  {academyData.linkedin && (
                    <li>
                      <a
                        href={academyData.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin-in']}
                          color="#5D6E82"
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </header>
              {academyData.intro && (
                <div className="profile-detail">
                  <p>{academyData.intro}</p>
                </div>
              )}
            </div>
          </Section>
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <div className='d-flex justify-content-between'>
                  <h5 className="text-600 mb-2">{t('credits.chartTitle')}</h5>
                  <IconButton
                    as={Link}
                    target="_blank"
                    to="/dashboard/events/buy-credits"
                    size="sm"
                    icon="cart-plus"
                    transform="shrink-2"
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      {t('credits.buyCreditsInCreditsPage')}
                    </span>
                  </IconButton>
                </div>
                <div className="pieChart mx-auto" style={{ width: '450px' }}>
                  <PieChart
                    quantity_available={
                      academyData?.credits?.quantity_available
                    }
                    quantity_used={academyData?.credits?.quantity_used}
                  />
                </div>
                <Link to={'/dashboard/credits'}>
                  <p>{t("credits.viewCreditDetails")}</p>
                </Link>
              </Row>
            </Card.Body>
          </Card>
          <LastMatches academyId={academyId} />
        </>
      )}
    </>
  );
};

export default connect(mapStateToProp)(Profile);
