import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import EventRegistrations from './EventRegistrations';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const mapStateToProp = state => ({
  isAcademy: state.auth.isAcademy
});

const EventBracketItem = ({
  gender,
  gi_nogi,
  seniority,
  weight,
  duration,
  belt,
  type,
  bracketUUID,
  eventId,
  academy_id,
  participants = []
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  return (
    <>
      <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
        <Flex>
          <div className="flex-1 position-relative">
            <h6 className="fs-0 mb-0">
              <div>
                <Link
                  className="text-primary me-1"
                  to={
                    location.pathname.includes('dashboard')
                      ? `/dashboard/brackets/${academy_id}/${eventId}/${bracketUUID}`
                      : `/public-events/brackets/${academy_id}/${eventId}/${bracketUUID}`
                  }
                >
                  {gender}, {seniority}, {weight}
                </Link>
              </div>
            </h6>
            <p className="mb-1">
              <p className="text-1000 mb-0">
                {t('events.eventDetail.eventBrackets.type')}: {type}
              </p>
              <p className="text-1000 mb-0">
                {t('events.eventDetail.eventBrackets.gi')}:{' '}
                {gi_nogi
                  ? t('events.eventDetail.eventBrackets.gi')
                  : t('events.eventDetail.eventBrackets.no_gi')}
              </p>
              <p className="text-1000 mb-0">
                {t('events.eventDetail.eventBrackets.duration')}: {duration}
              </p>
              <p className="text-1000 mb-0">
                {t('events.eventDetail.eventBrackets.belt')}: {belt}
              </p>
            </p>

            <EventRegistrations participants={participants} />
          </div>
        </Flex>
      </div>
    </>
  );
};

EventBracketItem.propTypes = {
  gender: PropTypes.string.isRequired,
  gi_nogi: PropTypes.number.isRequired,
  seniority: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  belt: PropTypes.string.isRequired
};

export default connect(mapStateToProp)(EventBracketItem);
