import React, { useContext } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { lowerCase } from 'lodash';

import { useTranslation } from 'react-i18next';

const BillingHeader = ({ selectedCredits }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('events.createEvents.buyCredits.checkoutTitle')}
      description={t(
        `events.createEvents.buyCredits.creditOptions.${selectedCredits}`
      )}
      className="mb-3"
    />
  );
};

export default BillingHeader;
