import { Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { size, includes, map } from 'lodash';

import classNames from 'classnames';

import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { ConfirmButton } from 'components/common/ConfirmButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import defaultActivityImg from 'assets/img/generic/activity.png';

import { useBreakpoints } from 'hooks/useBreakpoints';
import SoftBadge from 'components/common/SoftBadge';

import './activity.css';

import { useEffect, useState } from 'react';
import moment from 'moment';
import { getAvatarGroup } from 'helpers/utils';
import getActivities from 'api/activities/get-activities';
import { toast } from 'react-toastify';
import registerUserIntoActivity, {
  removeUserFromActivity
} from 'api/activities/register-activity';
import { Link, useHistory } from 'react-router-dom';
import { dispatchActivityEdit } from 'dux/auth/action-thunks/activity-thunk';

const mapStateToProp = state => ({
  isAcademy: state.auth.isAcademy,
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  userId: state.auth.userId
});

const Activity = ({
  isAcademy,
  uuid,
  userId,
  activity,
  academyId,
  date,
  isCurrentUserActive,
  isUserAthlete,
  handleActivitiesRefresh
}) => {
  const { t, i18n } = useTranslation();

  const {
    id,
    name,
    status,
    activityMetaID,
    academy_id: activityAcademyId,
    availableSpaces,
    time,
    users = [],
    instructor,
    activityAvatar
  } = activity;

  const history = useHistory();
  const dispatch = useDispatch();

  const hasUserRegisteredInActivity = includes(
    map(users, u => u.uuid),
    uuid
  );

  const [isErrorLoadingImg, setIsErrorLoadingImg] = useState(false);
  const [activityImg, setActivityImg] = useState(activityAvatar);
  const [isProcessing, setIsProcessing] = useState(false);

  const breakPointWidth = useBreakpoints().width;
  const typeScreen =
    breakPointWidth > 1150
      ? 'desktop'
      : breakPointWidth < 768
      ? 'mobile'
      : 'tablet';

  useEffect(() => {
    var image = document.createElement('img');
    image.src = activityAvatar;
    image.onerror = function () {
      setIsErrorLoadingImg(true);
      setActivityImg(defaultActivityImg);
    };
  }, [activityAvatar]);

  const handleDismissToast = () => {
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
  };

  const handleRegisterIntoActivity = async () => {
    try {
      setIsProcessing(true);

      //check if the user is Active
      const { isUserActive } = await getActivities({
        userId: uuid,
        academyId,
        selectedDate: date
      });

      if (!isUserActive) {
        toast.error(t('activities.inactiveUser'), { autoClose: 2000 });
        return;
      }

      await registerUserIntoActivity({
        academyId,
        userId,
        activityDate: moment(date).format('yyyy-MM-DD'),
        activityId: id,
        activityMetaId: activityMetaID
      });

      toast.success(t('activities.successToast'), { autoClose: 2000 });
      await handleActivitiesRefresh();
      handleDismissToast();
    } catch (error) {
      toast.error(t('activities.errorToast'));
      handleDismissToast();
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemoveFromActivity = async () => {
    try {
      setIsProcessing(true);

      await removeUserFromActivity({
        academyId,
        userId,
        activityDate: moment(date).format('yyyy-MM-DD'),
        activityId: id,
        activityMetaId: activityMetaID
      });

      toast.success(t('activities.removeSuccessToast'));
      await handleActivitiesRefresh();

      handleDismissToast();
    } catch (error) {
      toast.error(t('activities.removeErrorToast'));
      handleDismissToast();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Col className="mb-4" md={6} lg={4} sm={12}>
      <Flex
        direction="column"
        justifyContent="between"
        className="border rounded-1 h-100 pb-0"
      >
        <div className="overflow-hidden">
          <div className="relative">
            <div
              style={{
                background: `url(${activityImg}) ${
                  isErrorLoadingImg ? 'no-repeat center /cover' : ''
                }`
              }}
              className={classNames('activity-img fit-cover rounded-top')}
              alt={name}
            />
          </div>

          <div className="p-3 relative">
            {/* Display a Reserved Stamp for users who have registered */}
            {hasUserRegisteredInActivity && (
              <div className="reserved-bg">
                <div className="relative">
                  <div className="reserved-stamp">
                    {t('activities.reserved')}
                  </div>

                  {/* Only Allow User to cancel Present or Future Reservations */}
                  {moment(date).isSameOrAfter(moment(new Date()), 'day') && (
                    <ConfirmButton
                      preventCloseOnConfirm={true}
                      confirmPopoverPlacement={
                        typeScreen === 'desktop' ? 'left' : 'bottom-start'
                      }
                      buttonContent={
                        <Button
                          variant="falcon-default"
                          className="registerBtn cancelRegistrationBtn"
                          size="sm"
                          disabled={isProcessing}
                        >
                          <FontAwesomeIcon
                            className="registerBtnIcon"
                            color="rgb(167 55 30)"
                            icon="times"
                          />
                        </Button>
                      }
                      confirmTitle={t('activities.confirmTitle')}
                      confirmDescription={t(
                        'activities.confirmRemoveDescription'
                      )}
                      confirmActionBtnText={t('activities.confirmButtonText')}
                      onConfirmAction={handleRemoveFromActivity}
                    />
                  )}
                </div>
              </div>
            )}

            {/* Register button */}
            {/* Display Register button only for Athletes
             and (present/future) dates 
             and there're still available spots 
             and if the user isn't registered in the Activity
             */}
            {!isAcademy &&
              moment(date).isSameOrAfter(moment(new Date()), 'day') &&
              availableSpaces > size(users) &&
              !hasUserRegisteredInActivity && (
                <div className="absolute registerBtnWrapper">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>{t('activities.registerTooltip')}</Tooltip>
                    }
                  >
                    <ConfirmButton
                      confirmActionBtnVariant="primary"
                      preventCloseOnConfirm={true}
                      confirmPopoverPlacement={
                        typeScreen === 'desktop' ? 'left' : 'bottom-start'
                      }
                      buttonContent={
                        <Button
                          variant="falcon-default"
                          className="registerBtn"
                          size="sm"
                          disabled={isProcessing}
                        >
                          <FontAwesomeIcon
                            className="registerBtnIcon"
                            color="#bae46c"
                            icon="check"
                          />
                        </Button>
                      }
                      confirmTitle={t('activities.confirmTitle')}
                      confirmDescription={t('activities.confirmDescription')}
                      confirmActionBtnText={t('activities.confirmButtonText')}
                      onConfirmAction={handleRegisterIntoActivity}
                    />
                  </OverlayTrigger>
                </div>
              )}

            {/* Display an Edit button for the Academy who created the Activity */}
            {isAcademy && academyId === activityAcademyId && (
              <div className="absolute registerBtnWrapper">
                <Button
                  type="button"
                  onClick={() => {
                    dispatch(dispatchActivityEdit(id));
                    history.push(`/dashboard/activities/update`);
                  }}
                  variant="falcon-default"
                  className="registerBtn"
                  size="sm"
                >
                  <FontAwesomeIcon
                    className="registerBtnIcon"
                    icon="pencil-alt"
                  />
                </Button>
              </div>
            )}

            <div className="activity-date">
              <span>
                {moment(date).locale(i18n.language).format('ddd, MMM Do')}
                {' • '}
                {moment(time, 'HH:mm:ss')
                  .locale(i18n.language)
                  .format('HH:mmA')}
              </span>
            </div>
            <h4 className="fs-0 activity-header text-dark mt-2">
              {name}
              {status == 0 && (
                <>
                  <SoftBadge bg="danger" className="ms-2">
                    {t('activities.deactivatedLabel')}
                  </SoftBadge>{' '}
                  <br />
                </>
              )}

              <small className="fs--1 text-500"> - {instructor}</small>
            </h4>
            <span className="activity-available-spaces">
              {availableSpaces - size(users)} {t('activities.of')}{' '}
              {availableSpaces} {t('activities.availableSpots')}
            </span>

            <div className="usersContainer">
              <AvatarGroup className="justify-content-end">
                {getAvatarGroup(users).map(
                  ({ avatarUrl, uuid, name, tooltip }) => {
                    return (
                      <Avatar
                        src={avatarUrl && avatarUrl}
                        key={uuid}
                        displayNameInTooltip={isCurrentUserActive}
                        name={name}
                        tooltip={tooltip}
                        isExact
                        className="border border-3 rounded-circle border-light"
                      />
                    );
                  }
                )}
              </AvatarGroup>
            </div>
          </div>
        </div>
      </Flex>
    </Col>
  );
};

export default connect(mapStateToProp)(Activity);
