import { Card, Col, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ActivityFooter = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              {t('activities.create.footerTitle')}
            </h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="submit"
              disabled={disabled}
            >
              {t('activities.create.save')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActivityFooter;
