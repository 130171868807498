import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsDeleteBracket = {
  academyID: number;
  bracketUUID: string;
};
const deleteBracket = async (data: ArgsDeleteBracket): Promise<boolean> => {
  try {
    const response = await axios.delete(`${apiRoot}/bracket`, {
      data: {
        academyId: data.academyID,
        uuid: data.bracketUUID
      }
    });
    console.log(response);

    return response.status == 200;
  } catch (e) {
    return false;
  }
};
export default deleteBracket;
