import { useState } from 'react';
import { Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';

type Props = {
  confirmTitle: string;
  confirmDescription: string;
  confirmActionBtnText: string;
  confirmActionBtnVariant?: string;
  confirmPopoverPlacement?: Placement;
  onConfirmAction: () => void;
  buttonContent: JSX.Element;
  preventCloseOnConfirm?: boolean;
};

export const ConfirmButton = ({
  confirmDescription,
  confirmTitle,
  confirmActionBtnText,
  onConfirmAction,
  buttonContent,
  confirmActionBtnVariant = 'danger',
  confirmPopoverPlacement = 'right',
  preventCloseOnConfirm = false
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{confirmTitle}</Popover.Header>
      <Popover.Body>
        <p>{confirmDescription}</p>
        <Button
          variant={confirmActionBtnVariant}
          disabled={isProcessing}
          onClick={async () => {
            try {
              setIsProcessing(true);

              if (!preventCloseOnConfirm) {
                document.body.click(); //to close the popover by clicking outside..
              }
              await onConfirmAction();
            } finally {
              setIsProcessing(false);
              document.body.click(); //to close the popover by clicking outside..
            }
          }}
        >
          {isProcessing ? (
            <Spinner
              animation="grow"
              style={{ width: 15, height: 15, marginTop: 4 }}
            />
          ) : (
            confirmActionBtnText
          )}
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement={confirmPopoverPlacement}
      overlay={popover}
      rootClose={!(preventCloseOnConfirm && isProcessing)} //prevent overlay close, on confirm button press, and also when parent chooses to prevent close on confirm
    >
      {buttonContent}
    </OverlayTrigger>
  );
};
