import { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import ListParticipants from "./ListParticipant";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from "components/common/Divider";


const ButtonAddParticipant = ({ setRefetch, isRefetch, participantsIds, eventUuid }) => {

  const [modalShow, setModalShow] = useState(false);
  const {t} = useTranslation();
  return (
    <>
      <Col>
      <Divider/>
      <Button
        variant="primary"
        className="rounded"
        onClick={() => setModalShow(true)}
        size='sm'
      >
      <FontAwesomeIcon icon={'plus'} />
      {'  '}
        { t('bracketAddParticipant.add')}
      </Button>
      </Col>

      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setRefetch(!isRefetch);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
        {t('bracketAddParticipant.addParticipants')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListParticipants
          participantsIds={participantsIds}
          eventUuid={eventUuid}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
          variant="danger"
          onClick={() => {
            setModalShow(false);
            window.location.reload();
          }}>
        {t('bracketAddParticipant.close')}

          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ButtonAddParticipant;