/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Section from 'components/common/Section';
import { useTranslation } from 'react-i18next';
import getUserRegistrations from 'api/user/get-user-registrations';
import { Link, useHistory } from 'react-router-dom';
import TableListComponent from 'components/utils/shared/table/table';
import './profile.css';
import { useDispatch } from 'react-redux';
import { dispatchEventEntryInfo } from 'dux/auth/action-thunks/event-thunk';

const UserRegistrations = ({ uuid }) => {
  const [userRegistrations, setUserRegistrations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const columns = [
    {
      accessor: 'name',
      Header: t('profile.userRegistrations.columns.column1'),
      Cell: rowData => {
        const { name, academy_id, event_id, event_entry_id, uuid } = rowData.row.original;
        return (
          <Link
            className="text-primary"
            onClick={(event) => {
              event.preventDefault();

              dispatch(dispatchEventEntryInfo({
                entryAcademyId: academy_id,
                entryId: event_entry_id,
                eventId: event_id,
                eventUUID: uuid,
              }));

              history.push(`/dashboard/events/entry-detail/private`);
            }}
            to={`/dashboard/events/entry-detail/private`}
          >
            {name}
          </Link>
        );
      }
    },
    {
      accessor: 'description',
      Header: t('profile.userRegistrations.columns.column2'),
      Cell: rowData => {
        return <>{rowData.row.original["bracket.description"]}</>;
      }
    },
    {
      accessor: 'price',
      Header: t('profile.userRegistrations.columns.column3'),
      Cell: rowData => {
        const { price, currency } = rowData.row.original;
        return (
          <>
            {price} {currency}
          </>
        );
      }
    },
    {
      accessor: 'confirmed',
      Header: t('profile.userRegistrations.columns.column5'),
      Cell: rowData => {
        const { confirmed } = rowData.row.original;
        return (
          <>
            {confirmed ? t("profile.userRegistrations.columns.column5Yes") : t("profile.userRegistrations.columns.column5No")}
          </>
        );
      }
    },
    {
      accessor: 'entry',
      Header: t('profile.userRegistrations.columns.column4'),
      Cell: rowData => {
        const { academy_id, uuid } = rowData.row.original;
        return (
          <Link
            className="text-primary"
            to={`/dashboard/events/event-detail/${academy_id}/${uuid}`}
          >
            {rowData.row.original['event.name']}
          </Link>
        );
      }
    }
  ];

  useEffect(() => {
    (async () => {
      let registrations = await getUserRegistrations(uuid);
      setUserRegistrations(registrations);
      setIsLoading(false);
    })();
  }, []);



  return (
    <div className="profile-holder my-4">
      <div className="section-header">
        <h1>{t('profile.userRegistrations.title')}</h1>
      </div>
      <Section bg="light" className="py-0 profile matches">
        <TableListComponent
          columns={columns}
          data={userRegistrations}
          isLoading={isLoading}
        />
      </Section>
    </div>
  );
};

export default UserRegistrations;
