import axios from 'axios';
import { uniq, map } from 'lodash';
import moment from 'moment';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const apiRoot = process.env.REACT_APP_API_ROOT;

const getEventDetail = async (academyId: string, eventUUID: string) => {
  try {
    const response = await axios.get(
      `${apiRoot}/event/${academyId}/${eventUUID}`
    );
    return decodeEventDetail(response.data);
  } catch (e) {
    return null;
  }
};

export const decodeEventDetail = (data: any[]) => {
  if (data.length) {
    const event = data[0];
    const mdate = moment(event.event_date);
    const calendar = { month: mdate.format('MMM'), day: mdate.format('D') };
    const time = mdate.format('hh:mm A');

    const socialIcons = [];

    if (event.contact_facebook) {
      socialIcons.push({
        icon: ['fab', 'facebook'],
        color: 'facebook',
        href: event.contact_facebook
      });
    }
    if (event.contact_instagram) {
      socialIcons.push({
        icon: faInstagram,
        color: '700',
        href: event.contact_instagram
      });
    }
    if (event.contact_website) {
      socialIcons.push({
        icon: ['fa', 'link'],
        color: '700',
        href: event.contact_website
      });
    }
    const locationArray = event.location.split(',');
    return {
      id: event.uuid,
      event_end_date: event.event_end_date,
      location: event.location,
      calendar: calendar,
      title: event.name,
      type: 'Event',
      organizer: 'Default',
      dateTimeFormat: 'dddd, MMMM Do YYYY, h:mm:ss A',
      eventDateAndTime: mdate,
      registrationDeadline: {
        early: event.early_registration_final_date
          ? moment(event.early_registration_final_date)
          : null,
        normal: event.normal_registration_final_date
          ? moment(event.normal_registration_final_date)
          : null,
        late: event.late_registration_final_date
          ? moment(event.late_registration_final_date)
          : null
      },
      time: time,
      duration: '-',
      place: event.country || '-',
      image: event.avatarUrl || '',
      brackets: event.brackets || [],
      entries: event.entries || [],
      bracketMats: uniq(map(event.brackets || [], bracket => bracket.mat)),
      information: event.information,
      prizes: event.prizes,
      categories: event.categories,
      contact: {
        phone: event.contact_phone,
        email: event.contact_email,
        socialIcons
      },
      event: {
        ...event,
        locationLat: locationArray[0],
        locationLng: locationArray[1]
      },
      private: event.private === 1,
      eventStarted: event.eventStarted,
      eventType: event.eventType
    };
  }
};

export default getEventDetail;
