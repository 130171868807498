import axios from 'axios';

const excludedPaths = ['/user-registration'];
const isExcluded = (pathname) => {
  for(var i = 0; i< excludedPaths.length; i++){
    if(pathname.includes(excludedPaths[i])){
      return true;
    }
  }
  return false;
}

const excludedPathsWithAuth = ['/slickcomp-images','/slickcomp-videos'];
const isExcludedPathsWithAuth = (pathname) => {
  for(var i = 0; i< excludedPathsWithAuth.length; i++){
    if(pathname.includes(excludedPathsWithAuth[i])){ 
      return true;
    }
  }
  return false;
}

axios.interceptors.request.use(
  config => {
    if ( !isExcluded(window.location.pathname) && window.localStorage.getItem('token')) {
      config.headers = config.headers || {};      
      config.headers['Authorization'] = window.localStorage.getItem('token');
 
      if (!isExcludedPathsWithAuth(window.location.pathname)) {
        if(config.method === 'get'){
          config.params = config.params || {};
          config.params['language'] = window.localStorage.getItem('i18nextLng');
        }
        if(config.method === 'post' || config.method === 'put' || config.method === 'delete'){
          config.data['language'] = window.localStorage.getItem('i18nextLng');
        }
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
