import axios from 'axios';
import { lowerCase } from 'lodash';
import moment from 'moment';
const apiRoot = process.env.REACT_APP_API_ROOT;

type BuySubscriptionArgs = {
  academyId: number;
  planId: number;
  period: string;
  planUUID: string;
  planName: string;
  paymentInfo: any;
};
const buySubscriptionPlan = async (args: BuySubscriptionArgs) => {
  console.log(args);

  const { academyId, paymentInfo, planId, planUUID, planName, period } = args;

  let response = await axios.put(`${apiRoot}/academy-subscriptions/`, {
    action: 'buy',
    academyID: academyId,
    planID: planId,
    planName,
    status: 'active',
    periodicity: period,
    paymentInfo: paymentInfo
  });

  return response;
};
export default buySubscriptionPlan;
