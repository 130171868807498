import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { useState } from 'react';
import { useBreakpoints } from '../../../../hooks/useBreakpoints';
import { Card, Placeholder } from 'react-bootstrap';

const TableListComponent = props => {
  const [sizeCustomPage, setSizeCustomPage] = useState(props.perPage ?? 10);
  var zoomText = useBreakpoints().width < 600 ? 'fs--2' : 'fs--1';

  return (
    <div className="mt-3">
      <AdvanceTableWrapper
        columns={props.columns}
        data={props.data || []}
        sortable
        deletematch={props.handleDeleteMatch}
        pagination
        perPage={sizeCustomPage || 10}
        setSizeCustomPage={setSizeCustomPage}
        rowsPerPageOptions={props.rowsPerPageOptions}
      >
        <Card>
          {props.headerComponent ? (
            <Card.Header>{props.headerComponent}</Card.Header>
          ) : (
            ''
          )}
          <Card.Body className="p-0">
            {props.isLoading || false ? (
              <div className="m-4">
                {[1, 2, 4, 5, 6].map(i => (
                  <Placeholder as="p" animation="glow" key={i}>
                    <Placeholder xs={12} />
                  </Placeholder>
                ))}
              </div>
            ) : (
              <AdvanceTable
                table
                headerClassName="bg-white text-900  text-nowrap align-middle"
                rowClassName="align-middle text-800"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: `${zoomText} mb-0 overflow-hidden`
                }}
              />
            )}
          </Card.Body>
          {props.withPagination === undefined ||
          props.withPagination === true ? (
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={props.data ? props.data.length : 0}
                table
                deletematch={props.handleDeleteMatch}
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </Card.Footer>
          ) : (
            ''
          )}
        </Card>
      </AdvanceTableWrapper>
    </div>
  );
};

export default TableListComponent;
