import { Form } from 'react-bootstrap';
import paypal from 'assets/img/icons/icon-paypal-full.png';
import { useForm } from 'react-hook-form';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import buySubscriptionPlan from 'api/subscription/buySubscription';
import { useSelector } from 'react-redux';
import { sanitizePaypalResponse } from 'constants/paypal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect, useRef } from 'react';

const BillingDetails = ({ selectedPlan, period, setSelectedPeriodicity }) => {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { academyId } = useSelector(state => state.auth);
  const history = useHistory();

  const [_, payPalDispatch] = usePayPalScriptReducer();

  const periodRef = useRef('');
  useEffect(() => {
    periodRef.current = period;
    payPalDispatch({
      type: 'setLoadingStatus',
      value: 'pending'
    });
  }, [period]);

  const { priceAnnually, priceMonthly, idPayPalMonthly, idPayPalYearly } =
    selectedPlan;

  const handleCompletePayment = async paymentDetails => {
    try {
      await buySubscriptionPlan({
        period: periodRef.current,
        academyId: academyId,
        paymentInfo: {
          payPal_info: sanitizePaypalResponse(paymentDetails)
        },
        planId: selectedPlan.id,
        planUUID:
          lowerCase(periodRef.current) === 'monthly'
            ? idPayPalMonthly
            : idPayPalYearly,
        planName: selectedPlan.name
      });

      toast.success(t('subscriptions.checkout.successToast'), {
        onClose: () => {
          history.push('/dashboard/subscriptions');
        }
      });
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    } catch (error) {
      console.log('what', error);
      toast.error(t('subscriptions.checkout.errorToast'));
      setTimeout(() => {
        toast.dismiss();
      }, 3000);
    }
  };

  return (
    <Form>
      <Form.Select
        value={period}
        onChange={event => setSelectedPeriodicity(event.currentTarget.value)}
        className="mb-3"
      >
        <option value="Monthly">{t('subscriptions.checkout.monthly')}</option>
        <option value="Yearly">{t('subscriptions.checkout.yearly')}</option>
      </Form.Select>

      <Form.Check type="radio" className="mb-0 form-check" id="paypal">
        <Form.Check.Input
          type="radio"
          id="paypal"
          name="billing"
          checked={true}
          value="paypal"
        />
        <Form.Check.Label className="mb-0 d-block">
          <img src={paypal} height="20" alt="paypal" />
        </Form.Check.Label>
      </Form.Check>
      <p className="fs--1 mb-4">{t('subscriptions.checkout.payWithPaypal')}</p>

      <h6 className="d-flex justify-content-between">
        <span>{t('subscriptions.checkout.dueToday')}</span>
        <span>
          ${lowerCase(period) === 'monthly' ? priceMonthly : priceAnnually}{' '}
        </span>
      </h6>

      <hr />

      <div className="d-flex">
        <div className="flex-grow-1">
          <PayPalButtons
            className="mx-auto"
            fundingSource="paypal"
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id:
                  (lowerCase(period) === 'monthly'
                    ? idPayPalMonthly
                    : idPayPalYearly)
              });
            }}
            onApprove={async (data, actions) => {
              await handleCompletePayment(data);
            }}
            onError={error => {
              console.log('what', error,   
              (lowerCase(period) === 'monthly'
                ? idPayPalMonthly
                : idPayPalYearly));

              toast.error(t('subscriptions.checkout.errorToast'));
              setTimeout(() => {
                toast.dismiss();
              }, 3000);
            }}
          />
        </div>
      </div>

      <div className="text-center mt-2"></div>
    </Form>
  );
};

export default BillingDetails;
