import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Error500 = () => {
  const { t } = useTranslation();
  return(
    <Card className="text-center">
        <Card.Body className="p-5">
          <div className="display-1 text-300">500</div>
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            {t('errorsPages.error500.title')}
          </p>
          <hr />
          <p>
            {t('errorsPages.error500.message')}
            <a href="mailto:info@exmaple.com" className="ms-1">
            {t('errorsPages.error500.option0')}
            </a>
            .
          </p>
          <Link className="btn btn-primary btn-sm mt-3" to="/">
            <FontAwesomeIcon icon={faHome} className="me-2" />
            {t('errorsPages.error500.option1')}
          </Link>
        </Card.Body>
      </Card>
  );
};

export default Error500;
