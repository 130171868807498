import IconGroup from 'components/common/icon/IconGroup';
import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Table, Form, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { map, keys, size, compact, isEmpty, startCase } from 'lodash';
import { BracketMat } from './BracketMat';
import FilterBrackets from './FilterBrackets';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';

import { ResponsivePie } from '@nivo/pie';
import { stringToHslColor } from 'constants/color';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Link } from 'react-router-dom';

import bronzeMedalIcon from 'assets/img/icons/bronze_medal.png';
import goldMedalIcon from 'assets/img/icons/gold_medal.png';
import silverMedalIcon from 'assets/img/icons/silver_medal.png';
import { current } from '@reduxjs/toolkit';

import { useBreakpoints } from 'hooks/useBreakpoints';

const EventResults = ({ eventInfo }) => {
  const { t } = useTranslation();

  const { event } = eventInfo;

  const MEDALS = {
    silver: t('events.eventDetail.eventResults.silver'),
    gold: t('events.eventDetail.eventResults.gold'),
    bronze: t('events.eventDetail.eventResults.bronze')
  };
  const MEDALS_ICONS = {
    silver: silverMedalIcon,
    gold: goldMedalIcon,
    bronze: bronzeMedalIcon
  };

  const [currentMedal, setCurrentMedal] = useState(keys(MEDALS)[0]);

  const breakPointWidth = useBreakpoints().width;
  const isMobile = breakPointWidth < 768 ? true : false;


  const individualMedalists = event[`${currentMedal}_medalists`];
  const medalistsAcademiesData = compact(
    map(event[`${currentMedal}_medalist_academies`], stat => {
      const academy = startCase(
        stat.winner_academy || stat.loser_academy || ''
      );
      if (stat) {
        return {
          id: academy,
          label: academy,
          value: stat.count,
          color: stringToHslColor(academy)
        };
      }
    })
  );

  const medalistsAcademiesFill = map(medalistsAcademiesData, data => {
    return {
      match: {
        id: data.id
      },
      id: 'dots'
    };
  });
  const medalistColumns = [
    {
      accessor: 'winner',
      Header: t('events.eventDetail.eventResults.columns.winner_name'),
      Cell: rowData => {
        const { winner, loser } = rowData.row.original;
        return (
          <>
            <img src={MEDALS_ICONS[currentMedal]} width="30" height="30" />
            {winner || loser}
          </>
        );
      }
    },
    {
      accessor: 'winner_academy',
      Header: t('events.eventDetail.eventResults.columns.academy_name'),
      Cell: rowData => {
        const { winner_academy, loser_academy } = rowData.row.original;
        return <>{winner_academy || loser_academy}</>;
      }
    },
    {
      accessor: 'description',
      Header: t('events.eventDetail.eventResults.columns.bracket')
    }
  ];

  return (
    <div>
      <Flex className="medal-buttons-container-wrapper">
        {keys(MEDALS).map(tab => {
          return (
            <div
              className={` medal-btn-container medal-btn-${tab}-container ${tab === currentMedal ? `medal-selected-btn-container` : ''
                }`}
            >
              <button
                onClick={() => {
                  setCurrentMedal(tab);
                }}
                className={`cursor-pointer  medal-tab-btn medal-${tab}-btn`}
              >
                <span>
                  {size(event[`${tab}_medalists`])} {MEDALS[tab]}
                </span>
              </button>
            </div>
          );
        })}
      </Flex>

      <div>
        <div>
          <div className="medals-academy-header">
            <h4>{t('events.eventDetail.eventResults.academies')}</h4>

            <img src={MEDALS_ICONS[currentMedal]} />
          </div>

          {isEmpty(medalistsAcademiesData) && (
            <p className="my-2">
              {t(
                `events.eventDetail.eventResults.empty.${currentMedal}_academies`
              )}
            </p>
          )}

          {!isEmpty(medalistsAcademiesData) && (
            <>
              <div className="medals-chart-container">
                <MyResponsivePie
                  isMobile={isMobile}
                  dataFill={medalistsAcademiesFill}
                  data={medalistsAcademiesData}
                />
              </div>
            </>
          )}
        </div>
        <div>
          <h4>{t('events.eventDetail.eventResults.medalists')}</h4>

          {isEmpty(individualMedalists) && (
            <p className="my-2">
              {t(
                `events.eventDetail.eventResults.empty.${currentMedal}_medalists`
              )}
            </p>
          )}

          {!isEmpty(individualMedalists) && (
            <>
              <Section bg="light" className="py-0 profile matches">
                <AdvanceTableWrapper
                  columns={medalistColumns}
                  data={individualMedalists || []}
                  sortable
                  pagination
                  perPage={10}
                >
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </AdvanceTableWrapper>
              </Section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsivePie = ({ data, dataFill, isMobile }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: isMobile ? 90 : 80, bottom: 80, left: isMobile ? 90 : 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 0.2]]
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsStraightLength={isMobile ? 10 : undefined}
    arcLinkLabelsDiagonalLength={isMobile ? 5 : undefined}
    arcLinkLabelsTextOffset={isMobile ? 2 : undefined}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: 'color',
      modifiers: [['darker', 2]]
    }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    fill={dataFill}
    legends={[
      {
        anchor: 'bottom',
        direction: isMobile ? 'column' : 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: isMobile ? 8 : 0,
        itemWidth: 160,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000'
            }
          }
        ]
      }
    ]}
  />
);

export default EventResults;
