import React from 'react';
import { Card, Row, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { sortBy, isEmpty, capitalize, some } from 'lodash';
import { toast } from 'react-toastify';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { ConfirmButton } from 'components/common/ConfirmButton';
import cancelSubscriptionPlan from 'api/subscription/cancelSubscription';

export const SubscriptionsHistory = ({ subscriptionsHistory, academyId }) => {
  const { t } = useTranslation();

  const isAPremiumPlanActive = some(
    subscriptionsHistory,
    m => m.planName !== 'Basic' && m.status === 'active'
  );

  const handleCancelPlan = async (planId, periodicity, paymentInfo) => {
    try {
      await cancelSubscriptionPlan({
        academyId,
        paymentInfo: paymentInfo,
        periodicity,
        planId
      });
      toast.success(t('subscriptions.history.cancelPlanSuccessToast'), {
        onClose: () => {
          location.reload();
        }
      });

      setTimeout(() => {
        toast.dismiss();
      }, 1500);
    } catch (error) {
      toast.error(t('subscriptions.history.cancelPlanErrorToast'), {
        autoClose: 2000
      });
    }
  };

  const columns = [
    {
      accessor: 'planName',
      Header: t('subscriptions.history.planColumn')
    },
    {
      accessor: 'status',
      Header: t('subscriptions.history.statusColumn'),
      Cell: rowData => {
        const { status, planName } = rowData.row.original;

        if (planName === 'Basic' && isAPremiumPlanActive) {
          return t('subscriptions.history.inactivePlan');
        }

        return (
          <>
            {status === 'active'
              ? t('subscriptions.history.activePlan')
              : t('subscriptions.history.inactivePlan')}
          </>
        );
      }
    },
    {
      accessor: 'startDate',
      Header: t('subscriptions.history.startDateColumn')
    },
    {
      accessor: 'endDate',
      Header: t('subscriptions.history.endDateColumn')
    },
    {
      accessor: 'valid_Until',
      Header: t('subscriptions.history.validColumn')
    },
    {
      accessor: 'periodicity',
      Header: t('subscriptions.history.monthlyColumn'),
      Cell: rowData => {
        const { periodicity } = rowData.row.original;
        console.log(periodicity);

        return (
          <>
            {periodicity === 'Monthly'
              ? t('subscriptions.monthly')
              : t('subscriptions.yearly')}
          </>
        );
      }
    },
    {
      accessor: 'paymentInfo',
      Header: t('subscriptions.history.paymentDetailsColumn'),
      Cell: rowData => {
        const { paymentInfo } = rowData.row.original;
        return <>{paymentInfo?.type || '-'}</>;
      }
    },
    {
      accessor: 'action',
      Header: t('subscriptions.history.actionsColumn'),
      Cell: rowData => {
        const { planName, planID, periodicity, status, paymentInfo } =
          rowData.row.original;

        //Users can't cancel basic plan...
        //Only Active plans can be cancelled.
        if (planName === 'Basic' || status !== 'active') {
          return null;
        }

        return (
          <>
            <ConfirmButton
              confirmPopoverPlacement="left"
              buttonContent={
                <div>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          <strong>
                            {t('subscriptions.history.cancelPlan')}
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'times']}
                      className="btn-view cursor-pointer"
                    />
                  </OverlayTrigger>
                </div>
              }
              confirmTitle={t('subscriptions.history.cancelPlanTitle')}
              preventCloseOnConfirm={true}
              confirmDescription={t(
                'subscriptions.history.cancelPlanDescription'
              )}
              confirmActionBtnText={t(
                'subscriptions.history.cancelPlanProceedBtn'
              )}
              onConfirmAction={async () => {
                await handleCancelPlan(planID, periodicity, paymentInfo);
              }}
            />
          </>
        );
      }
    }
  ];

  return (
    <div className="my-3">
      <Card>
        <Card.Body className="position-relative">
          <Row>
            <h5 className="text-600 mb-2">
              {t('subscriptions.history.headerTitle')}
            </h5>

            {isEmpty(subscriptionsHistory) ? (
              <Alert variant="default" className="mb-0 rounded-0">
                <p className="empty-state-label">
                  {t('subscriptions.history.emptyState')}
                </p>
              </Alert>
            ) : (
              <AdvanceTableWrapper
                columns={columns}
                data={sortBy(
                  subscriptionsHistory,
                  ['status', 'valid_until'],
                  'desc'
                )}
                sortable
                pagination
                perPage={10}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
