import React, { useEffect, useState } from 'react';
import getEventParticipants from 'api/events/get-event-participants';
import { Card, Col, Form, Image, Placeholder, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Participant from './Participant';
import { useTranslation } from 'react-i18next';


const ListParticipants = ({ participantsIds, eventUuid }) => {
    const { academyId, eventID, bracketUUID } = useParams();
    const [participants, setParticipants] = useState([]);
    const [participantsFilter, setParticipantsFilter] = useState([]);
    const [isLoadingParticipant, setIsLoadingParticipant] = useState(true);

    const { t } = useTranslation();


    useEffect(() => {
        const loadParticipant = async () => {
            const data = await getEventParticipants({ academyId, eventUuid });
            setParticipants(data);
            setParticipantsFilter(data);
        }

        loadParticipant().then((v) => {
            setIsLoadingParticipant(false);
        });

    }, [])

    const filterPaticipant = (value) => {
        const filter = participants.filter((participant) => participant.userName.toLowerCase().includes(value.toLowerCase()));
        setParticipantsFilter(filter);
    }
    return (
        <Card>
            <Card.Header className="bg-light">
                <Row className="align-items-start">
                    <Col>
                        <h5 className="mb-0">{t('bracketAddParticipant.participants')} ({participants?.length}) </h5>
                    </Col>
                    <Col>
                        <Row className="">
                            <Col>
                                <Form.Control placeholder={
                                    t('bracketAddParticipant.search')
                                }
                                    size="sm" onChange={(e) => filterPaticipant(e.target.value)} />
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Card.Header>

            {(isLoadingParticipant && <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
                <Row className="gx-0 gy-1 text-center">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 8, 7, 6].map(participant => (
                        <Col className='g-1' key={participant} xs={6} md={4} lg={3} xxl={2}>
                            <Card style={{ width: '11rem' }}>
                                <Card.Body>
                                    <Placeholder animation="glow">
                                        <Placeholder wi xs={4} className="rounded-circle" >
                                            <div style={{ height: 50 }}></div>
                                        </Placeholder>

                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={6} /> <Placeholder xs={8} />
                                    </Placeholder>
                                    <Placeholder.Button animation='glow' xs={4} size='md'>
                                        <div style={{ height: 10 }}></div>
                                    </Placeholder.Button>
                                </Card.Body>
                            </Card>

                        </Col>
                    ))}
                </Row>
            </Card.Body>)
            }

            {(participants && <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1"

            >
                <Row className="gx-0 gy-1 text-center" style={{
                    maxHeight: 'calc(90vh - 210px)',
                    overflowY: 'auto'
                }}>
                    {participantsFilter.map(participant => (
                        <Col className='g-1' key={participant.user_id} xs={6} md={4} lg={3} xxl={2}>
                            <Participant
                                participant={participant}
                                isAdded={participantsIds.some((item) => item == participant.user_id)}
                                eventId={eventID}
                                academyID={academyId}
                                bracketUuid={bracketUUID}
                                isLoading={true}
                            />

                        </Col>
                    ))}
                </Row>
            </Card.Body>)
            }
        </Card>
    );
};

export default ListParticipants;
