/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import getBrackets from 'api/brackets/get-brackets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableSearchBrackets from './table-search-brackets';
import BracketHeader from './BracketHeader';
import TableListComponent from 'components/utils/shared/table/table';


const getMatchDuration = duration => {
  return moment.utc(parseInt(duration) * 1000).format('mm:ss');
};

const Match = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const academyId = useSelector(state => state.auth.academyId);
  const academyUUID = useSelector(state => state.auth.uuid);
  const { t } = useTranslation();


  const getDataMatch = async () => {
    const dataMatch = await getBrackets(academyUUID);
    setData(dataMatch);
    setFilterData(dataMatch);
    return dataMatch;
  };

  const handleDeleteMatch = bracketUUID => {
    setData([...data.filter(match => match.bracketUUID !== bracketUUID)]);
  };

  useEffect(async() => {
    const getMatches = async()=>{
      setIsLoading(true);
      await getDataMatch();
      setIsLoading(false);
    }
    getMatches();
  }, []);


  const columns = [
    {
      accessor: 'eventName',
      Header: t('bracketsColumns.column1')
    },
    {
      accessor: 'participants_names',
      Header: t('matchesColumns.column2')
    },
    {
      accessor: 'description',
      Header: t('bracketsColumns.column3')
    },
    {
      accessor: 'action',
      Header: t('matchesColumns.column5'),
      Cell: rowData => {
        const { bracketUUID, eventId } = rowData.row.original;

        console.log(`evnet andrey ${eventId}`);

        return (
            <div className='d-flex justify-content-center'>
              <Link
                className="btn-view"
                to={`/dashboard/brackets/${academyId}/${eventId}/${bracketUUID}`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={t('bracketsToolTips.view')}
              >
                <FontAwesomeIcon icon={['fas', 'eye']} className="bg text-primary" />
              </Link>
            </div>

        );
      }
    }
  ];

  return (<>    
    <TableSearchBrackets Data={data} setData={setFilterData} type="matches" />
    <small className='mb-3 ms-1 text-600'>{t('bracketsSearch.help')}</small>
   
    <TableListComponent
      columns={columns}
      data={filterData}
      handleDeleteMatch={handleDeleteMatch}
      headerComponent={<BracketHeader table/>}
      isLoading={isLoading}

    />
    </>

  );
};
export default Match;
