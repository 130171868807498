import React from 'react';
import IconGroup from 'components/common/icon/IconGroup';
import { Card } from 'react-bootstrap';
import EventEntries from './EventEntries';
import { useTranslation } from 'react-i18next';

const EventDetailAside = ({ eventInfo }) => {
  const { t, i18n } = useTranslation();

  const { place = t('events.eventDetail.blankInfo.location') } = eventInfo;

  const DATE_TIME_FORMAT = 'dddd, MMMM Do YYYY, h:mm:ss A';

  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6 className="mb-1">{t('events.eventDetail.date.title')}</h6>
          <p className="mb-1">
            {eventInfo.eventDateAndTime
              .locale(i18n.language)
              .format(DATE_TIME_FORMAT)}
          </p>

          {eventInfo.registrationDeadline.early && (
            <div className="mt-3">
              <h6 className="mb-1">
                {t('events.eventDetail.date.deadline.early')}
              </h6>
              <p className="mb-1">
                {eventInfo.registrationDeadline.early
                  .locale(i18n.language)
                  .format(DATE_TIME_FORMAT)}
              </p>
            </div>
          )}

          {eventInfo.registrationDeadline.normal && (
            <div className="mt-3">
              <h6 className="mb-1">
                {t('events.eventDetail.date.deadline.normal')}
              </h6>
              <p className="mb-1">
                {eventInfo.registrationDeadline.normal
                  .locale(i18n.language)
                  .format(DATE_TIME_FORMAT)}
              </p>
            </div>
          )}

          {eventInfo.registrationDeadline.late && (
            <div className="mt-3">
              <h6 className="mb-1">
                {t('events.eventDetail.date.deadline.late')}
              </h6>
              <p className="mb-1">
                {eventInfo.registrationDeadline.late
                  .locale(i18n.language)
                  .format(DATE_TIME_FORMAT)}
              </p>
            </div>
          )}

          <h6 className="mt-3 mb-1">
            {t('events.createEvents.fields.country')}
          </h6>
          <div className="mb-1">{place}</div>

          {eventInfo.contact.phone && (
            <div className="my-2">
              <h6 className="fs-0 mt-3 mb-1">
                {t('events.eventDetail.phone')}
              </h6>
              <p className="mb-1">{eventInfo.contact.phone}</p>
            </div>
          )}

          {eventInfo.contact.email && (
            <div className="my-2">
              <h6>{t('events.eventDetail.email')}</h6>
              <p className="mb-1">{eventInfo.contact.email}</p>
            </div>
          )}

          <div className="my-3">
            <h6>{t('events.eventDetail.eventBrackets.type')}</h6>
            <p className="mb-1">
              {eventInfo.private
                ? t('events.eventDetail.privateEvent')
                : t('events.eventDetail.publicEvent')}
            </p>
          </div>

          <IconGroup icons={eventInfo.contact.socialIcons} />
        </Card.Body>
      </Card>

      <EventEntries eventInfo={eventInfo} />
    </div>
  );
};

export default EventDetailAside;
