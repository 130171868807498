import { Tab, Nav, Card } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import EventSchedules from './EventSchedule';
import EventResults from './EventResults';
import { useParticipants } from '../hooks/useParticipants';
import { EventParticipantsList } from '../edit-event/EventParticipantsList';

import { useSelector } from 'react-redux';

const EventDetailContent = ({ eventInfo, handleRefresh }) => {
  const { t } = useTranslation();
  const {
    title,
    information = t('events.eventDetail.blankInfo.information'),
    prizes = t('events.eventDetail.blankInfo.prizes'),
    categories = t('events.eventDetail.blankInfo.category')
  } = eventInfo;

  const academyInfo = useSelector(state => state.auth);
  const { isAcademy, academyId } = academyInfo;

  const isCreatorAcademy = isAcademy && eventInfo?.event.academy_id === academyId;
  const allParticipants = useParticipants(eventInfo);

  return (
    <Card>
      <Card.Body>
        <Tab.Container defaultActiveKey="information">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                eventKey="information"
                className="ps-0 cursor-pointer outline-none"
              >
                {t('events.eventDetail.tabs.information')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="prizes"
                className="px-2 px-md-3 cursor-pointer outline-none"
              >
                {t('events.eventDetail.tabs.prizes')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="categories"
                className="px-2 px-md-3 cursor-pointer outline-none"
              >
                {t('events.eventDetail.tabs.category')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="schedule"
                className="px-2 px-md-3 cursor-pointer outline-none"
              >
                {t('events.eventDetail.tabs.schedule')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="results"
                className="px-2 px-md-3 cursor-pointer outline-none"
              >
                {t('events.eventDetail.tabs.resultsTab')}
              </Nav.Link>
            </Nav.Item>
            {isCreatorAcademy && (
              <Nav.Item>
                <Nav.Link
                  eventKey="participants"
                  className="px-2 px-md-3 cursor-pointer outline-none"
                >
                  {t('events.editEvents.participantsBtn')}
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="information">
              <h5 className="fs-0 my-3">{title}</h5>
              <div dangerouslySetInnerHTML={{ __html: information }} />
            </Tab.Pane>

            <Tab.Pane eventKey="prizes">
              <div
                className="my-3"
                dangerouslySetInnerHTML={{ __html: prizes }}
              />
            </Tab.Pane>

            <Tab.Pane eventKey="categories">
              <div
                className="my-3"
                dangerouslySetInnerHTML={{ __html: categories }}
              />
            </Tab.Pane>

            <Tab.Pane eventKey="schedule">
              <div className="my-3">
                <EventSchedules eventInfo={eventInfo} />
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="results">
              <div className="my-3">
                <EventResults eventInfo={eventInfo} />
              </div>
            </Tab.Pane>

            {isCreatorAcademy && (
              <Tab.Pane eventKey="participants">
                <div className="my-3">
                  <EventParticipantsList
                    allParticipants={allParticipants}
                    handleRefreshData={handleRefresh}
                  />
                </div>
              </Tab.Pane>
            )}

          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};

export default EventDetailContent;
