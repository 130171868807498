import { Card, Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DefaultDateTimePicker from 'components/common/DateTimePicker';
import { Controller } from 'react-hook-form';
import { includes, filter, map, range, get, isEmpty, keys } from 'lodash';

const ActivityDetails = ({
  errors,
  register,
  setValue,
  getValues,
  trigger,
  control,
  formData,
  setFormData,
  selectedDays,
  setSelectedDays
}) => {
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const { t } = useTranslation();
  const ALL_DAYS = {
    Mon: t('activities.create.fields.days.mon'),
    Tue: t('activities.create.fields.days.tue'),
    Wed: t('activities.create.fields.days.wed'),
    Thurs: t('activities.create.fields.days.thurs'),
    Fri: t('activities.create.fields.days.fri'),
    Sat: t('activities.create.fields.days.sat'),
    Sun: t('activities.create.fields.days.sun')
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">{t('activities.create.fields.title')}</Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>{t('activities.create.fields.name')}</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  isInvalid={!!errors.name}
                  placeholder={t('activities.create.fields.namePlaceholder')}
                  {...register('name', { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name && errors.name.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="startDate">
                <Form.Label>
                  {t('activities.create.fields.startDate')}
                </Form.Label>
                <Controller
                  control={control}
                  name="startDate"
                  render={() => (
                    <DefaultDateTimePicker
                      isInvalid={!!errors.startDate}
                      errorMessage={
                        errors.startDate ? errors.startDate.message : ''
                      }
                      showTimeSelect={false}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      placeholder={t('activities.create.fields.startDate')}
                      value={formData.startDate}
                      onValueChange={async newDate => {
                        handleChange('startDate', newDate);
                        setValue('startDate', newDate);

                        await trigger('endDate');
                        await trigger('startDate');
                      }}
                    />
                  )}
                  {...register('startDate', {
                    required: true,
                    validate: () => {
                      const value = getValues('startDate');
                      const endDate = getValues('endDate');

                      const result = moment(value).isBefore(moment(endDate));

                      return (
                        result ||
                        t('activities.create.fields.startDateValidationMessage')
                      );
                    }
                  })}
                />
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group controlId="endDate">
                <Form.Label>{t('activities.create.fields.endDate')}</Form.Label>
                <Controller
                  control={control}
                  name="endDate"
                  render={() => (
                    <DefaultDateTimePicker
                      isInvalid={!!errors.endDate}
                      errorMessage={
                        errors.endDate ? errors.endDate.message : ''
                      }
                      value={formData.endDate}
                      showTimeSelect={false}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      placeholder={t('activities.create.fields.endDate')}
                      onValueChange={async newDate => {
                        handleChange('endDate', newDate);
                        setValue('endDate', newDate);

                        await trigger('endDate');
                        await trigger('startDate');
                      }}
                    />
                  )}
                  {...register('endDate', {
                    required: true,
                    validate: () => {
                      const value = getValues('startDate');
                      const endDate = getValues('endDate');

                      const result = moment(value).isBefore(moment(endDate));

                      return (
                        result ||
                        t('activities.create.fields.endDateValidationMessage')
                      );
                    }
                  })}
                />
              </Form.Group>
            </Col>

            <Col md="12">
              <div className="border-dashed-bottom"></div>
              <div className="create-activity-day-header-label">
                {t('activities.create.fields.repeatLabel')}
              </div>

              <div className="create-activity-day-rows">
                {keys(ALL_DAYS).map(day => {
                  return (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedDays(prev => {
                          const newDays = includes(prev, day)
                            ? filter(prev, m => m !== day)
                            : [...prev, day];
                          setValue('selectedDays', newDays);
                          return newDays;
                        });
                      }}
                      key={day}
                      className={`create-activity-day-button ${
                        includes(selectedDays, day)
                          ? 'create-activity-day-selected-button'
                          : ''
                      }`}
                    >
                      <p className="create-activity-day-button-span">
                        {ALL_DAYS[day]}
                      </p>
                    </button>
                  );
                })}
                <span
                  className="create-activity-select-all-button"
                  onClick={() => {
                    setSelectedDays(keys(ALL_DAYS));
                    setValue('selectedDays', keys(ALL_DAYS));
                  }}
                >
                  {t('activities.create.fields.selectAll')}
                </span>
              </div>

              <div className="create-activity-selected-days-container">
                {!isEmpty(selectedDays) && (
                  <Row className="create-activity-repeat-row">
                    <Col sm="1" className="d-flex flex-column"></Col>
                    <Col sm="3">
                      <p
                        className="create-activity-repeat-button"
                        onClick={() => {
                          const firstDay = selectedDays[0];
                          const firstDayTime = getValues(
                            `days.${firstDay}.activityTime`
                          );

                          let time = {};

                          map(selectedDays, async day => {
                            time[`days.${day}.activityTime`] = firstDayTime;
                            setValue(`days.${day}.activityTime`, firstDayTime);
                            await trigger(`days.${day}.activityTime`);
                          });

                          setFormData({
                            ...formData,
                            ...time
                          });
                        }}
                      >
                        {t('activities.create.fields.repeatTime')}
                      </p>
                    </Col>
                    <Col sm="5">
                      <p
                        className="create-activity-repeat-button"
                        onClick={() => {
                          const firstDay = selectedDays[0];
                          const firstDayInstructorName = getValues(
                            `days.${firstDay}.instructorName`
                          );

                          let days = {};

                          map(selectedDays, async day => {
                            days[`days.${day}.instructorName`] =
                              firstDayInstructorName;

                            setValue(
                              `days.${day}.instructorName`,
                              firstDayInstructorName
                            );

                            await trigger(`days.${day}.instructorName`);
                          });

                          setFormData({
                            ...formData,
                            ...days
                          });
                        }}
                      >
                        {t('activities.create.fields.repeatInstructorName')}
                      </p>
                    </Col>
                    <Col sm="3">
                      <p
                        className="create-activity-repeat-button"
                        onClick={() => {
                          const firstDay = selectedDays[0];
                          const firstDaySpots = getValues(
                            `days.${firstDay}.availableSpots`
                          );

                          let spaces = {};

                          map(selectedDays, async day => {
                            handleChange(
                              `days.${day}.availableSpots`,
                              firstDaySpots
                            );

                            spaces[`days.${day}.availableSpots`] =
                              firstDaySpots;

                            setValue(
                              `days.${day}.availableSpots`,
                              firstDaySpots
                            );

                            await trigger(`days.${day}.availableSpots`);
                          });

                          setFormData({
                            ...formData,
                            ...spaces
                          });
                        }}
                      >
                        {t('activities.create.fields.repeatAvailableSpots')}
                      </p>
                    </Col>
                  </Row>
                )}

                {map(selectedDays, day => {
                  return (
                    <Row className="create-activity-selected-days-row">
                      <Col md="1" className="d-flex flex-column">
                        <span className="create-activity-selected-days-row-day-label">
                          <span className="create-activity-selected-days-row-day-label-header">
                            {t('activities.create.fields.dayLabel')} <br />
                          </span>
                          {ALL_DAYS[day]}
                        </span>
                      </Col>
                      <Col md="3">
                        <Form.Group controlId="activityTime">
                          <Form.Label>
                            {t('activities.create.fields.time')}
                          </Form.Label>

                          <Controller
                            control={control}
                            name={`days.${day}.activityTime`}
                            render={() => (
                              <DefaultDateTimePicker
                                errorMessage={
                                  get(errors, `days.${day}.activityTime`)
                                    ?.message || ''
                                }
                                isInvalid={
                                  !!get(errors, `days.${day}.activityTime`)
                                }
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                placeholder={t('activities.create.fields.time')}
                                value={
                                  get(formData, `days.${day}.activityTime`) ||
                                  null
                                }
                                onValueChange={async newDate => {
                                  handleChange(
                                    `days.${day}.activityTime`,
                                    newDate
                                  );
                                  setValue(`days.${day}.activityTime`, newDate);
                                  await trigger(`days.${day}.activityTime`);
                                }}
                              />
                            )}
                            {...register(`days.${day}.activityTime`, {
                              required: true
                            })}
                          />
                        </Form.Group>
                      </Col>

                      <Col md="5">
                        <Form.Group controlId="instructorName">
                          <Form.Label>
                            {t('activities.create.fields.instructorNameLabel')}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`days.${day}.instructorName`}
                            isInvalid={
                              !!get(errors, `days.${day}.instructorName`)
                            }
                            placeholder={t(
                              'activities.create.fields.instructorNameLabel'
                            )}
                            {...register(`days.${day}.instructorName`, {
                              required: true
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            {get(errors, `days.${day}.instructorName`)
                              ?.message || ''}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md="3">
                        <Form.Group controlId="availableSpots">
                          <Form.Label>
                            {t('activities.create.fields.spots')}
                          </Form.Label>
                          <Form.Select
                            name={`days.${day}.availableSpots`}
                            {...register(`days.${day}.availableSpots`, {
                              required: true
                            })}
                          >
                            {range(1, 31).map(spot => (
                              <option value={spot} key={spot}>
                                {spot} {t('activities.create.fields.spots')}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ActivityDetails;
