import PropTypes from 'prop-types';
import { Col, Row, } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const BracketHeader = ({ selectedRowIds }) => {
  const { t } = useTranslation();
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t('bracketHeader.title')}</h5>
      </Col>
     
    </Row>
  );
};

BracketHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default BracketHeader;
