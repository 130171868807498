import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Form,
  Spinner
} from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'components/common/Divider';
import { putBracketParticipant } from 'api/events/post-bracket-participant';
import { keys } from 'lodash';

export const ParticipantActionComponent = ({ bracket, handleRefreshData }) => {
  const [showQuestion, setShowQuestion] = useState(false);

  const { confirmed } = bracket;

  const { t } = useTranslation();

  const showConfirmQuestion = async () => {
    setShowQuestion(prev => !prev);
  };

  return (
    <div className="d-flex justify-content-evenly align-items-center">
      <ParticipantStatusComponent
        bracket={bracket}
        setShowQuestion={setShowQuestion}
        showQuestion={showQuestion}
        handleRefreshData={handleRefreshData}
        childComponent={
          <OverlayTrigger
            overlay={
              <Tooltip>
                <div
                  className={confirmed === 0 ? 'text-danger' : 'text-success'}
                >
                  <strong>
                    {confirmed === 1
                      ? t('events.editEvents.participantsActions.activated')
                      : t('events.editEvents.participantsActions.deactivated')}
                  </strong>
                </div>
              </Tooltip>
            }
          >
            <div
              onClick={() => showConfirmQuestion()}
              className={`${confirmed === 1 ? 'bg bg-success' : 'bg bg-danger'} 
              rounded-circle me-2`}
              style={{ width: 10, height: 10 }}
            />
          </OverlayTrigger>
        }
      />
    </div>
  );
};

const ConfirmParticipantModalQuestionStatus = ({
  onYes,
  onNot,
  question,
  show,
  setManualPaymentInfo,
  manualPaymentInfo,
  bracket,
  onHide
}) => {
  const { t } = useTranslation();
  const { confirmed, paymentInfo } = bracket;
  const [isProcessing, setIsProcessing] = useState(false);

  //Academy needs to enter the manual payment info for participants who aren't confirmed, and they have no payment info
  const isThereNeedForManualPaymentInfo =
    confirmed === 0 && keys(paymentInfo).length === 0;

  return (
    <Modal show={show} onHide={onHide}>
      <div className="d-flex justify-content-end m-3">
        <FontAwesomeIcon icon="xmark" />
      </div>
      <Modal.Body>
        <h5>{question}</h5>

        {isThereNeedForManualPaymentInfo && (
          <>
            <Divider />
            <Form.Group className="mb-3">
              <Form.Label>
                {t('events.editEvents.participantsActions.paymentInfo')}
              </Form.Label>
              <Form.Control
                value={manualPaymentInfo}
                onChange={event => {
                  setManualPaymentInfo(event.currentTarget.value);
                }}
                as="textarea"
                rows="3"
              ></Form.Control>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onNot}>
          {t('bracketModal.option1')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setIsProcessing(true);
            onYes();
          }}
          disabled={
            isProcessing ||
            (isThereNeedForManualPaymentInfo && !manualPaymentInfo)
          }
        >
          {isProcessing ? (
            <Spinner
              animation="grow"
              style={{ width: 15, height: 15, marginTop: 4 }}
            />
          ) : (
            t('bracketModal.option2')
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ParticipantStatusComponent = ({
  handleRefreshData,
  bracket,
  setShowQuestion,
  showQuestion,
  childComponent
}) => {
  const { t } = useTranslation();

  const [manualPaymentInfo, setManualPaymentInfo] = useState('');

  const {
    eventId,
    bracketId,
    userId,
    academyId,
    eventEntryId,
    confirmed,
    paymentInfo,
    weight
  } = bracket;

  const onHandledParticipantStatus = async () => {
    await putBracketParticipant({
      confirmed: confirmed === 0 ? 1 : 0, //deactivate or activate depending on current confirmed status
      bracket_id: Number(bracketId),
      academy_id: Number(academyId),
      event_entry_id: Number(eventEntryId),
      event_id: Number(eventId),
      user_id: Number(userId),
      weight: weight,
      manual_payment_info: manualPaymentInfo,
      payment_info: paymentInfo || {}
    });

    await handleRefreshData();
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <ConfirmParticipantModalQuestionStatus
        show={showQuestion}
        onHide={() => setShowQuestion(false)}
        manualPaymentInfo={manualPaymentInfo}
        setManualPaymentInfo={setManualPaymentInfo}
        onYes={async () => {
          await onHandledParticipantStatus();
          setShowQuestion(false);
        }}
        onNot={() => {
          setShowQuestion(false);
        }}
        bracket={bracket}
        question={
          confirmed === 0
            ? t('events.editEvents.participantsActions.questionActivated')
            : t('events.editEvents.participantsActions.questionDeactivated')
        }
      />
      {childComponent}
    </div>
  );
};
