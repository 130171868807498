import { useState, useEffect } from 'react';
import { flatten } from 'lodash';

export const useParticipants = eventInfo => {
  const [allParticipants, setAllParticipants] = useState([]);

  useEffect(() => {
    const participants = flatten(
      (eventInfo.brackets || []).map(bracket => {
        return (
          (bracket.participants || []).map(participant => {
            return {
              name: participant.userName,
              bracket: participant.bracketDescription,
              entry: participant.eventEntryName,
              weight: participant.weight,
              userId: participant.user_id,
              eventId: bracket.event_id,
              academyId: bracket.academy_id,
              eventEntryId: participant.event_entry_id,
              bracketId: participant.bracket_id,
              confirmed: participant.confirmed,
              paymentInfo: participant.payment_info,
              manualPaymentInfo: participant.manual_payment_info || ''
            };
          }) || []
        );
      })
    );
    setAllParticipants(participants);
  }, [eventInfo]);

  return allParticipants;
};
