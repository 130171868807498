import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from 'components/common/ConfirmButton';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';

const ActivityHeader = ({
  disabled,
  title,
  activityStatus,
  isProcessing,
  handleDeactivateActivity
}) => {
  const { t } = useTranslation();

  const breakPointWidth = useBreakpoints().width;
  const isMobile = breakPointWidth < 768 ? true : false;

  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              {' '}
              {t('activities.edit.title')} - {title}
            </h5>
          </Col>

          <Col xs="auto">
            <ConfirmButton
              preventCloseOnConfirm={true}
              confirmPopoverPlacement={isMobile ? 'bottom' : 'left'}
              buttonContent={
                <Button
                  variant="falcon-default"
                  className="me-2"
                  size="sm"
                  disabled={isProcessing}
                >
                  {activityStatus == 0
                    ? t('activities.edit.activateBtnLabel')
                    : t('activities.edit.deactivateBtnLabel')}
                </Button>
              }
              confirmTitle={t('activities.edit.confirmTitle')}
              confirmDescription={
                activityStatus == 0
                  ? t('activities.edit.activateConfirmDescription')
                  : t('activities.edit.confirmDescription')
              }
              confirmActionBtnVariant={
                activityStatus == 0 ? 'primary' : 'danger'
              }
              confirmActionBtnText={t('activities.edit.confirmButtonText')}
              onConfirmAction={handleDeactivateActivity}
            />

            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="submit"
              disabled={disabled}
            >
              {t('activities.create.save')}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActivityHeader;
