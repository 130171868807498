/* eslint-disable react/prop-types */
import React from 'react';
import { Col } from 'react-bootstrap';
import AdminControl from '../AdminControl';
import AdminMatchResult from '../AdminMatchResult';
import moment from 'moment';

const Footer = ({ data, time, isWon, ...rest }) => {
  const timer = moment.utc(time * 1000).format('mm:ss');
  return (
    <>
      <Col xs="auto d-flex flex-column">
        <div className="timer" style={{ color: "#3d7f2a"}}>{timer}</div>
      </Col>
      <Col>
        <header className="header">
          <h5 className="text-uppercase" style={{ color: "#FFFFFF"}}>{data && data.matchType}</h5>
          {data &&
            `${data.matchGender} ${data.seniority && `/ ${data.seniority}`} ${
              data.weight && `/ ${data.weight}`
            }`}
        </header>
        {isWon ? <AdminMatchResult {...rest} /> : <AdminControl {...rest} />}
      </Col>
     
    </>
  );
};

export default Footer;
