/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import Banner from './banner';
import coverSrc from 'assets/img/generic/4.jpg';
import avatarDefault from 'assets/img/team/avatar.png';
import { Col, Row, Form } from 'react-bootstrap';
import UserProfileFields from './user-profile-fields';
import AcademyProfileFields from './academy-profile-fields';
import AccountSettings from 'components/pages/user/settings/AccountSettings';
import getProfile from 'api/user/get-profile';
import getAcademy from 'api/academy/get-academy';
import { useParams } from 'react-router-dom';
import ACTION_TYPE from '../../../dux/auth/ACTION_TYPE';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpload } from 'react-use-upload';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  avatar: state.auth.isAcademy
});

const Edit = ({ academyId, uuid, dispatch }) => {
  const [avatar, setAvatar] = useState(null);
  const [visibility, setVisibility] = useState();
  const [visibilityChanged, setVisibilityChanged] = useState(false);
  const [academyBanner, setAcademyBanner] = useState({
    path: null,
    preview: coverSrc
  });
  let { isAcademy } = useParams();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    weight: '',
    belt: '',
    age: '',
    birthday: '',
    instagram: '',
    facebook: '',
    country: '',
    is_profile_visible: isAcademy || visibility == 1 ? 1 : 0,
    tagSettings: 'group-members',
    showFollowers: true,
    showEmail: true,
    showExperience: false,
    numberVisibility: true,
    allowFollow: false
  });
  const UPLOAD_URL = `${process.env.REACT_APP_API_IMAGES_ROOT}/slickcomp-images/academy-banner/${uuid}.png`;

  let [upload, { done, loading }] = useUpload(({ files }) => ({
    method: 'PUT',
    url: UPLOAD_URL,
    body: files[0],
    headers: { Authorization: window.localStorage.getItem('token') }
  }));

  const updatePhoto = file => {
    if (file) {
      setAcademyBanner({ path: null, preview: URL.createObjectURL(file) });
      upload({ files: [file] });
    }
  };

  const emitAvatarChange = useCallback(async () => {
    let data = null;
    if (isAcademy) {
      data = await getAcademy(academyId);
    } else {
      data = await getProfile(uuid);
    }
    if (data) {
      setAvatar(data[0].avatarUrl);
      dispatch({
        type: ACTION_TYPE.SET_AVATAR,
        avatar: data[0].avatarUrl
      });
    }
  }, []);

  const loadAvatar = async () => {
    let data = null;
    if (isAcademy) {
      data = await getAcademy(academyId);
    } else {
      data = await getProfile(uuid);
    }
    setAvatar(data[0].avatarUrl);
  };

  const handleVisibilityChanges = (value, manuallyChanged) => {
    setVisibilityChanged(manuallyChanged)
    setVisibility(value);
  };

  useEffect(() => {
    loadAvatar();
  }, []);

  return (
    <>
      <div className="academy-profile-banner-container">
        <Banner>
          <Banner.Header
            coverSrc={academyBanner.preview}
            avatar={avatar || avatarDefault}
            className="mb-8"
            isAcademy={isAcademy}
            uuid={uuid}
            onDone={emitAvatarChange}
          />
        </Banner>
        {isAcademy && (
          <Form.Group controlId="formFile">
            <Form.Label className="cover-image-file-input academy-profile-banner-upload-control">
              <FontAwesomeIcon icon="camera" className="me-2" />
              <span>{t('profile.academyBannerUpload')}</span>
            </Form.Label>
            <Form.Control
              type="file"
              className="d-none"
              onChange={e => updatePhoto(e.target.files[0])}
            />
          </Form.Group>
        )}
      </div>

      <Row className="g-3">
        <Col lg={8}>
          {isAcademy ? (
            <AcademyProfileFields id={academyId} />
          ) : (
            <UserProfileFields
              uuid={uuid}
              visibilityChanged={visibilityChanged}
              handleVisibilityChanges={handleVisibilityChanges}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <AccountSettings
              visibility={visibility}
              handleVisibilityChanges={handleVisibilityChanges}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProp)(Edit);
