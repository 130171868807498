/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Section from 'components/common/Section';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import TableListComponent from 'components/utils/shared/table/table';
import { Alert } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import './profile.css';
import { getAthletePayments } from 'api/user/subscription';

export const UserPayments = ({ uuid }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const academyUUID = useSelector(state => state.auth.uuid);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      let payments = await getAthletePayments(uuid, academyUUID);
      if (payments) {
        setData(payments);
        setIsLoading(false);
      }
    })();
  }, []);

  const columns = [
    {
      accessor: 'payment',
      Header: t('users.table.subscriptions.profile.paymentColumn'),
      Cell: rowData => {
        const { payment } = rowData.row.original;
        return <>{payment}</>;
      }
    },
    {
      accessor: 'paymentDate',
      Header: t('users.table.subscriptions.profile.paymentDateColumn'),
      Cell: rowData => {
        const { paymentDate } = rowData.row.original;
        return <span>{moment(paymentDate).format('yyyy-MM-DD')}</span>;
      }
    },
    {
      accessor: 'validUntil',
      Header: t('users.table.subscriptions.profile.validUntilColumn'),
      Cell: rowData => {
        const { validUntil } = rowData.row.original;
        return <span>{moment(validUntil).format('yyyy-MM-DD')}</span>;
      }
    }
  ];

  return (
    <div className="profile-holder">
      <div className="section-header">
        <h1>{t('users.table.subscriptions.profile.title')}</h1>
      </div>
      <Section bg="light" className="py-0 profile fighter matches">
        {!isLoading && isEmpty(data) ? (
          <Alert variant="default" className="mb-0 rounded-0">
            <p className="empty-state-label">
              {t('users.table.subscriptions.profile.emptyStates')}
            </p>
          </Alert>
        ) : (
          <TableListComponent
            columns={columns}
            data={data}
            perPage={5}
            isLoading={isLoading}
            withPagination={true}
          />
        )}
      </Section>
    </div>
  );
};
