import { useState } from 'react';
import { Row, Col, Card, Alert, Button } from 'react-bootstrap';
import eventBanner from 'assets/img/generic/13.jpg';
import Section from 'components/common/Section';
import { useTranslation } from 'react-i18next';
import './public-events.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import friendlyUrl from 'friendly-url';

const Event = ({ event }) => {
  const { t, i18n } = useTranslation();

  const [eventBannerImg, setEventBannerImg] = useState(
    event.image || eventBanner
  );

  return (
    <Col md="4" className="my-3" key={event.id}>
      <Card
        as={Link}
        className="text-decoration-none text-muted"
        to={`/public-events/${friendlyUrl(event.title)}/${event.id}`}
      >
        <Card.Img
          variant="top"
          width="100%"
          src={eventBannerImg}
          onError={() => {
            setEventBannerImg(eventBanner); //when there's an error loading the image (e.g img doesn't exist, etc), default to the event banner...
          }}
          alt={event.title}
        />
        <Card.Body>
          <Card.Title>
            <h5>{event.title}</h5>
          </Card.Title>
          <p className="text-muted">
            <small>
              {event.place}, {event.country}
            </small>
          </p>
          <p className="text-muted d-flex justify-content-between">
            <small className="text-muted">
              {moment(event.eventDate)
                .locale(i18n.language)
                .format('dddd, MMMM Do YYYY')}
            </small>
            <small className="text-muted">
              {moment().locale(i18n.language).to(moment(event.eventDate))}{' '}
            </small>
          </p>
        </Card.Body>
      </Card>
    </Col>
  );
};

const Events = ({ events, formData, homePage }) => {
  const { t, i18n } = useTranslation();

  return (
    <Section bg="light">
      <Row>
        <Col className="col-12">
          <h1
            className={
              homePage
                ? 'text-center events-title fs-1 fs-md-3 '
                : 'events-title fs-1 fs-md-3'
            }
          >
            {formData.upcoming
              ? t('publicEvents.upcoming')
              : t('publicEvents.past')}
          </h1>
        </Col>
      </Row>

      <Row className="mt-6">
        {events.length ? (
          events.map((event, index) => {
            return <Event event={event} key={index} />;
          })
        ) : (
          <Alert variant="default" className="mb-0 rounded-0 text-center">
            <h5>{t('publicEvents.eventEmptyState')}</h5>
          </Alert>
        )}
      </Row>
      {homePage && (
        <div className="pb-7 pb-xl-9 text-center text-xl-start">
          <Button
            variant="outline-dark"
            size="sm"
            as={Link}
            to="/public-events"
            className="border-2 rounded-pill mt-4 fs-0 py-2 upcoming-events-btn"
          >
            {t('showMoreEvents')}
          </Button>
        </div>
      )}
    </Section>
  );
};

export default Events;
