/* eslint-disable no-unused-vars */
import React from 'react';
import ForgetPasswordPage from 'components/pages/forgot-password';
import NewUserRegistration from 'components/pages/new-user-registration';
import PublicUserRegistration from 'components/pages/new-user-registration/public';
import ResetPasswordPage from 'components/pages/reset-password';
import { Switch, Route, Redirect } from 'react-router-dom';
import Logout from 'components/authentication/simple/Logout';
import NavbarAlternative from 'components/pages/landing/NavbarAlternative';
import NewAcademyRegistration from 'components/pages/academy-registration';

const FullPageLayout = () => {
  return (
    <>
      <NavbarAlternative />
      <Switch>
        <Route
          path="/forgot-password/:userType?"
          component={ForgetPasswordPage}
        />
        <Route
          path="/change-password/:uuid/:md5"
          component={ResetPasswordPage}
        />
        <Route path="/logout" exact component={Logout} />
        <Route
          path="/user-registration/:academyId/:userId"
          component={NewUserRegistration}
        />
        <Route
          path="/athlete-registration/:academyId?/:country?"
          component={PublicUserRegistration}
        />
        <Route
          path="/academy-registration"
          component={NewAcademyRegistration}
        />
        <Redirect to="/errors/404" />
      </Switch>
    </>
  );
};

export default FullPageLayout;
