import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Col, Form, FormCheck, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SubscriptionPricingHeader = ({
  selectedPlanPeriod,
  setSelectedPlanPeriod
}) => {
  const { t } = useTranslation();

  return (
    <Col xs={12} className="mb-3">
      <Row className="justify-content-center justify-content-sm-between">
        <Col sm="auto" className="text-center">
          <h5 className="d-inline-block">
            Billed{' '}
            {selectedPlanPeriod === 'monthly'
              ? t('subscriptions.monthly')
              : t('subscriptions.yearly')}
          </h5>
        </Col>
        <Col
          sm="auto"
          as={Flex}
          justifyContent="center"
          alignItems="center"
          className="mt-1 mt-sm-0"
        >
          <FormCheck.Label htmlFor="custom-switch" className="me-2">
            {t('subscriptions.monthly')}
          </FormCheck.Label>
          <Form.Check type="switch" id="yearly">
            <Form.Check.Input
              type="checkbox"
              onChange={event =>
                setSelectedPlanPeriod(
                  event.currentTarget.checked ? 'yearly' : 'monthly'
                )
              }
            />
            <Form.Check.Label className="ms-2 align-top">
              {t('subscriptions.yearly')}
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </Col>
  );
};

export default SubscriptionPricingHeader;
