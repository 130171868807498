import { useState, useMemo, useEffect } from 'react';
import { Row, Col, Tab, Tabs, Form, Alert } from 'react-bootstrap';
import Section from 'components/common/Section';
import DefaultDateTimePicker from 'components/common/DateTimePicker';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import './public-events.css';

const Hero = ({ handleChange, handleNameFilter, setFormData, formData }) => {
  const { t } = useTranslation();

  const [key, setKey] = useState('upcoming');
  const countriesOptions = useMemo(() => countryList().getData(), []);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    //when the tab is changed, default the dates to past/present corresponding to past/upcoming
    setFormData({
      ...formData,
      upcoming: Number(key === 'upcoming'), //set 1 for 'upcoming', and 0 for 'past'
      startDate:
        key === 'past'
          ? moment().add(-1, 'year').format('yyyy/MM/DD')
          : moment().format('yyyy/MM/DD'),
      endDate:
        key === 'past'
          ? moment().add(-1, 'day').format('yyyy/MM/DD')
          : moment().add(1, 'year').format('yyyy/MM/DD')
    });

    //clear date values on changing tabs
    setStartDate(null);
    setEndDate(null);
  }, [key]);

  useEffect(() => {
    if (startDate && !endDate) {
      setAlertMessage(t('publicEvents.endDateRequiredAlert'));
      return;
    }
    if (endDate && !startDate) {
      setAlertMessage(t('publicEvents.startDateRequiredAlert'));
      return;
    }
    if (endDate < startDate) {
      setAlertMessage(t('publicEvents.endDateLowerAlert'));
      return;
    }
    setAlertMessage('');

    if (startDate && endDate) {
      setFormData({
        ...formData,
        startDate: moment(startDate).format('yyyy/MM/DD'),
        endDate: moment(endDate).format('yyyy/MM/DD')
      });
    }
  }, [endDate, startDate]);

  return (
    <Section className="bg-dark light" position="center bottom" overlay>
      <Row className="justify-content-center align-items-center pt-4  pb-4 pb-xl-0">
        <Tabs
          activeKey={key}
          onSelect={k => setKey(k)}
          className="mb-3 public-events-nav-tabs"
        >
          <Tab
            eventKey="upcoming"
            title={t('publicEvents.upcoming')}
            tabClassName="public-events-nav-link"
          ></Tab>
          <Tab
            eventKey="past"
            title={t('publicEvents.past')}
            tabClassName="public-events-nav-link"
          ></Tab>
        </Tabs>

        <Row className="pt-3">
          <Col md={6}>
            <Form.Label className="public-events-form-label">
              {t('publicEvents.searchEvent')}
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              onChange={event => {
                handleNameFilter(event.target.value);
              }}
              placeholder={t(
                'events.createEvents.fields.eventTitlePlaceholder'
              )}
            />
          </Col>
          <Col md={6}>
            <Form.Label className="public-events-form-label">
              {t('publicEvents.gameType')}
            </Form.Label>
            <Select
              isClearable={true}
              options={[
                {
                  label: t('publicEvents.fields.gameType'),
                  value: 1
                }
              ]}
              placeholder={t('publicEvents.gameType')}
              onChange={value => {
                handleChange('gameType', value?.value || '');
              }}
            />
          </Col>
        </Row>
        <Row className="pt-2">
          <Col md={3}>
            <Form.Label className="public-events-form-label">
              {t('publicEvents.startDate')}
            </Form.Label>
            <DefaultDateTimePicker
              showTimeSelect={false}
              value={startDate}
              minDate={key === 'upcoming' ? new Date() : null} //allow user to select days in the Past when on the Past tab.
              maxDate={key === 'past' ? new Date() : null} //prevent selecting future dates for 'Past'
              showClearIcon={!!startDate}
              placeholder={t('publicEvents.startDate')}
              dateFormat="dd/MM/yyyy"
              onValueChange={newDate => {
                setStartDate(newDate);
              }}
            />
          </Col>
          <Col md={3}>
            <Form.Label className="public-events-form-label">
              {t('publicEvents.endDate')}
            </Form.Label>
            <DefaultDateTimePicker
              showTimeSelect={false}
              showClearIcon={!!endDate}
              value={endDate}
              minDate={key === 'upcoming' ? new Date() : null}
              maxDate={key === 'past' ? new Date() : null} //prevent selecting future dates for 'Past'
              placeholder={t('publicEvents.endDate')}
              dateFormat="dd/MM/yyyy"
              onValueChange={newDate => {
                setEndDate(newDate);
              }}
            />
          </Col>
          <Col md={6}>
            <Form.Label className="public-events-form-label">
              {t('publicEvents.selectCountries')}
            </Form.Label>
            <Select
              options={countriesOptions}
              isMulti={true}
              placeholder={t('publicEvents.selectCountriesName')}
              onChange={value => {
                handleChange(
                  'countries',
                  value.map(country => country.value)
                );
              }}
            />
          </Col>
          <Col md={12} className="pt-2">
            {alertMessage && (
              <Alert
                variant={'danger'}
                className="py-2"
                style={{ width: 'max-content' }}
              >
                <h6 className="mb-0 pr-3">{alertMessage}</h6>
              </Alert>
            )}
          </Col>
        </Row>
      </Row>
    </Section>
  );
};

export default Hero;
