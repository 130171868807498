import SearchParticipants from '../event-detail/SearchParticipants';
import TableListComponent from 'components/utils/shared/table/table';
import { ParticipantActionComponent } from './ConfirmParticipant';
import { useEffect, useState } from 'react';
import { EditParticipantWeight } from './EditParticipantWeight';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Alert } from 'react-bootstrap';

export const EventParticipantsList = ({
  allParticipants,
  handleRefreshData
}) => {
  const { t } = useTranslation();
  const [participantsData, setParticipantsData] = useState([]);

  const [editParticipantWeightIndex, setEditParticipantWeightIndex] =
    useState(null);

  useEffect(() => {
    setParticipantsData(allParticipants);
  }, [allParticipants]);

  const participantsColumns = [
    {
      accessor: 'name',
      Header: t('events.editEvents.participantsTable.name')
    },
    {
      accessor: 'bracket',
      Header: t('events.editEvents.participantsTable.bracket')
    },
    {
      accessor: 'entry',
      Header: t('events.editEvents.participantsTable.entry')
    },
    {
      accessor: 'weight',
      Header: t('events.editEvents.participantsTable.weight'),
      Cell: rowData => {
        return (
          <EditParticipantWeight
            participantIndex={rowData.row.index}
            selectedEditParticipantWeightIndex={editParticipantWeightIndex}
            handleSelectedEditParticipantWeightIndex={
              setEditParticipantWeightIndex
            }
            bracket={rowData.row.original}
            handleRefreshData={handleRefreshData}
          />
        );
      }
    },
    {
      accessor: 'action',
      Header: t('events.editEvents.participantsTable.action'),
      Cell: rowData => {
        return (
          <ParticipantActionComponent
            bracket={rowData.row.original}
            handleRefreshData={handleRefreshData}
          />
        );
      }
    }
  ];

  if (isEmpty(allParticipants)) {
    return (
      <Alert variant="default" className="mb-0 rounded-0">
        <p className="empty-state-label">{t('events.editEvents.participantEmptyState')}</p>
      </Alert>
    );
  }

  return (
    <div>
      <SearchParticipants
        Data={allParticipants}
        setData={setParticipantsData}
      />

      <TableListComponent
        columns={participantsColumns}
        data={participantsData}
        isLoading={false}
      />
    </div>
  );
};
