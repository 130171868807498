import React, { useEffect, useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';

import EventDetailAside from './EventDetailAside';
import EventDetailContent from './EventDetailContent';
import EventDetailHeader from './EventDetailHeader';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import getEventDetail from 'api/events/get-event';
import EventBrackets from './EventBrackets';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';

const mapStateToProp = state => ({

});

const EventDetail = () => {

  const { t } = useTranslation();
  const { id, academyId } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);

  const breakPointWidth = useBreakpoints().width;
  const isMobile= breakPointWidth < 768 ? true : false;

  const loadData = () => {
    (async () => {
      const data = await getEventDetail(academyId, id);
      setEventInfo(data);
      isLoading(false);
    })();
  }

  useEffect(() => {
    loadData();
  }, [id]);

  return (
    <>
      {loading &&
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      }

      {eventInfo && <>
        <EventDetailHeader eventInfo={eventInfo} />
        <Row className="g-3" xs={1} md={2} lg={3}>
          <Col xs={{ order: 1 }} lg={{ order: 1,span: '8' }} >
            <EventDetailContent eventInfo={eventInfo} handleRefresh={loadData} />  
            {!isMobile?<EventBrackets eventInfo={eventInfo} />:""}   
          </Col>
          {
            isMobile?
            <Col xs={{ order: 'last' }} md={{ order: 'last' }}>
              <EventBrackets eventInfo={eventInfo} />
            </Col>
            :""
          }
          <Col xs={{ order: 2 }} md={{ order: 2 }}>
            <EventDetailAside eventInfo={eventInfo} />
          </Col>
          
        </Row>
      </>
      }

    </>
  );
};

export default connect(mapStateToProp)(EventDetail);
