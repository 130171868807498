import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type Args = {
  academyUUID: string;
};

const getSubscriptionInfo = async ({ academyUUID }: Args) => {
  try {
    const historyResponse = await axios.get(
      `${apiRoot}/academy-subscriptions/${academyUUID}`
    );

    const plansResponse = await axios.get(`${apiRoot}/academy-billing-plans`);

    return {
      subscriptionsHistory: historyResponse.data || [],
      plans: plansResponse.data || []
    };
  } catch (e) {
    console.error(e);
  }
};
export default getSubscriptionInfo;
