import axios from 'axios';
import { replace } from "lodash";

const apiRoot = process.env.REACT_APP_API_ROOT;

type ClearBracketMatchesArgs = {
  academyID: number;
  bracketUUID: string;
  eventUUID: string;
};
const clearBracketMatches = async (
  data: ClearBracketMatchesArgs
): Promise<any> => {
  try {
    const response = await axios.delete(`${apiRoot}/bracket-matches`, {
      data: {
        academyId: data.academyID,
        bracketUUID: data.bracketUUID,
        eventUUID: data.eventUUID
      }
    });
    return { success: response.data.statusCode == 200, message: replace(response.data.body, '"') };
  } catch (e) {
    return false;
  }
};
export default clearBracketMatches;
