// eslint-disable-next-line
export const bgWhiteIcons = [
  { icon: ['fab', 'instagram'], color: 'instagram', bg: 'white', href: 'https://www.instagram.com/slickcomp', target: '_blank' },
  { icon: ['fab', 'whatsapp'], color: 'whatsapp', bg: 'white', href: 'https://wa.me/50686753954', target: '_blank' },
];

const socialcons = [
  { icon: ['fab', 'instagram'], color: 'instagram', bg: 'white', href: 'https://www.instagram.com/slickcomp', target: '_blank' },
];

export default socialcons;
