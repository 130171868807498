import React from "react";


export const useAcademyCreditsFocus = ({ loadData }) => {
    React.useEffect(() => {
        window.addEventListener("focus", loadData);

        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", loadData);
        };
    }, []);

}
