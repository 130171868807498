import axios from 'axios';
import moment from 'moment';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsInsertParticipantBracket = {
  eventId: number;
  academyID: number;
  bracketUuid: string;
  userId: string;
};

type ResponseParticipantBracket = {
  isSuccess: boolean;
  message: string;
};
const insertParticipantBracket = async (data: ArgsInsertParticipantBracket): Promise<ResponseParticipantBracket> => {
  try {

    const response = await axios.post(`${apiRoot}/bracket_participant`, {
      event_id: data.eventId,
      academy_id: data.academyID,
      bracket_uuid: data.bracketUuid,
      user_id: data.userId,
      confirmed: 1,
      event_entry_id: 0,
      dateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    });

    return {
      isSuccess: response.data.statusCode === 200,
      message: response.data.body,
    };
  } catch (e) {
    return {
      isSuccess: false,
      message: `${e}`,
    };
  }
};
export default insertParticipantBracket;
