import { Accordion, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SoftBadge from 'components/common/SoftBadge';

const Participant = ({ registration }) => {
  const { t } = useTranslation();

  const { userName, eventEntryName, confirmed, academyName} = registration;
  return (
    <div className="border rounded mb-2">
      <div className="py-3 px-3">
        <h5 className="fe-0">
          {userName}
        </h5>
       <h6>{academyName}</h6>
        <small>
            <SoftBadge
              pill
              bg={confirmed ? 'success' : 'danger'}
              className="fs--2"
            >
              {confirmed
                ? t('events.eventDetail.eventBrackets.confirmedPaymentLabel')
                : t('events.eventDetail.eventBrackets.waitingForPaymentLabel')}
            </SoftBadge>
        </small>
      </div>
    </div>
  );
};

const EventRegistrations = ({ participants }) => {
  const { t } = useTranslation();

  const completeRegistrations = participants.filter(m => m.confirmed);
  const incompleteRegistrations = participants.filter(m => !m.confirmed);

  if (!participants.length) {
    return null;
  }

  return (
    <div className="mt-3 ml--3">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {completeRegistrations.length}{' '}
            {t('events.eventDetail.eventBrackets.completeRegistrationsTitle')}
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {completeRegistrations.map((registration, index) => {
                return (
                  <Col sm={1} md={12} lg="auto" key={index}>
                    <Participant registration={registration} />
                  </Col>
                );
              })}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            {incompleteRegistrations.length}{' '}
            {t('events.eventDetail.eventBrackets.incompleteRegistrationsTitle')}
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {incompleteRegistrations.map((registration, index) => {
                return (
                  <Col sm={1} md={12} lg={"auto"} key={index}>
                    <Participant registration={registration} />
                  </Col>
                );
              })}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default EventRegistrations;
