import getSubscriptionInfo from 'api/subscription/get-subscription';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { capitalize, find } from 'lodash';

import { Card, Col, Row, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BillingDetails from './BillingDetails';
import BillingHeader from './BillingHeader';
import { useParams } from 'react-router-dom';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid
});

const AcademySubscriptionBilling = ({ academyId, uuid }) => {
  const { plan, period } = useParams();

  const { t } = useTranslation();
  const [loading, isLoading] = useState(true);
  const [subscriptionsData, setSubscriptionsData] = useState(null);

  const [selectedPeriodicity, setSelectedPeriodicity] = useState('');

  const loadData = async () => {
    const data = await getSubscriptionInfo({
      academyUUID: uuid
    });
    if (data) {
      setSubscriptionsData(data);
      setSelectedPeriodicity(capitalize(period));
    }
    isLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [plan]);

  const selectedPlan = subscriptionsData
    ? find(subscriptionsData.plans, m => m.id == plan)
    : null;

  return (
    <>
      {loading && (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      )}

      {subscriptionsData && (
        <div>
          <BillingHeader
            selectedPlan={selectedPlan}
            period={selectedPeriodicity}
          />
          <Row className="g-3">
            <Col lg={12} className="pe-lg-2 mb-3">
              <Card className="h-100">
                <Card.Header>
                  <h5 className="mb-0">{t('subscriptions.checkout.title')}</h5>
                </Card.Header>
                <Card.Body className="bg-light">
                  <BillingDetails
                    selectedPlan={selectedPlan}
                    setSelectedPeriodicity={setSelectedPeriodicity}
                    period={selectedPeriodicity}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProp)(AcademySubscriptionBilling);
