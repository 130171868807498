import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Alert } from 'react-bootstrap';
//import events from 'data/events/events';
import Event from './Event';
import Flex from 'components/common/Flex';
import getEvents from 'api/events/get-events';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid
});

const EventList = ({ academyId, uuid }) => {

  const { t } = useTranslation();
  const [page, setPage] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, isLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [rowIndex, setRowIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(false);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  const previousPage = () => {
    const _nextRowIndex = rowIndex - pageSize;
    setRowIndex(_nextRowIndex);
    const _nextPage = pageIndex - 1;
    setPageIndex(_nextPage);
    const _endRowIndex = _nextRowIndex + pageSize;
    doPaginate(events, _nextRowIndex, _endRowIndex);
  }

  const nextPage = () => {
    const _nextRowIndex = rowIndex + pageSize;
    setRowIndex(_nextRowIndex);
    const _nextPage = pageIndex + 1;
    setPageIndex(_nextPage);
    const _endRowIndex = _nextRowIndex + pageSize;
    doPaginate(events, _nextRowIndex, _endRowIndex);
  }

  const onChangePageSize = (value) => {
    const _newPageSize = parseInt(value);
    setPageSize(_newPageSize);
    const newPageIndex = Math.round(Number(rowIndex / _newPageSize));
    setPageIndex(newPageIndex);
    const newRowIndex = newPageIndex * _newPageSize;
    setRowIndex(newRowIndex)
    const _endRowIndex = newRowIndex + _newPageSize;
    doPaginate(events, newRowIndex, _endRowIndex);
  }

  const doPaginate = (data, _start, _end) => {
    const paginatedRecords = data.slice(_start, _end);
    setPage(paginatedRecords);
    setCanNextPage(_end < data.length);
    setCanPreviousPage(_start >= pageSize);
  }

  const loadData = () => {
    (async () => {
      const data = await getEvents(uuid);
      setEvents(data);
      isLoading(false);
      doPaginate(data, rowIndex, pageSize);
    })();
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card>
      <Card.Header
        as={Flex}
        justifyContent="between"
        alignItems="center"
        className="bg-light"
      >
        <h5 className="mb-0"> {t('events.listEvents.title')}</h5>

      </Card.Header>
      <Card.Body className="fs--1">
        <Row>
          {loading ?
            <Alert variant="default" className="mb-0 rounded-0">
              <h4 className="alert-heading">{t('events.listEvents.loading')}</h4>
            </Alert>
            :
            page.map((event, index) => (
              <Col key={event.id} md={6} className="h-100">
                <Event details={event} isLast={index === page.length - 1} />
              </Col>
            ))
          }
        </Row>
        <Row>
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={events ? events.length : 0}
              rowInfo
              navButtons
              rowsPerPageOptions={[10,20,30]}
              rowsPerPageSelection
              page={page}
              pageSize={pageSize}
              setPageSize={onChangePageSize}
              pageIndex={pageIndex}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProp)(EventList);
