import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type UserSubscriptionArgs = {
  academyID: string;
  userUUID: string;
  userID: string;
  validUntil: string;
  payment: string;
};

const addAthleteSubscription = async (data: UserSubscriptionArgs) => {
  let response = await axios.post(`${apiRoot}/user-subscriptions`, data);
  return response;
};

export const getAthletePayments = async (userUUID: string, academyUUID: string) => {
  const response = await axios.get(
    `${apiRoot}/user-subscriptions/${userUUID}/${academyUUID}`
  );
  return response.data;
}

export default addAthleteSubscription;
