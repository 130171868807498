import { useEffect, useState } from 'react';
import { Card, Col, Row, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Activity from './Activity';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import getActivities from 'api/activities/get-activities';
import DefaultDateTimePicker from 'components/common/DateTimePicker';
import { useDebounce } from 'react-use';
import { isEmpty, filter } from 'lodash';
import { InactiveUserAlert } from './InactiveUserAlert';

import { useBreakpoints } from '../../../hooks/useBreakpoints';


const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  isAcademy: state.auth.isAcademy
});

const ActivityList = ({ academyId, uuid, isAcademy }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState([]);

  const [activities, setActivities] = useState([]);
  const [isCurrentUserActive, setIsCurrentUserActive] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const [loading, isLoading] = useState(true);
  const [pageSize, setPageSize] = useState(9);
  const [rowIndex, setRowIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(false);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  const breakPointWidth = useBreakpoints().width;
  const isMobile= breakPointWidth < 768 ? true : false;

  const previousPage = () => {
    const _nextRowIndex = rowIndex - pageSize;
    setRowIndex(_nextRowIndex);
    const _nextPage = pageIndex - 1;
    setPageIndex(_nextPage);
    const _endRowIndex = _nextRowIndex + pageSize;
    doPaginate(activities, _nextRowIndex, _endRowIndex);
  };

  const nextPage = () => {
    const _nextRowIndex = rowIndex + pageSize;
    setRowIndex(_nextRowIndex);
    const _nextPage = pageIndex + 1;
    setPageIndex(_nextPage);
    const _endRowIndex = _nextRowIndex + pageSize;
    doPaginate(activities, _nextRowIndex, _endRowIndex);
  };

  const onChangePageSize = value => {
    const _newPageSize = parseInt(value);
    setPageSize(_newPageSize);
    const newPageIndex = Math.round(Number(rowIndex / _newPageSize));
    setPageIndex(newPageIndex);
    const newRowIndex = newPageIndex * _newPageSize;
    setRowIndex(newRowIndex);
    const _endRowIndex = newRowIndex + _newPageSize;
    doPaginate(activities, newRowIndex, _endRowIndex);
  };

  const doPaginate = (data, _start, _end) => {
    const paginatedRecords = data.slice(_start, _end);
    setPage(paginatedRecords);
    setCanNextPage(_end < data.length);
    setCanPreviousPage(_start >= pageSize);
  };

  const loadActivitiesData = () => {
    (async () => {
      isLoading(true);
      const { activities, isUserActive } = await getActivities({
        userId: uuid,
        academyId,
        selectedDate: startDate,
        isAcademy: isAcademy
      });

      //only show active Activities for Athletes..
      const displayActivities = isAcademy
        ? activities
        : filter(activities, m => m.status == 1);
      setActivities(displayActivities);

      setIsCurrentUserActive(isUserActive);

      isLoading(false);
      doPaginate(displayActivities, rowIndex, pageSize);
    })();
  };

  useDebounce(
    () => {
      loadActivitiesData();
    },
    1000,
    [startDate]
  );

  useEffect(() => {
    loadActivitiesData();
  }, []);

  return (
    <div>
      {/* Display Inactive Alert for Athletes who are inactive  */}
      {!isCurrentUserActive && !loading && !isAcademy && <InactiveUserAlert />}

      <Card>
        <Card.Header className="bg-light activity-card-header">
          <h5 className="mb-0"> {t('activities.title')}</h5>

          <Row className="activity-date-filter">
            <Col xs={1} className="centered-col">
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  isLoading(true);
                  setStartDate(prev => {
                    let date = new Date();
                    date.setDate(prev.getDate() - 1);
                    return date;
                  });
                }}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
            </Col>
            <Col xs={9} className="middle-date-col">
              <DefaultDateTimePicker
                showTimeSelect={false}
                value={startDate}
                showClearIcon={!!startDate}
                minDate={null}
                placeholder={t('activities.date')}
                dateFormat={isMobile ? "dd-MM-yyyy": "PPPP"}
                onValueChange={newDate => {
                  isLoading(true);
                  setStartDate(newDate);
                }}
              />
            </Col>
            <Col xs={1} className="centered-col">
              <Button
                variant="falcon-default"
                size="sm"
                onClick={() => {
                  isLoading(true);
                  setStartDate(prev => {
                    let date = new Date();
                    date.setDate(prev.getDate() + 1);
                    return date;
                  });
                }}
              >
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
            </Col>
            <Col md={1} />
          </Row>
        </Card.Header>

        <Card.Body className="fs--1">
          <Row>
            {loading ? (
              <Alert variant="default" className="mb-0 rounded-0">
                <h4 className="alert-heading">
                  {t('events.listEvents.loading')}
                </h4>
              </Alert>
            ) : (
              <>
                {isEmpty(activities) ? (
                  <Alert
                    variant="default"
                    className="mb-0 rounded-0 text-center"
                  >
                    <h5>{t('activities.emptyState')}</h5>
                  </Alert>
                ) : (
                  <>
                    {page.map(activity => (
                      <Activity
                        activity={activity}
                        date={startDate}
                        handleActivitiesRefresh={loadActivitiesData}
                        key={activity.id}
                        isCurrentUserActive={isCurrentUserActive}
                        isUserAthlete={isCurrentUserActive && !isAcademy}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </Row>
          <Row>
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={activities ? activities.length : 0}
                rowInfo
                navButtons
                rowsPerPageOptions={[9, 18, 36]}
                rowsPerPageSelection
                page={page}
                pageSize={pageSize}
                setPageSize={onChangePageSize}
                pageIndex={pageIndex}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                previousPage={previousPage}
              />
            </div>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default connect(mapStateToProp)(ActivityList);
