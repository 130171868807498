/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Section from 'components/common/Section';
import { getMatchById, putMatch } from 'api/match';
import MatchAdmin from 'api/admin/match/MatchAdmin';
import Participant from './Participant';
import Footer from './Footer';
import { Row } from 'react-bootstrap';

const ScoreCardDefault = ({
  data,
  matchAdmin,
  setP1score,
  setP2score,
  participant1,
  participant2,
  setTime,
  time
}) => {
  const timeRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [interval, setIntervalId] = useState(null);
  const [isStopped, setStopped] = useState(false);
  const stopped = useRef();
  stopped.current = isStopped;
  const intervalRef = useRef();
  const [swapMatch, setSwapMatch] = useState(false);

  const onStop = time => {
    setTime(time);
    setStopped(true);
  };
  const onSwap = v => {
    setSwapMatch(v);
  };
  const onResume = time => {
    setTime(time);
    setStopped(false);
  };
  const handlePointUpdate = (points, participant) => {
    if (participant === 1) {
      setP1score(score => {
        return {
          ...score,
          points: score.points + points
        };
      });
    }
    if (participant === 2) {
      setP2score(score => ({
        ...score,
        points: score.points + points
      }));
    }
  };
  const handleAdvantageUpdate = (advantage, participant) => {
    if (participant === 1) {
      setP1score(score => ({
        ...score,
        advantage: score.advantage + advantage
      }));
    }
    if (participant === 2) {
      setP2score(score => ({
        ...score,
        advantage: score.advantage + advantage
      }));
    }
  };
  const handlePenaltyUpdate = (penalty, participant) => {
    if (participant === 1) {
      setP1score(score => ({
        ...score,
        penalties: score.penalties + penalty
      }));
    }
    if (participant === 2) {
      setP2score(score => ({
        ...score,
        penalties: score.penalties + penalty
      }));
    }
  };
  const handleTimerUpdate = timeToAdd => {
    setTime(currentTime => timeToAdd + currentTime);
  };
  const handleConnection = (data, connectionId) => {
    putMatch(data, match.params.connectionId, connectionId);
    matchAdmin.ready();
  };
  const onStart = () => {
    setIntervalId(
      setInterval(() => {
        if (stopped.current) {
          return;
        }
        if (timeRef.current === 0) {
          clearInterval(intervalRef.current);
          return;
        }
        setTime(currentTime => (currentTime <= 0 ? 0 : currentTime - 1));
      }, 1000)
    );
  };
  const onWin = (participant, by) => {
    if (participant === 1) {
      setP1score(score => ({
        ...score,
        winner: true,
        by
      }));
      setP2score(score => ({
        ...score,
        winner: false,
        by: null
      }));
    }
    if (participant === 2) {
      setP1score(score => ({
        ...score,
        winner: false,
        by: null
      }));
      setP2score(score => ({
        ...score,
        winner: true,
        by
      }));
    }
  };
  useEffect(() => {
    (async () => {
      matchAdmin.onPointUpdate(handlePointUpdate);
      matchAdmin.onAdvantageUpdate(handleAdvantageUpdate);
      matchAdmin.onPenaltyUpdate(handlePenaltyUpdate);
      matchAdmin.onTimerUpdate(handleTimerUpdate);
      matchAdmin.onStart(onStart);
      matchAdmin.onStop(onStop);
      matchAdmin.onResume(onResume);
      matchAdmin.onEnd(() => setTime(0));
      matchAdmin.onWin(onWin);
      matchAdmin.onClose(window.close);
      matchAdmin.onSwap(onSwap);
      await matchAdmin.init();
    })();
    return () => clearInterval(intervalRef.current);
  }, []);
  return (
    <>
      <Section className="score-card light d-flex">
        <div className="holder d-flex flex-column flex-1">
          {data && (
            <>
              <Row
                className="match-wrapper flex-column m-0 position-relative"
              >
                <Row
                  className="justify-content-between flex-1 m-0"
                  style={
                    swapMatch
                      ? { backgroundColor: '#2a2764' }
                      : { backgroundColor: '#402433' }
                  }
                >
                  <Participant
                    participantData={data.participant1}
                    cardColor={swapMatch ? '#2a59ce' : '#d04e8c'}
                    scores={participant1}
                  />
                </Row>
                <Row
                  className="justify-content-between flex-1 m-0"
                  style={
                    swapMatch
                      ? { backgroundColor: '#402433' }
                      : { backgroundColor: '#2a2764' }
                  }
                >
                  <Participant
                    participantData={data.participant2}
                    cardColor={swapMatch ? '#d04e8c' : '#2a59ce'}
                    scores={participant2}
                  />
                </Row>
              </Row>
              <Row className="justify-content-between align-items-start flex-1 m-0">
                <Footer data={data} time={time} />
              </Row>
            </>
          )}
        </div>
      </Section>
    </>
  );
};

export default ScoreCardDefault;
