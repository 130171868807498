import ACTION_TYPE from '../ACTION_TYPE';

export const dispatchEventEdit = ({ eventAcademyId, eventUUID }) => dispatch => {
    dispatch({
        type: ACTION_TYPE.SET_EVENT_EDIT,
        payload: { eventAcademyId, eventUUID },
    });
};

export const dispatchControlMatchInfo = ({ controlMatchAcademyId, matchId }) => dispatch => {
    dispatch({
        type: ACTION_TYPE.SET_CONTROL_MATCH,
        payload: { controlMatchAcademyId, controlMatch_MatchId: matchId },
    });
};


export const dispatchMatchInfo = ({ matchAcademyId, matchId, matchConnectionId }) => dispatch => {
    dispatch({
        type: ACTION_TYPE.SET_MATCH_INFO,
        payload: { matchAcademyId, matchId, matchConnectionId },
    });
};

export const dispatchEventEntryInfo = ({ eventId, entryId, eventUUID, entryAcademyId }) => dispatch => {
    dispatch({
        type: ACTION_TYPE.SET_EVENT_ENTRY_INFO,
        payload: { eventId, entryId, eventUUID, entryAcademyId },
    });
};