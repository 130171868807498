import { Card } from 'react-bootstrap';
import { Col, Form, Row, Button } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import Select from 'react-select';
import { map, range, capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';


const BillingInformation = ({ entryInfo, handleFormChange, formData, errors }) => {

    const { entry } = entryInfo;
    const { t } = useTranslation();

    //don't show billing info form, when price is zero
    if (Number(entry.price) === 0) {
        return null;
    }

    return (
        <div className='mx-2'>
            <h5 className="fs-2 me-1 mb-2 mt-0">
                <span>{t('events.eventDetail.entryDetail.billing.title')}</span>
            </h5>
            <Row className="gx-2 gy-3">
                <Form.Group as={Col} lg={6} controlId="email">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.emailLabel')} *</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder={t('events.eventDetail.entryDetail.billing.emailPlaceholder')}
                        value={formData.email}
                        name="email"
                        className={errors?.email ? "border border-1 border-danger" : ""}
                        onChange={({ nativeEvent }) => {
                            handleFormChange("email", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="country">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.countryLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.countryPlaceholder')}
                        value={formData.country}
                        name="country"
                        className={errors?.country ? "border border-1 border-danger" : ""}
                        onChange={({ nativeEvent }) => {
                            handleFormChange("country", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="firstName">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.firstNameLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.firstNamePlaceholder')}
                        className={errors?.firstName ? "border border-1 border-danger" : ""}
                        value={formData.firstName}
                        name="firstName"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("firstName", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="lastName">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.lastNameLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.lastNamePlaceholder')}
                        value={formData.lastName}
                        className={errors?.lastName ? "border border-1 border-danger" : ""}

                        name="lastName"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("lastName", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="vat">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.vatNrLabel')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.vatNrPlaceholder')}
                        value={formData.vatNr}
                        name="vatNr"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("vatNr", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={12} controlId="streetName">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.streetNameLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.streetNamePlaceholder')}
                        value={formData.streetName}
                        className={errors?.streetName ? "border border-1 border-danger" : ""}

                        name="streetName"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("streetName", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={2} controlId="houseNumber">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.houseNumberLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.houseNumberPlaceholder')}
                        value={formData.nativeEvent}
                        name="houseNumber"
                        className={errors?.houseNumber ? "border border-1 border-danger" : ""}

                        onChange={({ nativeEvent }) => {
                            handleFormChange("houseNumber", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="addition">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.additionLabel')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.additionPlaceholder')}
                        value={formData.addition}
                        name="addition"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("addition", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="postalCode">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.postalCodeLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.postalCodePlaceholder')}
                        value={formData.postalCode}
                        name="postalCode"
                        className={errors?.postalCode ? "border border-1 border-danger" : ""}

                        onChange={({ nativeEvent }) => {
                            handleFormChange("postalCode", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={12} controlId="City">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.cityLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.cityPlaceholder')}
                        value={formData.city}
                        name="city"
                        className={errors?.city ? "border border-1 border-danger" : ""}

                        onChange={({ nativeEvent }) => {
                            handleFormChange("city", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="phoneNumber">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.phoneNumberLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.phoneNumberPlaceholder')}
                        value={formData.phoneNumber}
                        name="phoneNumber"
                        className={errors?.phoneNumber ? "border border-1 border-danger" : ""}
                        onChange={({ nativeEvent }) => {
                            handleFormChange("phoneNumber", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Col lg={6} className="mt-5">
                    <div className='d-flex'>
                        <i className='my-auto'>{t('events.eventDetail.entryDetail.billing.phoneNumberInfo')}</i>
                    </div>
                </Col>

            </Row>
        </div>
    );
};

export default BillingInformation;
