import NavbarStandard from '../landing/NavbarStandard';
import Section from 'components/common/Section';
import Cta from '../landing/Cta';
import FooterStandard from '../landing/FooterStandard';
import SingleElimination from 'components/dashboards/brackets/SingleElimination';

const PublicBracketDetail = () => {
  return (
    <div className="publicMatDetail">
      <NavbarStandard />

      <Section className="" position="center bottom" overlay>
        <SingleElimination />
      </Section>

      <Cta />
      <FooterStandard />
    </div>
  );
};

export default PublicBracketDetail;
