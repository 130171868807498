import Section from 'components/common/Section';
import { Row } from 'react-bootstrap';
import Participant from './Participant';
const MatchResultDefault = ({ data, getParticipantData }) => {

    return (
        <>
            <Section className="score-card light d-flex add">
                <div className="holder d-flex flex-column flex-1">
                    {data && (
                        <>
                            <Row
                                className="justify-content-between flex-1 m-0"
                                style={{ backgroundColor: '#402433' }}
                            >
                                <Participant
                                    participantData={getParticipantData(data[0].matchWinner)}
                                    winner={true}
                                    cardColor="#d04e8c"
                                    result={JSON.parse(data[0].result).winner}
                                    method={JSON.parse(data[0].result).method}
                                />
                            </Row>
                            <Row
                                className="justify-content-between flex-1 m-0"
                                style={{ backgroundColor: '#2a2764' }}
                            >
                                <Participant
                                    participantData={getParticipantData(data[0].matchLoser)}
                                    winner={false}
                                    cardColor="#2a59ce"
                                    result={JSON.parse(data[0].result).loser}
                                    method={JSON.parse(data[0].result).method}
                                />
                            </Row>
                        </>
                    )}
                </div>
            </Section>
        </>
    );
}


export default MatchResultDefault;