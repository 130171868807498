import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

import { Button, Fade } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useBreakpoints } from 'hooks/useBreakpoints';

import { toast } from 'react-toastify';
import classNames from 'classnames';
import { dispatchEventEntryInfo } from 'dux/auth/action-thunks/event-thunk';

const mapStateToProp = state => ({
  loggedIn: state.auth.loggedIn,
  isAcademy: state.auth.isAcademy
});

const EventEntryItem = ({
  name,
  description,
  price,
  currency,
  event_id,
  id,
  eventUUID,
  isAcademy,
  loggedIn,
  hasEventStarted,
  hasRegistrationDatePassed,
  isPrivate
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [displayRegisterButton, setDisplayRegisterButton] = useState(false);

  const breakPointWidth = useBreakpoints().width;
  const typeScreen =
    breakPointWidth > 1150
      ? 'desktop'
      : breakPointWidth < 768
        ? 'mobile'
        : 'tablet';

  const userIsActive = window.localStorage.getItem('active') == 1;
  const disabledEvent =
    (!userIsActive && isPrivate) ||
    hasEventStarted ||
    hasRegistrationDatePassed;

  return (
    <>
      <div
        className="bg-white border dark__bg-1100 py-2 px-3 position-relative rounded-1 mb-2"
        onMouseEnter={() => {
          setDisplayRegisterButton(true);
        }}
        onMouseLeave={() => {
          setDisplayRegisterButton(false);
        }}
      >
        <Flex>
          <div className="flex-1 position-relative ps-3">
            <h6 className="fs-0 mb-0">
              <div className="d-flex justify-content-between">
                <span className="me-1">
                  {name}
                  <SoftBadge pill bg="secondary" className="fs--2 mx-2">
                    {price} {currency}
                  </SoftBadge>
                </span>
                {/* Display the Register button only for Fighters (not academy) */}
                {!isAcademy && (
                  <Fade
                    in={
                      displayRegisterButton ||
                      ['mobile', 'tablet'].includes(typeScreen) //display the register button by default for mobile
                    }
                  >
                    <Button
                      className={classNames(
                        'rounded-pill border',
                        'bg',
                        { 'bg-secondary': disabledEvent },
                        { 'bg-primary': !disabledEvent }
                      )}
                      size="sm"
                      disabled={disabledEvent}
                      onClick={event => {
                        //If user isn't signed in, take the user to the athlete registration page
                        if (!loggedIn) {
                          event.preventDefault();

                          window.localStorage.setItem(
                            'entryRegistrationURL',
                            window.location.href
                          );

                          toast.warning(
                            t(
                              'events.eventDetail.eventEntries.registerWarning'
                            ),
                            {
                              autoClose: 3500
                            }
                          );

                          history.push(`/athlete-registration`);
                        }
                        //event hasn't started and registration date hasn't passed.. 
                        else if (!(hasEventStarted || hasRegistrationDatePassed)) {

                          dispatch(dispatchEventEntryInfo({
                            entryId: id,
                            eventId: event_id,
                            eventUUID: eventUUID,
                          }));
                          history.push(`/dashboard/events/entry-detail/${isPrivate ? 'private' : 'public'
                            }`);

                          return;

                        }
                      }}
                    >
                      {t('events.eventDetail.eventEntries.registerButton')}
                    </Button>
                  </Fade>
                )}
              </div>
            </h6>
            <p className="mb-0">
              <p className="text-1000 mb-0">{description}</p>
            </p>
          </div>
        </Flex>
      </div>
    </>
  );
};

export default connect(mapStateToProp)(EventEntryItem);
