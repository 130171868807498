import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import DashboardLayout from './DashboardLayout';
import FullPageLayout from './FullPageLayout';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import AuthCardRoutes from 'components/authentication/card/AuthCardRoutes';
import AuthSplitRoutes from 'components/authentication/split/AuthSplitRoutes';
import ErrorLayout from './ErrorLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import Landing from 'components/pages/landing/Landing';
import PublicEvents from 'components/pages/public-events/PublicEvents';
import ScoreCard from 'components/pages/admin-control-match/ScoreCard';
import { default as UserScoreCard } from 'components/pages/score-card/ScoreCard';

import PublicEventDetail from 'components/pages/public-events/PublicEventDetail';
import PublicMatDetail from 'components/pages/public-events/PublicMatDetail';
import TvModePublicMatDetail from 'components/pages/public-events/TvModePublicMatDetail';
import LoginPage from 'components/pages/signin';
import SingleElimination from 'components/dashboards/brackets/SingleElimination';
import PublicBracketDetail from 'components/pages/public-events/PublicBracketDetail';
import AthletePublicProfile from 'components/pages/public-athlete-page/athlete';
import AcademyPublicProfile from 'components/pages/public-academy-page/academy';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Switch>
        <Route path="/landing" component={Landing} />
        <Route path="/public-events" exact component={PublicEvents} />
        <Route
          path="/public-events/:eventName/:eventId"
          exact
          component={PublicEventDetail}
        />
        <Route
          path="/public-events/mat/:academyId/:eventId/:matId"
          exact
          component={PublicMatDetail}
        />
        <Route
          path="/public-events/mat/tv/:academyId/:eventId/:matId/:isPublic"
          exact
          component={TvModePublicMatDetail}
        />
        <Route
          path="/public-events/brackets/:academyId/:eventID/:bracketUUID"
          exact
          component={PublicBracketDetail}
        />
        <Route
          path="/control-match/"
          component={ScoreCard}
          exact
        />
        <Route
          path="/match/"
          component={UserScoreCard}
          exact
        />
        <Route path="/" component={Landing} exact />
        <Route
          path="/athlete/:athlete/:uuid"
          component={AthletePublicProfile}
          exact
        />
        <Route path="/academy/:academyName/:uuid" component={AcademyPublicProfile} exact />
        <Route path="/login" component={LoginPage} />
        <Route path="/dashboard" component={DashboardLayout} />
        <Route path="/authentication/simple" component={AuthSimpleLayout} />
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/authentication/split" component={AuthSplitRoutes} />
        <Route path="/authentication/wizard" component={WizardAuth} />
        <Route path="/errors" component={ErrorLayout} />
        <Route component={FullPageLayout} />
        <Redirect to="/errors/404" />
      </Switch>
      <SettingsPanel />
    </>
  );
};

export default Layout;
