import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import paypal from 'assets/img/icons/icon-paypal-full.png';
import BillingInformation from './BillingInformation';
import { useTranslation } from 'react-i18next';

const PaymentMethod = ({
  entryInfo,
  handleFormChange,
  formData,
  errors,
  processing,
  displayPayment,
  PaymentButton
}) => {
  const [, setMethod] = useState('paypal');
  const { t } = useTranslation();

  const { entry } = entryInfo;

  return (
    <Card>
      <Card.Header className="bg-light">
        <h5 className="mb-0">
          {t('events.eventDetail.entryDetail.paymentMethodTitle')}
        </h5>
      </Card.Header>
      <Card.Body>

        {/* Only Display the Paypal Checkbox when the entry isn't free...  */}
        {Number(entry.price) ? (
          <>
            <Form.Check
              type="radio"
              id="paypal"
              onChange={e => setMethod(e.target.id)}
              checked={true}
              className="d-flex align-items-center"
            >
              <Form.Check.Input
                type="radio"
                onChange={e => setMethod(e.target.id)}
                checked={true}
                name="payment-method"
              />
              <Form.Check.Label className="mb-0 ms-2">
                <img src={paypal} height={20} alt="card payment options" />
              </Form.Check.Label>
            </Form.Check>

            <div className="border-dashed-bottom mt-3 mb-3"></div>
          </>
        ) : null}

        <Row>
          <Col md={12} className="mb-xxl-0 position-relative">
            <BillingInformation
              entryInfo={entryInfo}
              formData={formData}
              handleFormChange={handleFormChange}
              errors={errors}
            />
          </Col>

          {displayPayment ?
            (
              <div className='mt-4 mb-3'>
                <PaymentButton />
              </div>
            )
            : (
              <Col
                md={12}
                className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start d-flex justify-content-center mt-2 mb-3"
              >
                <div className="border-dashed-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>

                <Button
                  variant="success"
                  type="submit"
                  disabled={processing}
                  className="mt-3 px-5 py-3 mx-auto"
                >
                  {processing
                    ? t(
                      'events.eventDetail.entryDetail.paymentButtonProcessingLabel'
                    )
                    : Number(entry.price)
                      ? t('events.eventDetail.entryDetail.paymentButtonLabel')
                      : t('events.eventDetail.entryDetail.freePaymentButtonLabel')}
                </Button>
              </Col>
            )}

        </Row>
      </Card.Body>
    </Card>
  );
};

export default PaymentMethod;
