import axios from 'axios';
import { time } from 'console';
import moment from 'moment';
const apiRoot = process.env.REACT_APP_API_ROOT;

const getFighterEvents = async (academyId: string) => {
  let academyData = await (
    await axios.get(`${apiRoot}/academy/${academyId}`)
  ).data;

  if (academyData.length) {
    const academy = academyData[0];

    let data = await (
      await axios.get(`${apiRoot}/events/${academy.uuid}`)
    ).data;
    return decodeEvents(data as any[]);
  }

  return [];
};

const decodeEvents = (data: any[]) => {
  return (data ?? []).reduce((result: any[], el: any) => {
    const mdate = moment(el.eventDate);
    const calendar = { month: mdate.format('MMM'), day: mdate.format('D') };
    const time = mdate.format('hh:mm A');
    const transformed = {
      id: el.eventUUID,
      calendar: calendar,
      title: el.eventName,
      type: 'Event',
      organizer: 'Default',
      time: time,
      duration: '-',
      place: el.country || '-',
      private: el.private
    };
    result.push(transformed);
    return result;
  }, []);
};

export default getFighterEvents;
