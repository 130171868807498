import { useEffect, useState } from 'react';
import { Alert, Row } from 'react-bootstrap';

import NavbarStandard from '../landing/NavbarStandard';
import Section from 'components/common/Section';
import Cta from '../landing/Cta';
import FooterStandard from '../landing/FooterStandard';
import EventDetailHeader from '../../dashboards/events/event-detail/EventDetailHeader';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import getPublicEventDetail from 'api/events/get-public-event-detail';
import { MatSchedules } from 'components/dashboards/events/event-detail/MatScheduleDetail';

const PublicMatDetail = () => {
  const { t } = useTranslation();

  const { eventId, academyId, matId } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);

  const loadData = () => {
    (async () => {
      const data = await getPublicEventDetail(eventId);
      setEventInfo(data);
      isLoading(false);
    })();
  };

  useEffect(() => {
    loadData();
  }, [eventId]);

  return (
    <div className='publicMatDetail'>
      <NavbarStandard />

      <Section className="bg-dark light p-0" position="center bottom" overlay>
        <Row className="justify-content-center align-items-center">
          {loading && (
            <Alert variant="default" className="p-5">
              <h4 className="alert-heading">{t('publicEvents.loading')}</h4>
            </Alert>
          )}

          {eventInfo && (
            <div>
              <EventDetailHeader eventInfo={eventInfo} />
            </div>
          )}
        </Row>
      </Section>
      {eventInfo && (
        <Section bg="light" className="pt-4">
          <MatSchedules
            eventInfo={eventInfo}
            academyId={academyId}
            eventId={eventId}
            matId={matId}
          />
        </Section>
      )}

      <Cta />
      <FooterStandard />
    </div>
  );
};

export default PublicMatDetail;
