import ACTION_TYPE from './ACTION_TYPE';
import INITIAL_STATE from './INITIAL_STATE';

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPE.LOG_ON:
      return { ...state, loggedIn: false, loginFailed: false };
    case ACTION_TYPE.LOGGED_IN: {
      return {
        ...state,
        loggedIn: true,
        token: action.token,
        academyId: action.academyId,
        userId: action.userId,
        uuid: action.uuid,
        isAcademy: action.isAcademy,
        avatar: action.avatar,
        billingPlanId: action.billingPlanId
      };
    }
    case ACTION_TYPE.SET_ACTIVITY_ID: {
      return {
        ...state,
        activityId: action.activityId
      };
    }
    case ACTION_TYPE.SET_EVENT_EDIT: {
      return {
        ...state,
        eventEdit: action.payload
      };
    }
    case ACTION_TYPE.SET_CONTROL_MATCH: {
      return {
        ...state,
        controlMatch: action.payload
      };
    }
    case ACTION_TYPE.SET_MATCH_INFO: {
      return {
        ...state,
        matchInfo: action.payload
      };
    }
    case ACTION_TYPE.SET_EVENT_ENTRY_INFO: {
      return {
        ...state,
        eventEntryRegisterInfo: action.payload
      };
    }
    case ACTION_TYPE.LOGIN_FAILED:
      return { ...state, loginFailed: true };
    case ACTION_TYPE.SET_AVATAR:
      return { ...state, avatar: action.avatar };
    case ACTION_TYPE.NORMAL:
      return { ...state, loginFailed: false };
    default:
      return state;
  }
};
export default authReducer;
