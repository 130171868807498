import { Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import buySubscriptionPlan from 'api/subscription/buySubscription';
import { toast } from 'react-toastify';
import { capitalize } from 'lodash';

const SubscriptionPlanCard = ({
  period,
  plan: { name, priceAnnually, priceMonthly, features, id }
}) => {
  const { t } = useTranslation();
  const { academyId } = useSelector(state => state.auth);
  const history = useHistory();

  const handleCompletePayment = async () => {
    try {
      const response = await buySubscriptionPlan({
        academyId: academyId,
        paymentInfo: {
          payPal_info: {
            orderID: '',
            subscriptionID: ''
          }
        },
        period: capitalize(period),
        planId: id,
        planName: name
      });

      if (response.data.statusCode == 400) {
        toast.warning(response.data.body);
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
        return;
      }

      toast.success(t('subscriptions.checkout.successToast'), {
        onClose: () => {
          history.push('/dashboard/subscriptions');
        }
      });
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    } catch (error) {
      toast.error(t('subscriptions.checkout.errorToast'), { autoClose: 2000 });
    }
  };

  return (
    <Col lg={4} className={classNames('border-top border-bottom')}>
      <div className="h100">
        <div className="text-center p-4">
          <h3 className="fw-normal my-0">{name}</h3>
          <h2 className="fw-medium my-4">
            <sup className="fw-normal fs-2 me-1">$</sup>
            {period === 'monthly' ? priceMonthly : priceAnnually}
            <small className="fs--1 text-700">
              / {period === 'monthly' ? 'month' : 'year'}
            </small>
          </h2>
          {name === 'Basic' ? (
            <Button variant={'outline-primary'} onClick={handleCompletePayment}>
              {t('subscriptions.subscribeBtn')}
            </Button>
          ) : (
            <Button
              as={Link}
              variant={'outline-primary'}
              to={`/dashboard/subscriptions/checkout/${id}/${period}`}
            >
              {t('subscriptions.subscribeBtn')}
            </Button>
          )}
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-4 py-4">
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1" key={feature}>
                <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                {feature}{' '}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default SubscriptionPlanCard;
