import { Card } from 'react-bootstrap';
import UserRegistrationItem from './UserRegistrationItem';
import { useTranslation } from 'react-i18next';


const UserRegistrations = ({ entryInfo, paymentButton }) => {
  const { t } = useTranslation();

  const {
    userRegistrations
  } = entryInfo;

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('events.eventDetail.entryDetail.registrationTitle')}</Card.Header>
      <Card.Body className="bg-light">

        {!userRegistrations.length && <p className='my-2'>{t('events.eventDetail.blankInfo.userRegistrations')}</p>}

        {userRegistrations.map((item, index) => (
          <UserRegistrationItem
            {...item}
            bracketDesc={item["bracket.description"]}
            key={index}
            index={index}
            PaymentButton={paymentButton}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default UserRegistrations;
