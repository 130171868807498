import axios, { AxiosError, AxiosResponse } from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type ArgsDeleteParticipant = {
    bracketUUID: string;
    academyId: string;
    userId: string;
  };

type ResponseFE={
  result: boolean,
  message: string
}

const deleteBracketParticipant = async (data: ArgsDeleteParticipant) :Promise<ResponseFE> => {
  try {
    const response = await axios.delete(`${apiRoot}/bracket_participant`, {data});
    return {
      result :response.data.statusCode ===200,
      message:response.data.body,
    };
  } catch (error) {
    if (axios.isAxiosError(error))  {
      return {
        result :false,
        message: error.message,
      };
    } else {
      return {
        result :false,
        message: '',
      };
    }
   
  }
};
export default deleteBracketParticipant;
