import React, { useState } from 'react';
import eventBanner from 'assets/img/generic/13.jpg';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Calendar';
import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dispatchEventEdit } from 'dux/auth/action-thunks/event-thunk';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  isAcademy: state.auth.isAcademy
});

const EventDetailHeader = ({ eventInfo, isAcademy, academyId, titleAsLink, isPublic }) => {
  const { id, event, image } = eventInfo;
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const [eventBannerImg, setEventBannerImg] = useState(image || eventBanner);

  return (
    <Card className={`p-0 mb-3 ${isPublic ? "eventBannerCardPublic" : ""}`}>
      <img
        className={`card-img-top`}
        src={eventBannerImg}
        onError={() => {
          setEventBannerImg(eventBanner); //when there's an error loading the image (e.g img doesn't exist, etc), default to the event banner...
        }}
        alt={eventInfo.title}
      />
      <Card.Body className="overflow-hidden">
        <Row className="flex-center">
          <Col>
            <Flex>
              <Calendar {...eventInfo.calendar} />
              <div className="fs--1 ms-2 flex-1">
                {titleAsLink ?
                  <Link to={`/dashboard/events/event-detail/${academyId}/${id}`}>
                    <h5 className="fs-0 text-capitalize">{eventInfo.title}</h5>
                  </Link> :
                  <h5 className="fs-0 text-capitalize">{eventInfo.title}</h5>
                }
                <p className="mb-0 text-capitalize">
                  {t('events.listEvents.event.organizedBy')}:
                  <Link to={`/academy/${eventInfo.event?.academyName.replace(/\s+/g, '-').toLowerCase()}/${eventInfo.event.academyUUID}`} target="_blank" className="text-700 ps-1">
                    {eventInfo.event.academyName}
                  </Link>
                </p>
              </div>
            </Flex>
          </Col>
          <Col md="auto" className="mt-4 mt-md-0">
            <Flex>
              {isAcademy && event.academy_id === academyId && (
                <Button
                  variant="falcon-primary"
                  size="sm"
                  onClick={() => {
                    dispatch(dispatchEventEdit({
                      eventAcademyId: academyId,
                      eventUUID: id,
                    }));
                    history.push(`/dashboard/events/update`);
                  }}
                  className="px-3"
                >
                  <FontAwesomeIcon icon={'pencil-alt'} className="me-1" />
                  {t('events.editEvents.title')}
                </Button>
              )}
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProp)(EventDetailHeader);
