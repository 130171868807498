import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

const getAcademiesFromCountry = async country => {
  try {
    const response = await axios.get(`${apiRoot}/academies?country=${country}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
export default getAcademiesFromCountry;
