import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;
const getAcademy = async (academyId: string) => {
  try {
    const response = await axios.get(`${apiRoot}/academy/${academyId}`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const checkIfAcademyIsPremium = async (academyId: string) => {
  const academy = await getAcademy(academyId);
  const isPremium = Number(academy[0].billingPlanId) >= 1; //if the billing plan id is greater than 1, the academy is a premium member..
  return isPremium;
};

export default getAcademy;
