import { useEffect, useState } from 'react';
import { Tab, Nav, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BracketMat } from './BracketMat';
import FilterBrackets from './FilterBrackets';

const EventSchedules = ({ eventInfo }) => {
  const { t } = useTranslation();

  const { bracketMats } = eventInfo;
  const [filteredBrackets, setFilteredBrackets] = useState([]);

  const [eventBrackets, setEventBrackets] = useState([]);

  useEffect(() => {
    setEventBrackets(eventInfo.brackets);
    setFilteredBrackets(eventInfo.brackets);
  }, [bracketMats]);

  return (
    <Tab.Container defaultActiveKey="brackets">
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link
            eventKey="brackets"
            className="ps-0 cursor-pointer outline-none"
          >
            {t('events.eventDetail.tabs.bracketsTab')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="findMatches"
            className="px-2 px-md-3 cursor-pointer outline-none"
          >
            {t('events.eventDetail.tabs.findMatches')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="brackets">
          <Row>
            <BracketMat
              bracketMats={bracketMats}
              eventInfo={eventInfo}
              brackets={eventBrackets}
              setEventBrackets={setEventBrackets}
            />
          </Row>
        </Tab.Pane>

        <Tab.Pane eventKey="findMatches">
          <div>
            <Row>
              <FilterBrackets
                Data={eventInfo.brackets}
                setData={setFilteredBrackets}
              />
            </Row>
            <Row>
              <BracketMat
                bracketMats={bracketMats}
                eventInfo={eventInfo}
                brackets={filteredBrackets}
                setEventBrackets={setEventBrackets}
              />
            </Row>
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default EventSchedules;
