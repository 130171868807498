import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

const getBracket = async (
  academyId: string,
  uuid: string,
  isPublic = false
) => {
  try {
    const response = isPublic
      ? await axios.get(`${apiRoot}/bracket-public/${academyId}/${uuid}`)
      : await axios.get(`${apiRoot}/bracket/${academyId}/${uuid}`);
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
export default getBracket;
