import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import EventEntryItem from './EventEntryItem';
import { useTranslation } from 'react-i18next';

import { some } from 'lodash';

const generateUUID = function () {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const EventEntries = ({ setValue }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const handleAddEntry = () => {
    setItems([
      ...items,
      {
        uuid: generateUUID(),
        name: '',
        description: '',
        price: 0.0,
        currency: 'USD',
        gender: 'Male',
        gi_nogi: 1,
        seniority: 'Juvenile'
      }
    ]);
  };
  const handleRemoveItem = uuid => {
    setItems(items.filter(item => item.uuid !== uuid));
  };
  const handleChange = (uuid, name, value) => {
    const newItems = [...items];
    const idx = newItems.findIndex(x => x.uuid == uuid);
    const updatedItem = { ...newItems[idx], [name]: value };
    setItems([
      ...newItems.slice(0, idx),
      updatedItem,
      ...newItems.slice(idx + 1)
    ]);
  };

  useEffect(() => {
    setValue('entries', items, { shouldValidate: true });
  }, [items]);

  //check if there are negative prices for any entry
  const isThereNegativePricesForAnyEntry = some(items, m => m.price < 0);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('events.createEvents.eventEntries')}</Card.Header>
      <Card.Body className="bg-light event-form-entries-inner-container">
        {items.map((item, index) => (
          <EventEntryItem
            {...item}
            key={index}
            index={index}
            handleRemove={handleRemoveItem}
            handleChange={handleChange}
          />
        ))}

        <IconButton
          onClick={handleAddEntry}
          disabled={isThereNegativePricesForAnyEntry}
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
        >
          {t('events.createEvents.addEntry')}
        </IconButton>
      </Card.Body>
    </Card>
  );
};

EventEntries.propTypes = {
  setValue: PropTypes.func
};

export default EventEntries;
