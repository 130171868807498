import { Card } from 'react-bootstrap';
import EventEntryItem from './EventEntryItem';
import { useTranslation } from 'react-i18next';


const EventEntries = ({ eventInfo }) => {
  const { t } = useTranslation();

  const {
    entries,
    registrationDeadline,
    event
  } = eventInfo;

  const hasRegistrationDatePassed =  registrationDeadline.late && registrationDeadline.late.isBefore();
  

  return (
    <Card className="my-3">
      <Card.Header as="h5">{t('events.eventDetail.eventEntries.title')}</Card.Header>
      <Card.Body className="bg-light">

        {!entries.length && <p className='my-2'>{t('events.eventDetail.blankInfo.entries')}</p>}

        {entries.map((item, index) => (
          <EventEntryItem
            {...item}
            isPrivate={eventInfo.private}
            eventUUID={eventInfo.id}
            hasEventStarted={!!event.event_started}
            hasRegistrationDatePassed={hasRegistrationDatePassed}
            key={index}
            index={index}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default EventEntries;
