import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const PieChart = ({ quantity_used, quantity_available }) => {
  const { t } = useTranslation();

  const mediaQuery = matchMedia('(max-width: 760px)');
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(mediaQuery.matches);
    mediaQuery.addEventListener('change', () => {
      setMatches(mediaQuery.matches);
    });
  }, []);

  const data = {
    labels: [
      `${t('credits.quantityUsed')} (${quantity_used})`,
      `${t('credits.quantityPurchased')} (${quantity_available})`,
      `${t('credits.academyQuantity')} (${quantity_available - quantity_used})`
    ],
    datasets: [
      {
        label: 'Credits Stats',
        data: [
          quantity_used,
          quantity_available,
          quantity_available - quantity_used
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          '#3c5a99'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          '#3c5a99'
        ],
        borderWidth: 1
      }
    ]
  };

  const options1 = {
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          padding: 20,
          boxWidth: 20
        }
      }
    }
  };
  const options2 = {
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          padding: 20,
          boxWidth: 20
        }
      }
    }
  };
  return (
    <div>
      <Pie data={data} options={matches ? options2 : options1} />
    </div>
  );
};

export default PieChart;
