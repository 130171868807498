import { useEffect, useState } from 'react';
import { Card, Row, Alert, Nav } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useSelector } from 'react-redux';
import axios from 'axios';
import getAcademy from 'api/academy/get-academy';
import PieChart from 'components/common/pieChart/PieChart';
import IconButton from 'components/common/IconButton';
import { useAcademyCreditsFocus } from 'hooks/useAcademyCredits';


const mapStateToProp = state => ({
  academyId: state.auth.academyId
});

const Credits = ({ academyId }) => {
  const { t } = useTranslation();
  const [creditsData, setCreditsData] = useState([]);
  const [loading, isLoading] = useState(true);
  const [sizeCustomPage, setSizeCustomPage] = useState(5);
  const history = useHistory();
  const [academyData, setAcademyData] = useState([
    {
      credits: { quantity_used: 0, quantity_available: 0 }
    }
  ]);
  const academyUUID = useSelector(state => state.auth.uuid);

  const creditsFetch = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_ROOT}/academy-credits/${academyUUID}`
      );
      if (res) {
        setCreditsData(res?.data);
      }
      isLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAcademyData = async () => {
    var data = await getAcademy(academyId);
    setAcademyData(data);
  };
  useEffect(() => {
    creditsFetch();
    loadAcademyData();
  }, []);

  useAcademyCreditsFocus({
    loadData: () => {
      creditsFetch();
      loadAcademyData();
    }
  });

  const columns = [
    {
      accessor: 'insertDate',
      Header: t('credits.insertColumn')
    },
    {
      accessor: 'updateDate',
      Header: t('credits.updateColumn')
    },
    {
      accessor: 'quantity',
      Header: t('credits.quantityColumn')
    },
    {
      accessor: 'paymentInfo',
      Header: t('credits.paymentInfoColumn'),
      Cell: rowData => {
        if (
          rowData?.row?.original?.paymentInfo != null &&
          rowData?.row?.original?.paymentInfo != '{}'
        )
          return (
            <>
              <OverlayTrigger
                trigger="click"
                placement="right"
                rootClose={true}
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Header as="h3">Payment Information</Popover.Header>
                    <Popover.Body>
                      <strong>{rowData?.row?.original?.paymentInfo}</strong>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>Click to see payment information</span>
              </OverlayTrigger>
            </>
          );
        else {
          return <></>;
        }
      }
    },
    {
      accessor: 'action',
      Header: t('credits.actionsColumn'),
      Cell: rowData => {
        if (
          rowData?.row?.original?.paymentInfo == null ||
          rowData?.row?.original?.paymentInfo == '{}'
        )
          return (
            <>
              <Nav.Link
                as={Link}
                className="p-0"
                to={{
                  pathname: '/dashboard/events/buy-credits',
                  state: rowData?.row?.original
                }}
              >
                <FontAwesomeIcon
                  icon={['fas', 'dollar-sign']}
                  className="btn-view cursor-pointer"
                />
              </Nav.Link>
            </>
          );
        else {
          return <></>;
        }
      }
    }
  ];

  return (
    <>
      {loading ? (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      ) : (
        creditsData && (
          <div className="my-3">
            <Card className="mb-3">
              <Card.Body>
                <Row>

                  <div className='d-flex justify-content-between'>
                    <h5 className="text-600 mb-2">{t('credits.chartTitle')}</h5>
                    <IconButton
                      as={Link}
                      target="_blank"
                      to="/dashboard/events/buy-credits"
                      size="sm"
                      icon="cart-plus"
                      transform="shrink-2"
                    >
                      <span className="d-none d-sm-inline-block ms-1">
                        {t('credits.buyCreditsInCreditsPage')}
                      </span>
                    </IconButton>
                  </div>

                  <div className="pieChart mx-auto" style={{ width: '450px' }}>
                    <PieChart
                      quantity_available={
                        academyData?.[0]?.credits?.quantity_available
                      }
                      quantity_used={academyData?.[0]?.credits?.quantity_used}
                    />
                  </div>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body className="position-relative">
                <Row>
                  <h5 className="text-600 mb-2">{t('credits.headerTitle')}</h5>
                  <AdvanceTableWrapper
                    columns={columns}
                    data={creditsData}
                    sortable
                    pagination
                    perPage={sizeCustomPage || 5}
                    setSizeCustomPage={setSizeCustomPage}
                    rowsPerPageOptions={[5, 10, 15]}
                  >
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <Card.Footer className="pb-0">
                      <AdvanceTableFooter
                        rowCount={creditsData ? creditsData.length : 0}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </Card.Footer>
                  </AdvanceTableWrapper>
                </Row>
              </Card.Body>
            </Card>
          </div>
        )
      )}
    </>
  );
};
export default connect(mapStateToProp)(Credits);
