/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Section from 'components/common/Section';
import { getMatchById, putMatch, postMatchResult } from 'api/match';
import MatchAdmin from 'api/admin/match/MatchAdmin';
import Participant from './Participant';
import Footer from './Footer';
import { Button, Row } from 'react-bootstrap';
import DonotRefresh from 'components/Alerts/DonotRefresh';
import Prompt from 'components/Alerts/Prompt';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './ScoreCard.css';
import swapImg from 'assets/img/swap.png';
const ScoreCardDefault = ({
  data,
  setP1score,
  setP2score,
  participant1,
  participant2,
  matchAdmin,
  connectionId,
  setTime,
  time
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [savePrompt, setSavePrompt] = useState(false);
  const [loading, setLoading] = useState(false);
  const timeRef = useRef();
  const [matchTime, setMatchTime] = useState(0);
  const matchTimeRef = useRef();
  matchTimeRef.current = matchTime;
  timeRef.current = time;
  const [isStarted, setStarted] = useState(false);
  const [isReady, setReady] = useState(false);
  const [isStopped, setStopped] = useState(false);
  const stopped = useRef();
  stopped.current = isStopped;
  const [isEnded, setEnded] = useState(false);
  const [endedAt, setEndedAt] = useState(null);
  const [isWon, setWon] = useState(false);
  const [interval, setIntervalId] = useState(null);
  const [swapMatch, setSwapMatch] = useState(false);
  const [swapShow, setSwapShow] = useState(true);
  const intervalRef = useRef();
  intervalRef.current = interval;

  const handlePointUpdate = (points, participant) => {
    if (participant === 1) {
      setP1score(score => {
        return {
          ...score,
          points: score.points + points,
          events: score.events.concat([
            {
              time: matchTimeRef.current,
              type: 'point',
              value: points
            }
          ])
        };
      });
    }
    if (participant === 2) {
      setP2score(score => ({
        ...score,
        points: score.points + points,
        events: score.events.concat([
          {
            time: matchTimeRef.current,
            type: 'point',
            value: points
          }
        ])
      }));
    }
  };
  const handleAdvantageUpdate = (advantage, participant) => {
    if (participant === 1) {
      setP1score(score => ({
        ...score,
        advantage: score.advantage + advantage,
        events: score.events.concat([
          {
            time: matchTimeRef.current,
            type: 'advantage',
            value: advantage
          }
        ])
      }));
    }
    if (participant === 2) {
      setP2score(score => ({
        ...score,
        advantage: score.advantage + advantage,
        events: score.events.concat([
          {
            time: matchTimeRef.current,
            type: 'advantage',
            value: advantage
          }
        ])
      }));
    }
  };
  const handlePenaltyUpdate = (penalty, participant) => {
    if (participant === 1) {
      setP1score(score => ({
        ...score,
        penalties: score.penalties + penalty,
        events: score.events.concat([
          {
            time: matchTimeRef.current,
            type: 'penalty',
            value: penalty
          }
        ])
      }));
    }
    if (participant === 2) {
      setP2score(score => ({
        ...score,
        penalties: score.penalties + penalty,
        events: score.events.concat([
          {
            time: matchTimeRef.current,
            type: 'penalty',
            value: penalty
          }
        ])
      }));
    }
  };
  const handleTimerUpdate = timeToAdd => {
    setTime(currentTime =>
      timeToAdd + currentTime < 0 ? 0 : timeToAdd + currentTime
    );
  };
  const onReady = () => {
    setReady(true);
  };
  const onStart = () => {
    setSwapShow(false);
    setStarted(true);
    setIntervalId(
      setInterval(() => {
        if (stopped.current) {
          return;
        }
        if (timeRef.current === 0) {
          onEnd();
          return;
        }
        setMatchTime(matchTime => matchTime + 1);
        setTime(currentTime => (currentTime < 0 ? 0 : currentTime - 1));
      }, 1000)
    );
  };
  const onSwap = val => {
    setSwapMatch(val);
  };
  const onStop = time => {
    setTime(time);
    setStopped(true);
  };
  const onResume = time => {
    setTime(time);
    setStopped(false);
  };

  const onEnd = () => {
    setEnded(true);
    setEndedAt(moment().format('YYYY-MM-DD HH:mm:ss'));
    setTime(0);
    clearInterval(intervalRef.current);
  };
  const onBack = () => {
    setEnded(false);
    setStarted(true);
  };
  const onFinalBack = () => {
    setWon(false);
  };
  const onWin = (participant, by) => {
    if (participant === 1) {
      setP1score(score => ({
        ...score,
        winner: true,
        by
      }));
      setP2score(score => ({
        ...score,
        winner: false,
        by: null
      }));
    }
    if (participant === 2) {
      setP1score(score => ({
        ...score,
        winner: false,
        by: null
      }));
      setP2score(score => ({
        ...score,
        winner: true,
        by
      }));
    }
    setWon(true);
  };
  const onSave = () => {
    setSavePrompt(true);
  };
  const onSaveNo = () => {
    setSavePrompt(false);
  };
  const onSaveYes = async () => {
    setSavePrompt(false);
    setLoading(true);
    await putMatch(
      {
        ...data,
        matchEndtime: endedAt
      },
      connectionId,
      null
    );
    const winner = participant1.winner ? participant1 : participant2;
    const loser = participant1.winner ? participant2 : participant1;
    await postMatchResult({
      matchId: data.matchId,
      matchWinner: winner.participant,
      matchLoser: loser.participant,
      result: {
        method: winner.by,
        timestamp: [
          {
            participant: participant1.participant,
            events: participant1.events
          },
          {
            participant: participant2.participant,
            events: participant2.events
          }
        ],
        winner: {
          points: winner.points,
          advantage: winner.advantage,
          penaty: winner.penalties
        },
        loser: {
          points: loser.points,
          advantage: loser.advantage,
          penaty: loser.penalties
        },
        matchDuration: matchTimeRef.current
      }
    });
    matchAdmin.close();
  };

  useEffect(() => {
    (async () => {
      matchAdmin.onPointUpdate(handlePointUpdate);
      matchAdmin.onAdvantageUpdate(handleAdvantageUpdate);
      matchAdmin.onPenaltyUpdate(handlePenaltyUpdate);
      matchAdmin.onTimerUpdate(handleTimerUpdate);
      matchAdmin.onReady(onReady);
      matchAdmin.onStart(onStart);
      matchAdmin.onEnd(onEnd);
      matchAdmin.onSwap(onSwap);
      matchAdmin.onWin(onWin);
      matchAdmin.onStop(onStop);
      matchAdmin.onResume(onResume);
      matchAdmin.onClose(() => history.goBack());
      await matchAdmin.init();
    })();
    return () => clearInterval(intervalRef.current);
  }, []);
  return (
    <>
      <Section className="score-card light d-flex">
        {loading && (
          <div className="fadeMe">
            <div className="scoreLoader opacity-75 bg-black position-fixed top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center">
              <div className="scoreLoader-content d-flex align-items-center justify-content-center">
                <Spinner
                  animation="grow"
                  style={{ width: 15, height: 15, marginTop: 4 }}
                />
                <p className="mb-0 mx-3">{t('loader.loaderText')}...</p>
              </div>
            </div>
          </div>
        )}
        <div className="holder d-flex flex-column flex-1">
          {data && (
            <>
              {savePrompt && (
                <Prompt
                  setSavePrompt={setSavePrompt}
                  prompt={t('adminControlMatch.scoreCard.promptText')}
                  onYes={onSaveYes}
                  onNo={onSaveNo}
                />
              )}
              <DonotRefresh />
              <Row
                className="match-wrapper flex-column m-0 position-relative"
              >
                {swapShow && (
                  <button
                    className={swapMatch ? 'swap-btn swap-rotate' : 'swap-btn'}
                    onClick={() => {
                      matchAdmin.swap(!swapMatch);
                    }}
                  >
                    <img src={swapImg} />
                  </button>
                )}

                <Row
                  className="justify-content-between flex-1 m-0"
                  style={
                    swapMatch
                      ? { backgroundColor: '#2a2764' }
                      : { backgroundColor: '#402433' }
                  }
                >
                  <Participant
                    addPoints={points => matchAdmin.addPoints(1, points)}
                    subtractPoints={points =>
                      matchAdmin.subtractPoints(1, points)
                    }
                    participantData={data.participant1}
                    cardColor={swapMatch ? '#2a59ce' : '#d04e8c'}
                    advantage={() => matchAdmin.advantage(1)}
                    disadvantage={() => matchAdmin.disadvantage(1)}
                    penalize={() => matchAdmin.penalize(1)}
                    depenalize={() => matchAdmin.depenalize(1)}
                    declareWinner={by => matchAdmin.declareWinner(1, by)}
                    scores={participant1}
                    isStarted={isStarted}
                    isEnded={isEnded}
                    isWon={isWon}
                  />
                </Row>
                <Row
                  className="justify-content-between flex-1 m-0"
                  style={
                    swapMatch
                      ? { backgroundColor: '#402433' }
                      : { backgroundColor: '#2a2764' }
                  }
                >
                  <Participant
                    addPoints={points => matchAdmin.addPoints(2, points)}
                    subtractPoints={points =>
                      matchAdmin.subtractPoints(2, points)
                    }
                    participantData={data.participant2}
                    advantage={() => matchAdmin.advantage(2)}
                    disadvantage={() => matchAdmin.disadvantage(2)}
                    penalize={() => matchAdmin.penalize(2)}
                    depenalize={() => matchAdmin.depenalize(2)}
                    declareWinner={by => matchAdmin.declareWinner(2, by)}
                    cardColor={swapMatch ? '#d04e8c' : '#2a59ce'}
                    scores={participant2}
                    isStarted={isStarted}
                    isEnded={isEnded}
                    isWon={isWon}
                  />
                </Row>
              </Row>
              <Row className="justify-content-between align-items-start flex-1 m-0">
                <Footer
                  data={data}
                  time={time}
                  isStarted={isStarted}
                  onStart={matchAdmin.startMatch}
                  onEnd={matchAdmin.endMatch}
                  onBack={onBack}
                  onFinalBack={onFinalBack}
                  isEnded={isEnded}
                  isWon={isWon}
                  isReady={isReady}
                  isStopped={isStopped}
                  onResume={() => matchAdmin.resumeMatch(time)}
                  onStop={() => matchAdmin.stopMatch(time)}
                  onSave={onSave}
                  proceedTimer={matchAdmin.addToTimer}
                  penalizeTimer={matchAdmin.subtractFromTimer}
                />
              </Row>
            </>
          )}
        </div>
      </Section>
    </>
  );
};

export default ScoreCardDefault;
