/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Profile from 'components/dashboards/fighter/profile';
import Edit from 'components/dashboards/user/edit';
import ProtectedRoute from '../ProtectedRoute';
import MatchResult from 'components/dashboards/match-result';
import Matches from 'components/dashboards/fighter/matches';
import EventList from 'components/dashboards/events/event-list/EventList';
import EventDetail from 'components/dashboards/events/event-detail/EventDetail';
import EntryDetail from 'components/dashboards/events/entry-detail/EntryDetail';
import FighterEventList from 'components/dashboards/fighter/events/Fighter_EventList';
import SingleElimination from 'components/dashboards/brackets/SingleElimination';
import MatScheduleDetail from 'components/dashboards/events/event-detail/MatScheduleDetail';
import ActivityList from 'components/dashboards/activities/ActivityList';

const FighterRoutes = () => {

  return(
    <Switch>
      <ProtectedRoute path="/dashboard/profile" exact component={Profile} />
      <ProtectedRoute path="/dashboard/user/edit/:uuid" exact component={Edit} />
      <ProtectedRoute
        path="/dashboard/user-matches/:academyId/:userId"
        exact
        component={Matches}
      />
      <ProtectedRoute
        path="/dashboard/match-result/:academyId/:matchid"
        exact
        component={MatchResult}
      />
      <ProtectedRoute path="/dashboard/activities" exact component={ActivityList} />
      <ProtectedRoute path="/dashboard/events" exact component={FighterEventList} />
      <ProtectedRoute path="/dashboard/events/event-detail/:academyId/:id" exact component={EventDetail} />
      <ProtectedRoute path="/dashboard/events/entry-detail/:status" exact component={EntryDetail} />
      <ProtectedRoute path="/dashboard/events/mat/:academyId/:id/:matId" exact component={MatScheduleDetail} />
      <ProtectedRoute
        path="/dashboard/brackets/:academyId/:eventID/:bracketUUID"
        exact
        component={SingleElimination}
      />
      <Redirect to="/errors/404" />
    </Switch>
  );
}
export default FighterRoutes;
